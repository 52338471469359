import { useState } from 'react';

import { IndeterminateCheckbox, SvgIcon } from 'common-ui';
import { Document } from 'features/pages/portfolio/DocumentLibrary/__generated__/Document';
import { TermsDocument } from 'features/pages/portfolio/DocumentLibrary/__generated__/TermsDocument';
import { styled } from 'style/ORSNNTheme';

const DocumentRow = styled.label`
  cursor: pointer;
  color: ${(props) => props.theme.color.slate200};
  padding: 4px;
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: rgba(232, 94, 229, 0.15);
  }
  gap: 8px;
`;

const DocumentTitle = styled.div`
  flex: 1 1 80%;
  textwrap: nowrap;
`;

const DocumentLink = styled.a`
  flex: 1 1 20%;
  padding: 0 8px;
  display: flex;
  color: ${(props) => props.theme.color.slate200};
  text-decoration: none;
`;

const LinkText = styled.span`
  padding-left: 8px;
  color: ${(props) => props.theme.color.accentDefault};
`;

interface GridProps {
  numColumns: number;
}
const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.numColumns}, 1fr);
  gap: 10px;
`;

interface DocumentListProps {
  documents: (Document | TermsDocument)[] | undefined;
  selectedDocuments?: string[];
  onDocumentSelect?: (documentId: string) => void;
  onDocumentDeselect?: (documentId: string) => void;

  numColumns?: number;
}

const DocumentList = ({
  documents,
  selectedDocuments = [],
  onDocumentSelect,
  onDocumentDeselect,
  numColumns = 1,
}: DocumentListProps) => {
  const [selectedDocs, setSelectedDocs] = useState<string[]>(selectedDocuments);

  const handleDocumentSelect = (documentId: string) => {
    if (selectedDocs.includes(documentId)) {
      setSelectedDocs((prev) => prev.filter((id) => id !== documentId));
      onDocumentDeselect && onDocumentDeselect(documentId);
    } else {
      setSelectedDocs((prev) => [...prev, documentId]);
      onDocumentSelect && onDocumentSelect(documentId);
    }
  };

  return (
    <div>
      <Grid numColumns={numColumns}>
        {documents ? (
          documents.map((document) => {
            const isChecked = selectedDocs.includes(document.id);
            return (
              <DocumentRow key={document.id}>
                <IndeterminateCheckbox
                  checked={isChecked}
                  onChange={(_e) => {
                    handleDocumentSelect(document.id);
                  }}
                />
                <DocumentTitle>{document.name}</DocumentTitle>
                <DocumentLink
                  target="blank"
                  href={document.documentUrl}
                  download
                  onClick={(e) => e.stopPropagation()}
                >
                  <SvgIcon name="pdf" />
                  <LinkText>View</LinkText>
                </DocumentLink>
              </DocumentRow>
            );
          })
        ) : (
          <span>No documents</span>
        )}
      </Grid>
    </div>
  );
};

export default DocumentList;
