import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useRef,
  useState,
  VFC,
} from 'react';

import SVG from 'assets/svgs/svg';
import { Key, OFF } from 'models/BrowserConstants';
import styled from 'styled-components';

const Container = styled.div`
  height: max-content;
  border-radius: 4px;
  border: 1px solid #9e9e9e;
  background: #1f1f1f;
  display: flex;
  align-items: center;
`;

const Input = styled.textarea`
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  margin: 12px 0 12px 16px;
  height: max-content;
  resize: none;
`;

const Submit = styled.button`
  background-color: inherit;
  border: none;
  padding: 4px;
  margin: 0 8px;
  border-radius: 4px;
  &:focus {
    outline: 1px solid #d743bd;
  }
`;

interface TextInputProps {
  value: string;
  onChange: (newValue: string) => void;
  onSubmit: (message: string) => void;
  disabled?: boolean;
  placeholderText?: string;
}

const MAX_ROWS = 4;

export const TextInput: VFC<TextInputProps> = ({
  value,
  onChange,
  onSubmit,
  disabled,
  placeholderText,
}) => {
  const [rows, setRows] = useState(1);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const maybeIncrementRows = () => {
    setRows((r) => Math.min(r + 1, MAX_ROWS));
  };

  const handleSubmit = useCallback(() => {
    if (value === '') {
      return;
    }
    onSubmit(value);
    onChange('');
    setRows(1);
    inputRef.current?.focus();
  }, [onChange, onSubmit, value]);

  return (
    <Container>
      <Input
        disabled={disabled}
        ref={inputRef}
        placeholder={placeholderText ? placeholderText : 'Type to respond'}
        autoComplete={OFF}
        rows={rows}
        value={value}
        onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          switch (e.key) {
            case Key.Enter:
              if (e.shiftKey) {
                maybeIncrementRows();

                return;
              }
              e.preventDefault();
              handleSubmit();
              break;
            default:
          }
        }}
        onKeyUp={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          // Edge-case: With only one row visible, user types beyond
          // the width of the textarea, causing an inner scroll context.
          // Scrolling in a single row is painful so we'll increment the
          // rows attribute to make it nicer.
          if (rows === 1 && e.currentTarget.scrollHeight > 30) {
            maybeIncrementRows();
          }
        }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          onChange(e.target.value);
          if (e.target.value === '') {
            setRows(1);
          }
        }}
      ></Input>
      <Submit disabled={disabled} onClick={handleSubmit}>
        <SVG name="send" width="24px" height="24px" fill="#FFFFFF" />
      </Submit>
    </Container>
  );
};
