import React, { useEffect, useRef, useState } from 'react';

import { Spinner, Section } from 'common-ui';
import { typographyLabelLargePrimaryRegular } from 'common-ui/typography';
import { AddUserSetting } from 'features/drilldown/cashflows/__generated__/AddUserSetting';
import { GetUserSetting } from 'features/drilldown/cashflows/__generated__/GetUserSetting';
import Chart from 'features/drilldown/cashflows/Chart';
import MonthlyTable from 'features/drilldown/cashflows/MonthlyTable';
import SummaryTable from 'features/drilldown/cashflows/SummaryTable';
import { GetPortfolioSummary_user_company_performanceData } from 'features/pages/portfolio/__generated__/GetPortfolioSummary';
import { GetCashflowByPool_userCompanyListing_performance_summary as PoolCashflowPerfSummary } from 'features/pages/portfolio/CashFlowModeling/__generated__/GetCashflowByPool';
import { usePortfolioContext } from 'features/pages/portfolio/PortfolioContext';
import { styled } from 'style/ORSNNTheme';

import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';

import {
  DefaultAssumptionType,
  PrepaymentAssumptionType,
  RateType,
} from '__generated__/globalTypes';

import { CashFlowModelingInputs } from './CashFlowModelingInputs';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import { GetDealCashFlows_deal_performanceSummary } from './gql/__generated__/GetDealCashFlows';
import { GetPortfolioCashFlows_user_company_performanceSummary } from './gql/__generated__/GetPortfolioCashFlows';

const ChartContainer = styled.div`
  width: 100%;
  height: 318px;
  ${typographyLabelLargePrimaryRegular}
  margin-top: 20px;
`;

export const SectionHeader = styled.div`
  ${typographyLabelLargePrimaryRegular}
  color: #f2f2f2;
  margin: 20px 0px;
`;

const ADD_USER_SETTING = gql`
  mutation AddUserSetting($name: String!, $value: String!) {
    addUserSetting(name: $name, value: $value)
  }
`;

const GET_USER_SETTING = gql`
  query GetUserSetting($name: String!) {
    user {
      id
      setting(name: $name)
    }
  }
`;

type CashFlowDataDisplayProps = {
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  userInputs: CashFlowsValidValues;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
};

export type PerformanceSummary =
  | GetDealCashFlows_deal_performanceSummary
  | GetPortfolioCashFlows_user_company_performanceSummary
  | undefined;

export type useSummaryDataReturnValue = {
  loading: boolean;
  data: PerformanceSummary;
  error: ApolloError | undefined;
};

export type useSelectedPoolCashflowValue = {
  loading: boolean;
  data?: PoolCashflowPerfSummary;
  error: ApolloError | undefined;
};

export const CashFlowsDataDisplay = (
  props: CashFlowDataDisplayProps,
): JSX.Element => {
  const { setTotalFilteredPoolData } = usePortfolioContext();
  let loading = false;
  let data = null;
  let monthlyCashFlow = null;
  let totals = null;
  const filteredPoolDataRef = useRef<Record<
    keyof GetPortfolioSummary_user_company_performanceData,
    number
  > | null>(null);

  if (props.selectedPoolCashflow && props.selectedPoolId) {
    loading = props.selectedPoolCashflow(
      props.selectedPoolId,
      props.userInputs,
    )?.loading;
    data = props.selectedPoolCashflow(
      props.selectedPoolId,
      props.userInputs,
    )?.data;
    monthlyCashFlow = data?.cashFlows?.monthlyCashFlows;
    totals = data?.cashFlows?.totals;
    if (!loading && data) {
      const totalFiltered: Record<
        keyof GetPortfolioSummary_user_company_performanceData,
        number
      > = {
        averageAge: data.averageAge,
        averageMaturity: data.averageMaturity,
        dti: data.dti,
        fico: data.fico,
        ltv: data.ltv,
        netCoupon: data.netCoupon,
        unpaidBalance: data.unpaidBalance,
        loanCount: data.loanCount,
        averageBalance: data.averageBalance,
        originalBalance: data.originalBalance,
      };

      filteredPoolDataRef.current = totalFiltered;
    }
  } else {
    loading = props.useSummaryData(props.userInputs)?.loading;
    data = props.useSummaryData(props.userInputs)?.data;
    monthlyCashFlow = data?.cashFlows?.monthlyCashFlows;
    totals = data?.cashFlows?.totals;
  }

  useEffect(() => {
    if (!loading) {
      setTotalFilteredPoolData(filteredPoolDataRef.current);
    }

    if (!props.selectedPoolId) {
      setTotalFilteredPoolData(null);
    }
  }, [loading, props.selectedPoolId, setTotalFilteredPoolData]);

  return (
    <>
      <Spinner loading={loading} />
      <SectionHeader>Cashflow Projections</SectionHeader>
      <ChartContainer>
        <Chart cashFlowMonths={monthlyCashFlow} />
      </ChartContainer>
      <div className="mt-5 flex flex-nowrap gap-x-3">
        <div>
          <SectionHeader>Cashflow Matrix</SectionHeader>
          <SummaryTable
            cashFlowTotals={totals ?? null}
            performanceSummary={data ?? null}
          />
        </div>
        {monthlyCashFlow != null && (
          <MonthlyTable monthlyCashFlows={monthlyCashFlow} />
        )}
      </div>
    </>
  );
};

export type CashFlowsProps = {
  settingKey: string;
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
};

export const CashFlows = (props: CashFlowsProps): JSX.Element | null => {
  const [addUserSetting] = useMutation<AddUserSetting>(ADD_USER_SETTING);

  const { data, loading, error } = useQuery<GetUserSetting>(GET_USER_SETTING, {
    variables: { name: props.settingKey },
    fetchPolicy: 'network-only',
  });

  const [formValues, setFormValues] = useState<CashFlowsValidValues>({
    cdr: 0,
    cpr: 0,
    lsr: 0,
    daysDelay: 0,
    rateType: RateType.Sofr,
    defaultAssumptionType: DefaultAssumptionType.CDR,
    prepaymentAssumptionType: PrepaymentAssumptionType.CPR,
  });

  useEffect(() => {
    if (!loading && !error && data && data.user.setting) {
      try {
        const validatedFormValues: CashFlowsValidValues = JSON.parse(
          data.user.setting,
        );
        setFormValues(validatedFormValues);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.log(e);
        }
      }
    }
  }, [data, loading, error]);

  const handleSuccessfulSubmission = (
    validatedFormValues: CashFlowsValidValues,
  ) => {
    setFormValues(validatedFormValues);
    addUserSetting({
      variables: {
        name: props.settingKey,
        value: JSON.stringify(validatedFormValues),
      },
    });
  };

  return (
    <Section>
      <CashFlowsFormWithDataDisplay
        onSubmit={handleSuccessfulSubmission}
        formValues={formValues}
        useSummaryData={props.useSummaryData}
        selectedPoolId={props.selectedPoolId}
        selectedPoolCashflow={props.selectedPoolCashflow}
      />
    </Section>
  );
};

export type CashFlowsFormWithDataDisplayProps = {
  onSubmit: (validatedFormValues: CashFlowsValidValues) => void;
  formValues: CashFlowsValidValues;
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  withServicingRate?: boolean;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
};

export const CashFlowsFormWithDataDisplay: React.FC<
  CashFlowsFormWithDataDisplayProps
> = ({
  onSubmit,
  formValues,
  useSummaryData,
  withServicingRate,
  selectedPoolId,
  selectedPoolCashflow,
}) => {
  const formValuesWithDefaults = formValues || {
    cdr: 0,
    cpr: 0,
    lsr: 0,
    daysDelay: 0,
    servicingRate: 0,
    rateType: RateType.Sofr,
    defaultAssumptionType: 'CDR',
    prepaymentAssumptionType: 'CPR',
  };
  return (
    <>
      <div className="pt-4">
        <CashFlowModelingInputs
          formValues={formValuesWithDefaults}
          onSubmit={onSubmit}
          withServicingRate={withServicingRate}
        />
      </div>
      {formValues && (
        <CashFlowsDataDisplay
          useSummaryData={useSummaryData}
          userInputs={formValues}
          selectedPoolId={selectedPoolId}
          selectedPoolCashflow={selectedPoolCashflow}
        />
      )}
    </>
  );
};
