import { useState, useEffect } from 'react';

import classNames from 'classnames';

import './png-icon.scss';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extra-large',
};

interface IconProps {
  name: string;
  size?: (typeof SIZES)[keyof typeof SIZES];
}

export const PngIcon = ({ size = SIZES.medium, name }: IconProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../assets/pngs/${name}.png`); // change relative path to suit your needs
        setImageUrl(response.default);
      } catch (error) {
        console.error(`${error}: Icon with name: ${name} not found!`);
      }
    };

    fetchImage();

    return () => {
      setImageUrl('');
    };
  }, [name]);

  const classes = classNames('png-icon', `png-icon--${size}`);

  return <img className={classes} src={imageUrl} alt={name} />;
};
