import { FC } from 'react';

export const KPISkeleton: FC = () => {
  return (
    <div className="flex min-w-36 animate-pulse flex-col gap-1 rounded bg-kip-gradient px-2 py-2 text-center">
      <div className="mb-2 h-4 w-16 rounded bg-slate-700"></div>
      <div className="h-4 w-20 rounded bg-slate-700"></div>
    </div>
  );
};
