import { gql } from '@apollo/client';

import { GET_DEAL_DOCUMENTS_FRAGMENT } from 'query/deal';

const DealDocumentsCardFragments = {
  TimelineCard: gql`
    fragment DealDocumentsCard on TimelineCard {
      ... on DealDocumentsCard {
        can_upload
        documents {
          id
          ...DocumentFields
        }
      }
    }
    ${GET_DEAL_DOCUMENTS_FRAGMENT}
  `,
};

export default DealDocumentsCardFragments;
