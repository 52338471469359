import { useMemo, useState } from 'react';

import { RowSelectionState } from '@tanstack/react-table';
import { DataDisplayTable, Spinner } from 'common-ui';
import { NETWORK_ERROR } from 'configs/messages';
import { DealCardFragments } from 'features/deals/cards/DealCard';
import { ListingCardFragments } from 'features/deals/cards/ListingCard';
import { isNotNullOrUndefined } from 'functions/typeUtils';
import { useTheme } from 'styled-components';

import {
  ApolloError,
  gql,
  NetworkStatus,
  OperationVariables,
  QueryResult,
  useQuery,
} from '@apollo/client';

import { DealBlocker, DealStatus } from '__generated__/globalTypes';

import { GetDeals } from './__generated__/GetDeals';
import {
  GetSellerListings,
  GetSellerListings_user_company_listings_UserCompanyListing as Listing,
  GetSellerListings_user_company_listings_UserCompanyListing_deals as Deal,
} from './__generated__/GetSellerListings';
import { activeListingsColumnDefinitions } from './ActiveListingsTable.config';

type DealWithRole = Deal & { role: DealBlocker };

const ListingFragment = gql`
  fragment ListingFragment on Listing {
    id
    loan_count
    wa_borrower_credit_score
    wa_coupon
    wa_loan_age_months
    wa_ltv
    wa_remaining_loan_terms_months
    ...ListingCardListing
  }
  ${ListingCardFragments.listing}
`;

const GET_SELLER_LISTINGS_QUERY = gql`
  query GetSellerListings {
    user {
      id
      company {
        id
        listings {
          id
          ...ListingFragment
          ... on UserCompanyListing {
            is_public
            deals {
              id
              counterparty {
                ...DealCardCounterparty
              }
              ...DealCardDeal
            }
          }
        }
      }
    }
  }
  ${ListingFragment}
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingCardFragments.listing}
`;

const GET_DEALS_QUERY = gql`
  query GetDeals {
    user {
      id
      company {
        id
        buyingDeals: deals(role: BUYER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            id
            ...ListingFragment
          }
        }
        sellingDeals: deals(role: SELLER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            id
            ...ListingFragment
          }
        }
      }
    }
  }
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingFragment}
`;

export const ErrorMessage = (props: {
  err: ApolloError | undefined;
}): JSX.Element | null => {
  if (props.err) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Error!', props.err);
    }
    return <div className="flex justify-center">{NETWORK_ERROR}</div>;
  }
  return null;
};

type Props = {
  gqlDeals: QueryResult<GetDeals, OperationVariables>;
};

const ActiveListings = (props: Props): JSX.Element => {
  const gql = useQuery<GetSellerListings>(GET_SELLER_LISTINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { gqlDeals } = props;

  // invert the map of deals to listings to match the listing - subdata(deal) structure
  // TODO we likely want to only show rows, or always expand rows since buyer would only ever have one deal per listing
  const buyingListings = useMemo(() => {
    const listings: Record<string, Listing> = {};
    (gqlDeals.data?.user.company.buyingDeals ?? [])
      .filter((deal) => deal.state.status !== DealStatus.COMPLETE)
      .filter(isNotNullOrUndefined)
      .forEach(
        (deal) => {
          const currentListing = listings[deal.listing.id];
          const currentDealsForListing = currentListing?.deals ?? [];
          listings[deal.listing.id] = {
            ...currentListing,
            ...deal.listing,
            deals: [
              ...currentDealsForListing,
              { ...deal, role: DealBlocker.BUYER } as DealWithRole,
            ],
          };
        },
        {} as Record<string, Listing>,
      );
    return Object.values(listings).reduce(
      (previous, listing) => [
        ...previous,
        { ...listing, subData: listing.deals },
      ],
      new Array<Listing>(),
    );
  }, [gqlDeals.data]);

  const listingsToShow = useMemo(
    () =>
      gql.data?.user.company.listings
        .map((listing) => listing as Listing)
        .filter((listing) => listing.is_public)
        .map((listing) => {
          return {
            ...listing,
            deals:
              listing.deals &&
              listing.deals
                .filter((deal) => DealStatus.COMPLETE !== deal.state.status)
                .map((deal) => ({ ...deal, role: DealBlocker.SELLER })),
            subData:
              listing.deals &&
              listing.deals
                .filter((deal) => DealStatus.COMPLETE !== deal.state.status)
                .map((deal) => ({ ...deal, role: DealBlocker.SELLER })),
          };
        }) ?? [],
    [gql.data],
  );

  const [selectedListings, setSelectedListings] = useState<RowSelectionState>(
    {},
  );
  const theme = useTheme();

  return (
    <>
      <ErrorMessage err={gql.error} />
      <DataDisplayTable
        // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
        data={[...listingsToShow, ...buyingListings]}
        // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
        columns={activeListingsColumnDefinitions}
        noDataMessage="Nothing to Show. Check the marketplace for loan pools, or publish your own."
        selection={{
          selected: selectedListings,
          onSelectionChange: setSelectedListings,
        }}
        stylingOptions={{
          alternateLightRows: false,
          subRowColor: theme.color.black,
          rowColor: theme.color.gray900,
        }}
        downloadCSV={true}
      />
      <Spinner
        loading={gql.loading || gql.networkStatus === NetworkStatus.loading}
      />
    </>
  );
};

const InProgressOverview = (): JSX.Element => {
  const gqlDeals = useQuery<GetDeals>(GET_DEALS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  return (
    <div className="page mx-4">
      <ActiveListings gqlDeals={gqlDeals} />
    </div>
  );
};

export { InProgressOverview };
