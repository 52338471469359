import { FC } from 'react';

export const DocumentPreviewSkeleton: FC = () => {
  return (
    <div className="flex h-full w-full animate-pulse flex-col items-center justify-center rounded bg-slate-800">
      <div className="mb-4 h-6 w-3/4 animate-pulse rounded bg-slate-700"></div>
      <div className="mb-4 h-6 w-2/3 animate-pulse rounded bg-slate-700"></div>
      <div className="h-6 w-1/2 animate-pulse rounded bg-slate-700"></div>
    </div>
  );
};
