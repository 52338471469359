import { ForwardedRef, forwardRef } from 'react';

import { getAttrToShowTooltip } from 'common-ui/tooltip/generalTooltip';
import { truncate } from 'lodash';
import { styled } from 'style/ORSNNTheme';

import { PillButton, PillButtonProps } from '../Buttons/PillButton/PillButton';

export type ComboDropdownValuePillProps = {
  description: string;
  iconName?: string;
  valueElipses?: number;
  iconOnClick?: () => void;
};

const StyledPillButton = styled(PillButton)`
  width: fit-content;
  outline: none;
  border: ${({ isActive, isFocused, theme }) =>
    isActive || isFocused ? `solid 1px ${theme.color.accentEmphasis}` : 'none'};
  &:focus {
    border: solid 1px ${({ theme }) => theme.color.accentEmphasis};
  }
`;

export const ComboDropdownValuePill = forwardRef(
  (
    {
      valueElipses,
      value,
      ...props
    }: PillButtonProps & { valueElipses?: number },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const tooltipProps = valueElipses
      ? getAttrToShowTooltip({ content: value || '' })
      : {};

    const truncatedValue = valueElipses
      ? truncate(value, { length: valueElipses })
      : value;
    return (
      <StyledPillButton
        ref={ref}
        {...props}
        {...tooltipProps}
        value={truncatedValue}
      />
    );
  },
);
