import DealStagesFragments from 'features/deals/DealStages/fragments';
import { PoolHeaderFragments } from 'features/loanPool/PoolHeader';
import { StatusTrackerFragments } from 'features/pages/marketplace/StatusTracker';

import { gql } from '@apollo/client';

import { CounterpartyBasicInfoFragment } from '../CounterpartyCard';

export const GET_DEAL_GENERAL_INFO_QUERY = gql`
  query GetDealGeneralInfo($id: ID!) {
    deal(id: $id) {
      dealId: id
      is_chat_enabled
      role
      counterparty {
        ...CounterpartyBasicInfo
      }
      carvingEnabled
      createdDate: created_date
      listing {
        id
        assetClass: asset_class
        ...PoolHeaderListing
      }
      ...DealStagesDeal
      ...StatusTrackerDeal
    }
  }
  ${CounterpartyBasicInfoFragment.company}
  ${PoolHeaderFragments.listing}
  ${StatusTrackerFragments.deal}
  ${DealStagesFragments.Deal}
`;
