import React from 'react';

import {
  Explanatory,
  StandardForm,
  Subheader,
  Header,
  SubmitButton,
  FieldsGrid,
  Label,
  FormikDropdownField,
  FormikTextInput,
} from 'common-ui/form/StandardForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

export interface FormValues {
  poolName: string;
  originationType: string;
}

const validationSchema = Yup.object().shape({
  poolName: Yup.string().required('Pool name is required'),
  originationType: Yup.string().required('Origination type is required'),
});

export interface CreatePoolFormProps {
  createPool: (formValues: FormValues) => void;
  closeModal: () => void;
}

const originationTypeOptions = [
  {
    label: 'Direct',
    value: 'direct',
  },
  {
    label: 'Indirect',
    value: 'indirect',
  },
  {
    label: 'Indirect: Fintech',
    value: 'indirect_fintech',
  },
  {
    label: 'Mixed',
    value: 'mixed',
  },
  {
    label: 'Non Applicable',
    value: 'n_a',
  },
];

const initialValues = {
  poolName: '',
  originationType: originationTypeOptions[0].value,
};

const CreatePoolForm: React.FC<CreatePoolFormProps> = ({
  createPool,
  closeModal,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        createPool(values);
        closeModal();
      }}
    >
      <StandardForm closeModal={closeModal}>
        <Header>Create A Pool</Header>
        <Subheader>POOL CREATION</Subheader>
        <Explanatory>Name the selected carve and save your Pool.</Explanatory>

        <FieldsGrid>
          <Label htmlFor="poolName">Create A Pool Name</Label>
          <FormikTextInput name="poolName" placeholder="Enter Name..." />

          <Label htmlFor="originationType">Select Origination Type</Label>
          <FormikDropdownField
            name="originationType"
            defaultValue={originationTypeOptions[0]}
            isMulti={false}
            options={originationTypeOptions}
          />
        </FieldsGrid>

        <SubmitButton label="Create Pool" />
      </StandardForm>
    </Formik>
  );
};

export default CreatePoolForm;
