import { useApolloClient } from '@apollo/client';

import { AssetClass, FileType, ProductType } from '__generated__/globalTypes';

import {
  GetFileUuid,
  GetFileUuidVariables,
} from 'query/__generated__/GetFileUuid';
import { GET_FILE_UUID } from 'query/getFileUuid';

const useFileUuid = () => {
  const client = useApolloClient();

  const fetchFileUuid = async (
    file: File,
    fileType: FileType,
    versionId?: string,
    parentId?: string,
    assetClass?: AssetClass,
    productType?: ProductType,
  ): Promise<string> => {
    try {
      const { data, errors } = await client.query<
        GetFileUuid,
        GetFileUuidVariables
      >({
        query: GET_FILE_UUID,
        variables: {
          input: {
            parentId: parentId,
            fileType,
            fileName: file.name,
            s3VersionId: versionId || '',
            assetClass: assetClass,
            productType: productType,
          },
        },
      });

      if (errors) {
        console.warn('Apollo errors occurred:', errors);
      }

      if (data.getFileUuid === null) {
        throw new Error('File UUID is null');
      }

      return data.getFileUuid;
    } catch (error) {
      console.error('Failed to fetch File UUID:', error);
      throw error;
    }
  };

  return { fetchFileUuid };
};

export default useFileUuid;
