import { useState } from 'react';

import { Spinner } from 'common-ui';
import { StratKey } from 'features/drilldown/Stratifications/startifications.config';
import { StratCard } from 'features/drilldown/Stratifications/StratCard';
import { StratsSelection } from 'features/drilldown/Stratifications/StratsSelection';
import { styled } from 'style/ORSNNTheme';

import { gql, useQuery } from '@apollo/client';

import { AssetClass } from '__generated__/globalTypes';

import {
  GetDealStrats,
  GetDealStratsVariables,
} from './gql/__generated__/GetDealStrats';
import { GET_DEAL_STRATS_QUERY } from './gql/inquiryQueries';

const StratCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;

  & > * {
    flex: 1 500px;
    max-height: 550px;
  }
`;

const StratsCardFragments = {
  TimelineCard: gql`
    fragment StratsCard on TimelineCard {
      ... on StratificationsCard {
        assetClass: asset_class
      }
    }
  `,
};

const useStratCard = (props: { dealId: string; assetClass: string }) => {
  const { data, loading } = useQuery<GetDealStrats, GetDealStratsVariables>(
    GET_DEAL_STRATS_QUERY,
    {
      variables: {
        id: props.dealId,
      },
    },
  );

  const [strats, setStrats] = useState<StratKey[]>([]);

  const performance = data?.deal?.performanceData;

  return {
    strats,
    setStrats,
    performance,
    loading,
  };
};

const StratCardBody = (props: { dealId: string; assetClass: AssetClass }) => {
  const { strats, setStrats, performance, loading } = useStratCard(props);

  const stratsBlock = performance ? (
    <>
      <StratsSelection
        selectedStrats={strats}
        onSelectedStratsChange={setStrats}
      />
      <StratCardsContainer>
        {strats.map((strat) => (
          <StratCard
            key={strat}
            strat={strat}
            assetClass={props.assetClass}
            summary={performance}
            avgSummary={performance}
          />
        ))}
      </StratCardsContainer>
    </>
  ) : null;

  return (
    <>
      {stratsBlock}
      <Spinner loading={loading} positionRelative={true} />
    </>
  );
};

export { StratCardBody, StratsCardFragments };
