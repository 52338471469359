import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import './input.scss';

interface PassedProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input = forwardRef(
  (props: PassedProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <>
        {props.label ? (
          <label className="input__label" htmlFor={props.name}>
            {props.label}
          </label>
        ) : null}
        <input
          {...props}
          ref={ref}
          id={props.name}
          className="input__field"
          aria-label={props.label}
          data-testid={props.name}
          tabIndex={0}
        />
      </>
    );
  },
);

export default Input;
