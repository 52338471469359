import { gql } from '@apollo/client';

const DocumentDetailsFragments = {
  document: gql`
    fragment Document on Document {
      __typename
      id
      name
      uploadedAt
      uploadedBy {
        given_name
        family_name
      }
      documentUrl
      documentSizeBytes
      is_template
    }
  `,
  termsDocument: gql`
    fragment TermsDocument on TermsDocument {
      __typename
      id
      name
      uploadedAt
      uploadedBy {
        given_name
        family_name
      }
      documentUrl
      documentSizeBytes
      is_template
    }
  `,
};

export { DocumentDetailsFragments };
