import { Dispatch, SetStateAction, useState } from 'react';

import { FIELD_CONFIG, formatPercent } from 'configs/columns';
import { styled, theme } from 'style/ORSNNTheme';
import { useDebounce } from 'use-debounce';

import { LoanField } from '__generated__/globalTypes';

function isNotNull<T>(action: T | null): action is T {
  return action != null;
}

const List = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margins: 0;
`;

const Column = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const SubjectText = styled.span`
  color: ${(props) => props.theme.color.gray500};
`;

const StyledLabelBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ListItemStyleProps = {
  overrides: {
    Root: {
      style: {
        backgroundColor: theme.color.cardBackground,
        maxHeight: '40px',
      },
    },
  },
};

function useDebouncedState<S>(
  initialState: S | (() => S),
  debounceDelay: number,
  debounceOptions?: {
    maxWait?: number;
    leading?: boolean;
    trailing?: boolean;
    equalityFn?: (left: S, right: S) => boolean;
  },
): [S, S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState<S>(initialState);
  const [dValue] = useDebounce(value, debounceDelay, debounceOptions);

  return [value, dValue, setValue];
}

const percentToBasisPoints = (s: string) => Math.round((+s / 100 - 1) * 10000);
const basisPointsToPercent = (n: number | null) =>
  n || n === 0 ? ((n / 10000 + 1) * 100).toFixed(3) : '';

type FormatCalculatedPriceInput = {
  currentBalanceCents?: number | null;
  priceCents?: number | null;
  rate?: number | null;
};
type FormattedPrice = {
  currentBalance: string;
  price: string;
  rate: string;
};
function formatCalculatedPrice(
  input: FormatCalculatedPriceInput,
): FormattedPrice {
  const roundedPrice =
    input?.priceCents != null ? Math.floor(input.priceCents) : null;
  const config = FIELD_CONFIG[LoanField.current_balance_cents];
  return {
    currentBalance: config.display(input.currentBalanceCents),
    price: config.display(roundedPrice),
    rate: input.rate != null ? formatPercent(input.rate, 3) : '-',
  };
}

function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number) => boolean,
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l)) return l;
  }
  return -1;
}

export {
  basisPointsToPercent,
  Column,
  findLastIndex,
  formatCalculatedPrice,
  isNotNull,
  List,
  ListItemStyleProps,
  percentToBasisPoints,
  StyledLabelBody,
  SubjectText,
  useDebouncedState,
};
