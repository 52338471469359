import { useContext } from 'react';

import Header from 'app-level/HeaderNav/Header/Header';
import {
  getMainRoute,
  useGetMainPageUrl,
  NavigationStateProvider,
} from 'app-level/navigation';
import { StyledTooltip } from 'common-ui';
import Account from 'features/pages/account/Account';
import { HomePage } from 'features/pages/home/index.page';
import { MarketDataPage } from 'features/pages/market-data/index.page';
import Marketplace from 'features/pages/marketplace/Marketplace';
import Portfolio from 'features/pages/portfolio/Portfolio/Portfolio';
import { PortfolioContextProvider } from 'features/pages/portfolio/PortfolioContext';
import { Toaster } from 'react-hot-toast';
import { Navigate, Route, Routes } from 'react-router-dom';
import BaseWeb from 'style/BaseWeb';
import 'style/main.css';
import 'style/main.scss'; // TODO: Remove once we migrate from SCSS to tailwindcss
import ORSNNTheme, { theme } from 'style/ORSNNTheme';

import { AuthContext, AuthState } from './context/AuthContext';
import { DataContextProvider } from './context/DataContext';
import UserAuthentication from './features/UserAuthentication';

const App = (): JSX.Element => {
  const { state } = useContext(AuthContext);

  const isUserSignedIn =
    [AuthState.SignedIn].includes(state.authState) && state.user != null;

  const getUrl = useGetMainPageUrl();

  const authenticatedApp = (
    <NavigationStateProvider>
      <div className="flex h-full flex-col">
        <Toaster
          toastOptions={{
            className: '',
            style: {
              border: '1px solid #731172',
              borderRadius: '6px',
              padding: '16px',
              color: '#ffffff',
              backgroundColor: '#1f1f1f',
            },
          }}
        />
        <Header />
        <div className="page-container grid flex-1 grid-cols-[min-content_12px_1fr_24px] grid-rows-[min-content_1fr] overflow-auto">
          <DataContextProvider>
            <PortfolioContextProvider>
              <Routes>
                <Route
                  path={getMainRoute('portfolio')}
                  element={<Portfolio />}
                />
                <Route
                  path={getMainRoute('marketplace')}
                  element={<Marketplace />}
                />
                <Route path={getMainRoute('account')} element={<Account />} />
                <Route path={'market-data'} element={<MarketDataPage />} />
                <Route path={getMainRoute('home')} element={<HomePage />} />
                <Route
                  path="*"
                  element={<Navigate to={getUrl('home')} replace />}
                />
              </Routes>
            </PortfolioContextProvider>
          </DataContextProvider>
        </div>
      </div>
    </NavigationStateProvider>
  );
  return (
    <div
      className="m-0 h-full text-[13px] font-normal leading-6 tracking-[0.88px] transition-all duration-300"
      style={{
        backgroundColor: theme.color.bgApp,
        color: theme.color.fgDefault,
      }}
    >
      <ORSNNTheme>
        <BaseWeb>
          {isUserSignedIn ? authenticatedApp : <UserAuthentication />}
          <StyledTooltip id="tooltip" />
        </BaseWeb>
      </ORSNNTheme>
    </div>
  );
};

export default App;
