import { gql } from '@apollo/client';

const GET_COLLATERAL_NOTES = gql`
  query GetCollateralNotes(
    $dealID: ID!
    $loanID: ID
    $fileName: String
    $limit: Int
    $paginationKey: String
  ) {
    collateralNotes(
      input: {
        dealID: $dealID
        loanID: $loanID
        fileName: $fileName
        limit: $limit
        paginationKey: $paginationKey
      }
    ) {
      files {
        file {
          PK
          SK
          loanID
          dealID
          fileName
          TTL
          s3Key
          ZipFileName
          collateralStatus
          collateralID
        }
        notes {
          noteID
          dealID
          loanID
          fileName
          note
          authorID
          authorRole
          buyerCompanyID
          sellerCompanyID
          createdAt
        }
      }
      nextPaginationKey
    }
  }
`;

const GET_MULTIPLE_COLLATERAL_FILES_METADATA = gql`
  query GetMultipleCollateralFilesMetadata(
    $input: MultipleCollateralFilesMetadataInput!
  ) {
    getMultipleCollateralFilesMetadata(input: $input) {
      files {
        PK
        SK
        loanID
        dealID
        fileName
        TTL
        s3Key
        ZipFileName
        collateralStatus
        collateralID
      }
    }
  }
`;

const GET_SINGLE_COLLATERAL_FILE_METADATA = gql`
  query GetSingleCollateralFileMetadata(
    $input: SingleCollateralFileMetadataInput!
  ) {
    getSingleCollateralFileMetadata(input: $input) {
      file {
        PK
        SK
        loanID
        dealID
        fileName
        TTL
        s3Key
        ZipFileName
        collateralStatus
        collateralID
      }
    }
  }
`;

const GET_COLLATERAL_FILE_DOWNLOAD_URL = gql`
  query GetCollateralFileDownloadURL($input: CollateralFileDownloadURLInput!) {
    getCollateralFileDownloadURL(input: $input) {
      URL
    }
  }
`;

export {
  GET_COLLATERAL_NOTES,
  GET_MULTIPLE_COLLATERAL_FILES_METADATA,
  GET_SINGLE_COLLATERAL_FILE_METADATA,
  GET_COLLATERAL_FILE_DOWNLOAD_URL,
};
