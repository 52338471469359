import { gql } from '@apollo/client';

export const DiligenceCardFragments = {
  TimelineCard: gql`
    fragment DiligenceCard on TimelineCard {
      ... on DiligenceCard {
        assetClass: asset_class
        role
        is_complete
        buyer_accepted
        seller_accepted
        should_show_summaries_tab
        original_pool_summary {
          current_balance_cents
          original_balance_cents
          loan_count
          wa_age_months
          wa_remaining_loan_terms_months
          wa_coupon
          wa_borrower_credit_score
          wa_ltv
          wa_dti
        }
        accepted_bid_summary {
          current_balance_cents
          original_balance_cents
          loan_count
          wa_age_months
          wa_remaining_loan_terms_months
          wa_coupon
          wa_borrower_credit_score
          wa_ltv
          wa_dti
        }
        post_diligence_summary {
          current_balance_cents
          original_balance_cents
          loan_count
          wa_age_months
          wa_remaining_loan_terms_months
          wa_coupon
          wa_borrower_credit_score
          wa_ltv
          wa_dti
        }
        diligence_reports {
          rejected_loans_xlsx_url
          rejected_loans_csv_url
          post_diligence_loans_xlsx_url
          post_diligence_loans_csv_url
        }
        deal {
          id
          sellerAcceptedDiligence: seller_accepted_diligence
          buyerAcceptedDiligence: buyer_accepted_diligence
          listing {
            id
            name
            provided_name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  `,
};
