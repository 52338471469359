import { useEffect, useMemo } from 'react';

import { Spinner } from 'baseui/spinner';
import StepLabel from 'ui-kit/StepLabel/StepLabel';

import { useQuery } from '@apollo/client';

import { GetUploadedFilesWithHeaders } from 'query/__generated__/GetUploadedFilesWithHeaders';
import { GET_UPLOADED_FILES_WITH_HEADERS } from 'query/getUploadedFilesWithHeaders';

import TapesUploadedTable, { InProgressFile } from './TapesUploadedTable';
import {
  SectionHeader,
  TapesUploadedPanel,
} from '../loanTapeUploader/LoanTapeUploader.styles';

type UploadHistoryProps = {
  uploadsInProgress: InProgressFile[];
};

const UploadHistory = (props: UploadHistoryProps) => {
  const { data, loading, error, startPolling, stopPolling } =
    useQuery<GetUploadedFilesWithHeaders>(GET_UPLOADED_FILES_WITH_HEADERS, {
      fetchPolicy: 'cache-and-network',
      pollInterval: 2000,
    });

  useEffect(() => {
    startPolling(2000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const loanTapeIds = useMemo(
    () =>
      data?.user.company.loan_tapes.map((loanTape) => loanTape.original_id) ||
      [],
    [data],
  );

  return (
    <TapesUploadedPanel>
      <>
        <SectionHeader>
          TAPES UPLOADED {loading && <Spinner size={12} />}
        </SectionHeader>
        {loanTapeIds.length > 0 && (
          <div className="mb-2 flex items-center">
            <StepLabel text="NEXT:" />
            <span className="ml-3 text-xl">Select a tape get started</span>
          </div>
        )}
        <TapesUploadedTable
          inProgressFiles={props.uploadsInProgress.filter(
            (file) => file.id == null || !loanTapeIds?.includes(file.id),
          )}
          tableRows={data?.user.company.loan_tapes}
          error={error}
        />
      </>
    </TapesUploadedPanel>
  );
};

export type { InProgressFile, UploadHistoryProps };

export default UploadHistory;
