import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { FileState } from '__generated__/globalTypes';

import { useIngestFile } from './hooks/useIngestFile';
import { InProgressOverlay } from './InProgressOverlay';
import { useTapeUploadContext } from './loanTapeUploader/TapeUploadContext';

interface FileIngestOperationContextProps {
  startFileIngest: (fileId: string) => void;
}

const FileIngestOperationContext =
  createContext<FileIngestOperationContextProps | null>(null);

interface FileIngestOperationWrapperProps {
  fileState: FileState;
  isStarted: boolean;
  setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * HACK (kentskinner)
 * Emergency fix for RC-1. Delete either this or FileIngestOperationProvider soon.
 */
export const FileIngestOperationWrapper: React.FC<
  FileIngestOperationWrapperProps
> = ({ fileState, isStarted, setIsStarted }) => {
  const [progress, setProgress] = useState<number>(0);
  const [description, setDescription] = useState<string>('Initializing...');
  const [buttonText, setButtonText] = useState<string>('Cancel');

  useEffect(() => {
    switch (fileState) {
      case FileState.PROCESSED:
        setProgress(0);
        setDescription('Starting...');
        setButtonText('Cancel');
        break;

      case FileState.READY_TO_PERSIST:
        setProgress(10);
        setDescription('Saving...');
        setButtonText('Cancel');
        break;

      case FileState.PERSISTING:
        setProgress(30);
        setDescription('Saving...');
        setButtonText('Cancel');
        break;

      case FileState.PERSISTED:
        setProgress(100);
        setDescription('File successfully saved.');
        setButtonText('OK');
        break;
    }
  }, [fileState]);

  return (
    <>
      <FileIngestOperation
        isStarted={isStarted}
        setIsStarted={setIsStarted}
        progress={progress}
        description={description}
        buttonText={buttonText}
      />
    </>
  );
};

const FileIngestOperationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { selectedFile } = useTapeUploadContext();
  const { ingestFile } = useIngestFile();
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [description, setDescription] = useState<string>('Initializing...');
  const [buttonText, setButtonText] = useState<string>('Cancel');

  useEffect(() => {
    if (selectedFile) {
      switch (selectedFile.state) {
        case FileState.PROCESSED:
          setProgress(0);
          setDescription('Starting...');
          setButtonText('Cancel');
          break;

        case FileState.READY_TO_PERSIST:
          setProgress(10);
          setDescription('Saving...');
          setButtonText('Cancel');
          break;

        case FileState.PERSISTING:
          setProgress(30);
          setDescription('Saving...');
          setButtonText('Cancel');
          break;

        case FileState.PERSISTED:
          setProgress(100);
          setDescription('File successfully saved.');
          setButtonText('OK');
          break;
      }
    }
  }, [selectedFile]);

  const startFileIngest = async (fileId: string) => {
    setIsStarted(true);

    // TODO(kentskinner): handle error.
    await ingestFile(fileId);
  };

  return (
    <FileIngestOperationContext.Provider value={{ startFileIngest }}>
      {children}
      <FileIngestOperation
        isStarted={isStarted}
        setIsStarted={setIsStarted}
        progress={progress}
        description={description}
        buttonText={buttonText}
      />
    </FileIngestOperationContext.Provider>
  );
};

const useFileIngestOperation = () => {
  const context = useContext(FileIngestOperationContext);
  if (!context) {
    throw new Error(
      'useFileIngestOperation must be used within FileIngestOperationProvider',
    );
  }
  return context;
};

const FileIngestOperation: React.FC<{
  isStarted: boolean;
  setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
  progress: number;
  description: string;
  buttonText: string;
}> = ({ isStarted, setIsStarted, progress, description, buttonText }) => {
  const navigate = useNavigate();
  const navigateToMyPortfolio = () => {
    navigate('/portfolio/dashboard');
  };

  return (
    <InProgressOverlay
      title="Ingesting file"
      description={description}
      open={isStarted}
      cancel={() => setIsStarted(false)}
      onSuccessClicked={() => {
        setIsStarted(false);
        navigateToMyPortfolio();
      }}
      progress={progress}
      cancelButtonText={buttonText}
      successButtonText={'Saved. Go to My Portfolio.'}
      isSuccess={progress === 100}
    />
  );
};

export { FileIngestOperationProvider, useFileIngestOperation };
