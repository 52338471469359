export type URLMap = {
  'macro_charts_data.json': string;
  'market_charts_data.json': string;
  'yc_chart_data.json': string;
  'mortgage_credit_curve_chart_data.json': string;
  'home_page_treasury_yields_chart_data.json': string;
  'home_page_sofr_yields_chart_data.json': string;
  'sofr_yc_chart_data.json': string;
} | null;

export enum QUERY_KEYS {
  UST_DATA = 'UST_DATA',
  CORPORATE_SPREADS = 'CORPORATE_SPREADS',
  UST_YIELD_CURVE = 'UST_YIELD_CURVE',
  SOFR_YIELD_CURVE = 'SOFR_YIELD_CURVE',
  CONSUMER_LOAN_SPREADS = 'CONSUMER_LOAN_SPREADS',
  CONSUMER_SECURED_LOAN_SPREADS = 'CONSUMER_SECURED_LOAN_SPREADS_LOAN_SPREADS',
  CONSUMER_UNSECURED_LOAN_SPREADS = 'CONSUMER_UNSECURED_LOAN_SPREADS',
  MACRO_CHART = 'MACRO_CHART',
  MORTGAGE_CREDIT_CURVE = 'MORTGAGE_CREDIT_CURVE',
  TREASURY_YIELDS = 'TREASURY_YIELDS',
  SOFR_YIELDS = 'SOFR_YIELDS',
}

export const chartColors = ['#e75ee4', '#3ccece', '#8490ec'];
export const chartGradientDataUri = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='620' height='382' viewBox='0 0 620 382' fill='none'%3E%3Cellipse opacity='0.4' cx='310' cy='191' rx='310' ry='191' fill='url(%23paint0_radial_1_2865)' fill-opacity='0.2'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_1_2865' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(310 191) rotate(90) scale(183.48 298.163)'%3E%3Cstop offset='0.104167' stop-color='%233CCECE' stop-opacity='0.96'/%3E%3Cstop offset='1' stop-color='%23FF00D2' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E`;

export interface URLMapDeprecated {
  [key: string]: string;
}
