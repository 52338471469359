import { NETWORK_ERROR } from 'configs/messages';
import {
  CounterpartyCard,
  CounterpartyBasicInfoFragment,
} from 'features/deals/dealCards/CounterpartyCard';
import ListingStratsCard, {
  ListingStratsCardFragments,
} from 'features/deals/dealCards/ListingStratsCard';
import { PoolHeaderFragments } from 'features/loanPool/PoolHeader';
import { useParams } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';

import { gql, useQuery } from '@apollo/client';

import { GetListing, GetListingVariables } from './__generated__/GetListing';
import ListingSidebar, { ListingSidebarFragments } from './ListingSidebar';

type ListingParams = {
  asset_class?: string;
  listing_id: string;
};

const GET_LISTING_QUERY = gql`
  query GetListing($id: ID!) {
    listing(id: $id) {
      id
      ...PoolHeaderListing
      ...ListingStratsCardListing
      ...ListingSidebarListing
      company {
        ...CounterpartyBasicInfo
      }
    }
  }
  ${PoolHeaderFragments.listing}
  ${ListingStratsCardFragments.listing}
  ${CounterpartyBasicInfoFragment.company}
  ${ListingSidebarFragments.listing}
`;

const DealPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .progress {
    flex: 0 0 25%;
    top: 0px;
    position: sticky;
  }

  .cards {
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

const ListingOverview = (): JSX.Element => {
  const params = useParams<ListingParams>();
  const { loading, error, data } = useQuery<GetListing, GetListingVariables>(
    GET_LISTING_QUERY,
    {
      variables: { id: params.listing_id || '' },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (error) {
    console.log(`Listing Error! ${error.message}`);
    return <>{NETWORK_ERROR}</>;
  }

  const listing = data?.listing;

  if (listing == null) {
    return <></>;
  }

  // TODO reuse DealRoom code to build out the view for a public offering
  // TODO add the Request Access button and modal
  // TODO add the Pool Summary and Stratifications
  // TODO make sure no filtering is allowed in this view
  // Note - the PoolHeader here is for the old offering view, not the new one
  // return (
  //   <>
  //     <Table>
  //       <PoolHeader listing={listing} showContactSellerButton />
  //       <PoolSummary totalData={listing?.performanceData} />
  //     </Table>
  //   </>
  // );
  return (
    <>
      <DealPage className="page">
        <ListingSidebar listing={listing} />
        <div className="cards">
          <CounterpartyCard
            {...listing}
            active={true}
            counterparty={listing.company}
            loading={loading}
          />
          <ListingStratsCard {...listing} loading={loading} />
        </div>
      </DealPage>
    </>
  );
};

export default ListingOverview;
