import { FC } from 'react';

export const MarketplaceBlotterSkeleton: FC = () => {
  return (
    <div>
      <h2 className="mb-4 font-heebo text-lg font-medium text-white">
        Marketplace Blotter
      </h2>
      <div className="flex overflow-x-auto xl:overflow-x-visible">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="flex-1 flex-shrink-0 cursor-pointer rounded border-r border-slate-500 bg-background-canvas p-3 text-white transition-all duration-150 last:border-r-0 hover:scale-105 hover:bg-slate-800 active:scale-100"
          >
            <div className="mb-2 h-4 w-32 max-w-[130px] animate-pulse break-words rounded bg-purple-300 font-medium text-purple-300 brightness-50"></div>
            <div className="mb-2 h-4 w-16 animate-pulse rounded bg-slate-700"></div>
            <div className="flex max-w-48 justify-between font-bold">
              <div className="mb-2 h-4 w-14 animate-pulse rounded bg-slate-700"></div>
              <div className="mb-2 h-4 w-14 animate-pulse rounded bg-slate-700"></div>
            </div>
            <div className="flex max-w-48 justify-between font-bold brightness-50">
              <div className="mb-2 h-4 w-12 animate-pulse rounded bg-green-500"></div>
              <div className="h-4 w-24 animate-pulse rounded bg-green-500"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
