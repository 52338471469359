import { useState, useEffect, useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import { useLazyQuery } from '@apollo/client';

import {
  GetUserNotifications,
  GetUserNotifications_userNotifications_notifications as Notification,
} from 'query/__generated__/GetUserNotifications';
import { GET_NOTIFICATIONS_QUERY } from 'query/notifications';

export const useNotifications = () => {
  const { state: authState } = useContext(AuthContext);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [fetchNotifications, { called, data, refetch, loading }] =
    useLazyQuery<GetUserNotifications>(GET_NOTIFICATIONS_QUERY, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const loadNotifications = () => {
      if (!called) {
        fetchNotifications();
      }
    };

    if (authState.user) {
      loadNotifications();
    }

    const intervalId = setInterval(() => {
      if (authState.user) {
        refetch();
      }
    }, 15000);

    const timeoutId = setTimeout(() => clearInterval(intervalId), 3600000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [fetchNotifications, called, refetch, authState.user]);

  useEffect(() => {
    if (
      data &&
      data.userNotifications &&
      data.userNotifications.notifications &&
      data.userNotifications.notifications.length > 0
    ) {
      setNotifications((prevNotifications) => {
        const newNotifications =
          data.userNotifications.notifications?.filter(
            (notification) => notification.notificationMetadata.type === 'app',
          ) || [];

        const mergedNotifications = [...prevNotifications];
        newNotifications.forEach((newNotification) => {
          const existingIndex = mergedNotifications.findIndex(
            (n) =>
              n.notificationMetadata.notificationID ===
              newNotification.notificationMetadata.notificationID,
          );
          if (existingIndex === -1) {
            mergedNotifications.push(newNotification);
          } else {
            mergedNotifications[existingIndex] = newNotification;
          }
        });

        mergedNotifications.sort(
          (a, b) =>
            Number(a.notificationMetadata.createdAt) -
            Number(b.notificationMetadata.createdAt),
        );

        return mergedNotifications.filter(
          (n) => n.notificationMetadata.type === 'app',
        );
      });
    }
  }, [data]);

  return { notifications, loading };
};
