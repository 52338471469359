import { useState } from 'react';

export interface TableQueryParameters<T, U> {
  filters: T[];
  sortings: { id: U; desc: boolean }[];
  pageNumber: number;
  pageSize: number;
}

export interface TableQueryParamsProps<T, U> {
  params: TableQueryParameters<T, U>;
  updateParams: (newParams: Partial<TableQueryParameters<T, U>>) => void;
}

const useTableQueryParams = <T, U>(
  initialParams?: Partial<TableQueryParameters<T, U>>,
): TableQueryParamsProps<T, U> => {
  const [params, setParams] = useState<TableQueryParameters<T, U>>({
    filters: [] as T[],
    sortings: [] as { id: U; desc: boolean }[],
    pageNumber: 1,
    pageSize: 10,
    ...initialParams,
  });

  const updateParams = (newParams: Partial<TableQueryParameters<T, U>>) => {
    setParams((prevState) => ({
      ...prevState,
      ...newParams,
    }));
  };

  return { params, updateParams };
};

export default useTableQueryParams;
