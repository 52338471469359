import React, { useEffect } from 'react';
interface PassedProps {
  setIsOpen: (isOpen: boolean) => void;
  headerText: string;
  subText: string;
  caption: string;
  children: React.ReactNode;
  type?: string;
  className?: string;
  width?: string;
}

export const Modal = ({
  headerText,
  subText,
  caption,
  children,
  setIsOpen,
  type,
  className,
  width,
}: PassedProps) => {
  useEffect(() => {
    const closeModal = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal);
  }, [setIsOpen]);

  return (
    <>
      <div
        className="fixed inset-0 z-[1] bg-black bg-opacity-50"
        onClick={() => setIsOpen(false)}
      />
      <div
        className={`
          fixed left-1/2 top-1/2 z-[1] flex -translate-x-1/2 -translate-y-1/2 
        transform rounded-lg p-6 text-white shadow-lg
          ${type === 'document-library' ? 'w-[700px]' : 'min-w-[380px]'}
          ${className}
        `}
        style={{ width: width || 'auto' }}
      >
        <div className="text-left">
          <div className="mb-2 text-xl font-semibold">{headerText}</div>
          <div className="mb-1 text-sm uppercase text-gray-400">{subText}</div>
          <div className="mb-4 text-xs text-gray-500">{caption}</div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};
