import { gql } from '@apollo/client';

const DocumentTemplatesFragments = {
  document: gql`
    fragment TemplateDocument on Document {
      id
      type
      __typename
      name
      uploadedAt
      uploadedBy {
        given_name
        family_name
      }
      documentUrl
      documentSizeBytes
      is_template
    }
  `,
};

export { DocumentTemplatesFragments };
