import { PropsWithChildren } from 'react';

import {
  IndeterminateCheckboxProps,
  IndeterminateCheckbox,
} from './IndeterminateCheckbox';

export type CheckboxWithLabelProps =
  PropsWithChildren<IndeterminateCheckboxProps>;

export function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  const { children, disabled, ...rest } = props;
  return (
    <label
      className={`inline-flex cursor-pointer select-none items-center gap-4 ${disabled ? 'opacity-50' : ''}`}
    >
      <IndeterminateCheckbox {...rest} />
      {children}
    </label>
  );
}
