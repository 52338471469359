import { gql } from '@apollo/client';

const INGEST_FILE_MUTATION = gql`
  mutation SetFileReadyToIngest($fileId: ID!) {
    ingestFile(id: $fileId) {
      id
      state
    }
  }
`;

export default INGEST_FILE_MUTATION;
