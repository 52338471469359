import { BaseButton } from 'common-ui';
import { Dialog, DialogProps } from 'common-ui/Dialog';
import { DialogActions } from 'common-ui/Dialog.styles';

export interface ConfirmDialogProps {
  dialog: DialogProps;
  message: string;
  confirmButtonText: string;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  dialog,
  message,
  onConfirm,
  confirmButtonText,
}) => {
  return (
    <Dialog dialog={dialog}>
      <div>{message}</div>
      <DialogActions>
        <BaseButton
          type="secondary"
          label="Cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="confirm"
          size="medium"
          onClick={() => {
            onConfirm();
            dialog.setIsOpen(false);
          }}
        >
          {confirmButtonText}
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
