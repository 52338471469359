import { useEffect, useState } from 'react';

import { BaseButton } from 'common-ui';
import { Dialog, DialogProps } from 'common-ui/Dialog';
import { isNotNullOrUndefined } from 'functions/typeUtils';

import { useMutation, useQuery } from '@apollo/client';

import { DealMessageAuthorRole } from '__generated__/globalTypes';

import {
  CreateCollateralNote,
  CreateCollateralNoteVariables,
} from 'mutation/__generated__/CreateCollateralNote';
import { CREATE_COLLATERAL_NOTE } from 'mutation/diligenceMutations';

import {
  GetCollateralNotes,
  GetCollateralNotesVariables,
} from 'query/__generated__/GetCollateralNotes';
import { GET_COLLATERAL_NOTES } from 'query/collateral';

interface CollateralNotesDialogProps {
  fileName: string;
  dialog: DialogProps;
  dealID: string;
  loanID: string;
}

const CollateralNotesDialog: React.FC<CollateralNotesDialogProps> = ({
  fileName,
  dialog,
  dealID,
  loanID,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  const { data, loading, refetch } = useQuery<
    GetCollateralNotes,
    GetCollateralNotesVariables
  >(GET_COLLATERAL_NOTES, {
    variables: {
      dealID,
      loanID,
      fileName,
    },
    skip: !dialog.isOpen,
    pollInterval: 10000,
  });

  const [createCollateralNote] = useMutation<
    CreateCollateralNote,
    CreateCollateralNoteVariables
  >(CREATE_COLLATERAL_NOTE);

  const handleAddNote = (text: string) => {
    createCollateralNote({
      variables: {
        input: {
          dealID,
          loanID,
          fileName,
          authorRole: DealMessageAuthorRole.BUYER, // TODO: ROMAN
          note: text,
        },
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (dialog.isOpen) {
      // If the dialog is opened, clear the note
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog}>
      <button
        className="text-grey-500 hover:text-grey-300 absolute right-[10px] top-[10px]"
        onClick={() => dialog.setIsOpen(false)}
      >
        ✕
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold">
        Add a Note for "{fileName}"
      </h2>
      <div className="mt-4 flex max-h-[444px] flex-col gap-2 overflow-y-auto">
        {loading && <div>Loading...</div>}
        {data &&
          data.collateralNotes?.files
            ?.flatMap((file: any) => file?.notes) //TODO: Roman - I would just rework the data pattern in this file.  The collateralNotes also has the metadata on it in files... so you should probably use it higher up and pass it down here to reduce gql calls.
            ?.filter(isNotNullOrUndefined)
            .map((note: any) => {
              return (
                <div key={note.id}>
                  <div>
                    <div className="mb-1 font-pt-sans-narrow text-xs text-slate-200">
                      {new Date(parseInt(note.createdAt) * 1000).toDateString()}
                    </div>
                    <div className="text-sm">{note.note}</div>
                  </div>
                </div>
              );
            })}
      </div>
      <textarea
        className="my-4 h-28 w-full rounded-md border border-pink-400 bg-background-surface p-2 text-sm text-foreground-default focus:border-pink-400 focus:outline-none"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => {
            handleAddNote(noteText);
          }}
        >
          Add Note
        </BaseButton>
      </div>
    </Dialog>
  );
};

export default CollateralNotesDialog;
