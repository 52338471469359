import { lighten, transitions } from 'polished';
import { css, styled } from 'style/ORSNNTheme';

const Form = styled.form`
  margin-top: 0.5rem;
  text-align: center;
  width: 400px;
  input {
    margin-bottom: 0.5rem;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color.accentEmphasis};
  color: ${(props) => props.theme.color.fgDefault};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 8px 32px;
  width 100%;

  ${transitions(['background-color'], '200ms ease-in')};

  &:hover {
    background-color: ${(props) => lighten(0.1, props.theme.color.accentEmphasis)};
  }
  &:disabled {
    background-color: ${(props) => props.theme.color.bgDisabled};
    color: ${(props) => props.theme.color.fgOnDisabled};
  }
`;

const InfoText = styled.div`
  color: ${(props) => props.theme.color.gray500};
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme.color.errorDefault};
  background-color: ${(props) => props.theme.color.fgDefault};
`;

const linkStyle = css`
  color: ${(props) => props.theme.color.brandMainPink};
  text-decoration: none;
  &:hover {
    color: unset;
    text-decoration: none;
    cursor: pointer;
    background-color: ${(props) => props.theme.color.gray900};
  }
  &.active {
    text-decoration: none;
    border-bottom: 4px solid ${(props) => props.theme.color.brandMainPink};
    color: ${(props) => props.theme.color.brandMainPink};
  }
`;

const brochureLinkStyle = css`
  text-decoration: none;
  display: block;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2 16px 44px)
  grid-template-areas: 
    "header header header header"
    "main main . sidebar"
    "footer footer footer footer";
  gap: 10px;
  width: 100%;
`;

const GridLabel = styled.label`
  justify-self: start;
  align-self: center;
  font-size: 12px;
`;

export {
  InfoText,
  Form,
  Button,
  brochureLinkStyle,
  linkStyle,
  Grid,
  GridLabel,
};
