import { PropsWithChildren, useCallback } from 'react';

import {
  ResponsivePie,
  MayHaveLabel,
  PieCustomLayerProps,
  PieSvgProps,
  PieTooltipProps,
  ComputedDatum, // Import ComputedDatum
} from '@nivo/pie';
import { animated } from '@react-spring/web';
import { getContrast } from 'polished';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  chartColors,
  CommonChartProps,
  chartTheme,
  formatValue,
  StyledTooltip,
} from './chartsCommons';

type ArcLabelProps<T> = Parameters<
  Exclude<PieSvgProps<T>['arcLabelsComponent'], undefined>
>[0];

export function PieChart<T extends MayHaveLabel>(
  props: CommonChartProps<T> & {
    centralValue: string;
    centralLabel?: string;
  },
) {
  const theme = useTheme();
  const intl = useIntl();

  const CenterAvg = useCallback(
    (centerProps: PieCustomLayerProps<MayHaveLabel>) =>
      CenteredMetric({
        ...centerProps,
        centralValue: props.centralValue,
        centralLabel: props.centralLabel,
      }),
    [props.centralValue, props.centralLabel],
  );

  function getLabelColor(cell: ComputedDatum<T>) {
    if (!cell.color) return theme.color.gray900;
    return getContrast(cell.color, theme.color.gray50) > 3
      ? theme.color.gray50
      : theme.color.gray900;
  }

  const ArcLabel = useCallback(
    (arcProps: ArcLabelProps<T>) => (
      <CustomArcLabel
        {...arcProps}
        formatLabel={props.formatKey}
        valFormaOptions={props.valueFormatOptions}
      />
    ),
    [props.formatKey, props.valueFormatOptions],
  );

  const Tooltip = useCallback(
    (tipProps: PieTooltipProps<T>) => (
      <PieTooltip {...tipProps} formatLabel={props.formatKey} />
    ),
    [props.formatKey],
  );

  return (
    <ResponsivePie<T>
      id={props.keyField}
      data={props.data}
      value={props.valueField}
      valueFormat={(value) =>
        formatValue(intl, value, props.valueFormatOptions)
      }
      enableArcLabels={true}
      enableArcLinkLabels={false}
      arcLabelsComponent={ArcLabel}
      arcLabelsTextColor={getLabelColor}
      colors={chartColors(theme)}
      theme={chartTheme(theme)}
      innerRadius={0.4}
      layers={['arcs', 'arcLabels', CenterAvg]}
      tooltip={Tooltip}
    />
  );
}

function PieTooltip<T>(
  props: PropsWithChildren<PieTooltipProps<T>> & {
    formatLabel: CommonChartProps<T>['formatKey'];
  },
) {
  if (!props.datum.value) return null;
  const label = props.formatLabel
    ? props.formatLabel(props.datum.label as string)
    : props.datum.label;
  return (
    <StyledTooltip>
      <span style={{ fontWeight: 600 }}>{label}</span>
      <br />
      {props.datum.formattedValue}
    </StyledTooltip>
  );
}

function CustomArcLabel<T>(
  props: ArcLabelProps<T> & {
    formatLabel: CommonChartProps<T>['formatKey'];
    valFormaOptions: CommonChartProps<T>['valueFormatOptions'];
  },
) {
  const theme = useTheme();
  const intl = useIntl();
  if (props.datum.arc.angleDeg < 15) return null;

  const label = props.formatLabel
    ? props.formatLabel(props.datum.label as string)
    : props.datum.label;
  const value = formatValue(intl, props.datum.value, {
    ...props.valFormaOptions,
    notation: 'compact',
  });

  return (
    <animated.g transform={props.style.transform}>
      <text
        textAnchor="middle"
        fill={props.style.textColor}
        style={{
          font: theme.typography.primarySmall,
          fontWeight: 900,
        }}
      >
        {label}
      </text>
      <text
        dy={20}
        textAnchor="middle"
        fill={props.style.textColor}
        style={{
          font: theme.typography.primaryXSmall,
        }}
      >
        {value}
      </text>
    </animated.g>
  );
}

function CenteredMetric({
  centerX,
  centerY,
  innerRadius,
  centralValue,
  centralLabel,
}: PieCustomLayerProps<MayHaveLabel> & {
  centralValue: string;
  centralLabel?: string;
}) {
  const theme = useTheme();

  return (
    <g>
      <circle
        fill={theme.color.white}
        r={innerRadius + 1}
        cx={centerX}
        cy={centerY}
        stroke={theme.color.blue400}
        strokeWidth={3}
      />
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          font: theme.typography.primaryHeadline4,
          fontWeight: 900,
          fill: theme.color.blue600,
        }}
      >
        {centralValue}
      </text>
      <text
        x={centerX}
        y={centerY}
        dy={25}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          font: theme.typography.primaryTiny,
          fontWeight: 700,
          fill: theme.color.blue600,
        }}
      >
        {centralLabel}
      </text>
    </g>
  );
}
