import { BaseButton, SvgIcon } from 'common-ui';
import { CloseButton } from 'common-ui/form/StandardForm';

import { GetNewsArticles_newsArticles as NewsArticle } from 'query/__generated__/GetNewsArticles';

import { ReadFullStoryLink, Subject } from './Home.styles';
import { Byline } from './News';

interface ArticleDialogProps {
  currentArticle: NewsArticle | null;
  currentArticleIndex: number;
  allArticles: NewsArticle[];
  hasMore: boolean;
  closeDialog: () => void;
  loadMoreArticles: () => void;
  handleNavigateArticle: (direction: 'next' | 'previous') => void;
}

const ArticleDialog: React.FC<ArticleDialogProps> = ({
  currentArticle,
  currentArticleIndex,
  allArticles,
  closeDialog,
  hasMore,
  loadMoreArticles,
  handleNavigateArticle,
}) => {
  return (
    <div className="flex h-full flex-col text-left">
      <CloseButton title="Close" onClick={() => closeDialog()} />
      {currentArticle && (
        <>
          <div className="flex-1">
            <Byline
              source={currentArticle.news_source}
              publication_date={currentArticle.publication_date}
              url={currentArticle.url}
            />
            <Subject>{currentArticle.subject}</Subject>
            <div className="my-4 overflow-y-auto font-light leading-normal text-gray-400">
              {currentArticle.summary}
            </div>
            <ReadFullStoryLink
              href={currentArticle.url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
              title="Read the full story"
            >
              Read the full story <SvgIcon name="arrow-circle-right" />
            </ReadFullStoryLink>
            <p className="text-center font-heebo text-xs font-medium leading-5 text-slate-200">
              – END –
            </p>
          </div>
          <div className="mt-4 flex justify-between gap-[100px]">
            <div className="flex-1">
              {currentArticleIndex > 0 && (
                <>
                  <button
                    className="bg-transparent block w-full cursor-pointer border-0 p-0 text-left"
                    onClick={() => handleNavigateArticle('previous')}
                  >
                    <p className="font-heebo text-xs font-medium leading-5 text-slate-200">
                      READ PREVIOUS
                    </p>
                    <div className="h-[108px] rounded-lg border border-[rgba(21,85,236,0.2)] pb-0 pl-4 pr-4 pt-3">
                      <Byline
                        source={
                          allArticles[currentArticleIndex - 1].news_source
                        }
                        publication_date={
                          allArticles[currentArticleIndex - 1].publication_date
                        }
                        url={allArticles[currentArticleIndex - 1].url}
                      />
                      <p className="line-clamp-2 overflow-hidden font-heebo text-base font-medium leading-6">
                        {allArticles[currentArticleIndex - 1].subject}
                      </p>
                    </div>
                  </button>
                </>
              )}
            </div>
            <div className="flex-1">
              {currentArticleIndex === allArticles.length - 1 && hasMore && (
                <BaseButton
                  type="secondary"
                  size="small"
                  label="See More Articles"
                  onClick={() => {
                    loadMoreArticles();
                  }}
                >
                  See More Articles
                </BaseButton>
              )}
              {currentArticleIndex < allArticles.length - 1 && (
                <>
                  <button
                    className="bg-transparent block w-full cursor-pointer border-0 p-0 text-left"
                    onClick={() => handleNavigateArticle('next')}
                  >
                    <p className="text-right font-heebo text-xs font-medium leading-5 text-slate-200">
                      READ NEXT
                    </p>
                    <div className="h-[108px] rounded-lg border border-[rgba(21,85,236,0.2)] pb-0 pl-4 pr-4 pt-3">
                      <Byline
                        source={
                          allArticles[currentArticleIndex + 1].news_source
                        }
                        publication_date={
                          allArticles[currentArticleIndex + 1].publication_date
                        }
                        url={allArticles[currentArticleIndex + 1].url}
                      />
                      <p className="line-clamp-2 overflow-hidden font-heebo text-base font-medium leading-6">
                        {allArticles[currentArticleIndex + 1].subject}
                      </p>
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ArticleDialog;
