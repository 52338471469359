import _ from 'lodash';

import { useMutation } from '@apollo/client';

import { HeaderMapInput } from '__generated__/globalTypes';

import {
  SubmitFileHeaderMap,
  SubmitFileHeaderMapVariables,
} from 'query/__generated__/SubmitFileHeaderMap';
import { SUBMIT_FILE_HEADER_MAP } from 'query/submitFileHeaderMap';

import { FieldsMap } from '../fieldMapping/MapRequiredFieldsCard';

export const useMapHeadersToFields = () => {
  const [submitFileHeaderMap] = useMutation<
    SubmitFileHeaderMap,
    SubmitFileHeaderMapVariables
  >(SUBMIT_FILE_HEADER_MAP);

  const mapHeadersToFields = async (fileId: string, fieldsMap: FieldsMap) => {
    const headerMaps = _.toPairs(fieldsMap).map(([field, header]) => {
      return {
        field,
        file_column: header,
      } as HeaderMapInput;
    });
    return submitFileHeaderMap({
      variables: {
        fileId: fileId,
        headerMaps,
      },
    });
  };

  return {
    mapHeadersToFields,
  };
};
