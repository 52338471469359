import React from 'react';

import { GetDealCounterparty_deal_counterparty_PrivateCompany_form5300LLMAnalysis as Form5300AnalysisData } from 'features/deals/DealStages/EventActionCards/CounterpartyInsightsBody/__generated__/GetDealCounterparty';

interface Form5300AnalysisProps {
  data: Form5300AnalysisData | null;
}

const Form5300Analysis: React.FC<Form5300AnalysisProps> = ({ data }) => {
  if (!data) return null;

  const emergingLendingRisks: Array<[string, string]> = [
    [
      'Portfolio Growth Rates',
      data.emergingLendingRisks?.portfolioGrowthRates || 'N/A',
    ],
    [
      'Participation Exposures',
      data.emergingLendingRisks?.participationExposures || 'N/A',
    ],
    [
      'Increasing Delinquencies',
      data.emergingLendingRisks?.increasingDelinquencies || 'N/A',
    ],
    [
      'Benchmark Comparisons',
      data.emergingLendingRisks?.benchmarkComparisons || 'N/A',
    ],
    [
      'Recession Sensitivity Analysis',
      data.emergingLendingRisks?.recessionSensitivityAnalysis || 'N/A',
    ],
  ];

  const dataDrivenInsights: string = data.dataDrivenInsights.join(' ') || 'N/A';

  return (
    <div className="mt-4 rounded-lg pt-2.5 shadow-sm">
      <h6 className="mb-2.5">Regulatory AI Analysis</h6>
      <p className="font-semibold text-slate-200">Emerging Lending Risks</p>
      <ol className="list-decimal border-b border-slate-500 pl-5 font-heebo text-slate-400">
        {emergingLendingRisks.map(([key, value]) => (
          <li
            key={key}
            className="border-b border-slate-700 py-2 last:border-0"
          >
            <span className="font-semibold text-slate-200">{key}: </span>
            {value}
          </li>
        ))}
      </ol>
      <p className="mt-3 font-semibold text-slate-200">Data-Driven Insights</p>
      <ul className="pl-5 font-heebo text-slate-400">
        <li className="border-b border-slate-700 py-2">{dataDrivenInsights}</li>
      </ul>
    </div>
  );
};

export default Form5300Analysis;
