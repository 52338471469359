import { useState } from 'react';

import {
  GetDealGeneralInfo,
  GetDealGeneralInfoVariables,
} from 'features/deals/dealCards/gql/__generated__/GetDealGeneralInfo';
import { GET_DEAL_GENERAL_INFO_QUERY } from 'features/deals/dealCards/gql/dealDetailsQueries';
import DealStages from 'features/deals/DealStages';
import { Messaging } from 'features/deals/Messaging/Messaging';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import DealRoomContext from './DealRoomContext';
import StatusTracker from './StatusTracker';

export function DealRoom() {
  const { deal_id: dealId } = useParams<{ deal_id: string }>();

  const { data, loading, startPolling, stopPolling } = useQuery<
    GetDealGeneralInfo,
    GetDealGeneralInfoVariables
  >(GET_DEAL_GENERAL_INFO_QUERY, {
    variables: { id: dealId as string },
    skip: !dealId,
  });

  const [currentCard, setCurrentCard] = useState<string>('Counterparty Detail');
  const [isDocumentSelectedForView, setDocumentSelectedForView] =
    useState<boolean>(false);

  if (dealId == null) {
    return <>Missing Deal Id</>;
  }
  const assetClass = data?.deal?.listing.assetClass;
  if (loading || !assetClass) {
    return (
      <div className="page flex flex-row gap-2">
        <div className="progress sticky top-0 flex min-w-[25%] flex-col gap-1.5">
          <div className="mb-2 h-[50%] w-full animate-pulse rounded bg-gray-700"></div>
          <div className="h-[50%] w-full animate-pulse rounded bg-gray-700"></div>
        </div>
        <div className="cards max-w-[75%] flex-[0_0_75%] space-y-2">
          <div className="h-14 w-full animate-pulse rounded bg-gray-700"></div>
          <div className="h-14 w-full animate-pulse rounded bg-gray-700"></div>
          <div className="h-14 w-full animate-pulse rounded bg-gray-700"></div>
        </div>
      </div>
    );
  }

  const doSetCurrentCard = (card: string) => {
    setCurrentCard(card);
  };

  const doSetDocumentSelectedForView = (isSelected: boolean) => {
    setDocumentSelectedForView(isSelected);
  };

  return (
    <DealRoomContext.Provider
      value={{
        dealId: dealId,
        assetClass: assetClass,
        currentCard,
        setCurrentCard: doSetCurrentCard,
        isDocumentSelectedForView,
        setDocumentSelectedForView: doSetDocumentSelectedForView,
      }}
    >
      <div className="min-w-[14px]" style={{ gridArea: 'sidebar' }}></div>
      {assetClass && data.deal != null ? (
        <div className="page flex flex-row gap-2">
          <div className="progress sticky top-0 flex flex-col gap-1.5">
            <StatusTracker deal={data.deal} />
            {data.deal?.is_chat_enabled && <Messaging dealId={dealId} />}
          </div>
          <div className="cards max-w-[75%] flex-[0_0_75%]">
            <DealStages
              deal={data.deal}
              startPolling={startPolling}
              stopPolling={stopPolling}
            />
          </div>
        </div>
      ) : null}
    </DealRoomContext.Provider>
  );
}
