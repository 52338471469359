import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';

import classnames from 'classnames';
import { SvgIcon } from 'common-ui';
import './pill-button.scss';

export type PillButtonType = 'primary' | 'muted';

export interface PillButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  description: string;
  iconName?: string;
  value?: string;
  isActive?: boolean;
  isFocused?: boolean;
  iconOnClick?: () => void;
  pillBtnType?: PillButtonType;
}

export const PillButton = forwardRef(
  (
    {
      description,
      value,
      iconName,
      className,
      iconOnClick,
      isActive,
      isFocused,
      pillBtnType,
      ...buttonProps
    }: PillButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const buttonClassName = classnames('pill-button', {
      'pill-button__clickable': !!buttonProps.onClick,
      'pill-button__active': isActive,
      'pill-button__focused': isFocused,
      'pill-button__with-icon': !!iconName,
      'pill-button__primary': !pillBtnType || pillBtnType === 'primary',
      'pill-button__muted': pillBtnType === 'muted',
      [className || '']: !!className,
    });

    return (
      <button {...buttonProps} ref={ref} className={buttonClassName}>
        <span
          className={classnames('pill-button__desc', {
            'pill-button__desc--main': !value,
          })}
        >
          {description}
        </span>
        {value && <span className="pill-button__val">{value}</span>}
        {iconName ? (
          <span
            className="pill-button__icon"
            onClick={iconOnClick}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SvgIcon name={iconName} />
          </span>
        ) : null}
      </button>
    );
  },
);
