import { gql } from '@apollo/client';

const GET_VALID_TERMS = gql`
  query GetValidTerms($input: GetValidTermsInput!) {
    getValidTerms(input: $input) {
      fieldName
      terms
    }
  }
`;

export { GET_VALID_TERMS };
