import { styled } from 'style/ORSNNTheme';

export const SectionHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0 8px 0;
  color: ${(props) => props.theme.color.slate100};
`;

export const TapesUploadedPanel = styled.div`
  flex: 1;
`;

export const LoadingMessage = styled.div`
  color: ${(props) => props.theme.color.slate50};
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  padding: 7px 16px 16px 10px;
`;
