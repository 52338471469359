import React, { useMemo } from 'react';

import { Spinner } from 'baseui/spinner';
import { BaseButton } from 'common-ui';
import { formatCurrency, formatPercent } from 'configs/columns';
import { CREATE_DEAL_MUTATION } from 'features/loanPool/PoolHeader';
import { Link, useNavigate } from 'react-router-dom';
import ProgressBar from 'ui-kit/ProgressBar/ProgressBar';

import { useMutation, useQuery } from '@apollo/client';

import {
  GetCompanyRegulatoryMetrics,
  GetCompanyRegulatoryMetricsVariables,
} from 'query/__generated__/GetCompanyRegulatoryMetrics';
import { GET_COMPANY_REGULATORY_METRICS } from 'query/companyRegulatoryMetrics';

import { RequestAccessPublicListing } from './__generated__/RequestAccessPublicListing';

type RequestAccessProps = {
  listingId: string;
  listingDetails: RequestAccessPublicListing;
  dealId?: string | null;
};

const RequestAccess: React.FC<RequestAccessProps> = ({
  listingDetails,
  listingId,
  dealId,
}) => {
  const navigate = useNavigate();
  const [createDeal, { loading: creatingDeal }] =
    useMutation(CREATE_DEAL_MUTATION);

  const { loading, data } = useQuery<
    GetCompanyRegulatoryMetrics,
    GetCompanyRegulatoryMetricsVariables
  >(GET_COMPANY_REGULATORY_METRICS, {
    variables: { id: listingId },
  });

  const company = data?.listing?.company;

  const navigateToDeal = (id: string) => navigate(`/marketplace/deal/${id}`);

  const handleAccept = () => {
    createDeal({ variables: { listingId } }).then((response) => {
      const id = response.data?.createDeal.id;

      if (id) {
        navigateToDeal(id);
      }
    });
  };

  const scaledServicingRate = listingDetails.servicing_fee_rate
    ? listingDetails.servicing_fee_rate / 100
    : 0;

  const stats = useMemo(
    () => [
      {
        label: 'UPB',
        value: formatCurrency(listingDetails.t_current_balance_cents),
      },
      { label: 'GWAC', value: formatPercent(listingDetails.wa_coupon ?? 0, 3) },
      {
        label: 'NWAC',
        value: formatPercent(
          (listingDetails.wa_coupon ?? 0) - scaledServicingRate,
          3,
        ),
      },
      {
        label: 'WAM',
        value: listingDetails.wa_remaining_loan_terms_months
          ? `${listingDetails.wa_remaining_loan_terms_months} Mo`
          : 'NO DATA',
      },
      {
        label: 'WALA',
        value: listingDetails.wa_loan_age_months
          ? `${listingDetails.wa_loan_age_months} Mo`
          : 'NO DATA',
      },
    ],
    [listingDetails, scaledServicingRate],
  );

  const metrics = useMemo(() => {
    const regulatoryMetrics = company?.regulatory_metrics;

    return {
      'Loans/Shares': regulatoryMetrics?.loans_shares_rank ?? 0,
      Delinquency: regulatoryMetrics?.delinquency_rank ?? 0,
      'Charge Offs': regulatoryMetrics?.charge_off_ratio_rank ?? 0,
      'Net Worth Ratio': regulatoryMetrics?.net_worth_ratio_rank ?? 0,
      'Expense Ratio': regulatoryMetrics?.expense_ratio_rank ?? 0,
      ROA: regulatoryMetrics?.roa_rank ?? 0,
      ROE: regulatoryMetrics?.roe_rank ?? 0,
      'Allowance/Total Loans':
        regulatoryMetrics?.allowance_total_loans_rank ?? 0,
      'Efficiency Ratio': regulatoryMetrics?.efficiency_ratio_rank ?? 0,
      'IEA/Total Assets': regulatoryMetrics?.iea_total_assets_rank ?? 0,
    };
  }, [company]);

  const renderOfferDetails = () => {
    return (
      <div className="mt-3 w-full max-w-[290px]">
        <h3 className="mb-3 text-sm uppercase text-slate-200">Offer Details</h3>
        {stats.map(({ label, value }) => (
          <div
            key={label}
            className="flex justify-between border-t border-slate-600 px-3 py-2"
          >
            <span className="text-sm text-slate-200">{label}</span>
            <span className="text-sm text-slate-200">{value}</span>
          </div>
        ))}
      </div>
    );
  };

  const averageIndex = company
    ? Object.values(metrics).reduce(
        (acc: number, rank) => acc + (rank ?? 0),
        0,
      ) / Object.values(metrics).length
    : 0;

  const handleRequestBtnClick = () => {
    if (dealId) {
      navigateToDeal(dealId);
    } else {
      handleAccept();
    }
  };

  return (
    <div className="relative">
      <div className="grid grid-cols-2 gap-6">
        <div>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <>
              <div className="mb-2 flex justify-between border-b border-t border-slate-500 pb-[9px] pt-[7px]">
                <div className="flex">
                  <p className="text-sm uppercase text-slate-200">
                    Regulatory Index
                  </p>
                </div>
                <p className="text-sm text-slate-200">{averageIndex}th Index</p>
              </div>

              {Object.entries(metrics).map(([label, rank]) => (
                <ProgressBar key={label} label={label} rank={rank} />
              ))}
            </>
          )}
        </div>
        <div>
          <div>
            <p className="mb-3 text-sm uppercase text-slate-200">
              Pool Publish Settings
            </p>
            <p className="mb-2 text-sm text-slate-200">
              Once the seller approves your request for data, you will be able
              to access the loan tape, generate strats and run cashflows on this
              pool of loans. You can place a bid at anytime.
            </p>
            <p className="text-sm text-slate-200">
              Track your deals in the{' '}
              <Link
                to="/marketplace/active_trades"
                className="uppercase text-purple-500 underline underline-offset-2"
              >
                Active Trades
              </Link>{' '}
              section.
            </p>
          </div>
          <div className="mt-4 flex justify-start gap-2">
            <BaseButton
              className="border-green-600 bg-green-600 text-white hover:border-green-700 hover:bg-green-700 hover:text-white"
              type="primary"
              size="small"
              label="Accept"
              onClick={() => handleRequestBtnClick()}
            >
              {dealId ? 'Go to Deal Room' : 'Request Access'}
            </BaseButton>
            <BaseButton
              className="border-green-600 text-white hover:border-green-700 hover:bg-green-700 hover:text-white"
              label="View"
              size="small"
              type="secondary"
              onClick={() => navigate(`/marketplace/listing/${listingId}`)}
            >
              View Stratifications
            </BaseButton>
          </div>
          <div className="mt-3 w-full max-w-[290px]">
            {renderOfferDetails()}
          </div>
        </div>
      </div>
      {creatingDeal && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <Spinner size={70} />
        </div>
      )}
    </div>
  );
};

export const SkeletonLoader: React.FC = () => (
  <div className="animate-pulse">
    <div className="mb-2 flex justify-between border-b border-t border-slate-500 pb-[9px] pt-[7px]">
      <div className="h-6 w-24 rounded bg-slate-700"></div>
      <div className="h-6 w-16 rounded bg-slate-700"></div>
    </div>

    {Array.from({ length: 8 }).map((_, index) => (
      <div key={index} className="mb-4 h-6 rounded bg-slate-700"></div>
    ))}
  </div>
);

export default RequestAccess;
