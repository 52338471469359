import { SvgIcon } from 'common-ui';
import { typographyLabelXSmallPrimaryMedium } from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';

// The action can be a button, or a link (e.g., for the download "button")
const StyledAction = styled.button<{ as: 'button' | 'a'; disabled?: boolean }>`
  // Variables
  --border-color: ${(props) => props.theme.color.accentMuted};
  --disabled-color: ${(props) => props.theme.color.inputBorderDisabled};
  --disabled-border-color: ${(props) => props.theme.color.bgDisabled};
  --hover-bg-color: ${(props) => props.theme.color.accentMuted};

  // Typography
  ${typographyLabelXSmallPrimaryMedium}

  // Basic Styles
  background-color: #231e27;
  color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 4px 8px;
  height: 24px;

  // Flex Layout
  display: flex;
  gap: 5px;
  align-items: center;

  // SVG colors
  svg path {
    fill: #9a1698; // Default color
  }

  &:hover {
    background-color: var(--hover-bg-color);
    svg path {
      fill: #fff; // White when hovered
    }
  }

  &:disabled {
    color: var(--disabled-color);
    border-color: var(--disabled-border-color);
    background-color: transparent;
    svg path {
      fill: var(--disabled-color); // Color when disabled
    }
  }

  &:disabled:hover {
    background-color: transparent;
  }

  ${(props) =>
    props.as === 'a' &&
    `
    text-decoration: none; 

    // Disable link if it's "disabled"
    pointer-events: ${props.disabled ? 'none' : 'auto'}; 

    // Change cursor to indicate disabled state
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'}; 
  `}

  // Apply the same disabled styles to the link when disabled prop is true
  ${(props) =>
    props.disabled &&
    `
    color: var(--disabled-color) !important;
    border-color: var(--disabled-border-color);
    background-color: transparent;
    svg path {
      fill: var(--disabled-color); // Color when disabled
    }
  `}
`;

interface ActionBaseProps {
  label: string;
  icon: string;
}

type ActionButtonProps = ActionBaseProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;
type ActionLinkProps = ActionBaseProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = ActionButtonProps &
  ActionLinkProps & {
    as?: 'button' | 'a';
  };

export const Action: React.FC<Props> = ({
  as = 'button',
  label,
  icon,
  ...rest
}) => {
  if (as === 'a') {
    return (
      <StyledAction
        as="a"
        disabled={rest.disabled}
        href={rest.disabled ? undefined : rest.href} // Remove href if it's disabled
        {...(rest as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {label} <SvgIcon name={icon} />
      </StyledAction>
    );
  }

  return (
    <StyledAction
      as="button"
      {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {label} <SvgIcon name={icon} />
    </StyledAction>
  );
};
