import { FC } from 'react';

import Dinero from 'dinero.js';
import { isUnsecEnabled } from 'features/flags';
import {
  GET_PORTFOLIO_SUMMARY_QUERY,
  kpiConfigs,
} from 'features/pages/portfolio/PortfolioSummary';

import { useQuery } from '@apollo/client';

import { AssetClass } from '__generated__/globalTypes';

import { GetListings } from 'query/__generated__/GetListings';
import { GET_LISTINGS } from 'query/getListings';

import {
  GetPortfolioSummary,
  GetPortfolioSummaryVariables,
} from '../__generated__/GetPortfolioSummary';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';

const usePortfolioSummary = (assetClass?: AssetClass) => {
  const { data, loading } = useQuery<
    GetPortfolioSummary,
    GetPortfolioSummaryVariables
  >(GET_PORTFOLIO_SUMMARY_QUERY, {
    variables: {
      hasAssetClass: !!assetClass,
      assetClass,
    },
    skip: !isUnsecEnabled() && assetClass === AssetClass.UNSECPERSONAL,
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });
  return { data, loading };
};

// Define the type for KPI configurations
type KpiConfig = {
  description: string;
  selector: string;
  display: (value: number) => string;
};

// Define the type for KPIs with value
type KpiWithValue = KpiConfig & {
  value: string;
};

const mapKpis = (data: GetPortfolioSummary | undefined): KpiWithValue[] => {
  return kpiConfigs.map((kpi) => {
    if (!data) {
      return {
        ...kpi,
        value: '-',
      };
    }

    const performanceData =
      data.user.company.assetClassPerformanceData ||
      data.user.company.performanceData;
    const value = performanceData ? (performanceData[kpi.selector] ?? 0) : 0;
    return {
      ...kpi,
      value: kpi.description === 'UPB' ? formatUpb(value) : kpi.display(value),
    };
  });
};

// Rounds down to nearest dollar, as requested by Ralf K.
const formatUpb = (value: number) => {
  const truncatedValue = Math.floor(value);
  return Dinero({
    amount: truncatedValue,
    currency: 'USD',
  }).toFormat('$0,0');
};

const KpiCardContainer = ({ kpis }: { kpis: KpiWithValue[] }) => (
  <div className="flex flex-row gap-4">
    <div className="flex h-16 min-w-48 flex-col items-center justify-center gap-1 rounded-lg border border-[#260626] bg-[#e028dd] bg-gradient-to-r from-[#1555ec] to-[#ff23e1] p-2 text-center">
      <div className="font-mono text-xl text-gray-100">{kpis[0].value}</div>
      <div>{kpis[0].description === '#' ? '# LOANS' : kpis[0].description}</div>
    </div>
    {kpis.slice(1).map((kpi) => (
      <div
        key={kpi.description}
        className="flex h-16 min-w-32 max-w-36 flex-col items-center justify-center gap-1 rounded-lg border border-gray-500 bg-gray-800 p-2 text-center"
      >
        <div className="font-mono text-xl text-gray-100">{kpi.value}</div>
        <div>{kpi.description === '#' ? '# LOANS' : kpi.description}</div>
      </div>
    ))}
  </div>
);

const PortfolioSkeleton: FC = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col gap-5 overflow-x-auto">
        {/* Total in your Portfolio */}
        <div className="mb-2 h-8 w-60 animate-pulse rounded bg-gray-700 opacity-50"></div>{' '}
        {/* Skeleton for "Total in your Portfolio" */}
        <div className="mb-4 h-6 w-20 animate-pulse rounded bg-gray-600 opacity-50"></div>{' '}
        {/* Skeleton for number of pools */}
        <div className="flex flex-row gap-4">
          <div className="flex h-16 min-w-48 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-[#260626] bg-gradient-to-r from-[#1555ec] to-[#ff23e1] p-2 opacity-50">
            <div className="h-6 w-24 rounded bg-gray-600"></div>
            <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="flex h-16 min-w-32 max-w-36 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-gray-500 bg-gray-700 p-2 opacity-50"
            >
              <div className="h-6 w-24 rounded bg-gray-600"></div>
              <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
            </div>
          ))}
        </div>
        {/* Auto */}
        <div className="mb-2 h-8 w-40 animate-pulse rounded bg-gray-700 opacity-50"></div>{' '}
        {/* Skeleton for "Auto" */}
        <div className="mb-4 h-6 w-20 animate-pulse rounded bg-gray-600 opacity-50"></div>{' '}
        {/* Skeleton for number of pools */}
        <div className="flex flex-row gap-4">
          <div className="flex h-16 min-w-48 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-[#260626] bg-gradient-to-r from-[#1555ec] to-[#ff23e1] p-2 opacity-50">
            <div className="h-6 w-24 rounded bg-gray-600"></div>
            <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="flex h-16 min-w-32 max-w-36 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-gray-500 bg-gray-700 p-2 opacity-50"
            >
              <div className="h-6 w-24 rounded bg-gray-600"></div>
              <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
            </div>
          ))}
        </div>
        {/* Home */}
        <div className="mb-2 h-8 w-40 animate-pulse rounded bg-gray-700 opacity-50"></div>{' '}
        {/* Skeleton for "Home" */}
        <div className="mb-4 h-6 w-20 animate-pulse rounded bg-gray-600 opacity-50"></div>{' '}
        {/* Skeleton for number of pools */}
        <div className="flex flex-row gap-4">
          <div className="flex h-16 min-w-48 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-[#260626] bg-gradient-to-r from-[#1555ec] to-[#ff23e1] p-2 opacity-50">
            <div className="h-6 w-24 rounded bg-gray-600"></div>
            <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="flex h-16 min-w-32 max-w-36 animate-pulse flex-col items-center justify-center gap-1 rounded-lg border border-gray-500 bg-gray-700 p-2 opacity-50"
            >
              <div className="h-6 w-24 rounded bg-gray-600"></div>
              <div className="mt-2 h-4 w-16 rounded bg-gray-500"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PortfolioDashboard = () => {
  const { data, loading } = usePortfolioSummary();
  const { data: autoData, loading: autoLoading } = usePortfolioSummary(
    AssetClass.AUTO,
  );
  const { data: homeData, loading: homeLoading } = usePortfolioSummary(
    AssetClass.HOME,
  );
  const { data: unsecData, loading: unsecLoading } = usePortfolioSummary(
    AssetClass.UNSECPERSONAL,
  );
  const { data: listingsData } = useQuery<GetListings>(GET_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });

  const kpis = mapKpis(data);
  const autoKpis = mapKpis(autoData);
  const homeKpis = mapKpis(homeData);

  const autoPools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.AUTO,
    ).length || 0;
  const homePools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.HOME,
    ).length || 0;
  const unsecPools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.UNSECPERSONAL,
    ).length || 0;
  const totalPools = autoPools + homePools;

  return (
    <PortfolioLayout hideSummery={true}>
      {loading ||
      autoLoading ||
      homeLoading ||
      (isUnsecEnabled() && unsecLoading) ? (
        <PortfolioSkeleton />
      ) : (
        <div className="flex h-full items-center justify-center">
          <div className="flex flex-col gap-5 overflow-x-auto">
            <div>
              <h2 className="text-2xl font-bold text-white">
                Total in your Portfolio
              </h2>
              <div className="text-lg text-gray-400">{totalPools} pools</div>
            </div>

            <KpiCardContainer kpis={kpis} />

            <div>
              <h1 className="text-3xl font-bold text-white">Auto</h1>
              <h2 className="text-2xl font-bold text-gray-400">
                Total by asset class
              </h2>
              <div className="text-lg text-gray-400">{autoPools} pools</div>
            </div>

            <KpiCardContainer kpis={autoKpis} />

            <div>
              <h1 className="text-3xl font-bold text-white">Home</h1>
              <h2 className="text-2xl font-bold text-gray-400">
                Total by asset class
              </h2>
              <div className="text-lg text-gray-400">{homePools} pools</div>
            </div>

            <KpiCardContainer kpis={homeKpis} />

            {isUnsecEnabled() && (
              <div>
                <h1 className="text-3xl font-bold text-white">
                  Unsecured Personal
                </h1>
                <h2 className="text-2xl font-bold text-gray-400">
                  Total by asset class
                </h2>
                <div className="text-lg text-gray-400">{unsecPools} pools</div>
                <KpiCardContainer kpis={mapKpis(unsecData)} />
              </div>
            )}
          </div>
        </div>
      )}
    </PortfolioLayout>
  );
};

export default PortfolioDashboard;
