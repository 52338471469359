import { forwardRef, MutableRefObject } from 'react';

import {
  Button,
  ButtonOverrides,
  ButtonProps,
  KIND,
  SHAPE,
  SIZE,
} from 'baseui/button';

const BUTTON_STYLE_OVERRIDES: ButtonOverrides = {
  BaseButton: {
    style: ({ $theme }) => ({
      border: `1px ${$theme.colors.primary} solid`,
      color: $theme.colors.white,
    }),
  },
};

const PillButton = forwardRef(
  (
    props: React.PropsWithoutRef<ButtonProps> &
      React.RefAttributes<HTMLButtonElement>,
    ref:
      | MutableRefObject<HTMLButtonElement | null>
      | ((instance: HTMLButtonElement | null) => void)
      | null,
  ): JSX.Element => (
    <Button
      shape={SHAPE.pill}
      size={SIZE.mini}
      kind={KIND.tertiary}
      overrides={BUTTON_STYLE_OVERRIDES}
      {...props}
      ref={ref}
    />
  ),
);

export { PillButton };
