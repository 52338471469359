import { css } from 'styled-components';

//styleName: typography/ParagraphSmall/Primary/Regular;
const typographyParagraphSmallPrimaryRegular = css`
  font-family: Heebo, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

//styleName: typography.MonoParagraphSmall;
const typographyMonoParagraphSmall = css`
  font-family: PT Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const typographyMono20 = css`
  font-family: PT Mono;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.4px;
`;

const typographyPTSansNarrow10 = css`
  font-family: PT Sans Narrow;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
`;

//styleName: typography/BadgeXSmall/Condensed/Bold;
const typographyBadgeXSmallCondensedBold = css`
  font-family: PT Sans Narrow;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0px;
`;

//styleName: typography/BadgeSmall/Condensed/Bold;
const typographyBadgeSmallCondensedBold = css`
  font-family: PT Sans Narrow;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
`;

//styleName: typography/Body/Primary/Medium;
const typographyBodyPrimaryMedium = css`
  font-family: Heebo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
`;

//styleName: typography/LabelSmall/Primary/Regular;
const typographyLabelSmallPrimaryRegular = css`
  font-family: Heebo, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
`;

//styleName: typography/LabelXSmall/Primary/Medium;
const typographyLabelXSmallPrimaryMedium = css`
  font-family: Heebo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
`;

//styleName: typography/LabelXSmall/Primary/Regular;
const typographyLabelXSmallPrimaryRegular = css`
  font-family: Heebo, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

//styleName: typography/XSmall/Primary/Regular;
const typographyXSmallPrimaryRegular = css`
  font-family: Heebo, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
`;

// No stylename indicated in Figma
const typographyHeebo12 = css`
  font-family: Heebo, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

// No stylename indicated in Figma
const typographyHeebo14Bold = css`
  font-family: Heebo, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const typographyHeebo20Bold = css`
  font-family: Heebo, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

// No stylename indicated in Figma
const typographyHeebo24 = css`
  font-family: Heebo, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
`;

const typographyHeebo32Bold = css`
  font-family: Heebo, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 47px;
`;

// No stylename indicated in Figma
const typographyHeebo10 = css`
  font-family: Heebo, sans-serif;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 12.6px;
`;

//styleName: typography/LabelLarge/Primary/Regular;
const typographyLabelLargePrimaryRegular = css`
  font-family: Heebo;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

export {
  typographyParagraphSmallPrimaryRegular,
  typographyMonoParagraphSmall,
  typographyMono20,
  typographyBadgeXSmallCondensedBold,
  typographyHeebo10,
  typographyHeebo12,
  typographyHeebo14Bold,
  typographyHeebo20Bold,
  typographyHeebo24,
  typographyHeebo32Bold,
  typographyBodyPrimaryMedium,
  typographyLabelSmallPrimaryRegular,
  typographyLabelXSmallPrimaryMedium,
  typographyLabelXSmallPrimaryRegular,
  typographyXSmallPrimaryRegular,
  typographyBadgeSmallCondensedBold,
  typographyPTSansNarrow10,
  typographyLabelLargePrimaryRegular,
};
