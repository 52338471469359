import React, { ReactNode, useState } from 'react';

export interface TabsProps {
  children: ReactNode;
  activeTab?: number;
  initialActiveTab?: number;
  onTabChange?: (index: number) => void;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({
  children,
  activeTab,
  initialActiveTab = 0,
  onTabChange,
  className = '',
}) => {
  const [internalActiveTab, setInternalActiveTab] = useState(initialActiveTab);
  const tabs = React.Children.toArray(children) as React.ReactElement[];

  const handleTabClick = (index: number) => {
    if (!tabs[index].props.disabled) {
      if (activeTab === undefined) {
        setInternalActiveTab(index);
      }
      if (onTabChange) {
        onTabChange(index);
      }
    }
  };

  const currentActiveTab =
    activeTab !== undefined ? activeTab : internalActiveTab;

  return (
    <div className={`flex flex-col ${className}`}>
      <div role="tablist" aria-label="Tab List" className="flex pb-3">
        {tabs.map((tab, index) => (
          <button
            key={index}
            role="tab"
            aria-selected={currentActiveTab === index}
            aria-controls={`tab-panel-${index}`}
            id={`tab-${index}`}
            tabIndex={currentActiveTab === index ? 0 : -1}
            className={`cursor-pointer border border-slate-50 px-8 py-1 text-sm transition-colors duration-300
              ${currentActiveTab === index ? 'bg-slate-50 text-overlay' : 'text-slate-200'}
              ${tab.props.disabled ? 'cursor-not-allowed opacity-30' : 'hover:bg-pink-800 hover:text-white'}
              ${index === 0 ? 'rounded-l-md' : ''}
              ${index === tabs.length - 1 ? 'rounded-r-md' : ''}
            `}
            onClick={() => handleTabClick(index)}
            disabled={tab.props.disabled}
          >
            {tab.props.label}
          </button>
        ))}
      </div>

      <div
        role="tabpanel"
        id={`tab-panel-${currentActiveTab}`}
        aria-labelledby={`tab-${currentActiveTab}`}
        tabIndex={0}
        className="mt-4"
      >
        {tabs[currentActiveTab]?.props.children}
      </div>
    </div>
  );
};

interface TabProps {
  label: string;
  children: ReactNode;
  disabled?: boolean;
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

export { Tabs, Tab };
