import { useMemo } from 'react';

import { SvgIcon } from 'common-ui';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { INFO_BID_SUMMARY_LIST } from 'configs/messages';
import { basisPointsToPercent } from 'features/deals/DealStages/EventActionCards/utils';

import { GetCarves_deal_carves_carve_summary } from 'query/__generated__/GetCarves';

import { useBiddingPricingContext } from './BiddingPricingContext';
import { DetailRow } from './BidSummary.styles';
import { formatCentsToDollars } from './formatting';

interface BidSummaryProps {
  carve: GetCarves_deal_carves_carve_summary;
  bidSummary: BidSummaryInfo;
}

function getAverageDuration(summary: BidSummaryInfo): number | undefined {
  if (summary.yieldDuration == null) {
    return summary.spreadDuration;
  } else if (summary.spreadDuration == null) {
    return summary.yieldDuration;
  }
  return (summary.yieldDuration + summary.spreadDuration) / 2;
}

export interface BidSummaryInfo {
  netYield?: number;
  yieldDuration?: number;
  spread?: number;
  spreadDuration?: number;
  purchaseDollarPrice: number;
}

const BidSummary = ({ carve, bidSummary }: BidSummaryProps) => {
  const { bidDetails } = useBiddingPricingContext();

  const participationPercent = bidDetails?.participationPercent.value
    ? Number(bidDetails.participationPercent.value)
    : null;

  const bidBasisPoints = bidDetails?.carve.stipulations?.bid_basis_points;
  const servicing = Number(bidDetails?.stipulations?.servicingRate.value);
  const gwac = carve.netCoupon * 100;

  const netWeightedAverageCoupon = useMemo(() => {
    if (servicing > 0) {
      return gwac - servicing;
    }
    return gwac;
  }, [gwac, servicing]);

  const calculatedPurchaseDollarPrice = useMemo(() => {
    if (bidBasisPoints != null && participationPercent != null) {
      const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
      const priceInDollars =
        (pricePercent / 100) *
        carve.unpaidBalance *
        (participationPercent / 100);
      return formatCentsToDollars(priceInDollars);
    }
    return '-';
  }, [bidBasisPoints, carve.unpaidBalance, participationPercent]);

  const calculatedNetYieldAtPrice = useMemo(() => {
    if (netWeightedAverageCoupon != null && bidBasisPoints != null) {
      const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
      if (pricePercent !== 0) {
        return (
          ((100 * netWeightedAverageCoupon) / pricePercent).toFixed(3) + '%'
        );
      }
    }
    return '-';
  }, [netWeightedAverageCoupon, bidBasisPoints]);

  return (
    <>
      <div>
        <ModalNotStyled
          trigger={
            <div className="flex justify-end p-1">
              <SvgIcon name="info" />
            </div>
          }
        >
          {({ closeModal }) => (
            <div className="w-full min-w-[430px] max-w-[650px] rounded-md border border-accent-muted bg-gray-950 p-4 text-foreground-default">
              <div className="mb-2 flex items-center justify-between">
                <h2 className="text-xl font-semibold">Bid Summary Details</h2>
                <button
                  className="text-gray-500 hover:text-gray-300"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
              <ul className="m-0 list-none p-0">
                {INFO_BID_SUMMARY_LIST.map((item, index) => (
                  <li
                    key={index}
                    className="border-t border-gray-700 px-2 py-1"
                  >
                    <div className="pr-24 text-sm font-bold text-gray-100">
                      {item.key}
                    </div>
                    {item.key !== item.infoText && (
                      <div className="text-sm font-bold text-gray-400">
                        {item.infoText}
                      </div>
                    )}
                    <div className="text-sm text-gray-400">
                      {item.explanation}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </ModalNotStyled>
      </div>
      <DetailRow>
        <div>UPB</div>
        <div>{formatCentsToDollars(carve.unpaidBalance)}</div>
      </DetailRow>
      <DetailRow>
        <div>GWAC</div>
        <div>{gwac.toFixed(3)}%</div>
      </DetailRow>
      <DetailRow>
        <div>NWAC</div>
        <div>{netWeightedAverageCoupon.toFixed(3)}%</div>
      </DetailRow>
      <DetailRow>
        <div>Price</div>
        <div>
          {bidBasisPoints ? basisPointsToPercent(bidBasisPoints) : '-'}%
        </div>
      </DetailRow>
      <DetailRow>
        <div>Net Yield @ Price</div>
        <div>{calculatedNetYieldAtPrice}</div>
      </DetailRow>
      {/* All below is assumption-based and is for the bidder*/}
      <DetailRow>
        <div>Yield to Maturity</div>
        <div>{bidSummary.netYield?.toFixed(3) ?? '-'}%</div>
      </DetailRow>
      <DetailRow>
        <div>Spread to Curve</div>
        <div>{bidSummary.spread?.toFixed(0) || '- '} bps</div>
      </DetailRow>
      <DetailRow>
        <div>Duration</div>
        <div>{getAverageDuration(bidSummary)?.toFixed(3) || '-'}</div>
      </DetailRow>
      <DetailRow>
        <div>{bidDetails?.defaultAssumptionType ?? 'CDR'}</div>
        <div>{bidDetails?.cdr ?? '-'}%</div>
      </DetailRow>
      <DetailRow>
        <div>{bidDetails?.prepaymentAssumptionType ?? 'CPR'}</div>
        <div>{bidDetails?.cpr}%</div>
      </DetailRow>
      <DetailRow>
        <div>Purchase Dollar Price</div>
        <div>{calculatedPurchaseDollarPrice}</div>
      </DetailRow>
    </>
  );
};

export default BidSummary;
