import * as navigation from 'app-level/navigation';
import { filtersToQueryFilters } from 'features/common-elements/filters/filterValuesParsing';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { useNavigate } from 'react-router-dom';

import { SortableField } from '__generated__/globalTypes';

import { usePortfolioFilters } from '../Portfolio/usePortfolioFilters';

export const useLoanLevelData = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const [portfolioFilters] = navigation.usePortfolioFilters();
  const gqlAssetClass = assetClass && ASSET_CLASS_IDS[assetClass];
  const filterProps = usePortfolioFilters();

  const navigate = useNavigate();
  const navigateToPoolManager = () => {
    navigate('/portfolio/pool-manager');
  };

  const queryFilters = filterProps.filtersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        portfolioFilters,
        filterProps.filtersConfig,
      )
    : [];
  const [loansSorting, setLoansSorting] =
    navigation.useSortingHelper<SortableField>();

  return {
    assetClass,
    gqlAssetClass,
    filterProps,
    navigateToPoolManager,
    queryFilters,
    loansSorting,
    setLoansSorting,
  };
};
