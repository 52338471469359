import { gql } from '@apollo/client';

const GET_PORTFOLIO_OVERVIEW_QUERY = gql`
  query GetPortfolioOverview {
    user {
      id
      company {
        id
        totalPerformance: performance_summary(filters: []) {
          unpaidBalance: current_balance_cents
        }
        homePerformance: performance_summary(
          filters: [{ field_name: asset_class, operator: IS, operand: "HOME" }]
        ) {
          unpaidBalance: current_balance_cents
        }
        autoPerformance: performance_summary(
          filters: [{ field_name: asset_class, operator: IS, operand: "AUTO" }]
        ) {
          unpaidBalance: current_balance_cents
        }
        unsecPersonalPerformance: performance_summary(
          filters: [
            { field_name: asset_class, operator: IS, operand: "UNSECPERSONAL" }
          ]
        ) {
          unpaidBalance: current_balance_cents
        }
      }
    }
  }
`;

export { GET_PORTFOLIO_OVERVIEW_QUERY };
