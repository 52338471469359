import bidConfirmationImgPath from 'assets/pngs/bid-confirmation.png';
import { BaseButton } from 'common-ui';
import { DialogActions } from 'common-ui/Dialog.styles';
import { formatCurrency, formatPercent } from 'configs/columns';
import { basisPointsToPercent } from 'features/deals/DealStages/EventActionCards/utils';
import { DateTime } from 'luxon';

import { BiddingAndPricingControllerCard_PlaceBidCard_carves as Carve } from './__generated__/BiddingAndPricingControllerCard';
import { BidDetails } from './BidDetails';

export interface BidConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  bidDetails: BidDetails;
  selectedCarve: Carve;
}

export const BidConfirmationDialog: React.FC<BidConfirmationDialogProps> = ({
  onConfirm,
  onCancel,
  bidDetails,
  selectedCarve,
}) => {
  if (!selectedCarve.carve_summary) {
    return <></>;
  }
  const upb = Math.round(
    (selectedCarve.carve_summary.unpaidBalance *
      Number(bidDetails.participationPercent.value)) /
      100,
  );
  const numDocsSelected = bidDetails.stipulations.document_ids.length;
  const hasDiligenceCompletionDate =
    selectedCarve.stipulations?.has_diligence_completion_date_seconds;
  const diligenceCompletionDate =
    selectedCarve.stipulations?.diligence_completion_date_seconds;
  const hasSettlementDate =
    selectedCarve.stipulations?.has_settlement_date_seconds;
  const settlementDate = selectedCarve.stipulations?.settlement_date_seconds;
  const bidBasisPoints = selectedCarve.stipulations?.bid_basis_points || 0;

  const hasCustomStipulations =
    (selectedCarve.stipulations?.custom_stipulations?.length || 0) > 0;

  return (
    <>
      <div className="mb-2 flex justify-center">
        <img
          src={bidConfirmationImgPath}
          width={233}
          height={232}
          alt="confirm bid"
          className="mx-auto"
        />
      </div>
      <div className="leading-0 text-center text-2xl font-medium text-slate-50">
        Bid Confirmation
      </div>
      <div>Would you like to confirm your Bid of</div>
      <table className="mt-3 w-[400px] border-collapse text-sm leading-5 text-slate-200">
        <tbody className="text-left">
          <tr className="border-t border-slate-600">
            <td>Price</td>
            <td className="p-2 text-right font-mono text-slate-300">
              {basisPointsToPercent(bidBasisPoints)}%
            </td>
          </tr>
          <tr className="border-t border-slate-600">
            <td>GWAC</td>
            <td className="p-2 text-right font-mono text-slate-300">
              {formatPercent(selectedCarve.carve_summary.netCoupon, 3)}
            </td>
          </tr>
          <tr className="border-t border-slate-600">
            <td>UPB</td>
            <td className="p-2 text-right font-mono text-slate-300">
              {formatCurrency(upb)}
            </td>
          </tr>
          {selectedCarve.stipulations?.is_servicing_retained && (
            <tr className="border-t border-slate-600">
              <td>Servicing Fee</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {selectedCarve.stipulations?.servicing_rate}%
              </td>
            </tr>
          )}
          {selectedCarve.stipulations?.has_percent_collateral_diligence && (
            <tr className="border-t border-slate-600">
              <td>Percent Collateral Diligence</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {selectedCarve.stipulations?.percent_collateral_diligence}%
              </td>
            </tr>
          )}
          {selectedCarve.stipulations?.has_min_net_yield_to_buyer && (
            <tr className="border-t border-slate-600">
              <td>Min Net Yield to Buyer</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {selectedCarve.stipulations?.min_net_yield_to_buyer}%
              </td>
            </tr>
          )}
          {hasDiligenceCompletionDate && diligenceCompletionDate && (
            <tr className="border-t border-slate-600">
              <td>Diligence Completion Date</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {DateTime.fromSeconds(diligenceCompletionDate).toLocaleString(
                  DateTime.DATE_SHORT,
                )}
              </td>
            </tr>
          )}
          {hasSettlementDate && settlementDate && (
            <tr className="border-t border-slate-600">
              <td>Settlement Date</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {DateTime.fromSeconds(settlementDate).toLocaleString(
                  DateTime.DATE_SHORT,
                )}
              </td>
            </tr>
          )}
          {hasCustomStipulations && (
            <tr className="border-t border-slate-600">
              <td>Custom Stipulations</td>
              <td className="p-2 text-right font-mono text-slate-300">
                {selectedCarve.stipulations?.custom_stipulations
                  ?.filter(Boolean)
                  .map((stip) => <div>{stip}</div>)}
              </td>
            </tr>
          )}
          <tr className="border-t border-slate-600">
            <td>Documents</td>
            <td className="p-2 text-right font-mono text-slate-300">
              {numDocsSelected} selected
            </td>
          </tr>
        </tbody>
      </table>
      <DialogActions>
        <BaseButton
          type="secondary"
          size="medium"
          label="cancel"
          onClick={() => onCancel()}
        >
          Cancel
        </BaseButton>
        <BaseButton size="medium" label="confirm" onClick={() => onConfirm()}>
          <b>Confirm Bid</b>
        </BaseButton>
      </DialogActions>
    </>
  );
};
