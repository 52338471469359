import { Spinner } from 'common-ui';
import { FilterValue, PillFilters } from 'features/common-elements';
import {
  filtersToQueryFilters,
  searchQueryToFilterValues,
  filterValuesToSearchQuery,
} from 'features/common-elements';
import {
  marketplaceFiltersConfig,
  abbrToFilterKey,
  filterKeyToAbbr,
} from 'features/OnMarket/marketplace.config';
// import { useState } from 'react';
// import { RowSelectionState } from '@tanstack/react-table';
import { MarketplaceTable } from 'features/OnMarket/MarketplaceTable';
import { useMarketPlaceListings_gql } from 'features/OnMarket/useMarketPlaceListings_gql';
import { useSearchParams } from 'react-router-dom';

const MarketHome = () => {
  const [search, setSearch] = useSearchParams();
  const searchFiltersStr = search.get('filters');
  const currentFilters = searchFiltersStr
    ? searchQueryToFilterValues(searchFiltersStr, abbrToFilterKey)
    : [];

  const queryFilters = filtersToQueryFilters<typeof marketplaceFiltersConfig>(
    currentFilters,
    marketplaceFiltersConfig,
  );

  // const [selectedPools, setSelectedPools] = useState<RowSelectionState>({});

  const { listings, loading } = useMarketPlaceListings_gql(queryFilters);

  function setFilters(filters: FilterValue<typeof marketplaceFiltersConfig>[]) {
    const newFilters = filterValuesToSearchQuery(filters, filterKeyToAbbr);
    const newSearch = new URLSearchParams();
    newSearch.set('filters', newFilters);
    setSearch(newSearch);
  }

  return (
    <>
      <div className="p-3" style={{ gridArea: 'summery' }}>
        <PillFilters
          filtersConfig={marketplaceFiltersConfig}
          currentFilters={currentFilters}
          setCurrentFilters={setFilters}
        />
      </div>
      <div className="min-w-[14px]" style={{ gridArea: 'sidebar' }}></div>
      <div className="page">
        <MarketplaceTable
          data={listings}
          // selection={{ selected: selectedPools, onSelectionChange: setSelectedPools }}
        />
        <Spinner loading={loading} />
      </div>
    </>
  );
};

export default MarketHome;
