import React from 'react';

import {
  typographyBadgeSmallCondensedBold,
  typographyHeebo12,
} from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';

import { CollateralStatus } from '__generated__/globalTypes';

import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';

interface DiligenceStatusProps {
  diligenceData: GetDealDiligence_deal_diligence[];
}

const orderedStatuses: CollateralStatus[] = [
  CollateralStatus.REQUIRED,
  CollateralStatus.RECEIVED,
  CollateralStatus.IN_PROGRESS,
  CollateralStatus.ADDITIONAL_QA_REQUIRED,
  CollateralStatus.COMPLETED,
  CollateralStatus.REJECTED,
];

const statusLabels: { [key in CollateralStatus]: string } = {
  [CollateralStatus.ADDITIONAL_QA_REQUIRED]: 'Additional QA Required',
  [CollateralStatus.COMPLETED]: 'Completed',
  [CollateralStatus.IN_PROGRESS]: 'In Progress',
  [CollateralStatus.RECEIVED]: 'Received',
  [CollateralStatus.REJECTED]: 'Rejected',
  [CollateralStatus.REQUIRED]: 'Required',
};

export const DiligenceStatus: React.FC<DiligenceStatusProps> = ({
  diligenceData,
}) => {
  const statusCounts: { [key: string]: number } = {};
  let totalCollateral = 0;

  diligenceData.forEach((diligence) => {
    diligence.collateral.forEach((collateral) => {
      if (collateral.collateralStatus !== null) {
        statusCounts[collateral.collateralStatus] =
          (statusCounts[collateral.collateralStatus] || 0) + 1;
      }
      totalCollateral++;
    });
  });

  const fields = orderedStatuses.map((status) => ({
    label: statusLabels[status] || status,
    valueAsPercentage:
      totalCollateral !== 0
        ? Math.round(((statusCounts[status] || 0) / totalCollateral) * 100)
        : null,
  }));

  return (
    <>
      {fields.map((field) => (
        <DiligenceStatusFieldLabel key={field.label}>
          {field.label}{' '}
          <DiligenceStatusFieldValue
            valueAsPercentage={field.valueAsPercentage}
          >
            {field.valueAsPercentage !== null
              ? `${field.valueAsPercentage}%`
              : '-'}
          </DiligenceStatusFieldValue>
        </DiligenceStatusFieldLabel>
      ))}
    </>
  );
};

const DiligenceStatusFieldLabel = styled.div`
  ${typographyHeebo12}
  color: ${(props) => props.theme.color.fgDefault};
`;

const DiligenceStatusFieldValue = styled.span<{
  valueAsPercentage: number | null;
}>`
  ${typographyBadgeSmallCondensedBold}
  color: ${(props) => {
    if (props.valueAsPercentage === null) {
      return props.theme.color.fgSubtle;
    }
    if (props.valueAsPercentage === 0) {
      return props.theme.color.fgSubtle;
    }
    if (props.valueAsPercentage === 100) {
      return props.theme.color.successDefault;
    }
    return props.theme.color.infoMuted;
  }};
`;
