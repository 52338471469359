import { useContext, useEffect, useState } from 'react';

import { BaseButton } from 'common-ui';
import { Dialog, DialogProps } from 'common-ui/Dialog';
import { AuthContext } from 'context/AuthContext';

import { gql, useMutation, useQuery } from '@apollo/client';

import { NoteStatus } from '__generated__/globalTypes';

import {
  CreateDiligenceNote,
  CreateDiligenceNoteVariables,
} from 'mutation/__generated__/CreateDiligenceNote';
import { CREATE_DILIGENCE_NOTE } from 'mutation/diligenceMutations';

import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import {
  GetDiligenceNotes,
  GetDiligenceNotesVariables,
} from 'query/__generated__/GetDiligenceNotes';
import { GET_DILIGENCE_NOTES } from 'query/diligence';

interface DiligenceNotesDialogProps {
  documentName: string;
  diligenceId: string;
  dialog: DialogProps;
}

const DiligenceNotesDialog: React.FC<DiligenceNotesDialogProps> = ({
  documentName,
  diligenceId,
  dialog,
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const { state: authState } = useContext(AuthContext);
  const [givenName, setGivenName] = useState<string>('');
  const [familyName, setFamilyName] = useState<string>('');

  const { data, loading, refetch } = useQuery<
    GetDiligenceNotes,
    GetDiligenceNotesVariables
  >(GET_DILIGENCE_NOTES, {
    variables: { diligence_id: diligenceId },
    skip: !dialog.isOpen || !diligenceId,
    pollInterval: 10000,
  });

  const [createDiligenceNote] = useMutation<
    CreateDiligenceNote,
    CreateDiligenceNoteVariables
  >(CREATE_DILIGENCE_NOTE);

  const handleAddNote = (text: string) => {
    createDiligenceNote({
      variables: {
        input: {
          diligence_id: diligenceId,
          note: text,
        },
      },
      update: (cache) => {
        const id = `Diligence:${diligenceId}`;
        const diligence = cache.readFragment<GetDealDiligence_deal_diligence>({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              id
              note_status
            }
          `,
        });

        if (!diligence) {
          return;
        }

        cache.writeFragment({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              id
              note_status
            }
          `,
          data: { ...diligence, note_status: NoteStatus.READ },
        });
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (authState.user) {
      authState.user.getUserAttributes((err, data) => {
        if (!err && data) {
          setGivenName(
            data.find((attr) => attr.getName() === 'given_name')?.getValue() ||
              '',
          );
          setFamilyName(
            data.find((attr) => attr.getName() === 'family_name')?.getValue() ||
              '',
          );
        }
      });
    }
  }, [authState.user]);

  useEffect(() => {
    if (dialog.isOpen) {
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog}>
      <button
        className="text-grey-500 hover:text-grey-300 absolute right-[10px] top-[10px]"
        onClick={() => dialog.setIsOpen(false)}
      >
        ✕
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold">
        Add a Note for "{documentName}"
      </h2>
      <div className="mt-4 flex max-h-[444px] flex-col gap-2 overflow-y-auto">
        {loading && <div>Loading...</div>}
        {data &&
          data.diligenceNotes?.entities?.map((note) => {
            const isCounterparty =
              `${note?.author.given_name} ${note?.author.family_name?.[0]}` !==
              `${givenName} ${familyName?.[0]}`;

            return (
              <div
                key={note?.id}
                className={`flex ${isCounterparty ? 'justify-start' : 'justify-end'}`}
              >
                <div
                  className={`max-w-[80%] ${isCounterparty ? 'text-left' : 'text-right'}`}
                >
                  <div className="text-gray-500">
                    <span>
                      ({note?.author.given_name}{' '}
                      {note?.author.family_name?.substring(0, 1)})
                    </span>
                  </div>
                  <div className="mb-1 font-pt-sans-narrow text-xs text-slate-200">
                    {note?.created_time
                      ? new Date(
                          parseInt(note.created_time) * 1000,
                        ).toDateString()
                      : ''}
                  </div>
                  <div className="text-sm">{note?.note}</div>
                </div>
              </div>
            );
          })}
      </div>
      <textarea
        className="my-4 h-28 w-full rounded-md border border-pink-400 bg-background-surface p-2 text-sm text-foreground-default focus:border-pink-400 focus:outline-none"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => handleAddNote(noteText)}
        >
          Add Note
        </BaseButton>
      </div>
    </Dialog>
  );
};

export default DiligenceNotesDialog;
