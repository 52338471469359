import React from 'react';

type ProgressBarProps = {
  label: string;
  rank?: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ label, rank }) => {
  let render = <p className="mr-2 text-sm uppercase">No Data</p>;

  if (rank) {
    const textPositionClass = rank > 70 ? 'left-[-35px] pr-2' : 'left-0 pl-2';
    render = (
      <div className="relative h-[18px] w-[200px] rounded-sm bg-custom-gradient">
        <div className="relative top-0 h-full" style={{ left: `${rank}%` }}>
          <div className="absolute left-0 top-0 h-full w-1 bg-white"></div>
          <div
            className={`absolute flex h-full items-center text-sm font-semibold text-white ${textPositionClass}`}
          >
            {rank} %
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-4 flex items-center justify-between">
      <p className="text-sm text-slate-400">{label}</p>
      <div className="flex items-center">{render}</div>
    </div>
  );
};

export default ProgressBar;
