import { gql } from '@apollo/client';

const GET_CONTRACTS_FOR_DEAL = gql`
  query GetContractsForDeal($dealId: ID!) {
    getContractsForDeal(dealId: $dealId) {
      contractId
      contractVersion
      data {
        buyerSigned
        sellerSigned
        buyerReferenceText
        sellerReferenceText
        readyToSignBuyer
        readyToSignSeller
      }
      originalFileName
      contractSigningError
    }
  }
`;

export default GET_CONTRACTS_FOR_DEAL;
