import { useState } from 'react';

import { BaseButton } from 'common-ui';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import { styled } from 'style/ORSNNTheme';

import { PredefinedOrCustomParameter } from './BidDetails';
import ErrorMessage from '../../../../common-ui/form/ErrorMessage';

const PercentageButtonGroup = styled.div`
  margin: 10px 0;
  display: flex;
  color: #9e9e9e;

  .c-button {
    display: block;
    padding: 6px 5px;
    width: 80px;
    height: 32px;
    font-family: Heebo;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    background-color: ${({ theme }) => theme.color.sectionBackground};
    border-radius: 0;
    border-color: ${({ theme }) => theme.color.sectionBackground};

    &:first-of-type {
      border-radius: 29px 0 0 29px;
    }

    &:last-of-type {
      border-radius: 0px 29px 29px 0;
    }

    &--selected {
      color: #e9e9e9;
      background-color: ${({ theme }) => theme.color.accentMuted};
      border-color: ${({ theme }) => theme.color.accentMuted};
    }
  }
`;

export interface SelectPercentageProps {
  options: number[];
  selectedOption: PredefinedOrCustomParameter;
  setSelectedOption: (value: PredefinedOrCustomParameter) => void;
  customMin?: number;
  customMax?: number;
}

interface CustomPercentageInputProps {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  error: boolean;
}

const CustomPercentageInput = ({
  disabled,
  value,
  setValue,
  error,
}: CustomPercentageInputProps) => {
  return (
    <NumberInputWithUnits
      width={'100%'}
      unit="%"
      disabled={disabled}
      error={error}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};

const SelectPercentage = ({
  options,
  selectedOption,
  setSelectedOption,
  customMin = 0,
  customMax = 100,
}: SelectPercentageProps) => {
  // customInputValue is a local state used to store the value of the custom input field.
  // It stores the value as the user types when the "Custom" option is selected,
  // allowing for the value to be retained and displayed correctly in the input field.
  const [_customInputValue, setCustomInputValue] = useState<string>('');

  const updateCustomValue = (value: string) => {
    // Update the local state with the new value typed by the user
    setCustomInputValue(value);

    // We also update the selectedOption with the new value, ensuring other components relying on this value are updated in real time.
    setSelectedOption({ isCustom: true, value: value, draftValue: value });
  };

  const isValidCustomValue = () => {
    if (selectedOption.draftValue === '') return false;
    const valueAsNumber = Number(selectedOption.draftValue);
    return valueAsNumber >= customMin && valueAsNumber <= customMax;
  };

  const isError = selectedOption.isCustom && !isValidCustomValue();

  return (
    <div style={{ width: '100%' }}>
      <PercentageButtonGroup>
        {options.map((optionValue) => (
          <BaseButton
            type="tertiary"
            key={optionValue}
            label={'' + optionValue}
            isSelected={
              !selectedOption.isCustom &&
              optionValue === Number(selectedOption.value)
            }
            onClick={() =>
              setSelectedOption({
                isCustom: false,
                value: '' + optionValue,
                draftValue: selectedOption.draftValue,
              })
            }
          >
            {optionValue}%
          </BaseButton>
        ))}
        <BaseButton
          type="tertiary"
          label="custom"
          key="custom"
          isSelected={selectedOption.isCustom}
          onClick={() =>
            setSelectedOption({
              isCustom: true,
              value: selectedOption.draftValue,
              draftValue: selectedOption.draftValue,
            })
          }
        >
          Custom
        </BaseButton>
        <div style={{ marginLeft: 10, paddingTop: 2, flexGrow: 1 }}>
          <CustomPercentageInput
            disabled={!selectedOption.isCustom}
            value={'' + selectedOption.draftValue}
            error={isError}
            setValue={updateCustomValue}
          />
          <ErrorMessage>
            {isError && (
              <span>
                Value between {customMin}-{customMax}%
              </span>
            )}
          </ErrorMessage>
        </div>
      </PercentageButtonGroup>
    </div>
  );
};

export default SelectPercentage;
