import { createQueryFilters } from 'context/DataContext';
import useTableQueryParams from 'functions/useTableQueryParams';

import { useQuery } from '@apollo/client';

import {
  Filter,
  SortableField,
  SortDirection,
  AssetClass,
  FilterableField,
  FilterOperator,
} from '__generated__/globalTypes';

import {
  GetDrilldown,
  GetDrilldownVariables,
} from 'query/__generated__/GetDrilldown';
import { GET_DRILLDOWN_QUERY } from 'query/drilldown';

const getDrilldownVariables = (
  assetClass: AssetClass | null,
  filters: Filter[],
  sorting: { id: SortableField; desc: boolean }[],
): GetDrilldownVariables => {
  const queryFilters = createQueryFilters(assetClass, filters);
  return {
    sort: {
      sort_field: sorting[0]?.id,
      sort_direction: sorting[0]?.desc ? SortDirection.desc : SortDirection.asc,
    },
    pagination: {},
    assetClassFilter: {
      field_name: FilterableField.asset_class,
      operator: FilterOperator.IS,
      operand: assetClass,
    },
    filters: queryFilters,
  };
};

export const usePortfolioLoans = (
  gqlAssetClass: AssetClass | null,
  queryFilters: Filter[],
  loansSorting: { id: SortableField; desc: boolean }[],
) => {
  const { params, updateParams } = useTableQueryParams();

  const { data, loading, previousData } = useQuery<
    GetDrilldown,
    GetDrilldownVariables
  >(GET_DRILLDOWN_QUERY, {
    variables: {
      ...getDrilldownVariables(gqlAssetClass, queryFilters, loansSorting),
      pagination: {
        offset: (params.pageNumber - 1) * params.pageSize,
        page_size: params.pageSize,
      },
    },
    skip: !gqlAssetClass,
    fetchPolicy: 'no-cache',
  });

  return { data, loading, previousData, params, updateParams };
};
