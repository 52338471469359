import { SvgIcon } from 'common-ui';
import { ContentDiv } from 'common-ui/Dialog.styles';
import { typographyXSmallPrimaryRegular } from 'common-ui/typography';
import { typographyLabelXSmallPrimaryMedium } from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';
import { css } from 'styled-components';

// TODO(kentskinner): give this a better name and move it somewhere central.
const purpleBorderWithRadius = css`
  border: 1px solid #9a1698;
  border-radius: 6px;
`;

export const CompactDialogContainer = styled(ContentDiv)`
  ${typographyLabelXSmallPrimaryMedium}
  ${purpleBorderWithRadius}
  width: 362px;
  padding: 5px 10px 10px;
  text-align: left;
`;

export const CompactDialogHeader = styled.h1`
  ${typographyLabelXSmallPrimaryMedium}
  color: #afafaf;
  padding: 0;
  margin: 3px 0 10px;
  position: relative;
`;

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CloseButtonContainer onClick={onClick}>
      <SvgIcon name="close" />
    </CloseButtonContainer>
  );
};

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
export const Row = styled.div`
  display: flex;
  gap: 24px;
`;
export const SmallRowLabel = styled.div`
  ${typographyXSmallPrimaryRegular}
  color: ${(props) => props.theme.color.fgMuted};
`;
export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const RowsPerPageContainer = styled.div`
  ${typographyXSmallPrimaryRegular}
  color: ${(props) => props.theme.color.fgMuted};
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
