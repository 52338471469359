import { gql } from '@apollo/client';

const ListingCardFragments = {
  listing: gql`
    fragment ListingCardListing on Listing {
      id
      name
      assetClass: asset_class
      upb: t_current_balance_cents
      wac: wa_coupon
      createdDate: created_date
      providedName: provided_name
    }
  `,
};

export { ListingCardFragments };
