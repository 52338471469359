import { Button, KIND, SIZE } from 'baseui/button';
import { theme } from 'style/ORSNNTheme';

const undoCompactPadding = {
  marginTop: `-10px`,
  marginBottom: `-10px`,
  marginLeft: `-12px`,
  marginRight: `-12px`,
};

export const ButtonField = (props: {
  label: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}): JSX.Element => (
  <Button
    kind={KIND.minimal}
    size={SIZE.compact}
    onClick={props.onClick}
    overrides={{
      BaseButton: {
        style: {
          color: theme.color.brandMainPink,
          ':hover': {
            color: theme.color.brandMainPink,
            textDecoration: `underline solid ${theme.color.brandMainPink}`,
          },
          ...undoCompactPadding,
        },
      },
    }}
  >
    {props.label}
  </Button>
);
