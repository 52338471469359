import { gql } from '@apollo/client';

const PoolSummaryFragments = {
  performanceSummary: gql`
    fragment PoolSummaryPerformanceSummary on PerformanceSummary {
      unpaidBalance: current_balance_cents
      originalBalance: original_balance_cents
      loanCount: loan_count
      averageBalance: wa_current_balance_cents
      averageOriginalBalance: wa_original_balance_cents
      netCoupon: wa_coupon
      averageAge: wa_age_months
      averageMaturity: wa_remaining_loan_terms_months
      fico: wa_borrower_credit_score
      dti: wa_dti
      ltv: wa_ltv
      states: strat_state {
        stateCode: key
        loanCount: t_count
      }
    }
  `,
};

export { PoolSummaryFragments };
