import styled from 'styled-components';

/**
 * @deprecated This shouldn't be used as-is. It is a placeholder to replace reactstrap
 */
export const ModalBody = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
  max-height: 75vh;
  overflow-y: auto;
`;
