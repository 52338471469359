import React from 'react';

import { MagicWand } from '@styled-icons/boxicons-solid/MagicWand';
import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline';
import { Spinner3 } from '@styled-icons/evil/Spinner3';
import { Close } from '@styled-icons/ionicons-outline/Close';
import { BaseButton } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import _ from 'lodash';

import { gql, useLazyQuery } from '@apollo/client';

import { LoanField } from '__generated__/globalTypes';

import {
  AutoMapFields,
  AutoMapFieldsVariables,
} from './__generated__/AutoMapFields';
import { FieldIdAndLabel, FieldsMap } from './MapRequiredFieldsCard';

const AUTO_MAP_FIELDS_QUERY = gql`
  query AutoMapFields($input: AutoMapFieldsInput!) {
    autoMapFields(input: $input) {
      matches {
        csvHeader
        loanField
      }
      errors {
        missingEnums
        multipleHeadersToSingleEnum
        detailedMessage
      }
    }
  }
`;

type LLMAutoMapComponentProps = {
  requiredFields: FieldIdAndLabel[];
  optionalFields: FieldIdAndLabel[];
  columns: string[];
  fileId?: string;
  onAutoMapComplete: (
    fieldsMap: FieldsMap,
    remainingUnmappedHeaders: string[],
  ) => void;
};

const LLMAutoMapComponent: React.FC<LLMAutoMapComponentProps> = ({
  requiredFields,
  optionalFields,
  columns,
  fileId,
  onAutoMapComplete,
}) => {
  const dialog = useDialog();
  const fieldEnums = requiredFields.concat(optionalFields);
  const [autoMapFields, { loading }] = useLazyQuery<
    AutoMapFields,
    AutoMapFieldsVariables
  >(AUTO_MAP_FIELDS_QUERY, {
    onCompleted: (data) => {
      const fieldsMap = {} as FieldsMap;
      const matchResults =
        (data.autoMapFields.matches?.filter(
          (match) => match != null && !!match.loanField && !!match.csvHeader,
        ) as { loanField: LoanField; csvHeader: string }[]) || [];
      requiredFields.forEach((field) => {
        const match = matchResults.find((m) => m.loanField === field.fieldId);
        if (match && !_.values(fieldsMap).includes(match.csvHeader)) {
          fieldsMap[field.fieldId] = match.csvHeader;
        }
      });
      optionalFields.forEach((field) => {
        const match = matchResults.find((m) => m.loanField === field.fieldId);
        if (match && !_.values(fieldsMap).includes(match.csvHeader)) {
          fieldsMap[field.fieldId] = match.csvHeader;
        }
      });
      if (data.autoMapFields.errors != null) {
        console.error(data.autoMapFields.errors);
      }
      const remainingHeaders = columns.filter(
        (header) => !_.values(fieldsMap).includes(header),
      );
      onAutoMapComplete(fieldsMap, remainingHeaders);
    },
  });

  const handleAutoMapClick = () => {
    // if fieldIds or columns are empty, don't call autoMapFields
    if (!fieldEnums.length || !columns.length) {
      console.error('LLMAutoMap: Field enums or columns are empty');
      return;
    }

    dialog.openDialog();
    const fieldIds = fieldEnums.map((f) => f.fieldId);
    autoMapFields({
      variables: {
        input: {
          columns: columns,
          enums: fieldIds,
          fileId: fileId,
        },
      },
    });
  };

  return (
    <>
      <Dialog dialog={dialog}>
        <span
          className="absolute right-4 top-4 inline-block hover:cursor-pointer"
          onClick={dialog.closeDialog}
        >
          <Close size={24} className="text-foreground-subtle" />
        </span>
        <div className="flex flex-row gap-6">
          <div className="flex h-14 w-14 items-center justify-center rounded-lg bg-info-muted">
            <InfoOutline size={24} className="text-black" />
          </div>
          <div className="flex w-[400px] flex-col text-left">
            <h2 className="mb-2 text-xl">
              Mapping uses our generative A.I. model
            </h2>
            <p className="text-base">
              Once mapping is complete, please review the results as this will
              help fine-tune the process for future mapping.
            </p>
            <div className="mt-2 flex justify-end">
              <BaseButton onClick={dialog.closeDialog} label="Close dialog">
                OK
              </BaseButton>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="mr-5">
        <BaseButton
          type="secondary"
          onClick={handleAutoMapClick}
          disabled={loading}
          label="AI Automap"
        >
          {loading ? (
            <Spinner3 size={20} className="mr-1 animate-spin" />
          ) : (
            <MagicWand size={20} className="mr-1" />
          )}
          AI Automap
        </BaseButton>
      </div>
    </>
  );
};

export default LLMAutoMapComponent;
