import { DateTime } from 'luxon';

import { BidCard_BidCard_bid } from './BidCardBody/__generated__/BidCard';
import { basisPointsToPercent } from './utils';

type BidSummarySectionProps = {
  bid: BidCard_BidCard_bid;
};

const BuyerBidSummary = ({ bid }: BidSummarySectionProps): JSX.Element => {
  const hasDiligenceCompletionDate =
    bid.stipulations?.has_diligence_completion_date_seconds;
  const diligenceCompletionDate =
    bid.stipulations?.diligence_completion_date_seconds;
  const hasSettlementDate = bid.stipulations?.has_settlement_date_seconds;
  const settlementDate = bid.stipulations?.settlement_date_seconds;
  const bidBasisPoints = bid.stipulations?.bid_basis_points || 0;
  const servicing = Number(bid.stipulations?.servicing_rate) || 0;
  const gwac = bid.carve_summary?.wa_coupon || 0;
  const nwac = 100 * gwac - servicing;

  const netYieldAtPrice = () => {
    const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
    if (pricePercent !== 0) {
      return ((100 * nwac) / pricePercent).toFixed(3) + '%';
    }
    return '-';
  };

  const hasCustomStipulations =
    (bid.stipulations?.custom_stipulations?.length ?? 0) > 0;

  if (bid.carve_summary == null) return <></>;

  return (
    <table className="mt-3 w-full border-collapse text-sm leading-5 text-slate-200">
      <tbody className="text-left">
        <tr className="border-t border-slate-600">
          <td>Price</td>
          <td className="text-right font-mono text-slate-300">
            {basisPointsToPercent(bidBasisPoints)}%
          </td>
        </tr>
        {bid.stipulations?.is_servicing_retained ? (
          <tr className="border-t border-slate-600">
            <td>Servicing Fee</td>
            <td className="text-right font-mono text-slate-300">
              {bid.stipulations?.servicing_rate}%
            </td>
          </tr>
        ) : (
          <tr className="border-t border-slate-600">
            <td>Servicing</td>
            <td className="text-right font-mono text-slate-300">Released</td>
          </tr>
        )}
        <tr className="border-t border-slate-600">
          <td>Net Yield @ Price</td>
          <td className="text-right font-mono text-slate-300">
            {netYieldAtPrice()}
          </td>
        </tr>
        {bid.stipulations?.has_percent_collateral_diligence && (
          <tr className="border-t border-slate-600">
            <td>Percent Collateral Diligence</td>
            <td className="text-right font-mono text-slate-300">
              {bid.stipulations?.percent_collateral_diligence}%
            </td>
          </tr>
        )}
        {bid.stipulations?.has_min_net_yield_to_buyer && (
          <tr className="border-t border-slate-600">
            <td>Min Net Yield to Buyer</td>
            <td className="text-right font-mono text-slate-300">
              {bid.stipulations?.min_net_yield_to_buyer}%
            </td>
          </tr>
        )}
        {hasDiligenceCompletionDate && diligenceCompletionDate && (
          <tr className="border-t border-slate-600">
            <td>Diligence Completion Date</td>
            <td className="text-right font-mono text-slate-300">
              {DateTime.fromSeconds(diligenceCompletionDate).toLocaleString(
                DateTime.DATE_SHORT,
              )}
            </td>
          </tr>
        )}
        {hasSettlementDate && settlementDate && (
          <tr className="border-t border-slate-600">
            <td>Settlement Date</td>
            <td className="text-right font-mono text-slate-300">
              {DateTime.fromSeconds(settlementDate).toLocaleString(
                DateTime.DATE_SHORT,
              )}
            </td>
          </tr>
        )}
        {hasCustomStipulations && (
          <tr className="border-t border-slate-600">
            <td>Custom Stipulations</td>
            <td className="text-right font-mono text-slate-300">
              {bid.stipulations?.custom_stipulations
                ?.filter(Boolean)
                .map((stip) => <div key={stip}>{stip}</div>)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BuyerBidSummary;
