export interface DocumentPreviewProps {
  documentUrl: string;
  fileExtension?: string;
}

const DocumentPreview = ({
  documentUrl,
  fileExtension,
}: DocumentPreviewProps) => {
  const renderImagePreview = () => (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <img
        src={documentUrl}
        alt="Document Preview"
        className="max-h-full max-w-full"
      />
    </div>
  );

  const renderCsvPreview = () => (
    <div className="h-screen overflow-auto bg-gray-100 p-4">
      <iframe
        title="CSV Preview"
        src={documentUrl}
        width="100%"
        height="780"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );

  const renderPdfPreview = () => (
    <div className="h-screen">
      <iframe
        title="PDF Preview"
        src={documentUrl}
        width="100%"
        height="780"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );

  const renderDownloadButton = () => (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <a
        href={documentUrl}
        download
        className="text-blue-500 underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Document
      </a>
    </div>
  );

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
      return renderImagePreview();

    case 'csv':
      return renderCsvPreview();

    case 'pdf':
      return renderPdfPreview();

    default:
      return renderDownloadButton();
  }
};

export default DocumentPreview;
