import DealStagesFragments from 'features/deals/DealStages/fragments';

import { gql } from '@apollo/client';

export const REJECT_BID = gql`
  mutation RejectBid($input: RejectBidInput!) {
    rejectBid(input: $input) {
      id
      ...DealStagesDeal
    }
  }
  ${DealStagesFragments.Deal}
`;
