import { useCallback } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';
import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';
import { GET_POOL_SETTINGS, SAVE_POOL_SETTINGS } from 'query/poolManager';

export const useUserSettings = (settingName: string) => {
  const savedPoolSettings = useQuery<GetPoolSettings>(GET_POOL_SETTINGS, {
    variables: { settingName },
    fetchPolicy: 'network-only',
  });

  const savedUserSettings = savedPoolSettings.data?.user?.setting;
  const userPoolSettings = savedUserSettings
    ? JSON.parse(savedUserSettings)
    : null;

  const [addUserSettingMutation] = useMutation<SavePoolSettings>(
    SAVE_POOL_SETTINGS,
    {
      onCompleted: () => {
        savedPoolSettings.refetch();
      },
    },
  );

  const addUserSetting = useCallback(
    (value: string) =>
      addUserSettingMutation({ variables: { settingName, value } }),
    [addUserSettingMutation, settingName],
  );

  return {
    addUserSetting,
    savedPoolSettings,
    userPoolSettings,
  };
};
