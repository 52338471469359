import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Spinner } from 'baseui/spinner';
import moment from 'moment';
import styled from 'styled-components';

import { ListDealMessages_dealMessages_entities } from './__generated__/ListDealMessages';

const Author = styled.div<{ $isActiveUser: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.$isActiveUser ? '#FFFFFF' : '#9E9E9E')};
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface MessageProps {
  dealMessage: ListDealMessages_dealMessages_entities;
  isLoading?: boolean;
}

const UnwrappedMessage: ForwardRefRenderFunction<
  HTMLDivElement,
  MessageProps
> = ({ dealMessage, isLoading }, ref) => {
  if (isLoading) {
    return (
      <div className="flex flex-col">
        <div className="white-space-pre-wrap rounded-b-lg bg-gray-950 p-4 shadow-md shadow-black">
          <SpinnerContainer>
            <Spinner color="#e85ee5" aria-label="loading more messages" />
          </SpinnerContainer>
        </div>
      </div>
    );
  }
  const { given_name, family_name, is_current_user } = dealMessage.author;
  return (
    <div className="flex flex-col" ref={ref}>
      <div className="flex flex-col items-start p-0">
        <Author $isActiveUser={is_current_user}>
          {`${given_name} ${family_name || ''}`}
        </Author>
        <div className="text-xs font-normal leading-5 text-gray-600">
          {moment
            .unix(Number(dealMessage.created_time))
            .format('MM-DD-YY @ h:mmA')}
        </div>
      </div>
      <div className="white-space-pre-wrap rounded-b-lg bg-gray-950 p-4 shadow-md shadow-black">
        {dealMessage.message}
      </div>
    </div>
  );
};

export const Message = forwardRef(UnwrappedMessage);
