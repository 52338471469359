import { gql, useQuery } from '@apollo/client';

import { GetListingsList } from './__generated__/GetListingsList';

export const GET_LISTINGS_LIST = gql`
  query GetListingsList {
    user {
      id
      company {
        id
        listings {
          id
          last_updated_date
          name
          asset_class
          created_date
          provided_name
          loan_count
          ... on PublicListing {
            servicing_fee_rate
          }
          state
          t_current_balance_cents
          wa_coupon
          wa_loan_age_months
          wa_borrower_credit_score
          wa_remaining_loan_terms_months
          wa_ltv
          wa_dti
          ... on UserCompanyListing {
            is_public
          }
        }
      }
    }
  }
`;

export const useGetListingsList = () => {
  return useQuery<GetListingsList>(GET_LISTINGS_LIST, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });
};
