import { Spinner } from 'baseui/spinner';
import { ToggleButton } from 'common-ui';
import toast from 'react-hot-toast';

import { gql, useMutation, useQuery } from '@apollo/client';

import { GetNotificationPreferences } from './__generated__/GetNotificationPreferences';
import { UpdateNotificationPreferencesVariables } from './__generated__/UpdateNotificationPreferences';

const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences {
    notificationPreferences {
      userPreferences {
        emailOptIn
      }
    }
  }
`;

const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation UpdateNotificationPreferences(
    $emailOptIn: Boolean!
    $textOptIn: Boolean!
    $phone: String!
  ) {
    putNotificationPreferences(
      requestData: {
        emailOptIn: $emailOptIn
        textOptIn: $textOptIn
        phone: $phone
      }
    ) {
      success
    }
  }
`;

const EmailOptInPrefs = () => {
  const { data, loading } = useQuery<GetNotificationPreferences>(
    GET_NOTIFICATION_PREFERENCES,
  );

  const [updateNotificationPreferences] = useMutation<
    UpdateNotificationPreferencesVariables,
    UpdateNotificationPreferencesVariables
  >(UPDATE_NOTIFICATION_PREFERENCES);

  const handleToggle = async (checked: boolean) => {
    await updateNotificationPreferences({
      variables: { emailOptIn: checked, textOptIn: false, phone: '' },
    });

    if (checked) {
      toast.success('You have opted in to receive email notifications.');
    } else {
      toast.success('You have opted out of email notifications.');
    }
  };

  const emailOptIn =
    data?.notificationPreferences?.userPreferences?.emailOptIn || false;

  return (
    <div className="p-4">
      <table className="w-full text-left">
        <tbody>
          <tr>
            <td className="w-52 pr-4 text-xs font-medium uppercase text-gray-500">
              Email Notifications Opt-In
            </td>
            <td>
              {loading ? (
                <div className="flex items-center justify-center">
                  <Spinner size={34} />
                </div>
              ) : (
                <div className="flex flex-col items-end">
                  <ToggleButton
                    initialState={emailOptIn}
                    checkedLabel="On"
                    uncheckedLabel="Off"
                    onChange={handleToggle}
                  />
                  {emailOptIn && (
                    <p className="mt-2 max-w-md text-xs text-gray-500">
                      You have opted in to receive email notifications.
                    </p>
                  )}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmailOptInPrefs;
