import { useMemo } from 'react';

import { DropdownPillComboboxSingleAdd, StyledPillsRow } from 'common-ui';
import { isNotNull } from 'features/deals/DealStages/EventActionCards/utils';
import { isArrayOf, isNumber, isString } from 'functions/typeUtils';
import { keyBy, chain } from 'lodash';

import { FilterableField } from '__generated__/globalTypes';

import { BooleanPillFilters } from './filterComponents/BooleanPillFilter';
import { ListInputPillFilter } from './filterComponents/ListInputPillFilter';
import { MultiSelectPillFilter } from './filterComponents/MultiSelectPillFilter';
import { RangePillFilter } from './filterComponents/RangePillFilter';
import { SingleSelectPillFilter } from './filterComponents/SingleSelectPillFilter';
import {
  BaseFiltersConfig,
  BooleanFilter,
  FilterValue,
  ListInputFilter,
  MultiSelectFilter,
  RangeFilter,
  SingleSelectFilter,
} from './filters.types';

export type PillFiltersProps<T extends BaseFiltersConfig> = {
  filtersConfig: T;
  currentFilters: FilterValue<T>[];
  setCurrentFilters: (filters: FilterValue<T>[]) => void;
};

export function PillFilters<T extends BaseFiltersConfig>(
  props: PillFiltersProps<T>,
) {
  const allFiltersSorted = useMemo(
    () =>
      chain(props.filtersConfig)
        .map((value, key) => ({
          name: key as keyof T,
          ...value,
        }))
        .sortBy('displayName')
        .value(),
    [props.filtersConfig],
  );

  const currentMap = useMemo(
    () => keyBy(props.currentFilters, 'name'),
    [props.currentFilters],
  );

  const availableFilters = allFiltersSorted.filter(
    (filter) => !currentMap[filter.name as string],
  );

  function removeFilter(_filterName: keyof T, index: number) {
    const newArr = [...props.currentFilters];
    newArr.splice(index, 1);
    props.setCurrentFilters(newArr);
  }

  function addFilter(filterConfig: (typeof availableFilters)[number]) {
    const newFilter = (() => {
      switch (filterConfig.type) {
        case 'boolean':
          return {
            name: filterConfig.name,
            value: false,
          };
        case 'range':
          return {
            name: filterConfig.name,
            min: filterConfig.min,
            max: filterConfig.max,
          };
        case 'select-single':
          return {
            name: filterConfig.name,
            value: null,
          };
        case 'select-multi':
          return {
            name: filterConfig.name,
            value: [],
          };
        case 'list-input':
          return {
            name: filterConfig.name,
            values: [],
          };
      }
    })();

    props.setCurrentFilters([...props.currentFilters, newFilter]);
  }

  function updateFilter(
    filterName: keyof T,
    index: number,
    values: [number, number] | string | boolean | string[],
  ) {
    const newArr = [...props.currentFilters];

    let val: Partial<FilterValue<T>>;
    if (
      isNotNull(values) &&
      isArrayOf(isNumber)(values) &&
      values.length === 2
    ) {
      val = {
        min: values[0],
        max: values[1],
      };
    } else if (
      filterName === FilterableField.account_id &&
      isArrayOf(isString)(values)
    ) {
      val = { values };
    } else {
      val = { value: values };
    }

    newArr[index] = {
      ...(newArr[index] as FilterValue<T>),
      ...val,
    };

    props.setCurrentFilters(newArr);
  }

  return (
    <StyledPillsRow>
      <span>Filter:</span>{' '}
      {props.currentFilters.map((filter, index) => {
        const filterConfig =
          filter.name in props.filtersConfig
            ? props.filtersConfig[filter.name]
            : null;
        if (!filterConfig) return null;
        const itemProps = {
          removeFilter,
          updateFilter,
          index,
          key: filter.name as string,
        };
        switch (filterConfig.type) {
          case 'boolean':
            return (
              <BooleanPillFilters<T>
                {...itemProps}
                filter={filter as BooleanFilter<T>}
                filterConfig={filterConfig}
              />
            );
          case 'range':
            return (
              <RangePillFilter<T>
                {...itemProps}
                rangeFilter={filter as RangeFilter<T>}
                filterConfig={filterConfig}
              />
            );
          case 'select-single':
            return (
              <SingleSelectPillFilter<T>
                {...itemProps}
                filter={filter as SingleSelectFilter<T>}
                filterConfig={filterConfig}
              />
            );
          case 'select-multi':
            return (
              <MultiSelectPillFilter<T>
                {...itemProps}
                filter={filter as MultiSelectFilter<T>}
                filterConfig={filterConfig}
              />
            );
          case 'list-input':
            return (
              <ListInputPillFilter<T>
                {...itemProps}
                filter={filter as ListInputFilter<T>}
                filterConfig={filterConfig}
              />
            );
          default:
            return null;
        }
      })}
      <DropdownPillComboboxSingleAdd
        options={availableFilters}
        onValueSelected={addFilter}
        buttonLabel="Add Filter"
        getOptionLabel={(option) => option.displayName}
        getOptionValue={(option) => option.name as string}
        comboLabel="Search for a filter"
        inputPlaceholder="Enter a filter name..."
        manuLabel="All filters (Ascending)"
      />
    </StyledPillsRow>
  );
}

export type CommonProps<T> = {
  removeFilter: (filterName: keyof T, index: number) => void;
  updateFilter: (
    filterName: keyof T,
    index: number,
    values: [number, number] | string | boolean | string[],
  ) => void;
  index: number;
};
