import { FC, useEffect, useState, useRef } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { Option } from 'features/common-elements';
import {
  USTYieldCurve,
  TreasuryYields,
  SOFRYieldCurve,
  SofrYields,
} from 'features/pages/market-data/components';
import { URLMap } from 'features/pages/market-data/shared';
import { MultiValue, SingleValue } from 'react-select';

import { useQuery } from '@apollo/client';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';

const isMultiValue = (
  value: SingleValue<Option> | MultiValue<Option>,
): value is MultiValue<Option> => {
  return Array.isArray(value);
};

const queryClient = new QueryClient();

const InterestRatesData: FC = () => {
  const [marketDataMapUrls, setMarketDataMapUrls] = useState<URLMap | null>(
    null,
  );
  const [selectedCategory, setSelectedCategory] = useState<string>('SOFR');
  const { data, loading } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.marketData) {
      setMarketDataMapUrls(data.marketData.urlMap as unknown as URLMap);
    }
  }, [data, loading]);

  const sliderRef = useRef<HTMLDivElement>(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (sliderRef.current) {
      const width = sliderRef.current.clientWidth - 10;
      const height = width / 1.618 - 50;
      setChartDimensions({ width, height });
    }
  }, []);

  const handleCategoryChange = (
    selectedOption: SingleValue<Option> | MultiValue<Option>,
  ) => {
    if (!selectedOption || isMultiValue(selectedOption)) {
      return;
    }
    setSelectedCategory(selectedOption.value);
  };

  const categoryOptions = [
    { value: 'Treasury', label: 'Treasury' },
    { value: 'SOFR', label: 'SOFR' },
  ];

  return (
    <div ref={sliderRef}>
      <div className="mb-4 flex items-end justify-between">
        <h2 className="font-heebo text-lg font-medium text-white">
          Interest Rates
        </h2>
        <Dropdown
          options={categoryOptions}
          onChange={handleCategoryChange}
          placeholder="Select Category"
          value={categoryOptions.find(
            (option) => option.value === selectedCategory,
          )}
        />
      </div>
      {selectedCategory === 'Treasury' ? (
        <>
          <USTYieldCurve
            dataUrl={marketDataMapUrls?.['yc_chart_data.json']}
            className="!mt-0 mb-2 w-full rounded-lg border border-slate-700 !px-0"
            {...chartDimensions}
          />
          <TreasuryYields
            dataUrl={
              marketDataMapUrls?.['home_page_treasury_yields_chart_data.json']
            }
            className="!mt-0 w-full rounded-lg border border-slate-700 !px-0"
            {...chartDimensions}
          />
        </>
      ) : (
        <>
          <SOFRYieldCurve
            dataUrl={marketDataMapUrls?.['sofr_yc_chart_data.json']}
            className="!mt-0 mb-2 w-full rounded-lg border border-slate-700 !px-0"
            {...chartDimensions}
          />
          <SofrYields
            dataUrl={
              marketDataMapUrls?.['home_page_sofr_yields_chart_data.json']
            }
            className="!mt-0 w-full rounded-lg border border-slate-700 !px-0"
            {...chartDimensions}
          />
        </>
      )}
    </div>
  );
};

export const InterestRates: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <InterestRatesData />
    </QueryClientProvider>
  );
};
