import { gql } from '@apollo/client';

const MARK_NOTIFICATION_READ_MUTATION = gql`
  mutation MarkNotificationRead($requestData: MarkReadRequest!) {
    markNotificationRead(requestData: $requestData) {
      success
    }
  }
`;

export { MARK_NOTIFICATION_READ_MUTATION };
