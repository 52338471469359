import { useState } from 'react';

import {
  useFloating,
  useInteractions,
  shift,
  offset,
  flip,
  useClick,
  useDismiss,
  UseFloatingOptions,
  OffsetOptions,
  autoUpdate,
} from '@floating-ui/react';

export function usePopout(options?: {
  placement?: UseFloatingOptions['placement'];
  offset?: OffsetOptions;
  onClose?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  function onOpenChange(open: boolean) {
    setIsOpen(open);
    if (!open) {
      options?.onClose?.();
    }
  }

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: onOpenChange,
    placement: options?.placement || 'bottom-start',
    middleware: [shift(), flip(), offset(options?.offset || 10)],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ]);

  return {
    isOpen,
    setIsOpen,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
    refs,
  };
}

export { FloatingPortal } from '@floating-ui/react';
