import { NavLink } from 'react-router-dom';

import './sub-header-menu-item.scss';
import { HeaderMenuItemConfig } from '../Header/headers.config';

interface PassedProps<RouteKeys extends string> {
  headerConfig: HeaderMenuItemConfig<RouteKeys>;
  to: string;
}

export const SubHeaderMenuItem = <RouteKeys extends string>({
  headerConfig,
  to,
}: PassedProps<RouteKeys>) => {
  return (
    <div className="sub_header_menu_item">
      <li className="sub_header_menu_item__list_item">
        <NavLink
          className={({ isActive }) =>
            headerConfig.disabled
              ? 'sub_header_menu_item__link sub_header_menu_item__link--disabled'
              : isActive
                ? 'sub_header_menu_item__link sub_header_menu_item__link--active'
                : 'sub_header_menu_item__link'
          }
          to={to}
        >
          {headerConfig.label}
        </NavLink>
      </li>
    </div>
  );
};
