import { FC } from 'react';

import Dinero from 'dinero.js';
import { isUnsecEnabled } from 'features/flags';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { GetPortfolioOverview } from './__generated__/GetPortfolioOverview';
import { KPISkeleton } from './KPISkeleton';
import { GET_PORTFOLIO_OVERVIEW_QUERY } from './PortfolioOverview.fragments';

const KPIComponent: FC<{ value?: number; label: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex min-w-36 flex-col gap-1 rounded bg-kip-gradient px-2 py-2 text-center">
      <p className="font-heebo text-sm font-bold text-slate-50">
        {Dinero({ amount: value ?? 0, currency: 'USD' }).toFormat()}
      </p>
      <span className="font-heebo text-xl font-black">{label}</span>
    </div>
  );
};

export const PortfolioOverview: FC = () => {
  const { data, loading } = useQuery<GetPortfolioOverview>(
    GET_PORTFOLIO_OVERVIEW_QUERY,
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <h2 className="font-heebo text-lg font-medium text-white">
          My Portfolio At A Glance
        </h2>
        <Link
          className="h-fit w-fit rounded border border-pink-500 px-1.5 text-pink-500 hover:scale-105 active:scale-100"
          to="/portfolio/tape-upload"
        >
          Upload Tape
        </Link>
      </div>
      <div className="flex flex-row justify-between gap-4 overflow-x-auto">
        {loading ? (
          <KPISkeleton />
        ) : (
          <KPIComponent
            label="ALL"
            value={data?.user.company.totalPerformance.unpaidBalance}
          />
        )}
        {loading ? (
          <KPISkeleton />
        ) : (
          <KPIComponent
            label="AUTO"
            value={data?.user.company.autoPerformance.unpaidBalance}
          />
        )}
        {loading ? (
          <KPISkeleton />
        ) : (
          <KPIComponent
            label="HOME"
            value={data?.user.company.homePerformance.unpaidBalance}
          />
        )}
        {loading && isUnsecEnabled() ? (
          <KPISkeleton />
        ) : (
          isUnsecEnabled() && (
            <KPIComponent
              label="UNSEC PERSONAL"
              value={data?.user.company.unsecPersonalPerformance.unpaidBalance}
            />
          )
        )}
      </div>
    </div>
  );
};
