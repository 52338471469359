import { gql } from '@apollo/client';

export const MARK_DILIGENCE_COMPLETE = gql`
  mutation MarkDiligenceComplete($input: MarkDiligenceCompleteInput!) {
    markDiligenceComplete(input: $input) {
      id
      state {
        status
        tooltip
      }
    }
  }
`;
