import { styled } from 'style/ORSNNTheme';

const DetailRow = styled.div`
  border-top: 1px solid #28303e;
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;

  color: var(--colors-slate-200, #868e9f);
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* typography/ParagraphSmall/Primary/Regular */
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export { DetailRow };
