import { useState, useEffect, useCallback, FC } from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { IconEdit, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { Pill, SortablePill } from 'ui-kit/Pill';

import { FetchResult, QueryResult, OperationVariables } from '@apollo/client';

import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';
import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';

import { KPI } from '../Dashboard/PoolManager/types';

type MetricsHeaderProps = {
  title: string;
  numPools?: number;
  metrics: KPI[];
  addUserSetting: (value: string) => Promise<FetchResult<SavePoolSettings>>;
  savedPoolSettings: QueryResult<GetPoolSettings, OperationVariables>;
  userPoolSettings?: string[];
};

const Tooltip: FC<{ label: string }> = ({ children, label }) => {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className="rounded-md bg-gray-950 p-2 text-gray-400 shadow-2xl"
            sideOffset={5}
          >
            {label}
            <RadixTooltip.Arrow className="fill-gray-950" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export const MetricsHeader: FC<MetricsHeaderProps> = ({
  title,
  numPools,
  metrics,
  addUserSetting,
  savedPoolSettings,
  userPoolSettings,
}) => {
  const { loading, error } = savedPoolSettings;
  const [isEditMode, setIsEditMode] = useState(false);
  const [displayedMetrics, setDisplayedMetrics] = useState<KPI[]>([]);
  const [editDisplayedMetrics, setEditDisplayedMetrics] = useState<KPI[]>([]);
  const [menuMetrics, setMenuMetrics] = useState<KPI[]>([]);
  const [editMenuMetrics, setEditMenuMetrics] = useState<KPI[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const saveUserSettings = useCallback(
    (metricsToUpdate: KPI[]) => {
      const selectorsToSave = metricsToUpdate.map((metric) => metric.selector);
      const formattedMetrics = JSON.stringify(selectorsToSave);
      return addUserSetting(formattedMetrics);
    },
    [addUserSetting],
  );

  useEffect(() => {
    if (!loading && !error) {
      if (!userPoolSettings || userPoolSettings.length === 0) {
        // If no metrics are saved, automatically add and save the first three
        const initialMetrics = metrics.slice(0, 3);
        setDisplayedMetrics(initialMetrics);
        setMenuMetrics(metrics.slice(3));
        saveUserSettings(initialMetrics);
      } else {
        const accumulatedMetrics = userPoolSettings
          .map((selector) =>
            metrics.find((metric) => metric.selector === selector),
          )
          .filter(Boolean) as KPI[];
        setDisplayedMetrics(accumulatedMetrics);
        setMenuMetrics(
          metrics.filter(
            (metric) =>
              !accumulatedMetrics.some((m) => m.selector === metric.selector),
          ),
        );
      }
    } else if (userPoolSettings == null) {
      setDisplayedMetrics([]);
      setMenuMetrics(metrics);
    }
  }, [userPoolSettings, metrics, loading, error, saveUserSettings]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setEditDisplayedMetrics((items) => {
        const oldIndex = items.findIndex((item) => item.selector === active.id);
        const newIndex = items.findIndex((item) => item.selector === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleRemove = useCallback(
    (selector?: string) => {
      if (selector && editDisplayedMetrics.length > 1) {
        setEditDisplayedMetrics((items) => {
          const removedItem = items.find((item) => item.selector === selector);
          if (removedItem) {
            setEditMenuMetrics((menuItems) => [...menuItems, removedItem]);
          }
          return items.filter((item) => item.selector !== selector);
        });
      }
    },
    [editDisplayedMetrics],
  );

  const handleAdd = useCallback(
    (selector?: string) => {
      if (selector) {
        const itemToAdd = editMenuMetrics.find(
          (item) => item.selector === selector,
        );
        if (itemToAdd) {
          setEditDisplayedMetrics((items) => [...items, itemToAdd]);
          setEditMenuMetrics((items) =>
            items.filter((item) => item.selector !== selector),
          );
        }
      }
    },
    [editMenuMetrics],
  );

  const handleEditMode = () => {
    setIsEditMode(true);
    setEditDisplayedMetrics([...displayedMetrics]);
    setEditMenuMetrics([...menuMetrics]);
  };

  const handleSave = () => {
    saveUserSettings(editDisplayedMetrics).then(() => {
      setDisplayedMetrics([...editDisplayedMetrics]);
      setMenuMetrics([...editMenuMetrics]);
      setIsEditMode(false);
    });
  };

  const handleCancel = () => {
    setEditDisplayedMetrics([...displayedMetrics]);
    setEditMenuMetrics([...menuMetrics]);
    setIsEditMode(false);
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="mb-3 flex items-center">
      <div className="flex flex-row items-center justify-between">
        <div>
          <span className="max-w-52 pr-8 text-xs text-slate-400">{title}</span>
          {numPools && (
            <span className="pr-8 text-xs text-white">{`${numPools} Pools`}</span>
          )}
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis]}
      >
        <div className="flex flex-row flex-wrap items-center space-x-4">
          {loading ? (
            <div className="flex flex-row gap-5">
              {Array.from({ length: 4 }).map((_, index) => (
                <Pill isLoading key={index} />
              ))}
            </div>
          ) : (
            <SortableContext
              items={(isEditMode ? editDisplayedMetrics : displayedMetrics).map(
                (metric) => metric.selector,
              )}
              strategy={horizontalListSortingStrategy}
            >
              {(isEditMode ? editDisplayedMetrics : displayedMetrics).map(
                (metric, _index) =>
                  isEditMode ? (
                    <SortablePill
                      key={metric.selector}
                      id={metric.selector}
                      label={metric.description}
                      value={metric.value}
                      isDraggable={editDisplayedMetrics.length > 1}
                      isRemovable={editDisplayedMetrics.length > 1}
                      onRemove={() => handleRemove(metric.selector)}
                    />
                  ) : (
                    <Pill
                      key={metric.selector}
                      label={metric.description}
                      value={metric.value}
                    />
                  ),
              )}
            </SortableContext>
          )}
        </div>
      </DndContext>
      {isEditMode && (
        <RadixDropdownMenu.Root>
          <Tooltip label="Add more metrics">
            <RadixDropdownMenu.Trigger asChild>
              <button
                className="ml-4 rounded-full bg-gray-800 p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
                aria-label="Add more metrics"
              >
                <IconEdit size={16} className="cursor-pointer text-gray-400" />
              </button>
            </RadixDropdownMenu.Trigger>
          </Tooltip>
          <RadixDropdownMenu.Portal>
            <RadixDropdownMenu.Content
              className="shadow-combined rounded-md bg-gray-950 px-4 py-3"
              sideOffset={5}
              style={{
                maxHeight: '200px',
                maxWidth: '250px',
                minWidth: '200px',
                overflowY: 'auto',
              }}
            >
              {editMenuMetrics.length > 0 ? (
                <div className="flex flex-col gap-2">
                  {editMenuMetrics.map((metric) => (
                    <Pill
                      key={metric.selector}
                      label={metric.description}
                      value={metric.value}
                      isAddableToList
                      onAdd={() => handleAdd(metric.selector)}
                    />
                  ))}
                </div>
              ) : (
                <div className="py-2 text-center text-sm text-gray-400">
                  <p className="mb-1">No more metrics available</p>
                </div>
              )}
              {editMenuMetrics.length > 0 && (
                <div className="mt-2 border-t border-gray-800 pt-2 text-center text-xs text-gray-400">
                  Click '+' to add an item to metrics list
                </div>
              )}
              <RadixDropdownMenu.Arrow className="fill-gray-950" />
            </RadixDropdownMenu.Content>
          </RadixDropdownMenu.Portal>
        </RadixDropdownMenu.Root>
      )}
      <div className="ml-8 flex gap-2">
        {!isEditMode && !loading && (
          <Tooltip label="Edit metrics">
            <button
              onClick={handleEditMode}
              className="rounded-full bg-gray-800 p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
              aria-label="Edit metrics"
            >
              <IconEdit size={16} className="text-gray-200" />
            </button>
          </Tooltip>
        )}
        {isEditMode && (
          <>
            <Tooltip label="Save changes">
              <button
                onClick={handleSave}
                className="rounded-full bg-gray-800 p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
                aria-label="Save changes"
              >
                <IconDeviceFloppy size={16} className="text-gray-200" />
              </button>
            </Tooltip>
            <Tooltip label="Cancel editing">
              <button
                onClick={handleCancel}
                className="rounded-full bg-gray-800 p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
                aria-label="Cancel editing"
              >
                <IconX size={16} className="text-gray-200" />
              </button>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};
