import { ReactNode, VFC } from 'react';

import { styled } from 'style/ORSNNTheme';

export interface EmptyAction {
  label: ReactNode;
  onClick: () => void;
}

interface EmptyProps {
  message: string;
  action?: EmptyAction;
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  width: 0;
  align-items: center;
  overflow: visible;
  position: relative;
  min-height: 100px;
`;

const Layout = styled.div`
  display: flex;
  gap: 17px;
  flex-direction: column;
  width: max-content;
  position: absolute;
  left: 64px;
`;

const Message = styled.h1`
  font: ${({ theme }) => theme.typography.dataP1};
  color: ${({ theme }) => theme.color.fgDefault};
`;

const Button = styled.button`
  border: 1px solid #e85ee5;
  color: #e85ee5;
  border-radius: 4px;
  background-color: transparent;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  &:focus {
    outline: none;
  }
`;

export const Empty: VFC<EmptyProps> = ({ message, action }) => {
  return (
    <Wrapper>
      <Layout>
        <Message>{message}</Message>
        {typeof action !== 'undefined' ? (
          <Button onClick={action.onClick}>{action.label}</Button>
        ) : null}
      </Layout>
    </Wrapper>
  );
};
