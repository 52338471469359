import { useMemo } from 'react';

import { typographyHeebo14Bold } from 'common-ui/typography';
import Dinero from 'dinero.js';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { Props as LabelProps } from 'recharts/types/component/Label';
import { CartesianViewBox, ViewBox } from 'recharts/types/util/types';
import { styled } from 'style/ORSNNTheme';

import { gql } from '@apollo/client';

import { MonthlyTableCashFlowMonth } from './gql/__generated__/MonthlyTableCashFlowMonth';

const COLORS = {
  principal: '#7865E0',
  interest: '#ffcf74',
  prepayments: '#11763d',
  lossRecovery: '#e95c7b',
};

const ChartFragments = {
  cashFlowMonth: gql`
    fragment ChartCashFlowMonth on CashFlowMonth {
      month
      cash_flow_cents
    }
  `,
};

type Props = {
  cashFlowMonths?: MonthlyTableCashFlowMonth[];
};

const ToolTipContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  background-color: #100f0e;
  border-radius: 4px;
  border-color: #e5e5e5;
  color: #ffffff;
  padding: 12px 16px;
  text-align: center;
  ${typographyHeebo14Bold}

  div {
    display: flex;
    justify-content: space-between;
  }
`;

type CustomTooltipProps = {
  active?: boolean;
  payload?: Payload<ValueType, NameType>[];
};

const CustomTooltip = ({
  active,
  payload,
}: CustomTooltipProps): JSX.Element => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;
    return (
      <ToolTipContainer>
        <div style={{ color: COLORS.principal }}>
          <span>Principal</span>{' '}
          <span>{`${formatCurrency(dataPoint.principal_cents)}`}</span>
        </div>
        <div style={{ color: COLORS.interest }}>
          <span>Interest</span>{' '}
          <span>{`${formatCurrency(dataPoint.interest_cents)}`}</span>
        </div>
        <div style={{ color: COLORS.prepayments }}>
          <span>Prepayments</span>{' '}
          <span>{`${formatCurrency(dataPoint.prepayments_cents)}`}</span>
        </div>
        <div style={{ color: COLORS.lossRecovery }}>
          <span>Loss Recovery</span>{' '}
          <span>{`${formatCurrency(dataPoint.losses_cents)}`}</span>
        </div>
      </ToolTipContainer>
    );
  } else {
    return <></>;
  }
};

const formatCurrency = (value: number) =>
  Dinero({ amount: value, currency: 'USD' }).toFormat('$0,0.00');

type ReferenceLabelProps = {
  value: string;
  fontSize?: number;
} & LabelProps;

const isCartesianViewBox = (viewBox?: ViewBox): viewBox is CartesianViewBox =>
  viewBox != null && 'x' in viewBox && 'y' in viewBox;

function ReferenceLabel(props: ReferenceLabelProps) {
  const { value, fontSize, viewBox } = props;
  if (!isCartesianViewBox(viewBox)) {
    return null;
  }
  const x = viewBox?.x || 0;
  const y = 48;
  const triangleSize = 10;

  return (
    <g>
      <text
        x={x - 20}
        y={y - 8}
        stroke={'#ffffff'}
        fontSize={fontSize || 10}
        fontFamily="Heebo"
      >
        {value}
      </text>
      <polygon
        points={`${x},${y + triangleSize} ${x - triangleSize / 2},${y} ${
          x + triangleSize / 2
        },${y}`}
        fill="#ffffff"
      />
    </g>
  );
}

/** WAL here means weighted average life
 * weighted in time direction by the principal paydowns
 * https://orsnn.slack.com/archives/C04Q86G0E4S/p1710987787341639
 * */
const getWAL = (cashFlowMonths?: MonthlyTableCashFlowMonth[]) => {
  if (!cashFlowMonths) {
    return 0;
  }
  const numerator = cashFlowMonths.reduce(
    (acc, curr) => acc + curr.cash_flow_cents * curr.month,
    0,
  );
  const denominator = cashFlowMonths.reduce(
    (acc, curr) => acc + curr.cash_flow_cents,
    0,
  );
  const wal = numerator / denominator;
  return Math.round(wal);
};

const Chart = ({ cashFlowMonths }: Props): JSX.Element => {
  const wal = useMemo(() => getWAL(cashFlowMonths), [cashFlowMonths]);

  return cashFlowMonths ? (
    <ResponsiveContainer width="99%" height="99%">
      <BarChart data={cashFlowMonths}>
        <XAxis dataKey="month" />
        <Bar
          dataKey="principal_cents"
          fill={COLORS.principal}
          stackId="a"
          name="Principal"
        />
        <Bar
          dataKey="interest_cents"
          fill={COLORS.interest}
          stackId="a"
          name="Interest"
        />
        <Bar
          dataKey="prepayments_cents"
          fill={COLORS.prepayments}
          stackId="a"
          name="Prepayments"
        />
        <Bar
          dataKey="losses_cents"
          fill={COLORS.lossRecovery}
          stackId="a"
          name="Loss Recovery"
        />
        <ReferenceLine
          x={wal}
          isFront={true}
          stroke="#ffffff"
          strokeDasharray="1 3"
          label={<ReferenceLabel value={`WAL: ${wal}`} />}
        />
        <Tooltip
          content={(props) =>
            CustomTooltip({
              ...props,
            })
          }
          cursor={{
            stroke: '#ffffff',
            strokeWidth: 1,
            fill: 'transparent',
          }}
        />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="left"
          wrapperStyle={{
            fontFamily: 'Heebo',
            fontSize: '12px',
            color: '#868e9f',
            paddingBottom: '30px',
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <></>
  );
};

export default Chart;
export { ChartFragments };
