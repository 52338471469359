import React, { FC } from 'react';

interface StepLabelProps {
  text: string;
  className?: string;
}

const StepLabel: FC<StepLabelProps> = ({ text, className }) => {
  return (
    <div
      className={`text-brand-emphasis flex items-center gap-2 bg-accent-subtle px-4 py-2 font-heebo text-lg font-normal capitalize leading-6 ${className}`}
    >
      {text}
    </div>
  );
};

export default StepLabel;
