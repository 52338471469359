import { useState } from 'react';

import { useDrag } from 'react-dnd';
import { styled } from 'style/ORSNNTheme';

import { ItemTypes } from './common';
import { SearchBox } from './SearchBox';

const StyledWrapper = styled.div`
  color: ${(props) => props.theme.color.gray300};
  font-size: 12px;
`;

const Instructions = styled.p`
  color: ${(props) => props.theme.color.slate200};
  font-size: 12.6px;
  padding-left: 12px;
`;

const HeaderItem = ({ label }: { label: string }): JSX.Element => {
  const [, drag] = useDrag(() => ({
    type: ItemTypes.HEADER,
    item: { label },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    // TODO(kentskinner): according to Figma the pill should be opaque when
    // dragging and dropping, but setting the opacity in the style here doesn't
    // seem to work. The cursor changes correctly, however.
    <StyledPill
      ref={drag}
      style={{
        cursor: 'grab',
      }}
    >
      {label}
    </StyledPill>
  );
};

const StyledPill = styled.div`
  padding: 6px 16px;
  margin: 10px;
  color: ${(props) => props.theme.color.fgDefault};
  line-height: 20px;
  font-size: 14px;

  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.slate300};
  border-radius: 100px;

  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.color.slate400};
  }
`;

const FileHeadersList = ({
  unmappedHeaders,
}: {
  unmappedHeaders: string[];
}) => {
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleSearchTermChanged = (text: string) => {
    setSearchTerm(text);
  };

  const matchesSearchText = (value: string) => {
    if (!searchTerm) return true;
    return value.toLowerCase().includes(searchTerm?.toLowerCase());
  };

  const sortedUnmmappedHeaders = [...unmappedHeaders];
  sortedUnmmappedHeaders.sort();

  return (
    <StyledWrapper>
      <p className="mb-1">
        Search for a column from your loan tape to map to ORSNN
      </p>
      <SearchBox
        placeholder="Filter columns by name"
        onSearchTermChanged={handleSearchTermChanged}
      />
      <Instructions className="mt-1">
        Select one field and drag and drop onto the respective field on the left
      </Instructions>
      <div className="flex h-[560px] flex-col flex-wrap content-start items-start justify-start overflow-x-auto">
        {sortedUnmmappedHeaders.filter(matchesSearchText).map((header) => {
          return <HeaderItem key={header} label={header} />;
        })}
      </div>
    </StyledWrapper>
  );
};

export default FileHeadersList;
