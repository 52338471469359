import React from 'react';

import styled from 'styled-components';

import { SvgIcon } from './Icons/SvgIcon/SvgIcon';
import { typographyLabelXSmallPrimaryRegular } from './typography';

export interface PageNumberBoxesProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  ${typographyLabelXSmallPrimaryRegular}
  gap: 5px;
  user-select: none;
`;

const PageDiv = styled.div<{ isActive?: boolean }>`
  width: auto;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;

  border: 1px solid ${(props) => props.theme.color.bgMuted};
  border-radius: 1px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
  // transition: background-color 0.3s ease;
  background-color: ${(props) =>
    props.isActive ? '#fff' : props.theme.color.bgSurface};
  border-color: ${(props) =>
    props.isActive ? props.theme.color.white : props.theme.color.bgMuted};
  color: ${(props) =>
    props.isActive ? props.theme.color.fgOnAccent : props.theme.color.fgMuted};

  &:hover {
    background-color: #fff;
  }
`;

const Dots = styled.span`
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisabledDiv = styled.div`
  opacity: 0.2;
`;

const CursorWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const getPages = (
  currentPage: number,
  totalPages: number,
): (number | 'dots')[] => {
  // Short-circuits for cases where totalPages <= 9
  if (totalPages <= 9)
    return Array.from({ length: totalPages }, (_, i) => i + 1);

  const pages: (number | 'dots')[] = [];

  const middlePage =
    currentPage <= 5
      ? 5
      : currentPage >= totalPages - 4
        ? totalPages - 4
        : currentPage;

  pages.push(1);
  pages.push(middlePage > 5 ? 'dots' : 2);
  pages.push(middlePage - 2);
  pages.push(middlePage - 1);
  pages.push(middlePage);
  pages.push(middlePage + 1);
  pages.push(middlePage + 2);
  pages.push(middlePage < totalPages - 4 ? 'dots' : totalPages - 1);
  pages.push(totalPages);

  return pages;
};

const PageNumberBoxes: React.FC<PageNumberBoxesProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  const pages = getPages(currentPage, totalPages);

  return (
    <PaginationContainer>
      {isPrevDisabled ? (
        <DisabledDiv>
          <CursorWrapper>
            <SvgIcon name="icon-previous-page" />
          </CursorWrapper>
        </DisabledDiv>
      ) : (
        <div onClick={() => onPageChange(currentPage - 1)}>
          <CursorWrapper>
            <SvgIcon name="icon-previous-page" />
          </CursorWrapper>
        </div>
      )}

      {pages.map((page, index) =>
        page === 'dots' ? (
          <Dots key={`dots-${index}`}>
            <SvgIcon name="icon-ellipsis" />
          </Dots>
        ) : (
          <PageDiv
            key={page}
            isActive={currentPage === page}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PageDiv>
        ),
      )}

      {isNextDisabled ? (
        <DisabledDiv>
          <CursorWrapper>
            <SvgIcon name="icon-next-page" />
          </CursorWrapper>
        </DisabledDiv>
      ) : (
        <div onClick={() => onPageChange(currentPage + 1)}>
          <CursorWrapper>
            <SvgIcon name="icon-next-page" />
          </CursorWrapper>
        </div>
      )}
    </PaginationContainer>
  );
};

export default PageNumberBoxes;
