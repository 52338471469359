import { CarveDetailsCard_CarveDetailsCard_carves_carve as Filter } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/__generated__/CarveDetailsCard';

import { FilterOperator, FilterableField } from '__generated__/globalTypes';

import { Criteria } from './CreateCarveForm';

export const convertFiltersToCriteria = (filters: Filter[]): Criteria[] => {
  // Group the filters by their field_name
  const groupedFilters: { [key in FilterableField]?: Filter[] } = {};

  for (const filter of filters) {
    const key = filter.field_name;
    if (!groupedFilters[key]) {
      groupedFilters[key] = [];
    }
    groupedFilters[key]?.push(filter);
  }

  // Convert grouped filters to criteria
  const criteriaList: Criteria[] = [];
  for (const [field, fieldFilters] of Object.entries(groupedFilters)) {
    const criteria: Criteria = {
      field: field as unknown as FilterableField,
    };

    if (fieldFilters) {
      for (const filter of fieldFilters) {
        switch (filter.operator) {
          case FilterOperator.GREATER_THAN_OR_EQUALS:
          case FilterOperator.GREATER_THAN:
            criteria.min = filter.operand ? Number(filter.operand) : null;
            break;
          case FilterOperator.LESS_THAN_OR_EQUALS:
          case FilterOperator.LESS_THAN:
            criteria.max = filter.operand ? Number(filter.operand) : null;
            break;
          case FilterOperator.IS:
            if (!criteria.values) criteria.values = [];
            criteria.values = filter.operandList || undefined;
            break;
        }
      }
    }

    criteriaList.push(criteria);
  }

  return criteriaList;
};

export const criteriaToFilters = (criteriaList: Criteria[]): Filter[] => {
  const filters: Filter[] = [];

  for (const criteria of criteriaList) {
    const { field, min, max, values } = criteria;

    if (min !== undefined && min !== null) {
      filters.push({
        field_name: field,
        operator: FilterOperator.GREATER_THAN_OR_EQUALS,
        operand: min.toString(),
        operandList: null,
      });
    }

    if (max !== undefined && max !== null) {
      filters.push({
        field_name: field,
        operator: FilterOperator.LESS_THAN_OR_EQUALS,
        operand: max.toString(),
        operandList: null,
      });
    }

    if (values) {
      filters.push({
        field_name: field,
        operator: FilterOperator.IS,
        operand: null,
        operandList: values,
      });
    }
  }

  return filters;
};
