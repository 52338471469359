import { FC } from 'react';

import { DocumentLibraryUpload } from './components/DocumentLibraryUpload';
import { InterestRates } from './components/InterestRates';
import { MarketColor } from './components/MarketColor';
import { MarketplaceBlotter } from './components/MarketplaceBlotter';
import { NotificationsSection } from './components/NotificationsSection';
import { PortfolioOverview } from './components/PortfolioOverview';
import { LatestNews } from './News';

export const HomePage: FC = () => {
  return (
    <div
      className="mt-3 h-full rounded-lg bg-background-surface p-3"
      style={{ gridArea: 'page' }}
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-[24px]">
        <div>
          <div className="overflow-x-hidden">
            <MarketColor />
          </div>
          <div className="mt-[16px] overflow-x-hidden">
            <InterestRates />
          </div>
        </div>
        <div>
          <div>
            <PortfolioOverview />
          </div>
          <div className="mt-[24px] overflow-hidden">
            <MarketplaceBlotter />
          </div>
          <div className="mt-[24px] h-[460px] overflow-hidden">
            <NotificationsSection />
          </div>
        </div>
        <div>
          <section
            data-testid="market-news-section"
            className="h-[360px] overflow-hidden"
          >
            <LatestNews />
          </section>
          <div className="mt-[24px] min-h-[360px]">
            <DocumentLibraryUpload />
          </div>
        </div>
      </div>
    </div>
  );
};
