import { useContext, useState, useEffect } from 'react';

import { IconTrash, IconPlus } from '@tabler/icons-react';
import { Spinner } from 'baseui/spinner';
import { BaseButton } from 'common-ui/Buttons';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { AuthContext } from 'context/AuthContext';
import { isNotNullOrUndefined } from 'functions/typeUtils';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select, { MultiValue, SingleValue } from 'react-select';
import { useTheme } from 'styled-components';

import { useQuery, useMutation } from '@apollo/client';

import { UserRole } from '__generated__/globalTypes';

import {
  UserManagementCreateUser,
  UserManagementCreateUserVariables,
} from 'mutation/__generated__/UserManagementCreateUser';
import {
  UserManagementRemoveUserFromCompany,
  UserManagementRemoveUserFromCompanyVariables,
} from 'mutation/__generated__/UserManagementRemoveUserFromCompany';
import {
  UserManagementUpdateUserRoles,
  UserManagementUpdateUserRolesVariables,
} from 'mutation/__generated__/UserManagementUpdateUserRoles';
import {
  UPDATE_USER_ROLES,
  REMOVE_USER_FROM_COMPANY,
  CREATE_USER,
} from 'mutation/userData';

import { GetUserCompany } from 'query/__generated__/GetUserCompany';
import {
  UserManagementListCompanyUserPermissions,
  UserManagementListCompanyUserPermissionsVariables,
} from 'query/__generated__/UserManagementListCompanyUserPermissions';
import { GET_USER_COMPANY } from 'query/company';
import { LIST_COMPANY_USER_PERMISSIONS } from 'query/permissionsQueries';

import { UserModel } from './User';

const userRoles = [
  { value: UserRole.admin, label: 'Admin' },
  { value: UserRole.research_user, label: 'Research User' },
  { value: UserRole.deal_user, label: 'Deal User' },
  { value: UserRole.viewer, label: 'Viewer' },
];

function isMultiValue<T>(
  value: SingleValue<T> | MultiValue<T>,
): value is MultiValue<T> {
  return Array.isArray(value);
}

const Permissions = () => {
  const theme = useTheme();
  const { state: authState } = useContext(AuthContext);
  const [_userInfo, setUserInfo] = useState<UserModel>(); // TODO unused state?
  const [_error, setError] = useState<string>(''); // TODO unused state?
  const [isRemovingUser, setIsRemovingUser] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      givenName: '',
      familyName: '',
      phoneNumber: '+1',
      roles: [{ value: UserRole.viewer, label: 'Viewer' }],
    },
  });

  const { data: companyData, loading: companyLoading } =
    useQuery<GetUserCompany>(GET_USER_COMPANY);

  const {
    loading,
    data,
    error: queryError,
    refetch,
  } = useQuery<
    UserManagementListCompanyUserPermissions,
    UserManagementListCompanyUserPermissionsVariables
  >(LIST_COMPANY_USER_PERMISSIONS, {
    variables: {
      requestData: { companyID: companyData?.user.company.id || '' },
    },
    skip: !companyData?.user.company.id,
    pollInterval: 60000,
  });

  useEffect(() => {
    if (authState.user && companyData?.user.company) {
      authState.user.getUserAttributes((err, data) => {
        if (err && process.env.NODE_ENV === 'development') {
          console.error(err);
        }
        if (data) {
          const givenName = data
            .find((user) => user.getName() === 'given_name')
            ?.getValue();
          const familyName = data
            .find((user) => user.getName() === 'family_name')
            ?.getValue();
          const email = data
            .find((user) => user.getName() === 'email')
            ?.getValue();
          setUserInfo({
            givenName: givenName || '',
            familyName: familyName || '',
            email: email || '',
            phone: '',
            title: '',
            company: companyData.user.company.name,
            roles: '',
          });
        }
      });
    }
  }, [authState.user, companyData]);

  useEffect(() => {
    if (companyData?.user.company.id) {
      refetch();
    }
  }, [companyData?.user.company.id, refetch]);

  const [addUserToCompany] = useMutation<
    UserManagementCreateUser,
    UserManagementCreateUserVariables
  >(CREATE_USER, {
    onCompleted: (data) => {
      if (!data.userManagementCreateUser.success) {
        setError('Failed to add user');
        toast.error('Failed to add user');
      } else {
        refetch();
        reset();
        setIsOpen(false);
        toast.success('User added successfully');
      }
    },
    onError: (error) => {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    },
  });

  const [updateUserRoles] = useMutation<
    UserManagementUpdateUserRoles,
    UserManagementUpdateUserRolesVariables
  >(UPDATE_USER_ROLES, {
    onCompleted: (data) => {
      if (!data.userManagementUpdateUserRoles.success) {
        setError('Failed to update roles');
        toast.error('Failed to update roles');
      } else {
        refetch();
        toast.success('Roles updated successfully');
      }
    },
    onError: (error) => {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    },
  });

  const [removeUserFromCompany] = useMutation<
    UserManagementRemoveUserFromCompany,
    UserManagementRemoveUserFromCompanyVariables
  >(REMOVE_USER_FROM_COMPANY, {
    onCompleted: (data) => {
      setIsRemovingUser(null);
      if (!data.userManagementRemoveUserFromCompany.success) {
        setError('Failed to remove user');
        toast.error('Failed to remove user');
      } else {
        refetch();
        toast.success('User removed successfully');
      }
    },
    onError: (error) => {
      setIsRemovingUser(null);
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    },
  });

  const handleUpdateRoles = (
    userEmail: string,
    companyID: string,
    roles: MultiValue<{ value: UserRole }>,
  ) => {
    updateUserRoles({
      variables: {
        requestData: {
          userEmail,
          companyID,
          roles: roles.map((role) => role.value),
        },
      },
    });
  };

  const handleRemoveUser = (userEmail: string, companyID: string) => {
    setIsRemovingUser(userEmail);
    removeUserFromCompany({
      variables: {
        requestData: { userEmail, companyID },
      },
    });
  };

  const handleAddUser = (data: {
    email: string;
    givenName: string;
    familyName: string;
    phoneNumber: string;
    roles: { value: UserRole }[];
  }) => {
    if (!companyData?.user.company.id) {
      setError('Company ID is required');
      return;
    }

    addUserToCompany({
      variables: {
        requestData: {
          userEmail: data.email,
          givenName: data.givenName,
          familyName: data.familyName,
          phoneNumber: data.phoneNumber,
          roles: data.roles.map((role) => role.value),
          companyID: companyData.user.company.id,
        },
      },
    });
  };

  const Trigger = (
    <div className="flex justify-end">
      <BaseButton
        label="Add User"
        type="primary"
        className="ml-2 flex gap-[4px]"
        size="small"
        onClick={() => setIsOpen(true)}
      >
        <IconPlus size={20} className="mr-1 !fill-none" />
        Add User
      </BaseButton>
    </div>
  );

  if (companyLoading) {
    return <Spinner size={34} />;
  }

  if (!companyData || !companyData.user.company) {
    return <div>No company information available.</div>;
  }

  const noPermission = (
    <div className="rounded-md bg-gray-800 p-4 text-white">
      <h2 className="text-center text-xl font-semibold">
        Ask your account admin to update users or permissions.
      </h2>
    </div>
  );

  const renderBody = () => {
    return (
      <div className="rounded-md p-4 text-white">
        {loading ? (
          <div className="flex items-center justify-center">
            <Spinner size={34} />
          </div>
        ) : (
          <div>
            <ModalNotStyled
              trigger={Trigger}
              initialOpen={isOpen}
              handleClose={() => setIsOpen(false)}
            >
              {({ closeModal }) => (
                <div className="w-[600px] overflow-y-auto rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
                  <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-semibold">Add New User</h2>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onClick={() => {
                        closeModal();
                        reset();
                      }}
                    >
                      ✕
                    </button>
                  </div>
                  <form onSubmit={handleSubmit(handleAddUser)} className="mt-4">
                    <div className="mt-4">
                      <label className="mb-1 block text-left text-xs font-bold capitalize">
                        Email
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: 'Email is required' }}
                        render={({ field }) => (
                          <input
                            type="email"
                            {...field}
                            required
                            className="color-[#E0E0E0] radius-[4px] w-full rounded-[4px] border-[1px] border-[#E0E0E0] bg-[transparent] px-[15px] py-[5px] text-sm"
                          />
                        )}
                      />
                      {errors.email && (
                        <p className="mt-[4px] text-xs text-red-200">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label className="mb-1 block text-left text-xs font-bold capitalize">
                        Given Name
                      </label>
                      <Controller
                        name="givenName"
                        control={control}
                        rules={{ required: 'Given Name is required' }}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            required
                            className="color-[#E0E0E0] radius-[4px] w-full rounded-[4px] border-[1px] border-[#E0E0E0] bg-[transparent] px-[15px] py-[5px] text-sm"
                          />
                        )}
                      />
                      {errors.givenName && (
                        <p className="mt-[4px] text-xs text-red-200">
                          {errors.givenName.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label className="mb-1 block text-left text-xs font-bold capitalize">
                        Family Name
                      </label>
                      <Controller
                        name="familyName"
                        control={control}
                        rules={{ required: 'Family Name is required' }}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            required
                            className="color-[#E0E0E0] radius-[4px] w-full rounded-[4px] border-[1px] border-[#E0E0E0] bg-[transparent] px-[15px] py-[5px] text-sm"
                          />
                        )}
                      />
                      {errors.familyName && (
                        <p className="mt-[4px] text-xs text-red-200">
                          {errors.familyName.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label className="mb-1 block text-left text-xs font-bold capitalize">
                        Phone Number
                      </label>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: 'Phone number is required',
                          pattern: {
                            value: /^\+1\d{10}$/,
                            message:
                              'Phone number must be in the format +1XXXXXXXXXX',
                          },
                        }}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            required
                            placeholder="+1XXXXXXXXXX"
                            className="color-[#E0E0E0] radius-[4px] w-full rounded-[4px] border-[1px] border-[#E0E0E0] bg-[transparent] px-[15px] py-[5px] text-sm"
                          />
                        )}
                      />
                      {errors.phoneNumber && (
                        <p className="mt-[4px] text-xs text-red-200">
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label className="mb-1 block text-left text-xs font-bold capitalize">
                        Roles
                      </label>
                      <Controller
                        name="roles"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isMulti
                            value={field.value}
                            options={userRoles}
                            onChange={field.onChange}
                            styles={{
                              control: (css) => ({
                                ...css,
                                borderRadius: '4px',
                                backgroundColor: theme.color.black,
                                color: '#E85EE5',
                                fontSize: '12px',
                                padding: '2px 4px',
                                border: `solid 1px ${theme.color.accentEmphasis}`,
                              }),
                              menu: (css) => ({
                                ...css,
                                borderRadius: '4px',
                                backgroundColor: theme.color.black,
                                color: theme.color.fgDefault,
                                font: theme.typography.primaryXSmall,
                                border: `solid 1px ${theme.color.accentEmphasis}`,
                                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)',
                                fontSize: '10px',
                                padding: '0px 10px',
                              }),
                              option: (css, optionProps) => ({
                                ...css,
                                padding: '4px 8px',
                                backgroundColor:
                                  optionProps.isSelected ||
                                  optionProps.isFocused
                                    ? theme.color.slate300
                                    : 'inherit',
                                ':first-child': {
                                  borderRadius: '4px 4px 0px 0px',
                                },
                                ':last-child': {
                                  borderRadius: '0px 0px 4px 4px',
                                },
                              }),
                              multiValue: (css) => ({
                                ...css,
                                fontSize: '12px',
                                marginRight: '6px',
                              }),
                              multiValueLabel: (css) => ({
                                ...css,
                                fontSize: '12px',
                              }),
                              multiValueRemove: (css) => ({
                                ...css,
                                color: theme.color.white,
                                ':hover': {
                                  color: '#731172',
                                },
                              }),
                            }}
                            closeMenuOnSelect={true}
                            unstyled={true}
                            openMenuOnClick={true}
                          />
                        )}
                      />
                    </div>
                    <div className="mt-4 flex justify-end gap-4">
                      <BaseButton
                        label="Add User"
                        type="primary"
                        onClick={handleSubmit(handleAddUser)}
                      >
                        Add User
                      </BaseButton>
                      <BaseButton
                        label="Cancel"
                        type="secondary"
                        onClick={() => {
                          closeModal();
                          reset();
                        }}
                      >
                        Cancel
                      </BaseButton>
                    </div>
                  </form>
                </div>
              )}
            </ModalNotStyled>

            <p className="mb-2 text-left text-white">Your company users</p>
            <div
              className="overflow-y-auto pb-[60px]"
              style={{ maxHeight: '800px' }}
            >
              {(data?.userManagementListCompanyUserPermissions?.userPermissions
                .length || 0) > 0
                ? data?.userManagementListCompanyUserPermissions?.userPermissions?.map(
                    ({ user, permission }) => (
                      <div
                        key={permission.principleID}
                        className="mb-2 flex items-center justify-between border-t border-gray-700 p-2 first:border-none"
                      >
                        <div className="flex flex-col items-start">
                          <p className="font-bold text-white">
                            {user.givenName} {user.familyName}
                          </p>
                          <p className="text-gray-400">{user.email}</p>
                        </div>
                        <div>
                          <Select
                            isMulti
                            value={permission.roles
                              ?.filter(isNotNullOrUndefined)
                              .map((role) => ({
                                value: role,
                                label: role.replace('_', ' ').toUpperCase(),
                              }))}
                            options={userRoles}
                            onChange={(options) =>
                              !isMultiValue(options) &&
                              handleUpdateRoles(
                                user.email,
                                companyData?.user.company.id || '',
                                options,
                              )
                            }
                            styles={{
                              control: (css) => ({
                                ...css,
                                borderRadius: '4px',
                                color: '#E85EE5',
                                fontSize: '10px',
                                margin: '0px 10px',
                                padding: '2px 4px',
                              }),
                              menu: (css) => ({
                                ...css,
                                width: '100%',
                                position: 'absolute',
                                borderRadius: '4px',
                                backgroundColor: theme.color.black,
                                color: theme.color.fgDefault,
                                font: theme.typography.primaryXSmall,
                                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)',
                                fontSize: '10px',
                                padding: '0px 10px',
                                zIndex: 5,
                              }),
                              option: (css, optionProps) => ({
                                ...css,
                                padding: '4px 8px',
                                fontWeight:
                                  optionProps.isSelected ||
                                  optionProps.isFocused
                                    ? '600'
                                    : '',
                                opacity:
                                  optionProps.isSelected ||
                                  optionProps.isFocused
                                    ? '1'
                                    : '0.5',
                                ':first-child': {
                                  borderRadius: '4px 4px 0px 0px',
                                },
                                ':last-child': {
                                  borderRadius: '0px 0px 4px 4px',
                                },
                              }),
                              multiValue: (css) => ({
                                ...css,
                                fontSize: '10px',
                                marginRight: '6px',
                              }),
                              multiValueLabel: (css) => ({
                                ...css,
                                fontSize: '10px',
                              }),
                              multiValueRemove: (css) => ({
                                ...css,
                                color: theme.color.white,
                                ':hover': {
                                  color: '#731172',
                                },
                              }),
                            }}
                            closeMenuOnSelect={true}
                            unstyled={true}
                            openMenuOnClick={true}
                          />
                        </div>
                        <div className="flex items-center">
                          <BaseButton
                            label="Delete"
                            size="small"
                            type="secondary"
                            onClick={() =>
                              handleRemoveUser(
                                user.email,
                                companyData?.user.company.id || '',
                              )
                            }
                            className="flex items-center text-slate-200"
                            disabled={isRemovingUser === user.email}
                          >
                            {isRemovingUser === user.email ? (
                              <Spinner size={16} />
                            ) : (
                              <>
                                <IconTrash
                                  size={20}
                                  className="mr-1 !fill-none"
                                />
                                <span>Delete</span>
                              </>
                            )}
                          </BaseButton>
                        </div>
                      </div>
                    ),
                  )
                : 'No users'}
            </div>
          </div>
        )}
      </div>
    );
  };

  return queryError?.message.includes('Not authorized')
    ? noPermission
    : renderBody();
};

export default Permissions;
