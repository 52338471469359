import React from 'react';

import { SvgIcon } from 'common-ui';

interface ErrorMessageProps {
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, className }) => {
  return (
    <div className={`relative ${className}`}>
      {children ? (
        <span className="flex items-center text-sm text-red-500">
          <SvgIcon name="Icon-error" className="mr-1 -translate-y-px" />
          {children}
        </span>
      ) : null}
    </div>
  );
};

export default ErrorMessage;
