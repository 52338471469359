import { Auth } from 'aws-amplify';
import axios from 'axios';

const LOG_FLUSH_INTERVAL = 5000; // Interval in milliseconds to flush logs

const logBuffer: Set<string> = new Set();
let logFlushTimeout: NodeJS.Timeout | null = null;

let apiUrl = '';

export const initializeLogger = (url: string) => {
  apiUrl = url;
  interceptConsoleLogs();
  setupClickstreamLogging();
  logPerformanceStats();
};

export const sendLogs = async (logs: string[] | string, token: string) => {
  if (!Array.isArray(logs)) {
    logs = [logs];
  }
  if (!apiUrl || !token) {
    return;
  }

  logs.forEach((log) => logBuffer.add(log));

  if (!logFlushTimeout) {
    logFlushTimeout = setTimeout(async () => {
      const logsToSend = Array.from(logBuffer);
      logBuffer.clear();
      logFlushTimeout = null;

      if (logsToSend.length > 0) {
        await axios.post(
          apiUrl,
          { logs: logsToSend },
          {
            headers: {
              Authorization: token,
            },
          },
        );
      }
    }, LOG_FLUSH_INTERVAL);
  }
};

const interceptConsoleLogs = () => {
  // Intercept console logs and errors
  const originalLog = console.log;
  const originalError = console.error;

  const getToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      originalError('Failed to retrieve security token', error);
      return '';
    }
  };

  console.log = (...args) => {
    if (process.env.NODE_ENV === 'development') {
      originalLog(...args);
    } else {
      getToken().then((token) => {
        args = args.map((arg) => {
          try {
            return JSON.stringify(arg);
          } catch {
            return String(arg);
          }
        });
        sendLogs(args, token);
      });
    }
  };

  console.error = (...args) => {
    if (process.env.NODE_ENV === 'development') {
      originalError(...args);
    } else {
      getToken().then((token) => {
        args = args.map((arg) => {
          try {
            return JSON.stringify(arg);
          } catch {
            return String(arg);
          }
        });
        sendLogs(args, token);
      });
    }
  };
};

const logEmfMetric = (
  logType: string,
  properties: Record<string, unknown>,
  metrics?: Record<string, { value: number; unit: string }>,
) => {
  const timestamp = Date.now();
  const emfLog: Record<string, unknown> = {
    _aws: {
      Timestamp: timestamp,
      CloudWatchMetrics: [
        {
          Namespace: 'ORSNN/Application',
          Dimensions: [['LogType']],
          Metrics: metrics
            ? Object.keys(metrics).map((name) => ({
                Name: name,
                Unit: metrics[name].unit,
              }))
            : [],
        },
      ],
    },
    LogType: logType,
    ...properties,
  };

  if (metrics) {
    for (const [key, metric] of Object.entries(metrics)) {
      emfLog[key] = metric.value;
    }
  }

  console.log(emfLog);
};

const setupClickstreamLogging = () => {
  document.addEventListener('click', (e: MouseEvent) => {
    if (e.target instanceof Element) {
      logEmfMetric(
        'UserClick',
        {
          elementType: e.target.tagName || 'unknown',
          elementId: e.target.id || 'none',
          elementClass: e.target.className || 'none',
          elementAriaLabel: e.target.getAttribute('aria-label') || 'none',
          x: e.clientX,
          y: e.clientY,
          page: window.location.pathname,
          timestamp: new Date().toISOString(),
        },
        { count: { value: 1, unit: 'Count' } },
      );
    } else {
      logEmfMetric(
        'UserClick',
        {
          elementType: 'unknown',
          e: JSON.stringify(e),
          timestamp: new Date().toISOString(),
          page: window.location.pathname,
        },
        { count: { value: 1, unit: 'Count' } },
      );
    }
  });
};

type PerformanceStats = {
  timeToFirstByte: number;
  domContentLoaded: number;
  pageLoadTime: number;
  firstPaint: number;
  firstContentfulPaint: number;
  largestContentfulPaint: number;
  navigationType: string;
};

// TODO use web-vitals or some similar library to get comprehensive performance metrics
const logPerformanceStats = () => {
  const logStats = () => {
    window.requestAnimationFrame(() => {
      const navigationEntry = performance.getEntriesByType(
        'navigation',
      )[0] as PerformanceNavigationTiming;
      if (!navigationEntry) return;

      const performanceData: PerformanceStats = {
        timeToFirstByte:
          navigationEntry.responseStart - navigationEntry.requestStart,
        domContentLoaded:
          navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime,
        pageLoadTime: navigationEntry.loadEventEnd - navigationEntry.startTime,
        firstPaint:
          performance.getEntriesByName('first-paint')[0]?.startTime || 0,
        firstContentfulPaint:
          performance.getEntriesByName('first-contentful-paint')[0]
            ?.startTime || 0,
        largestContentfulPaint:
          (
            performance.getEntriesByType(
              'largest-contentful-paint',
            )[0] as PerformanceEntry
          )?.startTime || 0,
        navigationType: navigationEntry.type,
      };

      const performanceMetrics = {
        timeToFirstByte: {
          value: performanceData.timeToFirstByte,
          unit: 'Milliseconds',
        },
        domContentLoaded: {
          value: performanceData.domContentLoaded,
          unit: 'Milliseconds',
        },
        pageLoadTime: {
          value: performanceData.pageLoadTime,
          unit: 'Milliseconds',
        },
        firstPaint: { value: performanceData.firstPaint, unit: 'Milliseconds' },
        firstContentfulPaint: {
          value: performanceData.firstContentfulPaint,
          unit: 'Milliseconds',
        },
        largestContentfulPaint: {
          value: performanceData.largestContentfulPaint,
          unit: 'Milliseconds',
        },
      };

      logEmfMetric(
        'PerformanceStats',
        {
          page: window.location.pathname,
          timestamp: new Date().toISOString(),
          navigationType: performanceData.navigationType,
        },
        performanceMetrics,
      );

      logPageView(window.location.pathname);
    });
  };

  if (document.readyState === 'complete') {
    logStats();
  } else {
    window.addEventListener('load', logStats);
  }

  // Use the PerformanceObserver to get metrics like LCP
  const observer = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      if (entry.entryType === 'navigation') {
        logStats();
      }
    }
  });

  observer.observe({ type: 'navigation', buffered: true });

  const originalPushState = history.pushState;
  history.pushState = function (...args) {
    originalPushState.apply(this, args);
    logStats();
  };

  const originalReplaceState = history.replaceState;
  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    logStats();
  };

  window.addEventListener('popstate', logStats);
};

const logPageView = (path: string) => {
  logEmfMetric(
    'PageView',
    {
      path,
      timestamp: new Date().toISOString(),
    },
    { count: { value: 1, unit: 'Count' } },
  );
};

export const logLatency = (operation: string, latencyMs: number) => {
  logEmfMetric(
    'Latency',
    {
      operation,
      timestamp: new Date().toISOString(),
    },
    { latencyMs: { value: latencyMs, unit: 'Milliseconds' } },
  );
};
