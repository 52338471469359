import { useMemo } from 'react';

import { FIELD_CONFIG } from 'configs/columns';
import { STATES } from 'configs/states';
import {
  BooleanFilterConfigEntry,
  RangeFilterConfigEntry,
  MultiSelectFilterConfigEntry,
  isOption,
  SingleSelectFilterConfigEntry,
  ListInputFilterConfigEntry,
} from 'features/common-elements/filters/filters.types';
import { isNotNull } from 'features/deals/DealStages/EventActionCards/utils';
import { isArrayOf, isEnumMember, isType } from 'functions/typeUtils';
import { invert, cloneDeep, map } from 'lodash';
import _ from 'lodash';

import { useQuery } from '@apollo/client';

import {
  AssetClass,
  FilterableField,
  FilterOperator,
} from '__generated__/globalTypes';

import {
  GetValidTerms,
  GetValidTermsVariables,
} from 'query/__generated__/GetValidTerms';
import { GET_VALID_TERMS } from 'query/getValidTerms';

//these were not implemented
//FilterableField.city
//FilterableField.account_id
//FilterableField.listing_id
//FilterableField.postal_code
//FilterableField.last_updated_date_seconds problematic..
//FilterableField.maturity_date_seconds
//FilterableField.next_payment_date_seconds
//FilterableField.origination_date_seconds

const filterFieldToAbbr = {
  [FilterableField.account_id]: 'acc',
  [FilterableField.age_months]: 'age',
  [FilterableField.auto_original_value_cents]: 'aov',
  [FilterableField.borrower_credit_score]: 'bcs',
  [FilterableField.current_balance_cents]: 'cba',
  [FilterableField.dti]: 'dti',
  [FilterableField.interest_rate]: 'ir',
  [FilterableField.loan_terms_months]: 'ltm',
  [FilterableField.ltv]: 'ltv',
  [FilterableField.occupancy]: 'occ',
  [FilterableField.original_balance_cents]: 'oba',
  [FilterableField.product]: 'p',
  [FilterableField.purpose]: 'pur',
  [FilterableField.remaining_loan_terms_months]: 'rlt',
  [FilterableField.cra]: 'cra',
  [FilterableField.state]: 'st',
};

const abbrToFilterField = invert(filterFieldToAbbr) as Record<
  string,
  PortfolioFilterField
>;

const portfolioHomeFiltersConfig = {
  [FilterableField.account_id]: {
    type: 'list-input',
    displayName: FIELD_CONFIG.account_id.string,
  },
  [FilterableField.age_months]: {
    type: 'range',
    displayName: FIELD_CONFIG.age_months.string,
    min: 1,
    max: 360,
    integer: true,
  },
  [FilterableField.cra]: {
    type: 'boolean',
    displayName: FIELD_CONFIG.cra.string,
    falseyLabel: 'Without CRA',
    truthyLabel: 'With CRA',
  },
  [FilterableField.state]: {
    type: 'select-multi',
    displayName: FIELD_CONFIG.state.string,
    filterableFieldName: FilterableField.state,
    filterOptions: map(STATES, (_value, key) => ({ value: key, label: key })),
    getOptionLabel: (option) =>
      typeof option === 'string' ? option : option.label,
    getOptionValue: (option) =>
      typeof option === 'string' ? option : option.value,
    valueToString: (options) =>
      isArrayOf(isOption)(options)
        ? options.map((option) => option.label).join(', ')
        : '',
  },
  [FilterableField.borrower_credit_score]: {
    type: 'range',
    displayName: FIELD_CONFIG.borrower_credit_score.string,
    min: 300,
    max: 850,
    integer: true,
  },
  [FilterableField.current_balance_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.current_balance_cents.string,
    min: 1,
    max: 200000000,
    integer: true,
    prepare: (value: number) => value * 100,
  },
  [FilterableField.dti]: {
    type: 'range',
    displayName: FIELD_CONFIG.dti.string,
    min: 0.05,
    max: 1.5,
    inputFormat: (value: string) => parseFloat(value) / 100,
    displayFormat: (value: number) => `${Math.round(value * 100)}`,
    decimal: true,
    percentage: true,
  },
  [FilterableField.interest_rate]: {
    type: 'range',
    displayName: FIELD_CONFIG.interest_rate.string,
    min: 0.5,
    max: 9,
    decimal: true,
    percentage: true,
  },
  [FilterableField.ltv]: {
    type: 'range',
    displayName: FIELD_CONFIG.ltv.string,
    min: 0.05,
    max: 1.5,
    inputFormat: (value: string) => parseFloat(value) / 100,
    displayFormat: (value: number) => `${Math.round(value * 100)}`,
    decimal: true,
    percentage: true,
  },
  [FilterableField.occupancy]: {
    type: 'select-multi',
    displayName: FIELD_CONFIG.occupancy.string,
    filterableFieldName: FilterableField.occupancy,
    filterOptions: [] as { value: string; label: string }[],
    getOptionLabel: (option) =>
      typeof option === 'string' ? option : option.label,
    getOptionValue: (option) =>
      typeof option === 'string' ? option : option.value,
    valueToString: (options) =>
      isArrayOf(isOption)(options)
        ? options.map((option) => option.label).join(', ')
        : '',
  },
  [FilterableField.product]: {
    type: 'select-multi',
    displayName: FIELD_CONFIG.product.string,
    filterableFieldName: FilterableField.product,
    filterOptions: [] as { value: string; label: string }[],
    getOptionLabel: (option) =>
      typeof option === 'string' ? option : option.label,
    getOptionValue: (option) =>
      typeof option === 'string' ? option : option.value,
    valueToString: (options) =>
      isArrayOf(isOption)(options)
        ? options.map((option) => option.label).join(', ')
        : '',
  },
  [FilterableField.purpose]: {
    type: 'select-multi',
    displayName: FIELD_CONFIG.purpose.string,
    filterableFieldName: FilterableField.purpose,
    filterOptions: [] as { value: string; label: string }[],
    getOptionLabel: (option) =>
      typeof option === 'string' ? option : option.label,
    getOptionValue: (option) =>
      typeof option === 'string' ? option : option.value,
    valueToString: (options) =>
      isArrayOf(isOption)(options)
        ? options.map((option) => option.label).join(', ')
        : '',
  },
  [FilterableField.original_balance_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.original_balance_cents.string,
    min: 1,
    max: 200000000,
    integer: true,
    prepare: (value: number) => value * 100,
  },
  [FilterableField.remaining_loan_terms_months]: {
    type: 'range',
    displayName: FIELD_CONFIG.remaining_loan_terms.string,
    min: 1,
    max: 360,
  },
} satisfies {
  [key in FilterableField]?:
    | RangeFilterConfigEntry
    | BooleanFilterConfigEntry
    | MultiSelectFilterConfigEntry<{ value: string; label: string }>
    | SingleSelectFilterConfigEntry<{ value: string; label: string }>
    | ListInputFilterConfigEntry;
};

const portfolioCarsFiltersConfig = {
  ...cloneDeep(portfolioHomeFiltersConfig),
  [FilterableField.auto_original_value_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.auto_original_value_cents.string,
    min: 1,
    max: 100000,
    prepare: (value: number) => value * 100,
  },
};

portfolioCarsFiltersConfig[FilterableField.ltv].max = 1.5;
portfolioCarsFiltersConfig[FilterableField.age_months].max = 60;
portfolioCarsFiltersConfig[FilterableField.current_balance_cents].max = 100000;
portfolioCarsFiltersConfig[FilterableField.interest_rate].max = 9;
portfolioCarsFiltersConfig[FilterableField.original_balance_cents].max = 100000;
portfolioCarsFiltersConfig[FilterableField.remaining_loan_terms_months].max =
  84;

const portfolioUnsecuredPersonalFiltersConfig = {
  ...cloneDeep(portfolioCarsFiltersConfig),
};

if (FilterableField.state in portfolioUnsecuredPersonalFiltersConfig) {
  // @ts-ignore - The 'delete' operator is used to remove a non-optional property
  delete portfolioUnsecuredPersonalFiltersConfig[FilterableField.state];
}

function getPortfolioFiltersConfig(assetClass: AssetClass) {
  switch (assetClass) {
    case AssetClass.HOME:
    case AssetClass.CRE:
      return portfolioHomeFiltersConfig;
    case AssetClass.AUTO:
      return portfolioCarsFiltersConfig;
    case AssetClass.UNSECPERSONAL:
      return portfolioUnsecuredPersonalFiltersConfig;
    default:
      throw new Error(`No filters config for asset class ${assetClass}`);
  }
}

type HasFilterableField = { filterableFieldName: FilterableField };
const hasFilterableField = isType<HasFilterableField>({
  filterableFieldName: isEnumMember(FilterableField),
});

function useFilterConfig(
  assetClass: AssetClass | null,
  input: Omit<GetValidTermsVariables['input'], 'fieldNames'>,
) {
  const filterConfig = useMemo(
    () => assetClass && getPortfolioFiltersConfig(assetClass),
    [assetClass],
  );

  const termFieldNames = useMemo(
    () =>
      filterConfig
        ? _.values(filterConfig)
            .filter((filter) => filter.type === 'select-multi')
            .map((filter) =>
              hasFilterableField(filter) ? filter.filterableFieldName : null,
            )
            .filter(isNotNull)
        : [],
    [filterConfig],
  );
  const { data: validTermsData } = useQuery<
    GetValidTerms,
    GetValidTermsVariables
  >(GET_VALID_TERMS, {
    variables: {
      input: {
        ...input,
        filters: [
          ...(input.filters != null ? input.filters : []),
          {
            field_name: FilterableField.asset_class,
            operator: FilterOperator.IS,
            operand: assetClass,
          },
        ],
        fieldNames: termFieldNames,
      },
    },
    skip: !assetClass,
  });

  const portfolioFiltersConfig = useMemo(() => {
    if (!filterConfig || !validTermsData) {
      return filterConfig;
    }
    const filtersConfig = { ...filterConfig };

    Object.values(filtersConfig).forEach((filter) => {
      if (filter.type === 'select-multi') {
        filter.filterOptions =
          validTermsData?.getValidTerms
            .find(
              (validTerms) =>
                validTerms.fieldName === filter.filterableFieldName,
            )
            ?.terms.map((term) => ({
              label: term,
              value: term,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) ||
          filter.filterOptions;
      }
    });

    return filtersConfig;
  }, [validTermsData, filterConfig]);
  return portfolioFiltersConfig;
}

type PortfolioFilterConfig = ReturnType<typeof getPortfolioFiltersConfig>;
type PortfolioFilterField = keyof PortfolioFilterConfig;

export type { PortfolioFilterConfig, PortfolioFilterField };

export {
  abbrToFilterField,
  filterFieldToAbbr,
  portfolioHomeFiltersConfig,
  getPortfolioFiltersConfig,
  useFilterConfig,
};
