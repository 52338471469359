import LoanDatatableFragments from 'features/drilldown/LoanDatatable/fragments';
import { StratificationsFragments } from 'features/drilldown/Stratifications';
import { PoolSummaryFragments } from 'features/loanPool/PoolSummary';

import { gql } from '@apollo/client';

export const GET_DEAL_LOANS_QUERY = gql`
  query GetDealLoans(
    $id: ID!
    $sort: Sort!
    $pagination: Pagination!
    $filters: [Filter!]
  ) {
    deal(id: $id) {
      id
      loans(sort: $sort, pagination: $pagination, filters: $filters) {
        id
        ...LoanDatatableLoan
      }
      performance_summary(filters: $filters) {
        unpaidBalance: current_balance_cents
        originalBalance: original_balance_cents
        loanCount: loan_count
        averageBalance: wa_current_balance_cents
        averageOriginalBalance: wa_original_balance_cents
        netCoupon: wa_coupon
        averageAge: wa_age_months
        averageMaturity: wa_remaining_loan_terms_months
        fico: wa_borrower_credit_score
        dti: wa_dti
        ltv: wa_ltv
        states: strat_state {
          stateCode: key
          loanCount: t_count
        }
      }
    }
  }
  ${LoanDatatableFragments.loan}
`;

export const GET_DEAL_STRATS_QUERY = gql`
  query GetDealStrats($id: ID!) {
    deal(id: $id) {
      id
      performanceData: performance_summary {
        ...PoolSummaryPerformanceSummary
        ...StratificationsPerformanceSummary
      }
    }
  }
  ${PoolSummaryFragments.performanceSummary}
  ${StratificationsFragments.performanceSummary}
`;
