import { gql } from '@apollo/client';

const GET_NOTIFICATIONS_QUERY = gql`
  query GetUserNotifications {
    userNotifications {
      notifications {
        notificationMetadata {
          userID
          notificationID
          type
          status
          createdAt
          sentAt
          readAt
          content {
            s3Key
            s3Bucket
            s3Version
          }
        }
        content
      }
    }
  }
`;

export { GET_NOTIFICATIONS_QUERY };
