import { getPortfolioRoute, useGet2ndLevelPageUrl } from 'app-level/navigation';
import { TapeUploadPage } from 'features/loanTape/TapeUploadPage';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CashFlowModeling } from '../CashFlowModeling';
import PortfolioDashboard from '../Dashboard/PortfolioDashboard';
import DocumentLibrary from '../DocumentLibrary/DocumentLibrary';
import LoanLevelData from '../LoanLevelData';
import PoolManager from '../PoolManager';
import Stratifications from '../Stratifications';

const Portfolio = () => {
  const getUrl = useGet2ndLevelPageUrl();
  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to={getUrl('portfolio', 'dashboard')} replace />}
      />
      <Route
        path={getPortfolioRoute('dashboard')}
        element={<PortfolioDashboard />}
      />
      <Route
        path={getPortfolioRoute('stratifications')}
        element={<Stratifications />}
      />
      <Route
        path={getPortfolioRoute('loan-level-data')}
        element={<LoanLevelData />}
      />
      <Route
        path={getPortfolioRoute('pool-manager')}
        element={<PoolManager />}
      />
      <Route
        path={getPortfolioRoute('cashflow-modeling')}
        element={<CashFlowModeling />}
      />
      <Route
        path={getPortfolioRoute('document-library')}
        element={<DocumentLibrary />}
      />
      <Route path={`tape-upload/:tapeId?`} element={<TapeUploadPage />} />
      <Route
        path={`${getPortfolioRoute('tape-upload')}/:tapeId?`}
        element={<TapeUploadPage />}
      />
    </Routes>
  );
};

export default Portfolio;
