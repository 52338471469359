import { useState } from 'react';

import ChangePasswordForm from './ChangePasswordForm';

import './Security.scss';

const Security = () => {
  const [changingPassword, setChangingPassword] = useState(false);

  return (
    <div className="account__security">
      <table>
        <tbody>
          <tr>
            <td className="heading" colSpan={changingPassword ? 2 : 1}>
              Password
              {changingPassword && (
                <ChangePasswordForm setIsOpen={setChangingPassword} />
              )}
            </td>
            {!changingPassword && (
              <td className="value">
                <div className="account__security__masked__password">
                  <span>••••••••••</span>
                  <button
                    className="account__security__password"
                    type="button"
                    onClick={() => setChangingPassword(true)}
                  >
                    Change
                  </button>
                </div>
              </td>
            )}
          </tr>
          {/*
          <tr>
            <td className='heading'>2-Step Verification</td>
            <td className='value'>
              <ToggleButton checkedLabel="On" uncheckedLabel="Off" />
            </td>
          </tr>
          <tr>
            <td className='heading'>Auto logout</td>
            <td className='value'>
              <ToggleButton checkedLabel="On" uncheckedLabel="Off" />
            </td>
          </tr>
          */}
        </tbody>
      </table>
    </div>
  );
};

export default Security;
