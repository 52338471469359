import * as navigation from 'app-level/navigation';
import { Spinner } from 'common-ui';
import { createQueryFilters } from 'context/DataContext';
import { filtersToQueryFilters } from 'features/common-elements';
import { PillFilters } from 'features/common-elements/filters/PillFilters';
import {
  abbrToKeys,
  keysToAbbr,
} from 'features/drilldown/Stratifications/parseAbbrString';
import { StratKey } from 'features/drilldown/Stratifications/startifications.config';
import { StratCard } from 'features/drilldown/Stratifications/StratCard';
import { StratsSelection } from 'features/drilldown/Stratifications/StratsSelection';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { useSearchParams } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';

import { useQuery } from '@apollo/client';

import {
  AssetClass,
  Filter,
  FilterableField,
  FilterOperator,
} from '__generated__/globalTypes';

import {
  GetDrilldown,
  GetDrilldownVariables,
} from 'query/__generated__/GetDrilldown';
import { GET_DRILLDOWN_QUERY } from 'query/drilldown';

import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import { usePortfolioFilters } from './Portfolio/usePortfolioFilters';

const getDrilldownVariables = (
  assetClass: AssetClass | null,
  filters: Filter[],
): GetDrilldownVariables => {
  const queryFilters = createQueryFilters(assetClass, filters);

  return {
    sort: {},
    pagination: {},
    assetClassFilter: {
      field_name: FilterableField.asset_class,
      operator: FilterOperator.IS,
      operand: assetClass,
    },
    filters: queryFilters,
  };
};

const StratCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    flex: 1 500px;
    max-height: 550px;
  }
`;

const Stratifications = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const gqlAssetClass = assetClass && ASSET_CLASS_IDS[assetClass];
  const filterProps = usePortfolioFilters();

  const queryFilters = filterProps.filtersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        filterProps.currentFilters,
        filterProps.filtersConfig,
      )
    : [];

  const [stratsSearch, setStratsSearch] = useSearchParams();
  const abbrStr = stratsSearch.get('strats');
  const selectedStratsFromUrl = abbrStr
    ? abbrToKeys(abbrStr)
    : (['strat_borrower_credit_score'] as StratKey[]);

  const { data, loading, previousData } = useQuery<
    GetDrilldown,
    GetDrilldownVariables
  >(GET_DRILLDOWN_QUERY, {
    variables: gqlAssetClass
      ? getDrilldownVariables(gqlAssetClass, queryFilters)
      : {
          assetClassFilter: {
            field_name: FilterableField.asset_class,
            operator: FilterOperator.IS,
            operand: assetClass,
          },
          sort: {},
          pagination: {},
          filters: [],
        },
    skip: !gqlAssetClass,
    fetchPolicy: 'cache-and-network',
  });

  function updateSelectedStrats(strats: StratKey[]) {
    const str = keysToAbbr(strats);
    const searchParams = new URLSearchParams(stratsSearch);
    searchParams.set('strats', str);
    setStratsSearch(searchParams);
  }

  if (!gqlAssetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const dataToShow = data || previousData;

  return (
    <PortfolioLayout
      filterRow={
        <div className="flex w-full flex-col gap-2">
          <StratsSelection
            selectedStrats={selectedStratsFromUrl}
            onSelectedStratsChange={updateSelectedStrats}
          />
          <PillFilters
            {...filterProps}
            filtersConfig={filterProps.filtersConfig}
          />
        </div>
      }
    >
      {dataToShow ? (
        <StratCardsContainer>
          {selectedStratsFromUrl.map((strat) => (
            <StratCard
              key={strat}
              strat={strat}
              assetClass={gqlAssetClass}
              summary={dataToShow?.user.company.performanceData}
              avgSummary={dataToShow?.user.company.performanceData}
            />
          ))}
        </StratCardsContainer>
      ) : null}
      <Spinner loading={loading} />
    </PortfolioLayout>
  );
};

export default Stratifications;
