import bidSuccessImgPath from 'assets/pngs/bid-success.png';
import { BaseButton } from 'common-ui';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';

export interface BidSucceededDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const BidSucceededDialog: React.FC<BidSucceededDialogProps> = ({
  onConfirm,
  onCancel,
}) => {
  return (
    <>
      <div className="mb-2 flex justify-center">
        <img
          src={bidSuccessImgPath}
          width={233}
          height={232}
          alt="bid succeeded"
        />
      </div>
      <StyledHeading>Your Bid was successful!</StyledHeading>
      <div className="w-[290px]">
        Would you like to track your bid or return back to the Marketplace?
      </div>
      <DialogActions>
        <BaseButton
          type="secondary"
          size="medium"
          label="cancel"
          onClick={() => onCancel()}
        >
          Back to Deal
        </BaseButton>
        <BaseButton size="medium" label="confirm" onClick={() => onConfirm()}>
          <b>Return to Active Trades</b>
        </BaseButton>
      </DialogActions>
    </>
  );
};
