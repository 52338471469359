import { FC } from 'react';

interface TooltipPayload {
  name?: string;
  value?: string | number;
  color?: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
}

export const CustomTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg border-none bg-gray-900 bg-opacity-80 p-2">
        <p className="m-0 font-bold text-white">{label}</p>
        {payload.map((entry, index) => (
          <p
            key={`item-${index}`}
            className="m-0 font-bold"
            style={{ color: entry.color || 'white' }}
          >
            {`${entry.name}: ${typeof entry.value === 'number' ? entry.value.toFixed(2) : entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};
