import React, { useEffect, useRef, useState } from 'react';

import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import { GetDealCashFlows_deal_performanceSummary_cashFlows_price_yield_matrix as PriceYieldMatrix } from 'features/drilldown/cashflows/gql/__generated__/GetDealCashFlows';
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from 'recharts';
import styled from 'styled-components';

import { StipulationFieldName } from '__generated__/globalTypes';

import { BidDetails } from './BidDetails';

const AXIS_COLOR = '#868e95';
const PRICE_REFERENCE_COLOR = '#ffcf74';
const TITLE_COLOR = '#e8e9ec';
const LEGEND_TEXT_COLOR = '#e8e9ec';

interface TooltipProps {
  active?: boolean;
  payload?: Array<ActivePayloadItem>;
  label?: string;
}

interface ActivePayloadItem {
  name: string;
  value: number;
  color: string;
  payload: {
    price: number;
    duration: number;
  };
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const roundedLabel = parseFloat(label || '').toFixed(3);
    const averageDuration =
      payload.reduce((acc, item) => acc + item.payload.duration, 0) /
      payload.length;
    const yieldPayload = payload.find((item) => item.name === 'Yield');
    const filteredPayload = payload.filter((item) => {
      if (item.name === 'Spread to spot' && yieldPayload) {
        return item.payload.price === yieldPayload.payload.price;
      }
      return item.name === 'Yield' || item.name !== 'Spread to spot';
    });
    const spreadToSpot = payload.find(
      (item) => item.name === 'Spread to spot',
    )?.value;

    return (
      <div
        className="font-si rounded p-4 text-xs font-bold shadow-lg"
        style={{ backgroundColor: '#e2e3e6' }}
      >
        <p
          className="m-0 mb-1 flex justify-between text-gray-800"
          style={{ color: '#28303e' }}
        >
          <span>Price: </span>
          <span>{roundedLabel}%</span>
        </p>
        {filteredPayload.map((item, index) => (
          <p
            key={index}
            className="m-0 mb-1 flex justify-between"
            style={{ color: item.color }}
          >
            <span>{item.name}: </span>
            <span>
              {item.name.includes('Spread')
                ? item.value.toFixed(0) + ' bps'
                : item.value.toFixed(3) + ' %'}
            </span>
          </p>
        ))}
        <p
          className="m-0 mb-1 flex justify-between text-gray-800"
          style={{ color: '#0f763d' }}
        >
          <span>Duration: </span>
          <span>{averageDuration.toFixed(3)}</span>
        </p>
        {spreadToSpot !== undefined && (
          <p
            className="m-0 flex justify-between text-gray-800"
            style={{ color: '#e0a028' }}
          >
            <span>Spread to spot: </span>
            <span>{spreadToSpot.toFixed(0)} bps</span>
          </p>
        )}
      </div>
    );
  }
  return null;
};

interface YieldToPriceChartProps {
  bidDetails: BidDetails;
  updateBidDetails: (
    bidDetails: BidDetails,
    fieldName?: StipulationFieldName,
    value?: string,
  ) => void;
  chartData: PriceYieldMatrix;
  upb: number;
  width?: number;
}

const LineChartContainer = styled.div`
  border: 1px solid #3b4351;
  border-radius: 4px;
  padding: 15px 10px;
  background-color: #1f1f1f;
`;

interface ChartClickEvent {
  activePayload?: Array<{
    payload: {
      price: number;
      duration: number;
    };
  }>;
}

const YieldToPriceChart: React.FC<YieldToPriceChartProps> = ({
  bidDetails,
  updateBidDetails,
  chartData,
  upb,
  width = 800,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: width, height: 484 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const aspectRatio = 555 / 384;
        const height = Math.min(width / aspectRatio, 500);
        setDimensions({ width, height });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [width]);

  const handleChartClick = (e: ChartClickEvent) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      const price = parseFloat(e.activePayload[0].payload.price.toFixed(3));
      const basisPoints = percentToBasisPoints(price.toString());
      updateBidDetails(
        {
          ...bidDetails,
          carve: {
            ...bidDetails.carve,
            stipulations: {
              ...bidDetails.stipulations,
              bid_basis_points: basisPoints,
            },
          },
        },
        StipulationFieldName.bid_basis_points,
        `${basisPoints}`,
      );
    }
  };

  const yieldData = chartData.yield_matrix?.map((point) => ({
    price: roundToThree((point[1] / upb) * 100),
    Yield: point[0] * 100,
    duration: point[2],
  }));

  const spreadData = chartData.spread_matrix?.map((point) => ({
    price: roundToThree((point[1] / upb) * 100),
    'Spread to spot': point[0] * 10000,
    duration: point[2],
  }));

  const yieldValues = (yieldData || []).map((point) => point.Yield);
  const yieldMin = Math.floor(Math.min(...yieldValues));
  const yieldMax = Math.ceil(Math.max(...yieldValues));
  const yieldTicks = yieldMax - yieldMin + 1;

  const bidBasisPoints = bidDetails.carve.stipulations?.bid_basis_points || 0;
  const bidPx = basisPointsToPercent(bidBasisPoints);

  return (
    <LineChartContainer ref={containerRef}>
      <LineChart
        width={dimensions.width - 10}
        height={dimensions.height}
        margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
        onClick={handleChartClick}
      >
        <CartesianGrid stroke="#3b4351" opacity="30%" />
        <XAxis
          stroke={AXIS_COLOR}
          dataKey="price"
          padding={{ left: 30, right: 30 }}
          type="number"
          domain={['dataMin', 'dataMax']}
          tickFormatter={(tick) => tick.toFixed(3)}
        >
          <Label value="PRICE" position="bottom" />
        </XAxis>
        <YAxis
          stroke={AXIS_COLOR}
          type="number"
          dataKey="Yield"
          domain={[yieldMin, yieldMax]}
          tickFormatter={(tick) => tick.toFixed(3)}
          tickCount={yieldTicks}
          padding={{ top: 10, bottom: 10 }}
        >
          <Label value="YIELD" offset={15} position="top" />
        </YAxis>
        <YAxis
          stroke={AXIS_COLOR}
          yAxisId="right"
          orientation="right"
          type="number"
          domain={[-100, 1100]}
          tickFormatter={(tick) => tick.toFixed(0)}
          tickCount={7}
        >
          <Label value="SPREAD" offset={15} position="top" dx={-10} />
        </YAxis>
        <Legend
          verticalAlign="top"
          height={60}
          align="right"
          iconType="circle"
          formatter={(value) => (
            <span style={{ color: LEGEND_TEXT_COLOR }} className="mr-2">
              {value}
            </span>
          )}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          data={yieldData}
          dataKey="Yield"
          stroke="#3142c4"
          dot={false}
          activeDot={false}
        />
        <Line
          type="monotone"
          data={spreadData}
          dataKey="Spread to spot"
          stroke="#e0a028"
          dot={false}
          activeDot={false}
          yAxisId="right"
        />
        {bidPx !== null && (
          <ReferenceLine
            x={bidPx}
            stroke={PRICE_REFERENCE_COLOR}
            label={{
              stroke: PRICE_REFERENCE_COLOR,
              position: 'top',
              value: `${bidPx}`,
            }}
          />
        )}
        <text x={10} y={15} fill={TITLE_COLOR} style={{ fontSize: '14px' }}>
          YIELD TO PRICE
        </text>
      </LineChart>
    </LineChartContainer>
  );
};

function roundToThree(num: number) {
  return +(Math.round(Number(num + 'e+3')) + 'e-3');
}

export default YieldToPriceChart;
