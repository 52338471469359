import { Dispatch, SetStateAction, useContext, useState } from 'react';

import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Spinner } from 'baseui/spinner';
import ErrorMessage from 'common-ui/form/ErrorMessage';
import { PasswordInput, TextInput } from 'common-ui/inputs/Inputs-styled';
import { useFormik } from 'formik';
import { styled } from 'style/ORSNNTheme';
import * as yup from 'yup';

import { Button, Form, Grid, GridLabel, InfoText, linkStyle } from './styles';
import {
  YUP_PASSWORD_MATCH_MESSAGE,
  YUP_PASSWORD_MATCH_REGEX,
} from './validation';
import {
  AuthContext,
  AuthContextAction,
  AuthUser,
} from '../../context/AuthContext';

const StyledSpinner = () => <Spinner size={12} />;

const StyledLink = styled.span`
  ${linkStyle}
`;

const ERROR_CODES = {
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  NEW_USER_REQUIRED: 'UserNotFoundException',
  PASSWORD_RESET_REQUIRED: 'PasswordResetRequiredException',
  USER_CONFIRMATION_REQUIRED: 'UserNotConfirmedException',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

type Credentials = {
  email: string;
  password: string;
};

const initialValues: Credentials = {
  email: '',
  password: '',
};

type SignInFormProps = {
  setIsNewPasswordRequired: Dispatch<SetStateAction<boolean>>;
  setCredentials: Dispatch<SetStateAction<Credentials>>;
};

const SignInForm = (props: SignInFormProps): JSX.Element => {
  const { state, dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const user: AuthUser = await Auth.signIn(
          values.email.toLowerCase(),
          values.password,
        );
        if (user.signInUserSession) {
          setIsLoading(false);
          dispatch({
            type: AuthContextAction.SET_SIGNED_IN_USER,
            payload: user,
          });
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsLoading(false);
          props.setCredentials({
            email: values.email,
            password: values.password,
          });
          props.setIsNewPasswordRequired(true);
        }
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: AuthContextAction.AUTH_ERROR,
          payload: { error: err.code },
        });
      }
    },
  });

  return (
    <>
      {state.authError ? (
        <ErrorMessage>
          Login failed. Please check your credentials or click Forgot Password
        </ErrorMessage>
      ) : (
        'Log in with your email and password'
      )}

      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridLabel htmlFor="email">Email</GridLabel>
          <TextInput
            withPrefix={false}
            autoComplete="email"
            autoFocus
            disabled={isLoading}
            error={state.authError === ERROR_CODES.NEW_USER_REQUIRED}
            placeholder="Institution Email Address"
            id="email"
            required
            {...formik.getFieldProps('email')}
          />
          <GridLabel htmlFor="password">Password</GridLabel>
          <PasswordInput
            withPrefix={false}
            autoComplete="password"
            disabled={isLoading}
            error={state.authError === ERROR_CODES.NOT_AUTHORIZED_EXCEPTION}
            id="password"
            placeholder={'Password'}
            required
            {...formik.getFieldProps('password')}
          />
        </Grid>
        <div>
          <Button type="submit" disabled={!formik.isValid || isLoading}>
            Sign In
            {isLoading ? <StyledSpinner /> : <></>}
          </Button>
        </div>
      </Form>
    </>
  );
};

const newPasswordInitialValues = {
  ...initialValues,
  newPassword: '',
  confirm_password: '',
};

const newPasswordValidationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
  newPassword: yup
    .string()
    .required()
    .matches(YUP_PASSWORD_MATCH_REGEX, YUP_PASSWORD_MATCH_MESSAGE),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .required('Confirm Password is required'),
});

type NewPasswordFormProps = {
  credentials: Credentials;
};

const NewPasswordForm = ({
  credentials,
}: NewPasswordFormProps): JSX.Element => {
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = { ...newPasswordInitialValues, ...credentials };
  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const user: AuthUser = await Auth.signIn(
          values.email.toLowerCase(),
          values.password,
        );
        Auth.completeNewPassword(user, values.newPassword).then((res) => {
          setIsLoading(false);
          dispatch({
            type: AuthContextAction.SET_SIGNED_IN_USER,
            payload: res as CognitoUser,
          });
        });
      } catch (e: any) {
        setIsLoading(false);
        dispatch({
          type: AuthContextAction.AUTH_ERROR,
          payload: e.code,
        });
      }
    },
  });

  return (
    <>
      New Password Required
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridLabel htmlFor="email">Email</GridLabel>
          <TextInput
            withPrefix={false}
            autoComplete="email"
            placeholder="Institution Email Address"
            disabled={isLoading}
            error={formik.errors.email != null}
            id="email"
            {...formik.getFieldProps('email')}
          />
          <GridLabel htmlFor="password">Current Password</GridLabel>
          <PasswordInput
            withPrefix={false}
            autoComplete="password"
            id="password"
            placeholder={'Current Password'}
            disabled={isLoading}
            error={formik.errors.password != null}
            autoFocus
            {...formik.getFieldProps('password')}
          />
          <GridLabel htmlFor="newPassword">New Password</GridLabel>
          <PasswordInput
            withPrefix={false}
            id="newPassword"
            disabled={isLoading}
            error={formik.errors.newPassword != null}
            placeholder={'Enter new password'}
            {...formik.getFieldProps('newPassword')}
          />
          <InfoText>{formik.errors.newPassword}</InfoText>
          <GridLabel htmlFor="newPassword">Confirm New Password</GridLabel>
          <PasswordInput
            withPrefix={false}
            required
            id="confirm_password"
            placeholder="Confirm Password"
            error={formik.errors.confirm_password != null}
            {...formik.getFieldProps('confirm_password')}
          />
          <InfoText>{formik.errors.confirm_password}</InfoText>
        </Grid>
        <Button type="submit" disabled={!formik.isValid}>
          Save new password
          {isLoading ? <StyledSpinner /> : <></>}
        </Button>
      </Form>
    </>
  );
};

type Props = {
  onFlip: () => void;
};

const SignIn = ({ onFlip }: Props): JSX.Element => {
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [credentials, setCredentials] = useState<Credentials>(initialValues);

  const form = isNewPasswordRequired ? (
    <NewPasswordForm credentials={credentials} />
  ) : (
    <SignInForm
      setIsNewPasswordRequired={setIsNewPasswordRequired}
      setCredentials={setCredentials}
    />
  );

  return (
    <>
      <h5>Sign in</h5>
      {form}
      <StyledLink onClick={onFlip}>Forgot password?</StyledLink>
    </>
  );
};

export default SignIn;
