import { useQuery } from '@apollo/client';

import { FileType } from '__generated__/globalTypes';

import { GetDocumentLibrary } from 'query/__generated__/GetDocumentLibrary';
import { GET_DOCUMENT_LIBRARY } from 'query/documentLibrary';

import DocumentCategory from './DocumentCategory';
import DocumentTemplates from './DocumentTemplates';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';

export const FILE_TYPES = [
  {
    type: FileType.TRANSACTION_DOCUMENT,
    name: 'Transaction Documents',
    docs: (data?: GetDocumentLibrary) =>
      data?.user.company.transactionDocs || [],
  },
  {
    type: FileType.LENDING_POLICY,
    name: 'Lending Policies',
    docs: (data?: GetDocumentLibrary) => data?.user.company.lendingDocs || [],
  },
  {
    type: FileType.DQ_AND_CHARGE_OFF,
    name: 'Delinquencies & charge-off data',
    docs: (data?: GetDocumentLibrary) => data?.user.company.dqDocs || [],
  },
  {
    type: FileType.SAMPLE_COLLATERAL,
    name: 'Sample Collateral Files',
    docs: (data?: GetDocumentLibrary) =>
      data?.user.company.sampleCollateralDocs || [],
  },
  {
    type: FileType.OTHER,
    name: 'Other',
    docs: (data?: GetDocumentLibrary) => data?.user.company.otherDocs || [],
  },
];

const DocumentLibrary = () => {
  const { data, refetch } = useQuery<GetDocumentLibrary>(GET_DOCUMENT_LIBRARY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 3000,
  });

  return (
    <PortfolioLayout hideSummery={true}>
      <div className="m-auto w-full rounded-md border border-pink-800 p-4">
        <div className="text-lg">Document Library</div>
        <div className="p-4 text-gray-500">
          Check the documents you want available when creating an Offering
        </div>
        <DocumentTemplates docs={data?.user.company.templates || []} />
        {FILE_TYPES.map((companyType) => {
          const { type, name } = companyType;
          return (
            <DocumentCategory
              companyId={data?.user.company.id || ''}
              key={type}
              fileType={type}
              name={name}
              refetch={refetch}
              docs={companyType.docs(data)}
            />
          );
        })}
      </div>
    </PortfolioLayout>
  );
};

export default DocumentLibrary;
