import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { FIELD_CONFIG } from 'configs/columns';
import { toPercentage } from 'features/pages/marketplace/BiddingAndPricing/formatting';
import { Link } from 'react-router-dom';
import { styled, theme } from 'style/ORSNNTheme';

import {
  GetSellerListingsData_user_company_listings_UserCompanyListing as Listing,
  GetSellerListingsData_user_company_listings_UserCompanyListing_deals as Deals,
} from './__generated__/GetSellerListingsData';

function isListing(original: Listing | Deals): original is Listing {
  return 'deals' in original;
}

const LinkWrapper = styled(Link)`
  color: ${theme.color.fgDefault};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${theme.color.fgMuted};
    cursor: pointer;
  }
`;

const LoanPoolLink = (props: CellContext<Listing | Deals, unknown>) => {
  const original = props.row.original;
  if (isListing(original)) {
    // TODO: Change rout to remittance
    return (
      <LinkWrapper to={`../listing/${original.id}`}>
        {original.providedName}
      </LinkWrapper>
    );
  } else if ('listing' in original) {
    return (
      <LinkWrapper to={`../deal/${original.id}`}>
        {original.listing.providedName}
      </LinkWrapper>
    );
  } else {
    return <></>;
  }
};

export const activeColumnDefinitions: ColumnDef<Listing | Deals>[] = [
  {
    accessorFn: (row) => {
      if (isListing(row)) {
        return row.providedName;
      } else {
        return row.listing.providedName;
      }
    },
    header: 'Loan Pool Name',
    cell: LoanPoolLink,
  },
  {
    accessorKey: 'createdDate',
    header: 'Date Created',
    cell: (props) => {
      if (!props.getValue()) {
        return <span>-</span>;
      }
      return (
        <span>
          {FIELD_CONFIG.maturity_date.display(props.getValue() as string)}
        </span>
      );
    },
  },
  {
    accessorKey: 'counterparty.name',
    header: 'Counterparty',
  },
  {
    header: 'Asset Class',
    accessorFn: (row: Listing | Deals) => {
      if ('assetClass' in row) {
        return row.assetClass;
      }
      if ('listing' in row && 'assetClass' in row.listing) {
        return row.listing.assetClass;
      }
      // Handle other cases or return a default value
      return undefined;
    },
  },
  {
    accessorKey: 'status',
    header: 'Completed',
    cell: () => {
      return (
        <span>
          <FontAwesomeIcon icon={faCheckCircle} color="#00ff00" />
        </span>
      );
    },
  },
  {
    accessorKey: 'loan_count',
    header: 'Loan Count',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_coupon',
    header: 'GWAC',
    cell: (props) => <span>{toPercentage(Number(props))}</span>,
  },
  {
    accessorKey: 'wa_loan_age_months',
    header: 'WALA',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_remaining_loan_terms_months',
    header: 'WAM',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_borrower_credit_score',
    header: 'FICO',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_ltv',
    header: 'LTV',
    cell: (props) => <span>{toPercentage(Number(props))}</span>,
    meta: {
      numeric: true,
    },
  },
];
