import { TapesUploadedTableFragments } from 'features/loanTape/components/TapesUploadedTable';
import { MappingCardFragments } from 'features/loanTape/fieldMapping/MapRequiredFieldsCard';
import { ProcessingReportCardFragments } from 'features/loanTape/ProcessingReportCard';

import { gql } from '@apollo/client';

const GET_UPLOADED_FILES_WITH_HEADERS = gql`
  query GetUploadedFilesWithHeaders {
    user {
      id
      company {
        id
        loan_tapes {
          id
          state
          ...TapesUploadedTableFile
          ...MappingCardFile
          ...ProcessingReportCardFile
        }
      }
    }
  }
  ${MappingCardFragments.loanTape}
  ${ProcessingReportCardFragments.loanTape}
  ${TapesUploadedTableFragments.loanTape}
`;

export { GET_UPLOADED_FILES_WITH_HEADERS };
