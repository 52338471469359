import { gql } from '@apollo/client';

// Define the GraphQL query
const GET_MARKET_DATA = gql`
  query GetMarketData {
    marketData {
      urlMap
    }
  }
`;

export { GET_MARKET_DATA };
