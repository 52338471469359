import BidStipulationFragments from 'features/pages/marketplace/BiddingAndPricing/BidStipulations.fragments';
import CarveTableFragments from 'features/pages/marketplace/BiddingAndPricing/CarveTable.fragments';

import { gql } from '@apollo/client';

export const GET_CARVES = gql`
  query GetCarves($deal_id: ID!) {
    deal(id: $deal_id) {
      id
      carves {
        id
        name
        carve {
          field_name
          operator
          operand
          operandList
        }
        ...BidStipulationsDealCarve
        ...CarveTableDealCarve
      } # TODO see if we can just remove this chunk of performance_summary and pull it from where we need it
      performance_summary {
        unpaidBalance: current_balance_cents
        originalBalance: original_balance_cents
        loanCount: loan_count
        averageBalance: wa_current_balance_cents
        averageOriginalBalance: wa_original_balance_cents
        netCoupon: wa_coupon
        servicingRate: wa_servicing_rate
        averageAge: wa_age_months
        averageMaturity: wa_remaining_loan_terms_months
        fico: wa_borrower_credit_score
        dti: wa_dti
        ltv: wa_ltv
      }
    }
  }
  ${BidStipulationFragments.DealCarve}
  ${CarveTableFragments.DealCarve}
`;
