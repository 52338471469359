import { useMemo } from 'react';

import { FIELD_CONFIG } from 'configs/columns';
import { GetFilterBounds_deal_performance_summary } from 'features/core/filter/__generated__/GetFilterBounds';
import { isEnumMember } from 'functions/typeUtils';

import { useQuery } from '@apollo/client';

import { FilterableField, LoanField } from '__generated__/globalTypes';

import {
  GetValidTerms,
  GetValidTermsVariables,
} from 'query/__generated__/GetValidTerms';
import { GET_VALID_TERMS } from 'query/getValidTerms';

import { FilterInputType } from './FilterInputs';

export type CarveFilterConfig = {
  labelAboveFilter: string;
  labelInDropdown: string;
  type: FilterInputType;
  step?: number;
  valueFormatter?: (value: number | undefined) => string;
  minBoundKey?: keyof GetFilterBounds_deal_performance_summary;
  maxBoundKey?: keyof GetFilterBounds_deal_performance_summary;
  filterOptions?: {
    label: string;
    value: string;
  }[];
};

export const carveFilterConfigs: {
  [key in FilterableField]?: CarveFilterConfig;
} = {
  /* TODO

    min_maturity_date: Date | null;
    min_origination_date: Date | null;
    min_servicing_rate: number;
  */
  [FilterableField.account_id]: {
    labelAboveFilter: 'Set Account IDs',
    labelInDropdown: FIELD_CONFIG.account_id.string,
    type: 'list-input',
  },
  [FilterableField.current_balance_cents]: {
    labelAboveFilter: 'Define Current Balance Range',
    labelInDropdown: FIELD_CONFIG.current_balance_cents.string,
    type: 'range',
    minBoundKey: 'min_current_balance_cents',
    maxBoundKey: 'max_current_balance_cents',
    // valueFormatter: FIELD_CONFIG.current_balance_cents.display,
    valueFormatter: (value) =>
      '$' + (value ? value / 100 : '').toLocaleString(),
    step: 10000,
  },

  [FilterableField.dti]: {
    labelAboveFilter: 'Define DTI Range',
    labelInDropdown: FIELD_CONFIG.dti.string,
    type: 'range',
    step: 0.01,
    valueFormatter: FIELD_CONFIG[LoanField.dti].display,
    minBoundKey: 'min_dti',
    maxBoundKey: 'max_dti',
  },
  [FilterableField.ltv]: {
    labelAboveFilter: 'Define LTV Range',
    labelInDropdown: FIELD_CONFIG.ltv.string,
    type: 'range',
    step: 0.01,
    valueFormatter: FIELD_CONFIG.ltv.display,
    minBoundKey: 'min_ltv',
    maxBoundKey: 'max_ltv',
  },
  [FilterableField.borrower_credit_score]: {
    labelAboveFilter: 'Define FICO Range',
    labelInDropdown: FIELD_CONFIG.borrower_credit_score.string,
    type: 'range',
    minBoundKey: 'min_borrower_credit_score',
    maxBoundKey: 'max_borrower_credit_score',
  },
  [FilterableField.interest_rate]: {
    labelAboveFilter: 'Define Coupon Range',
    labelInDropdown: FIELD_CONFIG.interest_rate.string,
    type: 'range',
    step: 0.00125,
    valueFormatter: FIELD_CONFIG.interest_rate.display,
    minBoundKey: 'min_coupon',
    maxBoundKey: 'max_coupon',
  },
  [FilterableField.occupancy]: {
    labelAboveFilter: 'Choose Occupancy',
    labelInDropdown: FIELD_CONFIG.occupancy.string,
    type: 'select-multi',
    filterOptions: [],
  },
  [FilterableField.product]: {
    labelAboveFilter: 'Choose Products',
    labelInDropdown: FIELD_CONFIG.product.string,
    type: 'select-multi',
    filterOptions: [],
  },
  [FilterableField.purpose]: {
    labelAboveFilter: 'Choose Purposes',
    labelInDropdown: FIELD_CONFIG.product.string,
    type: 'select-multi',
    filterOptions: [],
  },
  [FilterableField.state]: {
    labelAboveFilter: 'Define States',
    labelInDropdown: FIELD_CONFIG.state.string,
    type: 'select-multi',
    filterOptions: [],
  },
  [FilterableField.loan_terms_months]: {
    labelAboveFilter: 'Define Loan Terms Range (Months)',
    labelInDropdown: FIELD_CONFIG.loan_terms.string,
    type: 'range',
    minBoundKey: 'min_remaining_loan_terms_months',
    maxBoundKey: 'max_remaining_loan_terms_months',
  },
  [FilterableField.age_months]: {
    labelAboveFilter: 'Define Age Range (Months)',
    labelInDropdown: FIELD_CONFIG.age_months.string,
    type: 'range',
    minBoundKey: 'min_age_months',
    maxBoundKey: 'max_age_months',
  },
};

function useCarveFilterConfig(
  input: Omit<GetValidTermsVariables['input'], 'fieldNames'>,
) {
  const filterConfig = useMemo(() => carveFilterConfigs, []);

  const termFieldNames = useMemo(
    () =>
      Object.entries(filterConfig)
        .filter(([_fieldName, filter]) => filter.type === 'select-multi')
        .map(([fieldName]) => fieldName)
        .filter(isEnumMember(FilterableField)),
    [filterConfig],
  );

  const { data: validTermsData } = useQuery<
    GetValidTerms,
    GetValidTermsVariables
  >(GET_VALID_TERMS, {
    variables: {
      input: {
        ...input,
        fieldNames: termFieldNames as FilterableField[],
      },
    },
    skip: termFieldNames.length === 0,
  });

  const updatedFilterConfig = useMemo(() => {
    if (!validTermsData) {
      return filterConfig;
    }

    const filtersConfig = { ...filterConfig };

    Object.entries(filtersConfig).forEach(([fieldName, filter]) => {
      if (
        filter.type === 'select-multi' &&
        isEnumMember(FilterableField)(fieldName)
      ) {
        const validTerms = validTermsData.getValidTerms.find(
          (terms) => terms.fieldName === fieldName,
        );
        if (validTerms) {
          filter.filterOptions = validTerms.terms
            .map((term) => ({
              label: term,
              value: term,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        }
      }
    });

    return filtersConfig;
  }, [filterConfig, validTermsData]);

  return updatedFilterConfig;
}

export { useCarveFilterConfig };
