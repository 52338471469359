export const formatCentsToDollars = (cents?: number): string => {
  if (cents == null || isNaN(cents)) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
};

export const toPercentage = (num?: number): string => {
  if (num == null || isNaN(num)) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumSignificantDigits: 1,
  }).format(num);
};
