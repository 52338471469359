import { FC } from 'react';

import NotificationsDropdown from 'common-ui/Dropdown/Notifications/NotificationsDropdown';
import { useNotifications } from 'common-ui/hooks/useNotifications';

export const NotificationsSection: FC = () => {
  const { notifications, loading } = useNotifications();

  return (
    <>
      <h2 className="mb-4 font-heebo text-lg font-medium text-white">
        Notification Center
      </h2>
      <NotificationsDropdown
        notifications={notifications}
        loading={loading}
        className="!mt-0 max-h-[100%] bg-notification-gradient pb-[40px] shadow-none"
      />
    </>
  );
};
