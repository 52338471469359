import { useState } from 'react';

import { uploadConfig } from 'app-level/config/uploadConfig';
import { BaseButton, Modal } from 'common-ui';
import DocumentDetails from 'features/pages/portfolio/DocumentLibrary/DocumentDetails';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { styled } from 'style/ORSNNTheme';

import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { FileType } from '__generated__/globalTypes';

import { GetDocumentLibrary } from 'query/__generated__/GetDocumentLibrary';

import { Document } from './__generated__/Document';

export interface DocumentCategoryProps {
  companyId: string;
  fileType: FileType;
  name: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetDocumentLibrary>>;
  docs: Document[];
}

const MarkCheckboxInstructions = styled.div`
  padding: 8px;
  color: ${(props) => props.theme.color.gray600};
`;

const DocumentCategory = ({
  companyId,
  fileType,
  name,
  docs,
}: DocumentCategoryProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className="mt-2">
      <div className="flex items-center justify-between bg-gray-900 p-1 px-2">
        <div className="text-md uppercase text-gray-300">{name}</div>
        <div className="document-category__upload">
          <BaseButton
            icon="pill-plus"
            iconFill="#BDBDBD"
            isIconRight
            label="Document Upload"
            size="medium"
            type="tertiary"
            onClick={() => setIsModalOpen(true)}
          >
            Add a document
          </BaseButton>
        </div>
      </div>
      <MarkCheckboxInstructions>
        Mark the checkbox next to a document to create a template
      </MarkCheckboxInstructions>
      <DocumentDetails documents={docs} />
      {isModalOpen && (
        <Modal
          headerText="Add A Document"
          subText="New document will be added to following section"
          caption={`"${name}"`}
          setIsOpen={setIsModalOpen}
          type="document-library"
        >
          <FileUpload
            parentId={companyId}
            fileType={fileType}
            setIsOpen={setIsModalOpen}
            allowedFileTypes={uploadConfig.dealDocuments.allowedFileTypes || []}
          />
        </Modal>
      )}
    </div>
  );
};

export default DocumentCategory;
