import React, { ElementType, ReactElement, ReactText } from 'react';

import {
  IconUser,
  IconLock,
  IconShieldCheck,
  IconCheck,
  IconMail,
  IconHelpCircle,
  IconChevronDown,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DropdownMenuItem } from 'common-ui';
import { useSearchParams } from 'react-router-dom';

import AppPrefs from './AppPrefs';
import EmailOptInPrefs from './EmailOptInPrefs';
import Permissions from './Permissions';
import Security from './Security';
import User from './User';

type IconName =
  | 'user'
  | 'lock'
  | 'shield-check'
  | 'check-mark'
  | 'email-opt-in'
  | 'question-mark';

const iconMapping: Record<IconName, React.ElementType> = {
  user: IconUser,
  lock: IconLock,
  'shield-check': IconShieldCheck,
  'check-mark': IconCheck,
  'email-opt-in': IconMail,
  'question-mark': IconHelpCircle,
};

const menuItems = [
  {
    label: 'User',
    iconName: 'user' as IconName,
    id: 'user',
    content: <User />,
  },
  {
    label: 'Security',
    iconName: 'lock' as IconName,
    id: 'security',
    content: <Security />,
  },
  {
    label: 'User Role & Permissions',
    id: 'permissions',
    iconName: 'shield-check' as IconName,
    content: <Permissions />,
    adminOnly: true,
  },
  {
    label: 'Application Preferences',
    id: 'preferences',
    iconName: 'check-mark' as IconName,
    content: <AppPrefs />,
  },
  {
    label: 'Email Preferences',
    id: 'email-preferences',
    iconName: 'email-opt-in' as IconName,
    content: <EmailOptInPrefs />,
  },
  {
    label: 'Support',
    id: 'support',
    iconName: 'question-mark' as IconName,
    link: 'https://orsnn.zendesk.com/hc/en-us/requests/new',
    openInNewTab: true,
  },
];

export interface AccordionItemProps {
  title: string;
  iconName: IconName;
  children?: ReactElement | ReactText;
  expanded?: boolean;
  onChange: () => void;
  icon?: ReactElement;
  link?: string;
  newTab?: boolean;
}

const AccordionItem = ({
  iconName,
  title,
  children,
  expanded = true,
  onChange,
  icon,
  link,
  newTab,
}: AccordionItemProps) => {
  const IconComponent: ElementType | ReactElement =
    icon || iconMapping[iconName] || IconHelpCircle;

  const EntryTag = link ? 'a' : 'button';

  return (
    <EntryTag
      {...(link && { href: link })}
      {...(link && newTab && { target: '_blank', rel: 'noopener noreferrer' })}
      {...(!link && { onClick: onChange })}
      className={classNames(
        'w-full rounded-md border p-4 transition-colors',
        expanded ? 'bg-black' : 'border-gray-900 bg-gray-950',
      )}
    >
      <header
        className="flex items-center text-foreground-default"
        onClick={!link ? onChange : undefined}
      >
        <div className="mr-2">
          {React.isValidElement(IconComponent) ? (
            IconComponent
          ) : (
            <IconComponent size={20} />
          )}
        </div>
        <span>{title}</span>
        <div className="flex-grow"></div>
        {!link && (
          <div
            className={classNames('transition-transform', {
              'rotate-180': !expanded,
            })}
          >
            <IconChevronDown size={20} />
          </div>
        )}
      </header>
      {!link && expanded && <div>{children}</div>}
    </EntryTag>
  );
};

const Account = () => {
  let selected = 0;
  const [search, setSearch] = useSearchParams();
  const sectionFocus = search.get('section');

  if (sectionFocus) {
    const idx = menuItems.findIndex((m) => m.id === sectionFocus);
    if (idx >= 0 && selected !== idx) {
      selected = idx;
    }
  }

  return (
    <div className="mx-auto mb-24 mt-24 w-full max-w-6xl grid-area-page">
      <header className="mb-5 text-2xl font-bold">User Profile</header>
      <div className="flex gap-4">
        <section className="flex w-60 flex-col gap-2 rounded-lg border border-gray-900 bg-gray-950 p-2">
          {menuItems.map((m, index) => {
            const IconComponent = iconMapping[m.iconName] || IconHelpCircle;
            return (
              <DropdownMenuItem
                key={m.label}
                label={m.label}
                icon={<IconComponent size={20} />}
                active={index === selected}
                onClick={() => {
                  setSearch(new URLSearchParams([['section', m.id || '']]));
                }}
              />
            );
          })}
        </section>
        <section className="flex flex-grow flex-col gap-3">
          {menuItems.map((m, index) => (
            <AccordionItem
              key={m.label}
              title={m.label}
              iconName={m.iconName}
              expanded={index === selected}
              onChange={() => {
                if (!m.link) {
                  setSearch(new URLSearchParams([['section', m.id || '']]));
                }
              }}
              link={m.link}
              newTab={m.openInNewTab}
            >
              {m.content}
            </AccordionItem>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Account;
