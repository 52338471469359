import { Input as StyledInput, Postfix } from 'common-ui/inputs/Inputs-styled';
import { getAttrToShowTooltip } from 'common-ui/tooltip/generalTooltip';
import { FieldInputProps, FieldMetaProps } from 'formik';
import styled from 'styled-components';

const InputGroup = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Input = styled(StyledInput).attrs({
  withPrefix: false,
  withPostfix: true,
})`
  font-size: 0.875rem;
  width: 100%;
`;

const InputRightGroup = styled.div`
  width: 50%;
  min-width: 3.5rem;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  border: ${(props) => props.theme.color.inputBorderDefault} 1px solid;
  border-right: none;
  cursor: help;
  min-width: 3.5rem;
  width: 50%;
  padding: 4px 8px;
  margin: 0;
  white-space: nowrap;
`;

type FormSingleInputProps = {
  fieldProps: FieldInputProps<number | string>;
  fieldMeta: FieldMetaProps<number | string>;
  label?: string;
  placeholder?: string | undefined;
  unit?: string;
  tip?: string;
};

const renderValidFeedback = (hasError: boolean) =>
  hasError ? <strong>❌</strong> : <strong>✓</strong>;

function getTooltipProps(content: string) {
  return getAttrToShowTooltip({ content, delayShow: 500, place: 'bottom' });
}

export const FormSingleInput = ({
  fieldProps,
  fieldMeta,
  ...props
}: FormSingleInputProps) => {
  return (
    <InputGroup>
      <Label {...getTooltipProps(props.tip || '')} htmlFor={fieldProps.name}>
        {props.label}
      </Label>
      <InputRightGroup>
        <Input
          {...fieldProps}
          placeholder={props.placeholder}
          id={fieldProps.name}
          aria-describedby={`${fieldProps.name}-feedback ${fieldProps.name}-help`}
        />
        <Postfix>{props.unit}</Postfix>
      </InputRightGroup>
      {fieldMeta.touched ? renderValidFeedback(!!fieldMeta.error) : null}
    </InputGroup>
  );
};
