import { useMemo } from 'react';

import {
  DropdownPillComboboxSingleAdd,
  PillButton,
  StyledPillsRow,
} from 'common-ui';
import { keyBy } from 'lodash';

import { StratKey, stratificationsConfig } from './startifications.config';

const allStrats = Object.keys(stratificationsConfig) as StratKey[];

export function StratsSelection(props: {
  selectedStrats: StratKey[];
  onSelectedStratsChange: (strats: StratKey[]) => void;
}) {
  function addStrat(strat: StratKey) {
    props.onSelectedStratsChange([...props.selectedStrats, strat]);
  }
  function removeStrat(strat: StratKey) {
    props.onSelectedStratsChange(
      props.selectedStrats.filter((s) => s !== strat),
    );
  }

  const selectedMap = useMemo(
    () => keyBy(props.selectedStrats),
    [props.selectedStrats],
  );
  const options = useMemo(
    () =>
      allStrats
        .filter((st) => !selectedMap[st])
        .map((st) => ({
          value: st,
          label: stratificationsConfig[st]?.label || '',
        })),
    [selectedMap],
  );

  return (
    <StyledPillsRow>
      <span>Strat by:</span>{' '}
      {props.selectedStrats.map((strat) => (
        <PillButton
          description={stratificationsConfig[strat]?.label || ''}
          key={strat}
          iconName="pill-close"
          onClick={() => removeStrat(strat)}
        />
      ))}
      <DropdownPillComboboxSingleAdd
        options={options}
        buttonLabel="Add Stratification"
        onValueSelected={(option) => addStrat(option.value)}
      />
    </StyledPillsRow>
  );
}
