import React, { useState, ChangeEvent } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'style/ORSNNTheme';
import { useTheme } from 'styled-components';

const SearchBoxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const SearchBoxStyledInput = styled.input`
  color: ${(props) => props.theme.color.slate50};
  background-color: ${(props) => props.theme.color.inputBgDefault};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.slate200};
  border-radius: 4px;
  padding: 12px 30px 12px 16px;
  width: 100%;
`;

const ClearIcon = styled.div`
  position: absolute;
  right: 10px;
  padding: 10px; /* Increase the padding to make a larger clickable area */
  cursor: pointer;
  display: flex;
  align-items: center;
`;

type Props = {
  placeholder?: string;
  onSearchTermChanged: (text: string) => void;
};

export const SearchBox = ({
  placeholder,
  onSearchTermChanged,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearchTermChanged(value);
  };

  const handleClearClick = () => {
    setSearchTerm('');
    onSearchTermChanged('');
  };

  return (
    <SearchBoxContainer>
      <SearchBoxStyledInput
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {searchTerm && (
        <ClearIcon onClick={handleClearClick}>
          <FontAwesomeIcon icon={faTimes} color={theme.color.fgDefault} />
        </ClearIcon>
      )}
    </SearchBoxContainer>
  );
};
