import { SVGProps } from 'react';

const getPath = (name: string, props: SVGProps<SVGPathElement>) => {
  switch (name) {
    case 'backup':
      return (
        <path
          {...props}
          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
        />
      );
    case 'error-outline':
      return (
        <path
          {...props}
          d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      );
    case 'check-circle':
      return (
        <path
          {...props}
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        />
      );
    case 'send':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9979 2C20.8715 2.00032 20.7451 2.02445 20.6261 2.07241L2.66091 8.55972L2.52091 8.62988C1.9807 8.9287 1.67969 9.44079 1.67969 10.0003L1.68696 10.1505C1.73994 10.696 2.07888 11.1779 2.58396 11.4094L9.25456 14.7453L12.6061 21.4475L12.672 21.574C12.9361 22.0243 13.445 22.3211 14.0006 22.3211C14.6067 22.3211 15.1572 21.9679 15.4097 21.4169L21.9411 3.33991L21.9412 3.33969C21.9552 3.30078 21.9667 3.26117 21.9758 3.22112L21.9768 3.21772C21.9928 3.14604 22.001 3.07225 22.0008 2.99764L22.0003 2.98868C22 2.95861 21.9983 2.92854 21.9953 2.89858L21.9949 2.89176C21.989 2.83824 21.9788 2.78551 21.9646 2.73404C21.9283 2.60203 21.8644 2.47601 21.7731 2.36507L21.7717 2.36322L21.7688 2.35989C21.7539 2.34215 21.7384 2.32481 21.7222 2.30789L21.7193 2.30468L21.7172 2.30269C21.714 2.29941 21.7107 2.29614 21.7075 2.29289C21.6854 2.27083 21.6626 2.25001 21.6391 2.23044L21.6376 2.2291L21.6361 2.228C21.4513 2.07546 21.2246 1.99946 20.998 2L20.9979 2L20.9979 2ZM17.1261 5.46003L4.36797 10.0645L9.8032 12.783L17.1261 5.46003ZM11.217 14.1976L18.5356 6.87905L13.932 19.6275L11.217 14.1976Z"
          fill="white"
          {...props}
        />
      );
    default:
      return <path />;
  }
};

const SVG = ({
  name = '',
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 24 24',
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVG;
