import React from 'react';

import { DateTime } from 'luxon';

import { useMutation } from '@apollo/client';

import {
  MarkNotificationRead,
  MarkNotificationReadVariables,
} from 'mutation/__generated__/MarkNotificationRead';
import { MARK_NOTIFICATION_READ_MUTATION } from 'mutation/notifications';

import { GetUserNotifications_userNotifications_notifications as Notification } from 'query/__generated__/GetUserNotifications';
import { GET_NOTIFICATIONS_QUERY } from 'query/notifications';

const NotificationsDropdown: React.FC<{
  notifications: Notification[];
  loading: boolean;
  className?: string;
}> = ({ notifications, loading, className }) => {
  const [updateNotificationStatus] = useMutation<
    MarkNotificationRead,
    MarkNotificationReadVariables
  >(MARK_NOTIFICATION_READ_MUTATION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS_QUERY }],
  });

  const sortedNotifications = notifications.sort((a, b) => {
    return (
      Number(b.notificationMetadata.createdAt) -
      Number(a.notificationMetadata.createdAt)
    );
  });

  const handleDismissNotification = (notificationID: string) => {
    updateNotificationStatus({
      variables: { requestData: { notificationID } },
    });
  };

  const handleNotificationClick = (
    notificationID: string,
    readAt: number | null,
  ) => {
    if (!readAt || readAt < 0) {
      handleDismissNotification(notificationID);
    }
  };

  if (loading && (!notifications || !notifications.length)) {
    return <NotificationsSkeleton className={className} />;
  } else if (!notifications || !notifications.length) {
    return <>No Notifications</>;
  }

  return (
    <div
      className={`overflow-y-auto rounded-lg p-4 px-0 pt-0 font-heebo shadow-lg ${className}`}
    >
      {sortedNotifications.map((notification) => {
        const createdAt = Number(notification.notificationMetadata.createdAt);
        const readAt = Number(notification.notificationMetadata.readAt);

        return (
          <div
            key={notification.notificationMetadata.notificationID}
            className={`border-b-2 border-gray-800 bg-[#171717] ${readAt ? '' : 'border-l-2 border-l-pink-400'} flex items-end justify-between p-3 last:mb-0`}
            onClick={() =>
              handleNotificationClick(
                notification.notificationMetadata.notificationID,
                readAt,
              )
            }
          >
            <div className="w-full pr-2">
              <div className="flex items-center text-xs font-medium text-gray-400">
                {DateTime.fromSeconds(createdAt).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}
                <div className="px-[16px] py-[6px] text-sm font-medium">
                  {readAt ? (
                    <></>
                  ) : (
                    <div className="h-2 w-2 rounded-full bg-pink-400" />
                  )}
                </div>
              </div>
              <p
                className={`[&>a]rounded [&>a]bg-pink-600 [&>a]hover:scale-105 [&>a]active:scale-100 text-white [&>a]:px-1`}
                dangerouslySetInnerHTML={{ __html: notification.content }}
              ></p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const NotificationsSkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={`overflow-y-auto rounded-lg p-4 px-0 pt-0 shadow-lg ${className}`}
    >
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="flex items-end justify-between border-b-2 border-gray-800 bg-background-canvas p-3 last:mb-0"
        >
          <div className="w-full pr-2">
            <div className="flex animate-pulse items-center text-xs font-medium text-gray-400">
              <div className="mb-2 h-4 w-16 rounded bg-slate-700"></div>
            </div>
            <div className="mb-2 h-4 w-full animate-pulse rounded bg-slate-700 text-white"></div>
            <div className="mb-2 h-4 w-1/2 animate-pulse rounded bg-slate-700 text-white"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationsDropdown;
