import React, { useState } from 'react';

import { RangeSlider } from 'common-ui';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { GetFilterBounds_deal_performance_summary as FilterBounds } from 'features/core/filter/__generated__/GetFilterBounds';
import _ from 'lodash';
import { MultiValue } from 'react-select';

import { carveFilterConfigs } from './carveFilterConfigs';
import { Criteria } from './CreateCarveForm';

interface FilterInputProps {
  criteria: Criteria;
  filterBounds: FilterBounds;
  onChange: (criteria: Criteria) => void;
}

type FilterInputType = 'range' | 'select-multi' | 'list-input';

const FilterInput: React.FC<FilterInputProps> = ({
  criteria,
  filterBounds,
  onChange,
}) => {
  const fieldConfig = carveFilterConfigs[criteria.field];

  if (!fieldConfig) {
    console.log(
      `No field config found for field ${criteria.field}. Skipping filter input.`,
    );
    return <div>No field config found for field {criteria.field}</div>;
  }

  const InputComponent = (() => {
    switch (fieldConfig.type) {
      case 'select-multi':
        return (
          <SelectMultiFilterInput criteria={criteria} onChange={onChange} />
        );
      case 'range':
        return (
          <RangeFilterInput
            filterBounds={filterBounds}
            criteria={criteria}
            onChange={onChange}
          />
        );
      case 'list-input':
        return <ListFilterInput criteria={criteria} onChange={onChange} />;
      default:
        console.log(
          `Unsupported field type ${fieldConfig.type} for field ${criteria.field}.`,
        );
        return (
          <div>Unsupported field type {fieldConfig.type} for this field.</div>
        );
    }
  })();

  return InputComponent;
};

const roundDownToNearest = (value: number, nearest: number) =>
  Math.floor(value / nearest) * nearest;
const roundUpToNearest = (value: number, nearest: number) =>
  Math.ceil(value / nearest) * nearest;

const RangeFilterInput = ({
  criteria,
  filterBounds,
  onChange,
}: {
  criteria: Criteria;
  filterBounds: FilterBounds;
  onChange: (criteria: Criteria) => void;
}) => {
  const fieldConfig = carveFilterConfigs[criteria.field];
  if (
    fieldConfig === undefined ||
    fieldConfig.minBoundKey === undefined ||
    fieldConfig.maxBoundKey === undefined
  ) {
    return <div>This field is not filterable</div>;
  }

  if (!filterBounds) {
    return <div>Could not find filter bounds for field {criteria.field}</div>;
  }

  if (typeof criteria.min !== 'number') {
    return (
      <div>
        Invalid min value for field {criteria.field}: {criteria.min}
      </div>
    );
  }

  if (typeof criteria.max !== 'number') {
    return (
      <div>
        Invalid max value for field {criteria.field}: {criteria.max}
      </div>
    );
  }

  const filterMin = filterBounds[fieldConfig.minBoundKey];
  const filterMax = filterBounds[fieldConfig.maxBoundKey];

  const filterMinNumber = roundDownToNearest(
    Number(filterMin),
    fieldConfig.step || 1,
  );
  const filterMaxNumber = roundUpToNearest(
    Number(filterMax),
    fieldConfig.step || 1,
  );

  return (
    <div className="pt-5">
      <RangeSlider
        min={filterMinNumber}
        max={filterMaxNumber}
        step={fieldConfig?.step}
        defaultValue={[Number(criteria.min), Number(criteria.max)]}
        onChange={(sliderValues: number[]) => {
          onChange({
            ...criteria,
            min: sliderValues[0],
            max: sliderValues[1],
          });
        }}
        tooltip={{
          tipFormatter: fieldConfig.valueFormatter,
          tipProps: {
            style: {
              marginTop: '7px',
              color: '#E85EE5',
            },
          },
        }}
        minMaxFormatter={fieldConfig.valueFormatter}
        withTooltip={true}
        alwaysShowTooltip={true}
      />
    </div>
  );
};

interface Option {
  label: string;
  value: string;
}

const findInitialValues = (criteria: Criteria, options: Option[]) => {
  if (!criteria.values) return [];
  return options.filter((option) => criteria.values?.includes(option.value));
};

const SelectMultiFilterInput = ({
  criteria,
  onChange,
}: {
  criteria: Criteria;
  onChange: (criteria: Criteria) => void;
}) => {
  const options = carveFilterConfigs[criteria.field]?.filterOptions ?? [];
  const initialValues = findInitialValues(criteria, options);

  const handleChange = (newValue: MultiValue<Option>) => {
    const newValues = newValue.map((value) => value.value);

    onChange({
      ...criteria,
      values: newValues,
    });
  };

  return (
    <Dropdown
      isMulti={true}
      options={options}
      defaultValue={initialValues}
      onChange={handleChange}
    />
  );
};

const ListFilterInput = ({
  criteria,
  onChange,
}: {
  criteria: Criteria;
  onChange: (criteria: Criteria) => void;
}) => {
  const [inputValue, setInputValue] = useState(
    criteria.values?.join('\n') || '',
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInputValue(value);

    const values = value
      .split(/[\n,]+/)
      .map((val) => val.trim())
      .filter((val) => val.length > 0);

    onChange({
      ...criteria,
      values: _.uniq(values),
    });
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const accountCount = criteria.values?.length || 0;

  return (
    <div className="flex flex-col">
      <div
        className="flex cursor-pointer items-center justify-between rounded border p-2"
        onClick={toggleOpen}
      >
        <span>{accountCount} accounts included</span>
        <svg
          className={`h-4 w-4 transform transition-transform ${
            isOpen ? 'rotate-180 text-accent-default' : ''
          }`}
          viewBox="0 0 20 20"
        >
          <path
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.18l3.71-3.95a.75.75 0 011.08 1.04l-4.25 4.53a.75.75 0 01-1.08 0L5.21 8.27a.75.75 0 01.02-1.06z"
            fill="currentColor"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="mt-2">
          <textarea
            className="h-32 w-full resize-none rounded border bg-background-canvas p-2 text-input-text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Paste loan account IDs here, separated by commas or new lines"
          />
        </div>
      )}
    </div>
  );
};

export type { FilterInputProps, FilterInputType };

export {
  FilterInput,
  RangeFilterInput,
  SelectMultiFilterInput,
  ListFilterInput,
};
