import { styled } from 'style/ORSNNTheme';

export const StyledPillsRow = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.fgLabelDefault};
  font: ${(props) => props.theme.typography.primaryXSmall};
  gap: 1rem;
  align-items: center;
  button {
    margin: 0px;
  }
`;
