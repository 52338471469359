import React, { ReactNode } from 'react';

import { Button } from 'baseui/button';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { Input } from 'common-ui/inputs/Inputs-styled';
import { Option } from 'features/common-elements';
import { Form, useField, useFormikContext } from 'formik';
import { MultiValue, SingleValue } from 'react-select';
import { styled } from 'style/ORSNNTheme';

import ErrorMessage from './ErrorMessage';
import closeIcon from '../../assets/svgs/close-gray.svg';

const isMultiValue = (
  value: SingleValue<Option> | MultiValue<Option>,
): value is MultiValue<Option> => {
  return Array.isArray(value);
};

const FormContainer = styled.div`
  position: relative;
  font-size: 14px;
  color: #868e9f;

  background: #131316;
  padding: 8px 24px;

  border: 1px solid #e028dd;
  border-radius: 8px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 0;
  color: #fff;
`;

const Subheader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  color: #bbc5d7;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${closeIcon});
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Explanatory = styled(Subheader)``;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
`;

interface SubmitButtonProps {
  label: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label }) => {
  return (
    <SubmitButtonContainer>
      <Button size="compact" type="submit">
        {label}
      </Button>
    </SubmitButtonContainer>
  );
};

export interface StandardFormProps {
  closeModal: () => void;
  children: ReactNode;
}

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
`;

const Error = styled.div`
  grid-column: 2;
  color: red;
  margin-top: 5px;
`;

const StandardForm: React.FC<StandardFormProps> = ({
  closeModal,
  children,
}) => {
  return (
    <Form>
      <FormContainer>
        <CloseButton onClick={closeModal} />
        {children}
      </FormContainer>
    </Form>
  );
};
interface FormikDropdownFieldProps {
  name: string;
  options: Option[];
  isMulti?: boolean;
  defaultValue?: Option;
}

interface FormikTextInputProps {
  name: string;
  placeholder?: string;
}

const Label = styled.label`
  align-self: center;
`;

const FormikTextInput: React.FC<FormikTextInputProps> = ({
  name,
  placeholder = '',
}) => {
  const [field, meta] = useField(name);

  return (
    <div>
      <Input withPrefix={false} placeholder={placeholder} {...field} />
      <ErrorMessage>
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </ErrorMessage>
    </div>
  );
};

const FormikDropdownField: React.FC<FormikDropdownFieldProps> = ({
  name,
  options,
  isMulti = false,
  defaultValue,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Find the selected option from the value in the field
  const selectedOption = Array.isArray(field.value)
    ? options.filter((option) => field.value.includes(option.value))
    : options.find((option) => option.value === field.value);

  return (
    <div>
      <Dropdown
        options={options}
        isMulti={isMulti}
        defaultValue={defaultValue || options[0]}
        value={selectedOption}
        onChange={(option) => {
          if (isMultiValue(option)) {
            // If it's multi-select, map the options to get the values
            const value = option.map((o) => o.value);
            setFieldValue(name, value);
          } else {
            const value = option?.value;
            setFieldValue(name, value || '');
            helpers.setTouched(true);
          }
        }}
      />
      <ErrorMessage>
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </ErrorMessage>
    </div>
  );
};

export {
  StandardForm,
  Header,
  Subheader,
  CloseButton,
  Explanatory,
  Error,
  Label,
  FieldsGrid,
  FormikTextInput,
  FormikDropdownField,
  SubmitButton,
};
