import { gql } from '@apollo/client';

export const GET_USER_PERMISSIONS = gql`
  query GetUserPermissions($requestData: UserManagementGetUserRequest!) {
    userManagementGetUser(requestData: $requestData) {
      user {
        userID
        email
        givenName
        familyName
        phoneNumber
        lastLoginDate
      }
      permissions {
        companyID
        roles
        scopes {
          entity
          id
          role
        }
      }
    }
  }
`;

export const LIST_COMPANY_PERMISSIONS = gql`
  query UserManagementListCompanyPermissions(
    $requestData: UserManagementListCompanyPermissionsRequest!
  ) {
    userManagementListCompanyPermissions(requestData: $requestData) {
      permissions {
        principleID
        companyID
        roles
        scopes {
          entity
          id
          role
        }
      }
    }
  }
`;

export const LIST_COMPANY_USER_PERMISSIONS = gql`
  query UserManagementListCompanyUserPermissions(
    $requestData: UserManagementListCompanyUserPermissionsRequest!
  ) {
    userManagementListCompanyUserPermissions(requestData: $requestData) {
      userPermissions {
        user {
          userID
          email
          givenName
          familyName
          phoneNumber
          lastLoginDate
        }
        permission {
          principleID
          companyID
          roles
          scopes {
            entity
            id
            role
          }
        }
      }
    }
  }
`;
