import { memo, useMemo, useCallback } from 'react';

import { ColumnDef, SortingState, Updater } from '@tanstack/react-table';
import { AssetClassToLoanType } from 'app-level/config/assetClassTypes';
import {
  DataDisplayTable,
  DataDisplayTableProps,
  TableEmptyAction,
} from 'common-ui';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import DetailsCard from 'common-ui/Table/DetailsCard';
import { IconBtnWrapper } from 'common-ui/Table/tableStyles';
import { isNotNullOrUndefined } from 'functions/typeUtils';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { invert, mapValues, compact } from 'lodash';
import isEqual from 'react-fast-compare';

import { SortableField } from '__generated__/globalTypes';

import { LoanDatatableLoan as Loan } from './__generated__/LoanDatatableLoan';
import {
  assetClassToColumnsConfig,
  LoanColumnsConfig,
} from './loanTable.config';

type ValueOf<T> = T[keyof T];

function getColumn<LoanType extends Loan, K extends keyof LoanType>(
  columnsConfig: LoanColumnsConfig<LoanType>,
  field: K,
  withListingLink = true,
) {
  const config = columnsConfig[field];
  const fieldString = field.toString();
  if (!config) {
    throw new Error(`No config found for column ${fieldString}`);
  }

  const column: ColumnDef<LoanType, LoanType[K]> = {
    header: config.string,
    id: fieldString,
    accessorKey: field,
    meta: {
      // Changing alignment to 'left' by request of Jesse on 12/12/2023.
      alignment: 'left',
      // alignment: config.numeric ? 'right' : 'left',
      numeric: config.numeric ?? false,
    },
    cell: (props) => {
      const fieldValue = props.getValue() as LoanType[K]; //don't have the energy to convince ts about this type
      const loan = props.row.original;
      if (field === 'listing' && loan.listing && !withListingLink) {
        return loan.listing.name;
      }

      const account_id = loan.account_id;

      if (isNotNullOrUndefined(account_id) && field === 'account_id') {
        return (
          // [kentskinner]: HACK - I'm giving this column a fixed width to stop the columns
          // jumping around as the "trigger" appears and disappears.
          <div
            style={{ display: 'flex', width: '120px', alignItems: 'center' }}
          >
            <ModalNotStyled
              trigger={
                <IconBtnWrapper>
                  <SvgIcon name="info" />
                </IconBtnWrapper>
              }
            >
              {() => (
                <DetailsCard
                  header="Loan Details"
                  selectedLoanId={account_id}
                  companyId={loan.company.id}
                />
              )}
            </ModalNotStyled>
            {config.display(fieldValue, loan)}
          </div>
        );
      }

      return config.display(fieldValue, loan);
    },
    enableSorting: !!config.sortSelector,
  };

  return column;
}

function getColumns<LoanType extends Loan>(
  order: (keyof LoanType)[],
  config: LoanColumnsConfig<LoanType>,
  withListingLink: boolean,
): ColumnDef<LoanType, ValueOf<LoanType>>[] {
  return order.map((field) => getColumn(config, field, withListingLink));
}

export type LoanColumnDef<K extends keyof AssetClassToLoanType> = ColumnDef<
  AssetClassToLoanType[K],
  ValueOf<AssetClassToLoanType[K]>
>;

export interface BaseTableProps<K extends keyof AssetClassToLoanType> {
  data: AssetClassToLoanType[K][];
  strings: {
    empty: string;
  };
  emptyAction?: TableEmptyAction;
  assetClass: K;
  selection?: DataDisplayTableProps<
    AssetClassToLoanType[K],
    unknown
  >['selection'];
  sorting: {
    state: { id: SortableField; desc: boolean }[];
    onSortingChanged: (val: { id: SortableField; desc: boolean }[]) => void;
  };

  // If undefined, the default columns for this asset class will be used.
  columns?: LoanColumnDef<K>[];

  // Omit columns with these IDs
  omitColumnIds?: string[];

  pagination?: {
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;

    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
  withListingLink?: boolean;
}

export const getDefaultColumns = <K extends keyof AssetClassToLoanType>(
  assetClass: K,
  withListingLink: boolean,
): LoanColumnDef<K>[] => {
  const columnsConfig = assetClassToColumnsConfig[assetClass];
  if (!columnsConfig) {
    console.error('No column config found for asset class', assetClass);
    return [];
  }
  return getColumns<AssetClassToLoanType[K]>(
    columnsConfig.order,
    columnsConfig.config,
    withListingLink,
  );
};

function _LoanTable<K extends keyof AssetClassToLoanType>({
  data,
  strings,
  emptyAction,
  assetClass,
  selection,
  sorting,
  columns: customColumns,
  omitColumnIds = [],
  pagination,
  withListingLink = true,
}: BaseTableProps<K>) {
  const columnsConfig = assetClassToColumnsConfig[assetClass];

  /* eslint-disable */
  const sortFieldsToColumns = useMemo(
    () =>
      columnsConfig
        ? invert(
            mapValues(columnsConfig.config, (entry) => entry?.sortSelector),
          )
        : {},
    [columnsConfig],
  );

  const columnsToUse = useMemo(
    () =>
      (customColumns ?? getDefaultColumns(assetClass, withListingLink)).filter(
        (column) => !omitColumnIds.includes(column.id || ''),
      ),
    [customColumns, assetClass, withListingLink, omitColumnIds],
  );

  const sortingState = useMemo(
    () =>
      sorting?.state.map((entry) => ({
        ...entry,
        id: sortFieldsToColumns[entry.id],
      })),
    [sorting?.state, sortFieldsToColumns],
  );

  const handleSortingChanged = useCallback(
    (update: Updater<SortingState>) => {
      const val = typeof update === 'function' ? update(sortingState) : update;
      const sortVal = compact(
        val.map((it) => {
          const sortSelector =
            columnsConfig?.config[it.id as keyof typeof columnsConfig.config]
              ?.sortSelector;
          return sortSelector
            ? {
                ...it,
                id: sortSelector,
              }
            : null;
        }),
      );
      sorting?.onSortingChanged(sortVal);
    },
    [sortingState, columnsConfig, sorting],
  );

  const sortingProp = useMemo(
    () =>
      sortingState
        ? {
            state: sortingState,
            onSortingChanged: handleSortingChanged,
            isManual: true,
          }
        : undefined,
    [sortingState, handleSortingChanged],
  );

  const getRowId = useCallback(
    (row: Loan) => generateRowId(row.account_id, row.id),
    [],
  );

  if (!columnsConfig) {
    console.error('No column config found for asset class', assetClass);
    return null;
  }

  return (
    <DataDisplayTable
      // @ts-expect-error - getRowId expects specific loan fields. we should come back to this
      getRowId={getRowId}
      data={data}
      // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
      columns={columnsToUse}
      emptyAction={emptyAction}
      noDataMessage={strings.empty}
      selection={selection}
      sorting={sortingProp}
      pagination={pagination}
    />
  );
}

export const LoanTable = memo(_LoanTable, isEqual);

// Utility function to generate a composite row ID
/**
 * The getRowId function is used to uniquely identify each row in the table.
 * In this case, it's creating a unique identifier for each row by concatenating
 * the account_id and the id of each loan with a colon (:) as a separator.
 *
 * This is particularly useful in cases where you need to work with both account_id and id
 * for operations like row selection, sorting, etc. The unique identifier created by this function
 * can be split back into account_id and id when needed.
 *
 * Note: This assumes that neither account_id nor id includes a colon (:).
 */
export const generateRowId = (accountId: string | null, loanId: string) =>
  `${accountId}:${loanId}`;

// Utility function to parse a composite row ID
export const parseRowId = (rowId: string) => {
  const [accountId, loanId] = rowId.split(':');
  return { accountId, loanId };
};
