import { MultiValue, SingleValue } from 'react-select';

import {
  PillButtonCombobox,
  PillButtonComboboxProps,
} from './PillButtonCombobox';
import { SvgPillButton } from '../Buttons';
import { usePopout, FloatingPortal } from '../hooks/usePopout';

export function DropdownPillComboboxSingleAdd<T extends object>({
  buttonLabel,
  onValueSelected,
  ...comboProps
}: Omit<
  PillButtonComboboxProps<T, false>,
  'onCloseBtnClick' | 'onValueSelected'
> & {
  buttonLabel: string;
  onValueSelected: (value: T) => void;
}) {
  const {
    refs,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    floatingStyles,
    setIsOpen,
  } = usePopout();

  function onSelected(value: SingleValue<T> | MultiValue<T>) {
    onValueSelected(value as T);
  }

  return (
    <>
      <SvgPillButton
        {...getReferenceProps()}
        ref={refs.setReference}
        isSelected={isOpen}
        label={buttonLabel}
        iconName="pill-plus"
        fill="ffffff"
      />
      {isOpen ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PillButtonCombobox<T, false>
              {...comboProps}
              onClose={() => setIsOpen(false)}
              onValueSelected={onSelected}
            />
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
