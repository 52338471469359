import { useCallback, useEffect, useRef } from 'react';

import { useApolloClient } from '@apollo/client';

import { AssetClass, FileType, ProductType } from '__generated__/globalTypes';

import {
  getCollateralFileUploadURL,
  getCollateralFileUploadURLVariables,
} from 'mutation/__generated__/getCollateralFileUploadURL';
import {
  GetContractPostUrl,
  GetContractPostUrlVariables,
} from 'mutation/__generated__/GetContractPostUrl';
import {
  GetFilePostUrls,
  GetFilePostUrlsVariables,
} from 'mutation/__generated__/GetFilePostUrls';
import GET_COLLATERAL_FILE_UPLOAD_URL_MUTATION from 'mutation/getCollateralFileUploadURL';
import GET_CONTRACT_FILE_POST_URL_MUTATION from 'mutation/getContractPostUrls';
import GET_FILE_POST_URLS_MUTATION from 'mutation/getFilePostUrls';

import useFileUuid from './useFileUuid';

type UseS3UploaderProps = {
  onSuccess?: (file: File, versionId: string, uuid?: string) => void;
  onProgress?: (progress: number, file?: File) => void;
  onError?: (error: unknown) => void;
  fileType: FileType;
  parentId?: string;
  assetClass?: AssetClass;
  productType?: ProductType;
  allowedMimeTypes?: string[];
  isContract?: boolean;
  isCollateral?: boolean;
  uploadedBy?: string;
  loanId?: string;
};

const usePreSignedUrlQuery = (
  fileType: FileType,
  parentId?: string,
  assetClass?: AssetClass,
  productType?: ProductType,
  isContract?: boolean,
  isCollateral?: boolean,
) => {
  const client = useApolloClient();

  const getPresignedUrl = async (
    fileName: string,
    contentType: string,
    loanId: string,
  ) => {
    if (isContract && parentId != null) {
      const query: GetContractPostUrlVariables = {
        input: {
          dealId: parentId,
          fileName,
          contentType,
        },
      };

      const result = await client.mutate<
        GetContractPostUrl,
        GetContractPostUrlVariables
      >({
        mutation: GET_CONTRACT_FILE_POST_URL_MUTATION,
        variables: query,
      });

      if (!result.data) {
        throw new Error(
          'Could not obtain a pre-signed upload URL for contract.',
        );
      }
      return result.data.getContractFilePostUrl;
    } else if (isCollateral) {
      const query: getCollateralFileUploadURLVariables = {
        input: {
          dealID: parentId || '',
          fileName,
          loanID: loanId || '',
        },
      };

      const result = await client.mutate<
        getCollateralFileUploadURL,
        getCollateralFileUploadURLVariables
      >({
        mutation: GET_COLLATERAL_FILE_UPLOAD_URL_MUTATION,
        variables: query,
      });

      if (!result.data) {
        throw new Error(
          'Could not obtain a pre-signed upload URL for collateral zip.',
        );
      }

      return result.data.getContractFilePostUrl;
    } else {
      // Default S3 uploader logic
      const query = {
        mutation: GET_FILE_POST_URLS_MUTATION,
        variables: {
          parentId,
          fileType,
          fileName,
          contentType,
          assetClass,
          productType,
        },
      };

      const result = await client.mutate<
        GetFilePostUrls,
        GetFilePostUrlsVariables
      >(query);
      if (!result.data) {
        throw new Error('Could not obtain a pre-signed upload URL.');
      }
      return result.data.getFilePostUrl;
    }
  };

  return {
    getPresignedUrl,
  };
};

export const useS3Uploader = ({
  onSuccess,
  onProgress,
  onError,
  fileType,
  parentId,
  allowedMimeTypes,
  isContract,
  isCollateral,
  uploadedBy,
  loanId,
  ...rest
}: UseS3UploaderProps) => {
  const { fetchFileUuid } = useFileUuid();
  const { getPresignedUrl } = usePreSignedUrlQuery(
    fileType,
    parentId,
    rest.assetClass,
    rest.productType,
    isContract,
    isCollateral,
  );
  const getPresignedUrlRef = useRef<(file: File) => Promise<string>>(
    async () => '',
  );
  useEffect(() => {
    getPresignedUrlRef.current = async (file: File) => {
      return getPresignedUrl(file.name, file.type, loanId || '');
    };
  }, [getPresignedUrl, loanId]);

  const upload = useCallback(
    async (file: File) => {
      if (allowedMimeTypes != null && !allowedMimeTypes.includes(file.type)) {
        const error = new Error(
          'Invalid file type. Only CSV, TSV, and Excel files are allowed.',
        );
        console.error(error.message);
        onError?.(error);
        return;
      }

      try {
        const presignedUrl = await getPresignedUrlRef.current(file);

        const versionId = await performUpload(
          file,
          presignedUrl,
          onProgress,
          isContract,
          isCollateral,
          parentId,
          uploadedBy,
          loanId,
        );

        if (!isCollateral) {
          try {
            let uuid;
            if ('assetClass' in rest) {
              uuid = await fetchFileUuid(
                file,
                fileType,
                versionId,
                parentId,
                rest.assetClass,
                rest.productType,
              );
            } else {
              uuid = await fetchFileUuid(file, fileType, versionId);
            }
            onSuccess?.(file, versionId || '', uuid);
          } catch (error) {
            console.log('Failed to fetch file UUID:', error);
            onSuccess?.(file, versionId || '');
          }
        } else {
          onSuccess?.(file, versionId || '');
        }

        return versionId;
      } catch (error) {
        console.error('Failed to upload file:', error);
        onError?.(error);
      }
    },
    [
      onSuccess,
      onProgress,
      onError,
      getPresignedUrlRef,
      fileType,
      parentId,
      rest,
      fetchFileUuid,
      allowedMimeTypes,
      isContract,
      isCollateral,
      uploadedBy,
      loanId,
    ],
  );

  return {
    upload,
  };
};

const performUpload = (
  file: File,
  presignedUrl: string,
  onProgress?: (progress: number, file?: File) => void,
  isContract?: boolean,
  isCollateral?: boolean,
  dealId?: string,
  uploadedBy?: string,
  loanId?: string,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);

    // TODO: Refactor to separate services for handling different upload cases
    if (isCollateral) {
      xhr.setRequestHeader('x-amz-meta-dealid', dealId || '');
      xhr.setRequestHeader('x-amz-meta-filename', file.name);
      xhr.setRequestHeader('x-amz-meta-uploadedby', uploadedBy || '');

      if (loanId) {
        xhr.setRequestHeader('x-amz-meta-loanid', loanId);
      }
    }

    xhr.onerror = (err) => {
      console.log(err, 'error in upload');
      console.log(
        `Upload failed with status ${xhr.status}: ${xhr.statusText} : ${xhr}`,
      );
      reject(
        new Error(`Upload failed with status ${xhr.status}: ${xhr.statusText}`),
      );
    };
    xhr.onabort = () => reject(new Error('Upload aborted.'));
    xhr.onload = () => {
      if (xhr.status === 200) {
        const versionId = xhr.getResponseHeader('x-amz-version-id');
        if (isContract || isCollateral || versionId !== null) {
          resolve(versionId || '');
        } else {
          reject(new Error('Upload failed - no version ID returned.'));
        }
      } else {
        console.log(xhr, 'xhr in upload');
        console.log(
          `Upload failed with status ${xhr.status}: ${xhr.statusText}`,
        );
        reject(new Error(`Upload failed with status ${xhr.status}.`));
      }
    };

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        onProgress?.(percentComplete, file);
      }
    };

    xhr.send(file);
  });
};
