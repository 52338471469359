import React from 'react';

import classNames from 'classnames';

import './button-group.scss';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const TYPES = {
  primary: 'primary',
  secondary: 'secondary',
};

export type ButtonGroupProps = {
  /* aria-label */
  label: string;
  /* Button content */
  children: React.ReactNode;
  /* Size of button */
  size?: (typeof SIZES)[keyof typeof SIZES];
  /* Type of button */
  type?: (typeof TYPES)[keyof typeof TYPES];
};

export const ButtonGroup = ({
  children,
  label,
  size = SIZES.large,
  type = TYPES.primary,
}: ButtonGroupProps) => {
  const classes = classNames(
    'c-button_group',
    `c-button_group--${size}`,
    `c-button_group--${type}`,
  );

  return (
    <div aria-label={label} className={classes}>
      {children}
    </div>
  );
};
