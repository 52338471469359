import { useState, useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  GetCollateralNotes,
  GetCollateralNotesVariables,
} from 'query/__generated__/GetCollateralNotes';
import { GET_COLLATERAL_NOTES } from 'query/collateral';

import { Note } from '../DealDocumentTypes';

export const useCollateralNotes = () => {
  const client = useApolloClient();

  // State to store the fetched notes, mapped by collateral ID
  const [collateralNotesMap, setCollateralNotesMap] = useState<
    Record<string, Note[]>
  >({});

  const fetchCollateralNotes = useCallback(
    // Fetch notes for each dealID and optional loanID
    async (dealID: string, loanID?: string, fileName?: string) => {
      try {
        const result = await client.query<
          GetCollateralNotes,
          GetCollateralNotesVariables
        >({
          query: GET_COLLATERAL_NOTES,
          variables: {
            dealID,
            loanID,
            fileName,
            limit: 100, // Default limit, can be adjusted
            paginationKey: undefined, // Optional pagination support
          },
          fetchPolicy: 'network-only',
        });

        // Update the state with the fetched notes
        setCollateralNotesMap((prevMap) => {
          const newMap = { ...prevMap };

          // Group notes by file
          const notesGroupedByFile =
            result.data?.collateralNotes?.files?.reduce(
              (acc, fileWithNotes) => {
                const fileKey =
                  fileWithNotes?.file.collateralID ||
                  fileWithNotes?.file.fileName ||
                  '';
                return {
                  ...acc,
                  [fileKey]: fileWithNotes?.notes || [],
                };
              },
              {},
            ) || {};

          return {
            ...newMap,
            ...notesGroupedByFile,
          };
        });

        return result.data?.collateralNotes?.nextPaginationKey;
      } catch (error) {
        console.error('Error fetching collateral notes:', error);
        return undefined;
      }
    },
    [client],
  );

  // Return the notes map and the fetch function
  return { collateralNotesMap, fetchCollateralNotes };
};
