import { InputHTMLAttributes, useState } from 'react';

import { Auth } from 'aws-amplify';
import { BaseButton, SvgIcon } from 'common-ui';
import Input from 'common-ui/inputs/Input/Input';

import './ChangePasswordForm.scss';

interface ChangePasswordFormProps {
  setIsOpen: (isOpen: boolean) => void;
  initialError?: string;
  initialOldPassword?: string;
  initialNewPassword?: string;
  initialConfirmPassword?: string;
}

const PasswordInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div className="change__password__input">
      <Input {...props} type={passwordVisible ? 'text' : 'password'} />
      <button
        type="button"
        className="change__password__showbtn"
        onClick={() => setPasswordVisible(!passwordVisible)}
      >
        <SvgIcon name={passwordVisible ? 'eye' : 'eye-slash'} />
      </button>
    </div>
  );
};

const ChangePasswordForm = ({
  setIsOpen,
  initialError = '',
  initialOldPassword = '',
  initialNewPassword = '',
  initialConfirmPassword = '',
}: ChangePasswordFormProps) => {
  const [inProgress, setInProgress] = useState(false);
  const [oldPassword, setOldPassword] = useState(initialOldPassword);
  const [newPassword, setNewPassword] = useState(initialNewPassword);
  const [confirmPassword, setConfirmPassword] = useState(
    initialConfirmPassword,
  );
  const [error, setError] = useState(initialError);

  async function changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) {
    let closeModal = false;
    setError('');
    setInProgress(true);
    try {
      if (!oldPassword) {
        setError('Old password cannot be empty.');
        return;
      }

      if (!newPassword || !confirmPassword) {
        setError('New password cannot be empty.');
        return;
      }

      if (newPassword !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }

      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      closeModal = true;
    } catch (err: unknown) {
      let message = 'Unknown error';
      if (err instanceof Error) message = err.message;
      setError(message);
    } finally {
      setInProgress(false);
      if (closeModal) {
        setIsOpen(false);
      }
    }
  }

  return (
    <form>
      <div className="change__password">
        <div className="change__password__input" style={{ display: 'none' }}>
          {/* Added for accessibility only */}
          <Input placeholder="Email" autoComplete="email" />
        </div>
        <PasswordInput
          placeholder="Old password"
          value={oldPassword}
          autoComplete="current-password"
          onChange={(event) => {
            setOldPassword(event.target.value);
          }}
        />
        <PasswordInput
          placeholder="New password"
          value={newPassword}
          autoComplete="new-password"
          onChange={(event) => {
            setNewPassword(event.target.value);
          }}
        />
        <PasswordInput
          placeholder="Confirm password"
          value={confirmPassword}
          autoComplete="new-password"
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
        />
        {error && <div className="change__password__error">{error}</div>}
        <div className="change__password__button">
          <BaseButton
            size="medium"
            label="Save Password"
            disabled={
              inProgress || !oldPassword || !newPassword || !confirmPassword
            }
            onClick={() =>
              changePassword(oldPassword, newPassword, confirmPassword)
            }
          >
            Save Password
          </BaseButton>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
