import { gql, useQuery } from '@apollo/client';

import {
  Filter,
  FilterOperator,
  FilterableField,
  AssetClass,
} from '__generated__/globalTypes';

import {
  SummaryTableFragments,
  MonthlyTableFragments,
  ChartFragments,
} from './cashflowDataFragments';
import { CashFlowsValidValues } from '../configurations/cashflow-inputs';
import {
  GetDealCashFlows,
  GetDealCashFlowsVariables,
} from './__generated__/GetDealCashFlows';

const DEAL_QUERY = gql`
  query GetDealCashFlows(
    $dealId: ID!
    $cdr: Float!
    $cpr: Float!
    $lsr: Float!
    $prepaymentAssumptionType: PrepaymentAssumptionType
    $defaultAssumptionType: DefaultAssumptionType
    $servicingRate: Float
    $daysDelay: Int
    $filters: [Filter!]
    $marketYield: Float!
    $rateType: RateType
  ) {
    deal(id: $dealId) {
      id
      performanceSummary: performance_summary(filters: $filters) {
        ...SummaryTablePerformanceSummary
        cashFlows: cash_flows(
          conditionalDefaultRate: $cdr
          conditionalPrepaymentRate: $cpr
          lossSeverityRate: $lsr
          prepaymentAssumptionType: $prepaymentAssumptionType
          defaultAssumptionType: $defaultAssumptionType
          servicingRate: $servicingRate
          daysDelay: $daysDelay
          marketYield: $marketYield
          rateType: $rateType
        ) {
          totals {
            ...SummaryTableCashFlowTotals
          }
          monthlyCashFlows: monthly_cash_flows {
            ...ChartCashFlowMonth
            ...MonthlyTableCashFlowMonth
          }
          price_yield_matrix {
            spread_matrix
            yield_matrix
          }
        }
      }
    }
  }
  ${SummaryTableFragments.cashFlowTotals}
  ${SummaryTableFragments.performanceSummary}
  ${MonthlyTableFragments.cashFlowMonth}
  ${ChartFragments.cashFlowMonth}
`;

export function getUseDealCashflows(
  assetClass: AssetClass,
  dealId: string,
  filters: Filter[],
) {
  const useDealCashflows_gql = (userInputs: CashFlowsValidValues) => {
    const { data, loading, error } = useQuery<
      GetDealCashFlows,
      GetDealCashFlowsVariables
    >(DEAL_QUERY, {
      variables: {
        dealId: dealId,
        cdr: userInputs.cdr / 100,
        cpr: userInputs.cpr / 100,
        lsr: userInputs.lsr / 100,
        daysDelay: userInputs.daysDelay,
        servicingRate:
          userInputs.servicingRate != null
            ? userInputs.servicingRate / 100
            : null,
        rateType: userInputs.rateType,
        prepaymentAssumptionType: userInputs.prepaymentAssumptionType,
        defaultAssumptionType: userInputs.defaultAssumptionType,
        marketYield: 3.5 / 100, // TODO remove hardcoding of marketYield this is just to pass build
        filters: [
          {
            field_name: FilterableField.asset_class,
            operator: FilterOperator.IS,
            operand: `${assetClass}`,
          },
          ...filters.map((filter: Filter) => ({
            field_name: filter.field_name,
            operator: filter.operator,
            operand: filter.operand,
            operandList: filter.operandList,
          })),
        ],
      },
    });

    return {
      loading,
      error,
      data: data?.deal?.performanceSummary,
    };
  };
  return useDealCashflows_gql;
}
