import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import Dinero from 'dinero.js';
import { styled } from 'style/ORSNNTheme';

import { MonthlyTableCashFlowMonth } from './gql/__generated__/MonthlyTableCashFlowMonth';

const formatCurrency = (value: number) =>
  Dinero({ amount: value, currency: 'USD' }).toFormat('$0,0.00');

type MonthColConfig<T extends keyof MonthlyTableCashFlowMonth> = {
  string: string;
  sortable: boolean;
  numeric?: boolean;
  display: (value: MonthlyTableCashFlowMonth[T]) => string;
};

type Config = {
  [FieldName in keyof MonthlyTableCashFlowMonth]?: MonthColConfig<FieldName>;
};

const NO_DATA = '-';

const LISTING_COLS: Config = {
  month: {
    string: 'Month',
    sortable: false,
    display: (value) => value?.toLocaleString() ?? NO_DATA,
  },
  starting_balance_cents: {
    string: 'Starting Balance',
    sortable: false,
    display: (value) => formatCurrency(value),
  },
  payments_cents: {
    string: 'Payments',
    sortable: false,
    display: (value) => formatCurrency(value),
  },
  principal_cents: {
    string: 'Principal',
    sortable: false,
    display: (value) => formatCurrency(value),
  },
  interest_cents: {
    string: 'Interest',
    sortable: false,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  servicing_cents: {
    string: 'Servicing',
    sortable: false,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  prepayments_cents: {
    string: 'Prepayments',
    sortable: false,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  defaults_cents: {
    string: 'Defaults',
    sortable: false,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  losses_cents: {
    string: 'Losses',
    sortable: false,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  ending_balance_cents: {
    string: 'Ending Balance',
    sortable: false,
    display: (value) => formatCurrency(value),
  },
  cash_flow_cents: {
    string: 'Cash Flow',
    sortable: false,
    display: (value) => formatCurrency(value),
  },
};

type Props = {
  monthlyCashFlows: MonthlyTableCashFlowMonth[];
};

const Container = styled.div``;

const tableColumns = Object.entries(LISTING_COLS).map(([key, config]) => {
  if (config == null) {
    return <></>;
  }
  return (
    <TableBuilderColumn
      key={key}
      id={key}
      numeric={config.numeric}
      header={config.string}
      sortable={config.sortable}
    >
      {(row: MonthlyTableCashFlowMonth) => {
        const value = row[key as keyof MonthlyTableCashFlowMonth];
        return config.display(value);
      }}
    </TableBuilderColumn>
  );
});

const MonthlyTable = ({ monthlyCashFlows }: Props): JSX.Element => (
  <Container>
    <TableBuilder
      data={monthlyCashFlows}
      overrides={{
        TableHeadCell: {
          style: {
            color: '#868e9f',
            fontSize: '12px',
            fontFamily: 'Heebo',
          },
        },
        TableBodyRow: {
          style: () => ({}),
        },
        TableBodyCell: {
          style: ({ $colIndex }) => ({
            color: '#f4f4fd',
            fontSize: '12px',
            fontFamily: 'PT Mono',
            textAlign: $colIndex === 0 ? 'left' : 'right',
          }),
        },
      }}
    >
      {tableColumns}
    </TableBuilder>
  </Container>
);

export default MonthlyTable;
