import { createQueryFilters } from 'context/DataContext';
import { ASSET_CLASS_IDS, AssetClassId } from 'models/AssetClass';

import { gql, useQuery } from '@apollo/client';

import { Filter } from '__generated__/globalTypes';

import {
  ChartFragments,
  MonthlyTableFragments,
  SummaryTableFragments,
} from './cashflowDataFragments';
import { CashFlowsValidValues } from '../configurations/cashflow-inputs';
import {
  GetPortfolioCashFlows,
  GetPortfolioCashFlowsVariables,
} from './__generated__/GetPortfolioCashFlows';

const PORTFOLIO_QUERY = gql`
  query GetPortfolioCashFlows(
    $cdr: Float!
    $cpr: Float!
    $lsr: Float!
    $daysDelay: Int
    $filters: [Filter!]
    $marketYield: Float!
  ) {
    user {
      id
      company {
        id
        ... on UserCompany {
          id
          performanceSummary: performance_summary(filters: $filters) {
            ...SummaryTablePerformanceSummary
            cashFlows: cash_flows(
              conditionalDefaultRate: $cdr
              conditionalPrepaymentRate: $cpr
              lossSeverityRate: $lsr
              daysDelay: $daysDelay
              marketYield: $marketYield
            ) {
              totals {
                ...SummaryTableCashFlowTotals
              }
              monthlyCashFlows: monthly_cash_flows {
                ...ChartCashFlowMonth
                ...MonthlyTableCashFlowMonth
              }
            }
          }
        }
      }
    }
  }
  ${SummaryTableFragments.cashFlowTotals}
  ${SummaryTableFragments.performanceSummary}
  ${MonthlyTableFragments.cashFlowMonth}
  ${ChartFragments.cashFlowMonth}
`;

export function getUsePortfolioCashflow(
  assetClassId: AssetClassId,
  filters: Filter[],
) {
  const assetClass = ASSET_CLASS_IDS[assetClassId];

  const usePortfolioCashflow_gql = (userInputs: CashFlowsValidValues) => {
    const { data, loading, error } = useQuery<
      GetPortfolioCashFlows,
      GetPortfolioCashFlowsVariables
    >(PORTFOLIO_QUERY, {
      variables: {
        cdr: userInputs.cdr / 100,
        cpr: userInputs.cpr / 100,
        lsr: userInputs.lsr / 100,
        daysDelay: userInputs.daysDelay,
        filters: createQueryFilters(assetClass, filters),
        marketYield: 3.5 / 100, // TODO remove hardcoded placeholder when market yield is in backend
      },
    });

    return {
      loading,
      error,
      data: data?.user.company.performanceSummary,
    };
  };
  return usePortfolioCashflow_gql;
}
