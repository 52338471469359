import { PropsWithChildren } from 'react';

import baseStyled, {
  css as baseCss,
  ThemedCssFunction,
  ThemedStyledInterface,
  ThemeProvider,
} from 'styled-components';

import colors from './utilities/colors.module.scss';
import sizes from './utilities/sizes.module.scss';
import typography from './utilities/typography.module.scss';

const theme = {
  color: colors,
  size: sizes,
  typography: typography,
};

type ThemeProps = {
  children: PropsWithChildren<JSX.Element>;
};
const ORSNNTheme = ({ children }: ThemeProps): JSX.Element => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
export default ORSNNTheme;
export { theme };
export type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = baseCss as ThemedCssFunction<Theme>;
