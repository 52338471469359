import clsx from 'clsx';
import { SvgIcon } from 'common-ui';
import { NavLink } from 'react-router-dom';

import { SIDE_NAV_CONFIG } from './SideNav.config';

export const SideNav: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={clsx('ml-3 mt-24 inline-flex flex-col gap-3 p-2', className)}
    >
      {SIDE_NAV_CONFIG.map((navConfig) => (
        <li className="list-none text-center" key={navConfig.routeSegment}>
          <NavLink
            to={`${navConfig.routeSegment}`}
            className={({ isActive }) =>
              clsx(
                'flex flex-col items-center p-1 px-2 pt-3 text-[8px] text-white no-underline',
                {
                  'pointer-events-none cursor-not-allowed opacity-20':
                    navConfig.disabled,
                  'rounded border border-accent-emphasis bg-gray-950': isActive,
                },
              )
            }
            tabIndex={navConfig.disabled ? -1 : 0}
          >
            <SvgIcon name={navConfig.iconName} />
            <div className="mt-2 leading-snug">{navConfig.text}</div>
          </NavLink>
        </li>
      ))}
    </div>
  );
};
