import { useState, useEffect, useMemo, FC, useCallback } from 'react';

import { useMountEffect } from '@react-hookz/web';
import { DropdownPillComboboxSingleAdd, PillButton } from 'common-ui';
import _ from 'lodash';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { FetchResult, QueryResult, OperationVariables } from '@apollo/client';

import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';
import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';

import { KPI } from './types';
import { isMetricMatchingFilter } from './utils/helpers';
import './pool-manager.scss';

type PassedProps = {
  title: string;
  numPools?: number;
  metrics: KPI[];
  addUserSetting: (value: string) => Promise<FetchResult<SavePoolSettings>>;
  savedPoolSettings: QueryResult<GetPoolSettings, OperationVariables>;
  userPoolSettings?: string[];
  autoExpanded?: boolean;
};

export const Metrics: FC<{
  metrics: KPI[];
  handleClick?: (metric: KPI) => () => void;
}> = ({ metrics, handleClick }) => (
  <>
    {metrics.map((metric) => (
      <PillButton
        key={metric.description}
        description={metric.description}
        value={metric.value}
        onClick={handleClick?.(metric)}
      />
    ))}
  </>
);

const getMeticsSkeleton = (count: number) => {
  return (
    <div className="flex flex-row gap-5">
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="h-[30px] w-32 animate-pulse rounded-full bg-gray-950 shadow-sm"
        />
      ))}
    </div>
  );
};

const PoolHeader: FC<PassedProps> = ({
  title,
  numPools,
  metrics,
  addUserSetting,
  savedPoolSettings,
  userPoolSettings,
  autoExpanded = false,
}) => {
  const { loading, error } = savedPoolSettings;
  const [displayedMetrics, setDisplayedMetrics] = useState<KPI[]>([]);

  useEffect(() => {
    if (!loading && !error && userPoolSettings) {
      const accumulatedMetrics = userPoolSettings
        .map((selector) =>
          metrics.find((metric) => metric.selector === selector),
        )
        .filter(Boolean) as KPI[];
      setDisplayedMetrics(accumulatedMetrics);
    } else if (userPoolSettings == null) {
      setDisplayedMetrics([]);
    }
  }, [userPoolSettings, metrics, loading, error, title]);

  const saveUserSettings = useCallback(
    (metricsToUpdate: KPI[]) => {
      const selectorsToSave = metricsToUpdate.map((metric) => metric.selector);
      const formattedMetrics = JSON.stringify(selectorsToSave);
      return addUserSetting(formattedMetrics);
    },
    [addUserSetting],
  );

  useMountEffect(() => {
    if (autoExpanded) {
      saveUserSettings(metrics).then(() => setDisplayedMetrics(metrics));
    }
  });

  const handleClick = (selectedMetric: KPI) => () => {
    const updatedMetrics = displayedMetrics.filter(
      (metric) => metric.selector !== selectedMetric.selector,
    );
    saveUserSettings(updatedMetrics).then(() =>
      setDisplayedMetrics(updatedMetrics),
    );
  };

  const handleAddMetric = (selectedMetric: KPI) => {
    if (
      !displayedMetrics.some(
        (metric) => metric.selector === selectedMetric.selector,
      )
    ) {
      const updatedMetrics = [...displayedMetrics, selectedMetric];
      saveUserSettings(updatedMetrics).then(() =>
        setDisplayedMetrics(updatedMetrics),
      );
    }
  };

  const availableMetrics = useMemo(
    () => _.differenceBy(metrics, displayedMetrics, 'selector'),
    [displayedMetrics, metrics],
  );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="pool-manager">
      <span className="pool-manager__desc">{title}</span>
      {numPools && (
        <span className="pool-manager__num_pools">{`${numPools} Pools`}</span>
      )}
      {loading ? (
        <>{getMeticsSkeleton(4)}</>
      ) : (
        <Metrics
          metrics={displayedMetrics}
          handleClick={autoExpanded ? undefined : handleClick}
        />
      )}
      {!autoExpanded && (
        <DropdownPillComboboxSingleAdd
          options={availableMetrics}
          getOptionLabel={(option: KPI) => option.description}
          getOptionValue={(option: KPI) => option.value}
          onValueSelected={handleAddMetric}
          buttonLabel="Add a metric to the Pool Summary"
          comboLabel="Search for a metric"
          inputPlaceholder="Enter a metric name..."
          manuLabel="All metrics (Ascending)"
          filterOption={(
            option: FilterOptionOption<KPI>,
            searchInput: string,
          ) => isMetricMatchingFilter(option, searchInput)}
        />
      )}
    </div>
  );
};

export default PoolHeader;
