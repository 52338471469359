import { gql } from '@apollo/client';

const SettlementCardFragments = {
  TimelineCard: gql`
    fragment SettlementCard on TimelineCard {
      ... on SettlementCard {
        role
        is_complete
      }
    }
  `,
};

export default SettlementCardFragments;
