import React, { ReactNode } from 'react';

import classNames from 'classnames';

export const CarveName: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <label className="flex h-10 items-center gap-2 p-2 font-heebo text-base text-white">
      {children}
    </label>
  );
};

export const CarveDetailsContainer: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div className="flex flex-col font-heebo text-base text-slate-200">
      {children}
    </div>
  );
};

export const CarveDetailsRow: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="flex min-h-[36px] justify-between border-b border-slate-600 odd:bg-slate-800">
    {children}
  </div>
);

export const CarveDetailsLabel: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="w-1/3 min-w-[100px] border-r border-slate-600 p-1.5 px-3 pr-3 text-left capitalize sm:w-1/4">
    {children}
  </div>
);

export const CarveDetailsValue: React.FC<{
  children: React.ReactNode;
  fullWidth?: boolean;
}> = ({ children, fullWidth = false }) => (
  <div
    className={`${fullWidth ? 'w-full text-left' : 'flex-grow text-right'} p-1.5 pl-3`}
  >
    {children}
  </div>
);

export const Subheader: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="font-heebo text-sm text-gray-400">{children}</div>;
};

export const CarveSubheader: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div className="flex h-8 items-center gap-2 px-2 pb-2 font-heebo text-base text-sm text-gray-400">
      {children}
    </div>
  );
};

export const CarveActions: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <div className="mb-5 flex gap-4">{children}</div>;
};

export const CarveCardContainer: React.FC<{
  highlight?: boolean;
  children: ReactNode;
}> = ({ children, highlight = false }) => {
  return (
    <div
      className={classNames(
        'min-w-[222px] max-w-[355px] flex-grow rounded-lg font-heebo text-base',
        {
          'text-pink-300': highlight,
          'text-white': !highlight,
        },
      )}
    >
      {children}
    </div>
  );
};
