import { PropsWithChildren } from 'react';

import { getPropertyAccessor } from '@nivo/core';
import {
  ComputedCell,
  HeatMapDatum,
  HeatMapSerie,
  ResponsiveHeatMap,
  TooltipProps,
} from '@nivo/heatmap';
import { forOwn } from 'lodash';
import { getContrast } from 'polished';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { chartTheme, formatValue } from './chartsCommons';
import { CommonChartProps, StyledTooltip } from './chartsCommons';
import { statesMatrix } from './statesMatrix';

type Item = HeatMapDatum & {
  label: string;
};

export function StatesHeatMap<T>(props: CommonChartProps<T>) {
  const theme = useTheme();
  const data = prepareData(props);
  const intl = useIntl();
  intl.formatNumber(1234, {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
  });
  function getLabelColor(cell: Omit<ComputedCell<Item>, 'labelTextColor'>) {
    if (!cell.color) return theme.color.gray900;
    return getContrast(cell.color, theme.color.gray50) > 3
      ? theme.color.gray50
      : theme.color.gray900;
  }

  return (
    <ResponsiveHeatMap
      data={data}
      valueFormat={(value) =>
        formatValue(intl, value, props.valueFormatOptions)
      }
      emptyColor={theme.color.bgSurface}
      theme={chartTheme(theme)}
      forceSquare={true}
      label={(item) => item.data.label}
      colors={{
        colors: [
          theme.color.blue100,
          theme.color.blue200,
          theme.color.blue300,
          theme.color.blue400,
          theme.color.blue500,
        ],
        type: 'quantize',
      }}
      labelTextColor={getLabelColor}
      margin={{ top: 10, right: 10, bottom: 10, left: 100 }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          translateX: -100,
          ticks: 5,
          tickFormat: (value) =>
            formatValue(intl, value, {
              ...props.valueFormatOptions,
              notation: 'compact',
            }),
        },
      ]}
      hoverTarget="cell"
      inactiveOpacity={1}
      xInnerPadding={0.1}
      yInnerPadding={0.1}
      tooltip={MapTooltip}
    />
  );
}

function MapTooltip(props: PropsWithChildren<TooltipProps<Item>>) {
  if (!props.cell.value) return null;
  return <StyledTooltip>{props.cell.formattedValue}</StyledTooltip>;
}

function prepareData<T>(props: CommonChartProps<T>): HeatMapSerie<Item, {}>[] {
  const data: { id: string; data: Item[] }[] = prepareEmptyData();
  props.data.forEach((row) => {
    const state = getPropertyAccessor(props.keyField)(
      row,
    ) as keyof typeof statesMatrix;

    const value = props.valueField
      ? (getPropertyAccessor(props.valueField)(row) as number)
      : null;

    const stateLocation = state in statesMatrix ? statesMatrix[state] : null;
    if (!stateLocation) {
      return;
    }
    data[stateLocation.y].data[stateLocation.x] = {
      x: stateLocation.x.toString(),
      y: value,
      label: state,
    };
  });
  return data;
}

function prepareEmptyData() {
  const data: HeatMapSerie<Item, {}>[] = new Array(8)
    .fill(null)
    .map((_, idx) => ({
      id: idx.toString(),
      data: new Array(12).fill(null).map((_, idx2) => ({
        x: idx2.toString(),
        y: null,
        label: '',
      })),
    }));

  forOwn(statesMatrix, (stateLocation, state) => {
    data[stateLocation.y].data[stateLocation.x] = {
      x: stateLocation.x.toString(),
      y: null,
      label: state,
    };
  });
  return data;
}
