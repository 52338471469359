import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  useReducer,
} from 'react';

import { Filter, FilterableField } from '__generated__/globalTypes';

enum FilterContextActionType {
  INIT = 'INIT',
  TOGGLE_FIELD = 'TOGGLE_FIELD',
  ADD_FILTER = 'ADD_FILTER',
  ADD_FILTERS = 'ADD_FILTERS',
  UPDATE_FILTER = 'UPDATE_FILTER',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  SET_FILTERS = 'SET_FILTERS',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_FILTERS = 'REMOVE_FILTERS',
  REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS',
}

export interface FilterContextFilter extends Filter {
  id: string;
}

export interface InitAction {
  type: FilterContextActionType.INIT;
  payload: Array<FilterContextFilter>;
}

export interface ToggleFieldAction {
  type: FilterContextActionType.TOGGLE_FIELD;
  payload: FilterableField;
}

export interface AddFilterAction {
  type: FilterContextActionType.ADD_FILTER;
  payload: FilterContextFilter;
}

export interface UpdateFilterAction {
  type: FilterContextActionType.UPDATE_FILTER;
  payload: FilterContextFilter;
}

export interface UpdateFiltersAction {
  type: FilterContextActionType.UPDATE_FILTERS;
  payload: Array<FilterContextFilter>;
}

export interface AddFiltersAction {
  type: FilterContextActionType.ADD_FILTERS;
  payload: Array<FilterContextFilter>;
}

export interface SetFiltersAction {
  type: typeof FilterContextActionType.SET_FILTERS;
  payload: Array<FilterContextFilter>;
}

export interface RemoveFilterAction {
  type: typeof FilterContextActionType.REMOVE_FILTER;
  payload: string;
}

export interface RemoveFiltersAction {
  type: typeof FilterContextActionType.REMOVE_FILTERS;
  payload: Array<string>;
}

export interface RemoveAllFiltersAction {
  type: typeof FilterContextActionType.REMOVE_ALL_FILTERS;
}

export type FilterActionTypes =
  | InitAction
  | ToggleFieldAction
  | AddFilterAction
  | AddFiltersAction
  | UpdateFilterAction
  | UpdateFiltersAction
  | SetFiltersAction
  | RemoveFilterAction
  | RemoveFiltersAction
  | RemoveAllFiltersAction;

type ContextProps = {
  state: FilterState;
  dispatch: Dispatch<FilterActionTypes>;
};

type FilterState = {
  filters: Array<FilterContextFilter>;
  init: Array<FilterContextFilter>;
  selectedFieldNames: Array<FilterableField>;
};

const init = (filters?: Array<FilterContextFilter>): FilterState => ({
  filters: filters ?? [],
  init: filters ?? [],
  selectedFieldNames: Array.from(
    new Set(filters?.map((filter) => filter.field_name)),
  ),
});

const FilterContext = createContext<ContextProps>({
  state: init(),
  dispatch: () => undefined,
});

interface FilterContextProps {
  filters?: Array<FilterContextFilter>;
}

function reducer(state: FilterState, action: FilterActionTypes): FilterState {
  if (process.env.NODE_ENV === 'development') {
    console.log(action);
    console.log(state);
  }
  switch (action.type) {
    case FilterContextActionType.INIT:
      return {
        ...state,
        filters: state.init,
      };
    case FilterContextActionType.TOGGLE_FIELD:
      return {
        ...state,
        selectedFieldNames: state.selectedFieldNames.includes(action.payload)
          ? state.selectedFieldNames.filter(
              (fieldName) => fieldName !== action.payload,
            )
          : [...state.selectedFieldNames, action.payload],
      };
    case FilterContextActionType.ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, action.payload],
      };
    case FilterContextActionType.ADD_FILTERS:
      return {
        ...state,
        filters: [...state.filters, ...action.payload],
      };
    case FilterContextActionType.UPDATE_FILTER: {
      const filterIndex = state.filters.findIndex(
        (filter) => filter.id === action.payload.id,
      );
      if (filterIndex === -1) {
        return { ...state, filters: [...state.filters, action.payload] };
      } else {
        const newFilters = state.filters;
        newFilters.splice(filterIndex, 1, action.payload);
        return {
          ...state,
          filters: newFilters,
        };
      }
    }
    case FilterContextActionType.UPDATE_FILTERS: {
      const updatedFilterIds = action.payload.map((filter) => filter.id);
      const existingFilterIds = state.filters.map((filter) => filter.id);
      const newFilters = state.filters.map((filter) => {
        const filterIdIndex = updatedFilterIds.indexOf(filter.id);
        if (filterIdIndex !== -1) {
          const updatedFilter = action.payload[filterIdIndex];
          return updatedFilter;
        } else {
          return filter;
        }
      });
      const remainingFilters = action.payload.filter(
        (filter) => !existingFilterIds.includes(filter.id),
      );
      return {
        ...state,
        filters: [...newFilters, ...remainingFilters],
      };
    }
    case FilterContextActionType.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case FilterContextActionType.REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter((filter) => filter.id !== action.payload),
      };
    case FilterContextActionType.REMOVE_FILTERS:
      return {
        ...state,
        filters: state.filters.filter(
          (filter) => !action.payload.includes(filter.id),
        ),
      };
    case FilterContextActionType.REMOVE_ALL_FILTERS:
      return {
        ...state,
        filters: [],
      };
    default:
      return state;
  }
}

const FilterContextProvider = (
  props: PropsWithChildren<FilterContextProps>,
): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer<FilterState, FilterActionTypes>>(
    reducer,
    init(props.filters),
  );

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};

function isValid(a: Filter): boolean {
  if (a.field_name && a.operator && (a.operand || a.operandList)) {
    return true;
  }
  return false;
}

const toFilter = (filter: Filter): Filter => ({
  field_name: filter.field_name,
  operator: filter.operator,
  operand: filter.operand,
  operandList: filter.operandList,
});

export {
  FilterContextActionType,
  FilterContext,
  FilterContextProvider,
  toFilter,
  isValid,
};
