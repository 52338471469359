import { FC } from 'react';

const InfoBadge: FC<{ label: string }> = ({ label }) => {
  return (
    <div className="h-[18px] rounded-sm bg-info-subtle px-1 py-[2px] font-pt-sans-narrow text-xs font-bold leading-3 text-info-default">
      {label}
    </div>
  );
};

export { InfoBadge };
