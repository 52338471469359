import * as navigation from 'app-level/navigation';
import { FIELD_CONFIG } from 'configs/columns';
import { createQueryFilters } from 'context/DataContext';
import { filtersToQueryFilters } from 'features/common-elements/filters/filterValuesParsing';
import { GetDealLoans_deal_performance_summary } from 'features/deals/dealCards/gql/__generated__/GetDealLoans';
import { PortfolioSummaryMetrics } from 'features/drilldown/cashflows/gql/cashflowDataFragments';
import { useUserSettings } from 'features/pages/portfolio/Dashboard/PoolManager/utils/useUserSettings';
import { ASSET_CLASS_IDS } from 'models/AssetClass';

import { gql, useQuery } from '@apollo/client';

import { GetDealDiligence_deal_diligence_performance_summary } from 'query/__generated__/GetDealDiligence';

import {
  GetPortfolioSummary,
  GetPortfolioSummaryVariables,
  GetPortfolioSummary_user_company_performanceData,
} from './__generated__/GetPortfolioSummary';
import { KPI } from './Dashboard/PoolManager/types';
import { MetricsHeader } from './Portfolio/MetricsHeader';
import { PortfolioFilterConfig } from './Portfolio/portfolioFilters.config';
import { usePortfolioFilters } from './Portfolio/usePortfolioFilters';
import { SelectedPoolData, usePortfolioContext } from './PortfolioContext';

export const GET_PORTFOLIO_SUMMARY_QUERY = gql`
  query GetPortfolioSummary(
    $hasAssetClass: Boolean!
    $assetClass: String
    $filters: [Filter!]
  ) {
    user {
      id
      company {
        id
        performanceData: performance_summary(filters: []) {
          ...PortfolioSummaryMetrics
        }
        assetClassPerformanceData: performance_summary(
          filters: [
            { field_name: asset_class, operator: IS, operand: $assetClass }
          ]
        ) @include(if: $hasAssetClass) {
          ...PortfolioSummaryMetrics
        }
        filteredPerformanceData: performance_summary(filters: $filters) {
          ...PortfolioSummaryMetrics
        }
      }
    }
  }
  ${PortfolioSummaryMetrics.performanceSummaryMetrics}
`;

export type KPIConfig = {
  description: string;
  synonyms?: string[];
  selector: keyof GetPortfolioSummary_user_company_performanceData;
  display: (value: number) => string;
};

export const kpiConfigs: KPIConfig[] = [
  {
    description: 'UPB',
    synonyms: ['Unpaid Principal Balance'],
    selector: 'unpaidBalance',
    display: FIELD_CONFIG.original_balance_cents.display,
  },
  {
    description: '#',
    selector: 'loanCount',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'WALA',
    synonyms: ['Weighted Average Loan Age'],
    selector: 'averageAge',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'WAM',
    synonyms: ['Weighted Average Maturity'],
    selector: 'averageMaturity',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'GWAC',
    selector: 'netCoupon',
    display: FIELD_CONFIG.interest_rate.display,
  },
  {
    description: 'FICO',
    selector: 'fico',
    display: (value: number) => (value ?? 0).toLocaleString(),
  },
  {
    description: 'DTI',
    selector: 'dti',
    display: FIELD_CONFIG.dti.display,
  },
  {
    description: 'LTV',
    selector: 'ltv',
    display: FIELD_CONFIG.ltv.display,
  },
];

export const generateMetrics = (
  performanceData:
    | GetPortfolioSummary_user_company_performanceData
    | GetDealLoans_deal_performance_summary
    | GetDealDiligence_deal_diligence_performance_summary
    | SelectedPoolData
    | undefined,
): KPI[] => {
  return kpiConfigs.map((kpi) => {
    const value = performanceData?.[kpi.selector] ?? 0;
    return {
      ...kpi,
      value: kpi.display(value),
    };
  });
};

const PortfolioSummary = () => {
  const { selectedPoolsData, totalFilteredPoolData } = usePortfolioContext();
  const assetClass = navigation.usePortfolioAssetClass();
  const gqlAssetClass = assetClass && ASSET_CLASS_IDS[assetClass];
  const [portfolioFilters] = navigation.usePortfolioFilters();
  const filterProps = usePortfolioFilters();
  const queryFilters = filterProps.filtersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        portfolioFilters,
        filterProps.filtersConfig,
      )
    : [];
  const hasAssetClass = Boolean(gqlAssetClass);

  const generalUserSettings = useUserSettings(
    `pool-manager-performanceData-${gqlAssetClass}`,
  );
  const poolBySelectionSettings = useUserSettings(
    `pool-manager-total-by-selection-${gqlAssetClass}`,
  );
  const assetClassUserSettings = useUserSettings(
    `pool-manager-assetClassPerformanceData-${gqlAssetClass}`,
  );
  const filteredUserSettings = useUserSettings(
    `pool-manager-filteredPerformanceData-${gqlAssetClass}`,
  );

  const { data } = useQuery<GetPortfolioSummary, GetPortfolioSummaryVariables>(
    GET_PORTFOLIO_SUMMARY_QUERY,
    {
      variables: {
        hasAssetClass,
        assetClass: gqlAssetClass,
        filters: createQueryFilters(gqlAssetClass, queryFilters),
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { company } = data?.user || {};

  return (
    <>
      <MetricsHeader
        title="Total in Portfolio"
        metrics={generateMetrics(company?.performanceData)}
        addUserSetting={generalUserSettings.addUserSetting}
        savedPoolSettings={generalUserSettings.savedPoolSettings}
        userPoolSettings={generalUserSettings.userPoolSettings}
      />

      {hasAssetClass && (
        <MetricsHeader
          title="Total in Asset Class"
          metrics={generateMetrics(company?.assetClassPerformanceData)}
          addUserSetting={assetClassUserSettings.addUserSetting}
          savedPoolSettings={assetClassUserSettings.savedPoolSettings}
          userPoolSettings={assetClassUserSettings.userPoolSettings}
        />
      )}

      {!!selectedPoolsData.length && (
        <MetricsHeader
          title="Total in Pool"
          metrics={generateMetrics(selectedPoolsData[0])}
          addUserSetting={poolBySelectionSettings.addUserSetting}
          savedPoolSettings={poolBySelectionSettings.savedPoolSettings}
          userPoolSettings={poolBySelectionSettings.userPoolSettings}
        />
      )}

      {queryFilters.length > 0 && !selectedPoolsData.length && (
        <MetricsHeader
          title="Total Filtered"
          metrics={generateMetrics(company?.filteredPerformanceData)}
          addUserSetting={filteredUserSettings.addUserSetting}
          savedPoolSettings={filteredUserSettings.savedPoolSettings}
          userPoolSettings={filteredUserSettings.userPoolSettings}
        />
      )}

      {queryFilters.length > 0 &&
        totalFilteredPoolData &&
        selectedPoolsData.length && (
          <MetricsHeader
            title="Total Filtered in Pool"
            metrics={generateMetrics(totalFilteredPoolData)}
            addUserSetting={filteredUserSettings.addUserSetting}
            savedPoolSettings={filteredUserSettings.savedPoolSettings}
            userPoolSettings={filteredUserSettings.userPoolSettings}
          />
        )}
    </>
  );
};

export default PortfolioSummary;
