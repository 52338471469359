import { useState, useCallback, useEffect } from 'react';

import { BaseButton } from 'common-ui';
import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';

import { FileType } from '__generated__/globalTypes';

import { InProgressFile } from '../DocumentDetails';

interface PassedProps {
  parentId: string;
  fileType: FileType;
  setIsOpen?: (isOpen: boolean) => void;
  allowedFileTypes?: string[];
  disabled?: boolean;
  showButtons?: boolean;
  width?: string;
  height?: string;
  onFileSuccess?: (file?: File) => void;
  timeToHideProgress?: number;
  isContract?: boolean;
  isCollateral?: boolean;
  vertical?: boolean;
  allowedTypesMessage?: string;
  onProgressCallback?: (progress: number, file?: File) => void;
  onUploadCallback?: (isUploading: boolean) => void;
  showProgress?: boolean;
  uploadedBy?: string;
}

const FileUpload = ({
  parentId,
  fileType,
  setIsOpen,
  allowedFileTypes = [],
  disabled = false,
  showButtons = true,
  width,
  height,
  onFileSuccess,
  timeToHideProgress,
  isContract = false,
  isCollateral = false,
  vertical = false,
  allowedTypesMessage,
  onProgressCallback,
  onUploadCallback,
  showProgress = true,
  uploadedBy,
}: PassedProps) => {
  const [uploadsInProgress, setUploadsInProgress] = useState<InProgressFile[]>(
    [],
  );
  const [uploaded, setUploaded] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (uploaded && timeToHideProgress) {
      timer = setTimeout(() => {
        setUploaded(false);

        if (onUploadCallback) {
          onUploadCallback(false);
        }

        setUploadsInProgress([]);
      }, timeToHideProgress * 1000);
    }
    return () => clearTimeout(timer);
  }, [uploaded, timeToHideProgress, onUploadCallback]);

  const onSuccess = useCallback(
    (file: File, _versionId?: string, uuid?: string) => {
      setUploadsInProgress((prev) => {
        const existingFile = prev.find(
          (f) => f.name === file.name && f.lastModified === file.lastModified,
        );
        if (existingFile) {
          existingFile.id = uuid;
        }
        return [...prev];
      });

      setUploaded(true);

      if (onUploadCallback) {
        onUploadCallback(true);
      }

      if (onFileSuccess) {
        onFileSuccess(file);
      }
    },
    [setUploadsInProgress, onFileSuccess, onUploadCallback],
  );

  const onProgress = useCallback(
    (progress: number, file?: File) => {
      if (!file) {
        return;
      }

      setUploaded(false);

      if (onUploadCallback) {
        onUploadCallback(false);
      }

      setUploadsInProgress((prev) => {
        const existingFile = prev.find(
          (f) => f.name === file.name && f.lastModified === file.lastModified,
        );
        if (existingFile) {
          existingFile.progress = {
            percentage: progress,
          };
        } else {
          prev.push({
            name: file.name,
            lastModified: file.lastModified,
            progress: {
              percentage: progress,
            },
          });
        }
        return [...prev];
      });

      if (onProgressCallback) {
        onProgressCallback(progress, file);
      }
    },
    [setUploadsInProgress, onProgressCallback, onUploadCallback],
  );

  const { upload } = useS3Uploader({
    onSuccess,
    onProgress,
    fileType,
    parentId: parentId,
    isContract,
    isCollateral,
    uploadedBy,
  });

  const onFileDrop = async (file: File) => {
    if (disabled) return false;

    if (allowedFileTypes.length > 0 && !allowedFileTypes.includes(file.type)) {
      return;
    }
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleClose = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(false);
    }
  }, [setIsOpen]);

  return (
    <div
      className={`file_upload transition-opacity ${disabled ? 'opacity-50' : 'opacity-100'}`}
    >
      <FileDropZone
        onFileDrop={onFileDrop}
        allowedFileTypes={allowedFileTypes}
        width={width}
        height={height}
        disabled={disabled}
        vertical={vertical}
        allowedTypesMessage={allowedTypesMessage}
      />
      {showProgress && uploadsInProgress.length > 0 && (
        <div className="file_upload__details_container">
          {uploadsInProgress.map((file: InProgressFile, index) => (
            <div key={file.id || index} className="file_upload__progress_bar">
              <span className="file_upload__progress_bar_file_name">
                {file.name}
              </span>
              {file.progress != null && file.progress.percentage > 0 && (
                <ProgressIndicator percent={file.progress.percentage} />
              )}
            </div>
          ))}
        </div>
      )}
      {uploaded && (
        <p className="my-2 flex justify-center text-xs text-gray-100">
          Files uploaded successfully
        </p>
      )}
      {showButtons && (
        <div className="mt-4 flex justify-center gap-4">
          {uploaded && (
            <BaseButton
              label="Done"
              size="medium"
              type="primary"
              onClick={handleClose}
            >
              Done
            </BaseButton>
          )}
          <BaseButton
            label="Close"
            size="medium"
            type="secondary"
            onClick={handleClose}
          >
            Close
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
