import { styled } from 'style/ORSNNTheme';
import { css } from 'styled-components';

type SectionProps = {
  dividerBottom?: boolean;
  dividerRight?: boolean;
};

export const Section = styled.div`
  background-color: ${(props) => props.theme.color.sectionBackground};
  ${(props: SectionProps) =>
    props.dividerBottom &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.color.borderDefault};
    `}
  ${(props: SectionProps) =>
    props.dividerRight &&
    css`
      border-right: 2px solid ${(props) => props.theme.color.borderDefault};
    `}
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  overflow: auto;
`;
