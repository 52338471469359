import { useEffect, useState } from 'react';

import { BaseButton, SvgIcon } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { ContentDiv } from 'common-ui/Dialog.styles';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';

import { NewsArticleField, NewsSource } from '__generated__/globalTypes';

import {
  GetNewsArticles,
  GetNewsArticlesVariables,
} from 'query/__generated__/GetNewsArticles';
import { GetNewsArticles_newsArticles as NewsArticle } from 'query/__generated__/GetNewsArticles';
import { GET_NEWS_ARTICLES } from 'query/getNewsArticles';

import ArticleDialog from './ArticleDialog';

// Format date as MM.DD.YYYY as shown in Figma: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=8525-43616&mode=design&t=NQkWzefFekrWhX2g-4
const formatDate = (dateAsString: string) => {
  const formatted = new Date(dateAsString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formatted.replace(/\//g, '.');
};

export const Byline: React.FC<{
  source: NewsSource;
  publication_date: string;
  url: string;
}> = ({ source, publication_date, url }) => (
  <div className="flex justify-start gap-3">
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="no-underline hover:scale-105 active:scale-100"
      title="Read the full story"
    >
      <span className="py-1/2 rounded-sm bg-white px-1 text-xs tracking-tighter text-info-default">
        {source.replaceAll('_', ' ')}
      </span>
    </a>
    <span className="flex items-center text-xs">
      {formatDate(publication_date)}
    </span>
  </div>
);

const NewsListItem: React.FC<{
  item: NewsArticle;
  onReadStory: () => void;
}> = ({ item, onReadStory }) => {
  return (
    <article className="w-auto flex-col rounded-lg border-b border-gray-800 bg-background-canvas p-3 text-sm">
      <Byline
        source={item.news_source}
        publication_date={item.publication_date}
        url={item.url}
      />
      <div className="flex w-auto flex-row justify-between">{item.subject}</div>
      <div className="flex justify-end">
        <button
          onClick={onReadStory}
          data-testid="read-story-button"
          className="flex w-32 flex-row flex-nowrap items-center gap-1.5 text-right text-pink-400 hover:scale-105 active:scale-100"
        >
          READ STORY
          <SvgIcon name="arrow-circle-right" />
        </button>
      </div>
    </article>
  );
};

const NewsListItemSkeleton: React.FC = () => {
  return (
    <div className="w-auto flex-col gap-1 rounded-lg border border-gray-800 bg-background-canvas p-3 text-sm">
      <div className="flex justify-start gap-3">
        <span className="py-1/2 h-4 w-16 animate-pulse rounded-sm bg-slate-400 px-1 text-xs tracking-tighter text-info-default" />
        <span className="py-1/2 h-4 w-20 animate-pulse rounded-sm bg-slate-700 px-1 text-xs tracking-tighter text-info-default" />
      </div>
      <div className="flex w-auto flex-col justify-between gap-1 pt-1">
        <div className="h-4 w-full animate-pulse rounded-sm bg-slate-700" />
        <div className="h-4 w-1/2 animate-pulse rounded-sm bg-slate-700" />
      </div>
      <div className="flex justify-end">
        <div className="flex w-32 flex-row flex-nowrap items-center gap-1.5 text-right text-pink-400 hover:scale-105 active:scale-100">
          <span className="h-4 w-32 animate-pulse rounded-sm bg-pink-400 brightness-50" />
          <span className="h-4 w-10 animate-pulse rounded-sm bg-pink-400 brightness-50" />
        </div>
      </div>
    </div>
  );
};

const PAGE_SIZE = 12;

const LatestNews: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [allArticles, setAllArticles] = useState<NewsArticle[]>([]);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const dialog = useDialog();

  const { data, error, loading, refetch } = useQuery<
    GetNewsArticles,
    GetNewsArticlesVariables
  >(GET_NEWS_ARTICLES, {
    variables: {
      sort: {
        sort_field: NewsArticleField.publication_date_seconds,
      },
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const loadMoreArticles = () => {
    setOffset((offset) => offset + PAGE_SIZE);
  };

  useEffect(() => {
    if (data) {
      if (data.newsArticles.length < PAGE_SIZE) {
        setHasMore(false);
      }
      setAllArticles((prevArticles) => [...prevArticles, ...data.newsArticles]);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    });
  }, [offset, refetch]);

  const handleReadStory = (index: number) => {
    setCurrentArticleIndex(index);
    dialog.openDialog();
  };

  const currentArticle = allArticles[currentArticleIndex];

  const handleNavigateArticle = (direction: 'next' | 'previous') => {
    if (direction === 'next' && currentArticleIndex < allArticles.length - 1) {
      setCurrentArticleIndex((prevIndex) => prevIndex + 1);
    }

    if (direction === 'previous' && currentArticleIndex > 0) {
      setCurrentArticleIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <h2 className="font-heebo text-lg font-medium text-white">Market News</h2>
      <div
        className="max-h-[100%] overflow-y-auto pb-[40px] pt-5"
        data-testid="market-news-articles"
      >
        <div className="flex flex-col overflow-auto">
          {allArticles.length === 0 ? (
            <>
              <NewsListItemSkeleton />
              <NewsListItemSkeleton />
              <NewsListItemSkeleton />
            </>
          ) : (
            allArticles.map((item, index) => {
              return (
                <NewsListItem
                  key={index}
                  item={item}
                  onReadStory={() => handleReadStory(index)}
                />
              );
            })
          )}
          {hasMore ? (
            <BaseButton
              type="secondary"
              size="small"
              label="View More"
              onClick={loadMoreArticles}
              data-testid="view-more-articles"
            >
              See More Articles
            </BaseButton>
          ) : (
            <div style={{ margin: 10 }}>No more articles available</div>
          )}
          {loading && <div>Loading news articles...</div>}
          {error && <div>Error loading news articles: {error.message}.</div>}
        </div>

        <Dialog dialog={dialog} contentComponent={ArticleDialogContentDiv}>
          <ArticleDialog
            closeDialog={dialog.closeDialog}
            currentArticle={currentArticle}
            currentArticleIndex={currentArticleIndex}
            allArticles={allArticles}
            hasMore={hasMore}
            loadMoreArticles={loadMoreArticles}
            handleNavigateArticle={handleNavigateArticle}
          />
        </Dialog>
      </div>
    </>
  );
};

export { LatestNews };

const ArticleDialogContentDiv = styled(ContentDiv)`
  background-color: rgba(19, 19, 22, 1);
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  border-radius: 8px;
  max-width: 95vw;
  max-height: 95vh;
  width: 1000px;
  height: 800px;
  position: relative;
`;
