import { useState } from 'react';

import { BaseButton } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { DialogActions } from 'common-ui/Dialog.styles';
import { GetTimelineVariables } from 'features/deals/DealStages/__generated__/GetTimeline';
import { GET_DEAL_TIMELINE } from 'features/deals/DealStages/fragments';
import DealButton from 'ui-kit/DealButton/DealButton';

import { useMutation } from '@apollo/client';

import { SubmitBidInput } from '__generated__/globalTypes';

import {
  SubmitBid,
  SubmitBidVariables,
} from 'mutation/__generated__/SubmitBid';
import { SUBMIT_BID } from 'mutation/submitBid';

import { BidConfirmationDialog } from './BidConfirmationDialog';
import { BidButtonsGroup } from './BiddingAndPricingSection.styles';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { BidSucceededDialog } from './BidSucceededDialog';

export const BidActionButtons = () => {
  const { selectedCarve, dealId, bidDetails } = useBiddingPricingContext();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const getDealTimelineVariables: GetTimelineVariables = {
    id: dealId,
  };

  const [submitBid] = useMutation<SubmitBid, SubmitBidVariables>(SUBMIT_BID, {
    refetchQueries: [
      {
        query: GET_DEAL_TIMELINE,
        variables: getDealTimelineVariables,
      },
    ],
  });

  const bidConfirmationDialog = useDialog();
  const bidSucceededDialog = useDialog();
  const errorDialog = useDialog();

  if (!dealId) {
    return <p>Invalid Deal ID</p>;
  }
  const onSubmitBid = () => {
    bidConfirmationDialog.openDialog();
  };

  const onBidConfirmed = () => {
    bidConfirmationDialog.closeDialog();
    const bidBasisPoints = selectedCarve?.stipulations?.bid_basis_points;

    if (
      bidDetails &&
      selectedCarve &&
      selectedCarve.stipulations &&
      bidBasisPoints !== null &&
      bidBasisPoints !== undefined &&
      selectedCarve.id
    ) {
      const input: SubmitBidInput = {
        deal_id: dealId,
        bid_basis_points: bidBasisPoints,
        carve_id: selectedCarve.id,
        document_ids: bidDetails.stipulations.document_ids,
      };
      console.log('Sending bid to server', input);
      submitBid({
        variables: {
          input,
        },
      })
        .then(() => {
          bidSucceededDialog.openDialog();
        })
        .catch((err) => {
          setErrorMessage(err.message);
          errorDialog.openDialog();
        });
    }
  };

  const submitEnabled =
    bidDetails &&
    bidDetails.carve.stipulations?.bid_basis_points != null &&
    selectedCarve?.stipulations != null &&
    selectedCarve?.id != null;

  return (
    <>
      <BidButtonsGroup>
        <DealButton
          disabled={!submitEnabled}
          onClick={onSubmitBid}
          variant="success"
          label="submit_bid"
        >
          Submit Bid
        </DealButton>
      </BidButtonsGroup>

      {bidDetails && selectedCarve && (
        <Dialog dialog={bidConfirmationDialog}>
          <BidConfirmationDialog
            bidDetails={bidDetails}
            selectedCarve={selectedCarve}
            onCancel={() => bidConfirmationDialog.closeDialog()}
            onConfirm={() => onBidConfirmed()}
          />
        </Dialog>
      )}

      <Dialog dialog={bidSucceededDialog}>
        <BidSucceededDialog
          onCancel={() => bidSucceededDialog.closeDialog()}
          onConfirm={() => bidSucceededDialog.closeDialog()}
        />
      </Dialog>

      <Dialog dialog={errorDialog}>
        <div>ERROR: {errorMessage}</div>
        <DialogActions>
          <BaseButton
            size="medium"
            label="cancel"
            onClick={() => errorDialog.closeDialog()}
          >
            OK
          </BaseButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
