export const cardsConfig = {
  counterparty: {
    name: 'Counterparty Details',
    needAccess: false,
  },
  loans: {
    name: 'Loan Level Data',
    needAccess: true,
  },
  documents: {
    name: 'Deal Documents',
    needAccess: true,
  },
  strats: {
    name: 'Strats',
  },
  carve: {
    name: 'Trade Carve Manager',
    needAccess: true,
  },
  bidding: {
    name: 'Bidding and Pricing',
  },
  diligence: {
    name: 'Diligence',
  },
  settlement: {
    name: 'Settlement',
  },
  complition: {
    name: 'Deal Completion',
  },
};

export type CardsKey = keyof typeof cardsConfig;

export const cardsOrder: CardsKey[] = [
  'counterparty',
  'loans',
  'documents',
  'strats',
  'carve',
  'bidding',
  'diligence',
  'settlement',
  'complition',
];
