import { useState } from 'react';

import { uploadConfig } from 'app-level/config/uploadConfig';
import { BaseButton, Modal } from 'common-ui';
import { TemplateDocument } from 'features/pages/portfolio/DocumentLibrary/__generated__/TemplateDocument';
import DocumentTemplates from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';

import { useLazyQuery } from '@apollo/client';

import { FileType } from '__generated__/globalTypes';

import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { GET_DEAL_DOCUMENTS } from 'query/deal';

import { DealDocumentsCard_DealDocumentsCard } from './__generated__/DealDocumentsCard';

type Props = { dealId: string } & DealDocumentsCard_DealDocumentsCard;

const DealDocumentsCardBody = (props: Props): JSX.Element => {
  const variables = { id: props.dealId };
  const [isDealModalOpen, setIsDealModalOpen] = useState<boolean>(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const [_getDealDocuments, { data }] = useLazyQuery<
    GetDealDocuments,
    GetDealDocumentsVariables
  >(GET_DEAL_DOCUMENTS, {
    variables,
  });

  const getDealDocumentsVariables: GetDealDocumentsVariables = {
    id: props.dealId,
  };
  const refetchQueries = [
    {
      query: GET_DEAL_DOCUMENTS,
      variables: getDealDocumentsVariables,
    },
  ];

  const documents = data?.deal?.documents ?? props.documents;

  const dealDocuments = documents.filter(
    (doc) => doc.type !== 'TERMS_DOCUMENT',
  );
  const termsDocuments = documents.filter(
    (doc) => doc.type === 'TERMS_DOCUMENT',
  );

  return (
    <>
      <div className="flex justify-between gap-5">
        <div className="flex-1">
          <h3 className="mb-4 text-lg text-white">Deal Documents</h3>
          <DocumentTemplates
            docs={(dealDocuments as TemplateDocument[]) || []}
            label="Uploaded deal documents"
            showDelete={props.can_upload}
            dealId={props.dealId}
            refetchQueries={refetchQueries}
            showInRow={true}
          />
          {props.can_upload && (
            <div className="mt-4">
              <BaseButton
                icon="pill-plus"
                iconFill="#BDBDBD"
                isIconRight
                label="Upload Deal Document"
                size="medium"
                type="tertiary"
                onClick={() => setIsDealModalOpen(true)}
              >
                Upload deal documents
              </BaseButton>
            </div>
          )}
        </div>

        <div className="flex-1">
          <h3 className="mb-4 text-lg text-white">Terms Documents</h3>
          <DocumentTemplates
            docs={(termsDocuments as TemplateDocument[]) || []}
            label="Uploaded terms documents"
            showDelete={props.can_upload}
            dealId={props.dealId}
            refetchQueries={refetchQueries}
            showInRow={true}
          />
          {props.can_upload && (
            <div className="mt-4">
              <BaseButton
                icon="pill-plus"
                iconFill="#BDBDBD"
                isIconRight
                label="Upload Terms Document"
                size="medium"
                type="tertiary"
                onClick={() => setIsTermsModalOpen(true)}
              >
                Upload an LOI
              </BaseButton>
            </div>
          )}
        </div>
      </div>

      {isDealModalOpen && (
        <Modal
          headerText="Add A Deal Document"
          subText=""
          caption=""
          setIsOpen={setIsDealModalOpen}
          type="document-library"
        >
          <FileUpload
            parentId={props.dealId}
            fileType={FileType.DEAL_FILE}
            setIsOpen={setIsDealModalOpen}
            allowedFileTypes={uploadConfig.dealDocuments.allowedFileTypes || []}
          />
        </Modal>
      )}

      {isTermsModalOpen && (
        <Modal
          headerText="Add A Terms Document"
          subText=""
          caption=""
          setIsOpen={setIsTermsModalOpen}
          type="document-library"
        >
          <FileUpload
            parentId={props.dealId}
            fileType={FileType.TERMS_DOCUMENT}
            setIsOpen={setIsTermsModalOpen}
            allowedFileTypes={['application/pdf', 'image/jpeg']}
          />
        </Modal>
      )}
    </>
  );
};

export default DealDocumentsCardBody;
export { default as DealDocumentsCardFragments } from './fragments';
