import React, { useState, useEffect } from 'react';

import {
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

export function ModalNotStyled(props: {
  trigger?: React.ReactElement;
  handleClose?: () => void;
  initialOpen?: boolean;
  children: (args: { closeModal: () => void }) => React.ReactElement;
  className?: string;
}) {
  const [open, setOpen] = useState(props.initialOpen || false);
  const { context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { handleClose } = props;

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'dialog' });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const Trigger =
    props?.trigger &&
    React.cloneElement(
      props.trigger,
      getReferenceProps({
        ref: refs.setReference,
      }),
    );

  useEffect(() => {
    if (!open && handleClose) {
      handleClose();
    }
  }, [open, handleClose]);

  return (
    <>
      {Trigger}
      {open ? (
        <FloatingPortal>
          <div
            className="fixed inset-0 z-10 bg-black bg-opacity-50"
            onClick={() => setOpen(false)}
          />
          <div
            ref={refs.setFloating}
            className={`fixed left-1/2 top-1/2 z-10 flex w-full -translate-x-1/2 -translate-y-1/2 transform justify-center ${props.className}`}
            {...getFloatingProps()}
          >
            {props.children({ closeModal: () => setOpen(false) })}
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
