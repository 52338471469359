import { styled } from 'style/ORSNNTheme';

export const PrimitiveCard = styled.div`
  border: 1px solid ${(props) => props.theme.color.accentDisabled};
  background: ${(props) => props.theme.color.bgSurface};
  border-radius: 8px;
  padding: 12px 14px;

  :hover {
    border: 1px solid ${(props) => props.theme.color.accentMuted};
  }
`;
