import { useEffect, useMemo, useState } from 'react';

import * as navigation from 'app-level/navigation';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { filtersToQueryFilters, PillFilters } from 'features/common-elements';
import { CashFlows } from 'features/drilldown/cashflows/CashFlows';
import { getUsePortfolioCashflow } from 'features/drilldown/cashflows/gql/usePortfolioCashflows_gql';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';

import { GetListings_user_company_listings as Listing } from 'query/__generated__/GetListings';

import { useGetListingsList } from './getListingsList_gql';
import { getUseSelectedPoolCashflow } from './getSelectedPoolCashflow_gql';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';
import { usePortfolioFilters } from '../Portfolio/usePortfolioFilters';
import {
  transformSelectedPoolsData,
  usePortfolioContext,
} from '../PortfolioContext';

const CashFlowModeling = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const { setSelectedPoolsData } = usePortfolioContext();
  const filterProps = usePortfolioFilters();
  const [selectedPool, setSelectedPool] = useState<{
    label: string;
    value: string;
    poolData: Listing;
  } | null>(null);
  const { data: listingsList } = useGetListingsList();

  const { poolFromUrl, setPool } = usePortfolioFilters();

  const poolOptions = useMemo(() => {
    const normalizedAssetClass = assetClass?.toLowerCase();

    return listingsList?.user?.company?.listings
      ?.filter(
        (listing) => listing.asset_class.toLowerCase() === normalizedAssetClass,
      )
      ?.sort((a, b) => {
        const nameA = (a.provided_name || a.name).toLowerCase();
        const nameB = (b.provided_name || b.name).toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      ?.map((listing) => ({
        label: listing.provided_name || listing.name,
        value: listing.id,
        poolData: listing,
      }));
  }, [listingsList?.user?.company?.listings, assetClass]);

  const { setCurrentFilters, currentFilters } = filterProps;

  useEffect(() => {
    if (!poolOptions || !poolFromUrl) return;

    const selected = poolOptions.find((pool) => pool.value === poolFromUrl);
    if (selected) {
      setSelectedPool(selected);
    } else {
      setPool(null);
    }
  }, [poolOptions, poolFromUrl, setPool]);

  useEffect(() => {
    if (selectedPool) {
      setSelectedPoolsData(transformSelectedPoolsData([selectedPool.poolData]));
      setCurrentFilters([...currentFilters]);
      setPool(selectedPool.value);
    } else if (poolOptions && poolFromUrl && poolOptions.length > 0) {
      const poolFromUrlOption = poolOptions.find(
        (pool) => pool.value === poolFromUrl,
      );
      if (poolFromUrlOption) {
        setSelectedPool(poolFromUrlOption);
      }
    } else {
      setSelectedPoolsData([]);
    }
  }, [
    selectedPool,
    setSelectedPoolsData,
    setCurrentFilters,
    currentFilters,
    setPool,
    poolFromUrl,
    poolOptions,
  ]);

  if (!assetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const queryFilters = filtersToQueryFilters<PortfolioFilterConfig>(
    filterProps.currentFilters,
    filterProps.filtersConfig,
  );
  const useCashflowSummery = getUsePortfolioCashflow(assetClass, queryFilters);
  const userSettingKey = `cashflowsportfolio${assetClass}`;
  const useSelectedPoolCashflow = getUseSelectedPoolCashflow(
    assetClass,
    queryFilters,
  );

  const handlePoolChange = (
    option: { label: string; value: string; poolData: Listing } | null,
  ) => {
    if (!option) {
      setPool(null);
      setSelectedPool(null);
    } else {
      setSelectedPool(option);
    }
  };

  return (
    <PortfolioLayout
      filterRow={
        <div className="flex w-full flex-col gap-2">
          <PillFilters
            {...filterProps}
            filtersConfig={filterProps.filtersConfig}
          />
          <div className="flex flex-row justify-end">
            <Dropdown
              options={poolOptions || []}
              value={selectedPool}
              isMulti={false}
              className="w-1/6"
              isClearable
              onChange={handlePoolChange}
              placeholder="Select Pool"
            />
          </div>
        </div>
      }
    >
      <CashFlows
        key={assetClass}
        settingKey={userSettingKey}
        useSummaryData={useCashflowSummery}
        selectedPoolId={selectedPool?.value}
        selectedPoolCashflow={useSelectedPoolCashflow}
      />
    </PortfolioLayout>
  );
};

export { CashFlowModeling };
