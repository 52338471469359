import { gql } from '@apollo/client';

const COMPANY_FRAGMENT = gql`
  fragment HeaderCompany on Company {
    __typename
    type
    ... on PrivateCompany {
      id
      name
    }
    ... on UserCompany {
      id
      name
    }
  }
`;

const PoolHeaderFragments = {
  listing: gql`
    fragment PoolHeaderListing on Listing {
      id
      name
      provided_name
      assetClass: asset_class
      description
      createdDate: created_date
      seller: company {
        ...HeaderCompany
      }
      in_progress_deal_id
    }
    ${COMPANY_FRAGMENT}
  `,
  deal: gql`
    fragment PoolHeaderDeal on Deal {
      id
      createdDate: created_date
      buyer {
        ...HeaderCompany
      }
      seller {
        ...HeaderCompany
      }
    }
    ${COMPANY_FRAGMENT}
  `,
};

const CREATE_DEAL_MUTATION = gql`
  mutation CreateDeal($listingId: ID!) {
    createDeal(listing_id: $listingId) {
      id
    }
  }
`;

export { PoolHeaderFragments, CREATE_DEAL_MUTATION };
