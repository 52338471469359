import React from 'react';

import { SliderProps } from 'rc-slider/lib/Slider';
import { Tooltip, ITooltip } from 'react-tooltip';
import { styled } from 'style/ORSNNTheme';

export type TooltipRender = SliderProps['handleRender'];

const StyledTooltip = styled(Tooltip)`
  background-color: transparent;
  color: ${(props) => props.theme.color.brandMainPink};
  border: none;
`;

export function getTooltipRender(
  sliderId: string,
  tipFormatter?: (value: number) => React.ReactNode,
  tipProps?: ITooltip,
  alwaysShowTooltip?: boolean,
) {
  const render: TooltipRender = (node, handleProps) => {
    const formatter = tipFormatter || ((value: number) => value.toString());
    return (
      <>
        {node}
        <StyledTooltip
          {...tipProps}
          anchorSelect={`#${sliderId} .rc-slider-handle-${
            handleProps.index + 1
          }`}
          place="top"
          offset={1}
          isOpen={alwaysShowTooltip || handleProps.dragging}
        >
          {formatter(handleProps.value)}
        </StyledTooltip>
      </>
    );
  };

  return render;
}
