import { gql } from '@apollo/client';

const GET_FILE_POST_URLS_MUTATION = gql`
  mutation GetFilePostUrls(
    $parentId: ID
    $fileType: FileType!
    $fileName: String!
    $contentType: String!
    $assetClass: AssetClass
    $productType: ProductType
  ) {
    getFilePostUrl(
      parentId: $parentId
      fileType: $fileType
      fileName: $fileName
      contentType: $contentType
      assetClass: $assetClass
      productType: $productType
    )
  }
`;

export default GET_FILE_POST_URLS_MUTATION;
