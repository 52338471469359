import {
  getMarketPlaceRoute,
  useGet2ndLevelPageUrl,
} from 'app-level/navigation';
import { ExecutedOverview } from 'features/deals/Executed';
import ListingOverview from 'features/listings/ListingOverview';
import { Navigate, Route, Routes } from 'react-router-dom';

import ActiveTrades from './ActiveTrades';
import { DealRoom } from './DealRoom';
import MarketHome from './MarketHome';

const Marketplace = () => {
  const getUrl = useGet2ndLevelPageUrl();

  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to={getUrl('marketplace', 'market-home')} replace />}
      />
      <Route
        path={getMarketPlaceRoute('market-home')}
        element={<MarketHome />}
      />
      <Route
        path={getMarketPlaceRoute('active_trades')}
        element={<ActiveTrades />}
      />
      <Route
        path={getMarketPlaceRoute('executed_trades')}
        element={<ExecutedOverview />}
      />
      <Route path="listing/:listing_id" element={<ListingOverview />} />
      <Route path="deal/:deal_id" element={<DealRoom />} />
    </Routes>
  );
};

export default Marketplace;
