const NETWORK_ERROR =
  'There was a problem loading the page, please try refreshing or contacting support@orsnn.com';

const INFO_BID_SUMMARY_LIST = [
  {
    key: 'UPB',
    label: 'Unpaid Balance',
    infoText: 'Unpaid Balance',
    explanation:
      'UPB refers to the remaining principal balance of all the loans in a pool. It represents the total amount that borrowers within the pool still owe.',
  },
  {
    key: 'GWAC',
    label: 'Gross WAC',
    infoText: 'Gross WAC',
    explanation:
      'GWAC is the weighted average interest rate of the loans in a pool before adjustments. It provides a snapshot of the average interest income that the loan pool generates.',
  },
  {
    key: 'Price',
    label: 'Price',
    infoText: 'Price',
    explanation:
      'Price is the amount at which a pool of loans is bought or sold. It is quoted as a percentage of the pool`s face value.',
  },
  {
    key: 'NWAC',
    label: 'Gross WAC - servicing rate',
    infoText: 'Gross WAC - servicing rate',
    explanation:
      'NWAC is the GWAC of the loan pool adjusted for servicing fees and other costs associated with managing the pool.',
  },
  {
    key: 'Net Yield @ Price',
    label: 'NWAC / Price',
    infoText: 'NWAC / Price',
    explanation:
      'Net Yield at Price is a measure of the return on investment based on the NWAC and the price paid for the loan pool. It is calculated by dividing the NWAC by the price. This metric helps investors understand the yield they can expect to earn relative to the price they paid for the loan pool.',
  },
  {
    key: 'Yield',
    label: 'IRR matching the Price',
    infoText: 'IRR matching the Price',
    explanation:
      'Yield refers to the Internal Rate of Return (IRR) that matches the price of the loan pool. It reflects the overall return on the investment by taking into account the timing and amount of cash flows.',
  },
  {
    key: 'Spread to Curve',
    label: 'Constant spread above the yield curve matching the Price',
    infoText: 'Constant spread above the yield curve matching the Price',
    explanation:
      'Spread to Curve represents the additional yield that investors expect over a standard yield curve to compensate for additional risk. It is calculated as the constant spread that matches the price.',
  },
  // Not ready yet
  // {
  //   key: 'Spread to Reference',
  //   label: 'IRR spread over reference point on the yield curve',
  //   infoText: 'IRR spread over reference point on the yield curve',
  //   explanation: 'Spread to Reference measures the difference between the IRR of the loan pool and the yield of a comparable risk-free security.'
  // },
  {
    key: 'Duration',
    label: 'Interest rate duration at Price',
    infoText: 'Interest rate duration at Price',
    explanation:
      'Duration measures the sensitivity of the loan pool`s price to changes in interest rates. It represents the weighted average time until the pool`s cash flows are received.',
  },
  {
    key: 'Purchase Dollar Price',
    label: 'Price x UPB',
    infoText: 'Price x UPB',
    explanation:
      'Purchase Dollar Price is the total amount paid to acquire the loan pool, calculated by multiplying the price by the unpaid balance (UPB).',
  },
];

export { NETWORK_ERROR, INFO_BID_SUMMARY_LIST };
