import { useState } from 'react';

import poolSuccessImgPath from 'assets/pngs/carve-confirmation.png';
import { Button } from 'baseui/button';
import { BaseButton, ModalNotStyled, Spinner } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import {
  DialogActions,
  DialogBodyText,
  StyledHeading,
} from 'common-ui/Dialog.styles';
import { PillFilters } from 'features/common-elements';
import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';

import CreatePoolForm, { FormValues } from './CreatePoolForm';
import { useCreatePool } from './hooks/useCreatePool';
import { useLoanLevelData } from './hooks/useLoanLevelData';
import { usePortfolioLoans } from './hooks/usePortfolioLoans';
import { useSelection } from './hooks/useSelection';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import {
  SelectionStatus,
  SelectionStatusLine,
} from '../../../common-ui/SelectionStatusLine';

const LoanLevelData = () => {
  const {
    gqlAssetClass,
    filterProps,
    navigateToPoolManager,
    queryFilters,
    loansSorting,
    setLoansSorting,
  } = useLoanLevelData();

  // Dialogs
  const poolCreatedDialog = useDialog();
  const errorDialog = useDialog();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { createPool, lastCreatedPool } = useCreatePool();

  const { data, loading, previousData, params, updateParams } =
    usePortfolioLoans(gqlAssetClass, queryFilters, loansSorting);

  const {
    selectedLoans,
    setSelectedLoans,
    selectionStatus,
    setSelectionStatus,
    onClear,
    onSelectPage,
    onSelectAll,
  } = useSelection(params, data);

  if (!gqlAssetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const dataToShow = data || previousData;
  const loanCount = dataToShow?.user.company.performanceData.loanCount || 0;

  const potentialPoolSize =
    selectionStatus === SelectionStatus.AllSelected
      ? loanCount
      : Object.keys(selectedLoans).length;

  const onSubmitCreatePoolForm = (formValues: FormValues) => {
    createPool(
      formValues,
      gqlAssetClass,
      queryFilters,
      selectedLoans,
      selectionStatus,
    )
      .then(() => {
        poolCreatedDialog.openDialog();
      })
      .catch((error) => {
        console.error('Error creating pool:', error);
        setErrorMessage(
          'There was an error creating the loan pool. Please retry or contact support.',
        );
        errorDialog.openDialog();
      });
  };

  return (
    <PortfolioLayout
      filterRow={
        <PillFilters
          {...filterProps}
          filtersConfig={filterProps.filtersConfig}
        />
      }
      actions={
        <ModalNotStyled
          trigger={
            <Button disabled={potentialPoolSize === 0}>
              {potentialPoolSize === 0
                ? 'Select loans to create a pool'
                : `Create pool of ${potentialPoolSize.toLocaleString()} loans`}
            </Button>
          }
        >
          {({ closeModal }) => (
            <CreatePoolForm
              closeModal={closeModal}
              createPool={onSubmitCreatePoolForm}
            />
          )}
        </ModalNotStyled>
      }
    >
      {dataToShow ? (
        <>
          <SelectionStatusLine
            status={selectionStatus}
            numberSelectedOnPage={Object.keys(selectedLoans).length}
            totalItems={loanCount}
            onClear={onClear}
            onSelectAll={onSelectAll}
          />
          <LoanTable
            data={dataToShow?.user.company.loans ?? []}
            assetClass={gqlAssetClass}
            strings={{ empty: 'No Loans Found' }}
            selection={{
              selected: selectedLoans,
              onSelectionChange: (newSelection) => {
                setSelectedLoans(newSelection);
                setSelectionStatus(SelectionStatus.None);
              },
              onHeaderCheckboxChange: (checked) => {
                if (checked) {
                  onSelectPage();
                } else {
                  onClear();
                }
              },
            }}
            sorting={{
              state: loansSorting,
              onSortingChanged: setLoansSorting,
            }}
            pagination={{
              updateParams: updateParams,
              queryParams: params,
              totalItems: loanCount,
            }}
          />
        </>
      ) : null}
      <Spinner loading={loading} />

      {/* For debugging, show list of selected account ids */}
      {/* <div>Selected Loans:</div>
      <div>{JSON.stringify(selectedLoans)}</div> */}

      <Dialog dialog={poolCreatedDialog}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={poolSuccessImgPath} alt="bid succeeded" />{' '}
          <StyledHeading style={{ marginTop: '50px' }}>
            Pool Created
          </StyledHeading>
          <DialogBodyText style={{ width: '300px' }}>
            "{lastCreatedPool?.friendlyName}" is now available. Would you like
            to go to the Pool Manager?
          </DialogBodyText>
          <DialogActions style={{ width: '150px' }}>
            <BaseButton
              size="medium"
              type="secondary"
              label="no"
              onClick={() => poolCreatedDialog.closeDialog()}
            >
              No
            </BaseButton>
            <BaseButton
              size="medium"
              label="yes"
              onClick={() => {
                poolCreatedDialog.closeDialog();
                navigateToPoolManager();
              }}
            >
              Yes
            </BaseButton>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog dialog={errorDialog}>
        <>
          <div>{errorMessage}</div>
          <DialogActions>
            <BaseButton
              size="medium"
              label="cancel"
              onClick={() => errorDialog.closeDialog()}
            >
              OK
            </BaseButton>
          </DialogActions>
        </>
      </Dialog>
    </PortfolioLayout>
  );
};

export default LoanLevelData;

//this is the DELETE loan query
// const DELETE_LOAN = gql`
//   mutation DeleteLoan($id: ID!) {
//     deleteLoan(id: $id)
//   }
// `;

// const [deleteLoan, { called, loading: loadingDeleteLoan }] =
//   useMutation<DeleteLoan>(DELETE_LOAN);

// deleteLoan({
//   variables: { id: row.id },
//   update: (cache) => {
//     const normalizedId = cache.identify({
//       id: row.id,
//       __typename: assetClassToTypename(gqlAssetClass),
//     });
//     cache.evict({ id: normalizedId });
//     cache.gc();
//   },
// });
