import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import { FileState } from '__generated__/globalTypes';

import { GetLoanTape, GetLoanTapeVariables } from './__generated__/GetLoanTape';
import { TapesUploadedTableFragments } from './components/TapesUploadedTable';
import MapRequiredFieldsCard, {
  MappingCardFragments,
} from './fieldMapping/MapRequiredFieldsCard';
import LoanTapeUploader from './loanTapeUploader/LoanTapeUploader';
import ProcessingReportCard, {
  ProcessingReportCardFragments,
} from './ProcessingReportCard';

const GET_LOAN_TAPE_QUERY = gql`
  query GetLoanTape($tapeId: ID!) {
    user {
      id
      company {
        id
        loanTape: file(id: $tapeId) {
          id
          ...TapesUploadedTableFile
          ...MappingCardFile
          ...ProcessingReportCardFile
        }
      }
    }
  }
  ${MappingCardFragments.loanTape}
  ${ProcessingReportCardFragments.loanTape}
  ${TapesUploadedTableFragments.loanTape}
`;

export const TapeUploadFlow: React.FC = () => {
  const tapeId = useParams<{ tapeId: string }>().tapeId;

  const { data, loading } = useQuery<GetLoanTape, GetLoanTapeVariables>(
    GET_LOAN_TAPE_QUERY,
    {
      variables: { tapeId: tapeId as string },
      skip: !tapeId,
      pollInterval: 5000,
      fetchPolicy: 'network-only',
    },
  );

  const selectedFile = data?.user.company.loanTape;

  const mapFieldsCardRef = useRef<HTMLDivElement>(null);
  const scrollToMapFieldsCard = () => {
    mapFieldsCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const processingReportCardRef = useRef<HTMLDivElement>(null);

  const allowedStates = [
    FileState.PROCESSED,
    FileState.READY_TO_PERSIST,
    FileState.PERSISTING,
    FileState.PERSISTED,
    FileState.TO_DELETE,
    FileState.DELETING,
    FileState.DELETED,
    FileState.DELETE_ERROR,
    FileState.ERROR_HEADER,
    FileState.ERROR_PASSWORD,
    FileState.ERROR,
  ];

  const shouldShowProcessingReportCard =
    selectedFile &&
    selectedFile.processingReport &&
    !loading &&
    allowedStates.includes(selectedFile.state);

  return (
    <>
      <LoanTapeUploader onClickNext={scrollToMapFieldsCard} />
      <div ref={mapFieldsCardRef} className="relative">
        {selectedFile?.headers && !loading && (
          <MapRequiredFieldsCard selectedFile={selectedFile} />
        )}
      </div>
      <div ref={processingReportCardRef}>
        {shouldShowProcessingReportCard && (
          <ProcessingReportCard selectedFile={selectedFile} />
        )}
      </div>
    </>
  );
};
