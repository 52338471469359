import { useState } from 'react';

import classNames from 'classnames';

interface ToggleButtonProps {
  initialState?: boolean;
  disabled?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  onChange?: (checked: boolean) => void;
}

export function ToggleButton({
  initialState,
  disabled,
  checkedLabel,
  uncheckedLabel,
  onChange,
}: ToggleButtonProps) {
  const [checked, setChecked] = useState(initialState || false);

  return (
    <div className="flex items-center gap-2">
      <label
        className={classNames(
          'relative h-5 w-10 cursor-pointer rounded-full border transition-all duration-300',
          checked
            ? 'border-accent-default bg-accent-default'
            : 'border-pink-50 bg-accent-subtle',
          disabled ? 'cursor-not-allowed bg-accent-muted' : 'cursor-pointer',
        )}
      >
        <input
          type="checkbox"
          disabled={!!disabled}
          defaultChecked={!!checked}
          onChange={() => {
            setChecked(!checked);
            onChange && onChange(!checked);
          }}
          className="hidden"
        />
        <span
          className={classNames(
            'absolute top-[3px] h-3 w-3 rounded-full transition-all duration-300',
            checked
              ? 'left-[calc(100%-4px)] -translate-x-full transform bg-accent-subtle'
              : 'left-[4px] bg-accent-default',
          )}
        ></span>
      </label>
      {checked && checkedLabel && (
        <span className="text-xs font-medium text-foreground-default">
          {checkedLabel}
        </span>
      )}
      {!checked && uncheckedLabel && (
        <span className="text-xs font-medium text-foreground-default">
          {uncheckedLabel}
        </span>
      )}
    </div>
  );
}
