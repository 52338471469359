import { AttachFile } from '@styled-icons/material-outlined';
import prettyBytes from 'pretty-bytes';
import styled from 'styled-components';

const CardBody = styled.div`
  border-top: ${(props) => props.theme.color.borderDefault} 1px solid;
  border-right: ${(props) => props.theme.color.borderDefault} 1px solid;
  border-bottom: ${(props) => props.theme.color.borderDefault} 1px solid;

  display: flex;
  align-items: center;
  padding: 0.75rem;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.color.darkPrimaryLabel};
    left: 0;
  }
`;

const AttachmentIcon = styled(AttachFile)`
  width: 0.875rem;
  margin-right: 0.8rem;
`;

const Name = styled.a`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-right: 0.875rem;

  &,
  &:hover,
  &:visited {
    color: ${(props) => props.theme.color.white};
  }
`;

const Size = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #d9d9d9;
`;

type FileCardProps = {
  name: string;
  size: number;
  url: string;
  className?: string;
};

function FileCardComp({ name, size, url, className }: FileCardProps) {
  return (
    <CardBody className={className}>
      <AttachmentIcon />
      <Name href={url} target="_blank">
        {name}
      </Name>
      <Size>{prettyBytes(size)}</Size>
    </CardBody>
  );
}

export const FileCard = styled(FileCardComp)``;
