import { gql } from '@apollo/client';

export const Form5300AnalysisFragment = gql`
  fragment Form5300Analysis on PrivateCompany {
    id
    form5300LLMAnalysis {
      emergingLendingRisks {
        portfolioGrowthRates
        participationExposures
        increasingDelinquencies
        benchmarkComparisons
        recessionSensitivityAnalysis
      }
      dataDrivenInsights
    }
  }
`;
