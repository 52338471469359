import { FC, useEffect, useState, useRef } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConsumerSecuredLoanSpreadsChart } from 'features/pages/market-data/components';
import { URLMap } from 'features/pages/market-data/shared';

import { useQuery } from '@apollo/client';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';

const queryClient = new QueryClient();

const MarketData: FC = () => {
  const [marketDataMapUrls, setMarketDataMapUrls] = useState<URLMap | null>(
    null,
  );
  const { data, loading } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.marketData) {
      setMarketDataMapUrls(data.marketData.urlMap as unknown as URLMap);
    }
  }, [data, loading]);

  const sliderRef = useRef<HTMLDivElement>(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (sliderRef.current) {
      const width = sliderRef.current.clientWidth - 30;
      const height = width / 1.618;
      setChartDimensions({ width, height });
    }
  }, []);

  return (
    <div ref={sliderRef}>
      <div className="mb-4 flex items-end justify-between">
        <h2 className="font-heebo text-lg font-medium text-white">
          Market Color
        </h2>
      </div>
      <ConsumerSecuredLoanSpreadsChart
        dataUrl={marketDataMapUrls?.['market_charts_data.json']}
        className="!mt-0 w-full rounded-lg border border-slate-700 px-0"
        {...chartDimensions}
      />
    </div>
  );
};

export const MarketColor: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MarketData />
    </QueryClientProvider>
  );
};
