import { Spinner } from 'baseui/spinner';

import { gql } from '@apollo/client';

import { Agency } from '__generated__/globalTypes';

import { CounterpartyBasicInfo } from './__generated__/CounterpartyBasicInfo';

const CounterpartyBasicInfoFragment = {
  company: gql`
    fragment CounterpartyBasicInfo on Company {
      __typename
      type
      ... on PrivateCompany {
        id
        name
        regulatory_number
        regulatory_agency
      }
    }
  `,
};

type CounterpartyCardProps = {
  loading: boolean;
  active: boolean;
  counterparty?: CounterpartyBasicInfo;
};

const CounterpartyDetails = ({
  counterparty,
}: {
  counterparty: CounterpartyBasicInfo;
}) => {
  if (!counterparty) return null;

  const nameSection =
    counterparty.__typename === 'PrivateCompany' ? (
      <div className="flex justify-between border-t border-slate-600 px-3 py-2">
        <span className="text-gray-800">Name:</span>
        <span className="text-slate-100">{counterparty.name}</span>
      </div>
    ) : null;
  const regulatoryNumberSection =
    counterparty.__typename === 'PrivateCompany' ? (
      <div className="flex justify-between border-t border-slate-600 px-3 py-2">
        {counterparty.regulatory_agency !== Agency.NONE && (
          <>
            <span className="text-gray-800">
              {counterparty.regulatory_agency} Regulatory Number:
            </span>
            <span className="text-slate-100">
              {counterparty.regulatory_number}
            </span>
          </>
        )}
      </div>
    ) : null;

  return (
    <div className="min-w-[290px]">
      {nameSection}
      <div className="flex justify-between border-t border-slate-600 px-3 py-2">
        <span className="text-gray-800">Type:</span>
        <span className="text-slate-100">{counterparty.type}</span>
      </div>
      {regulatoryNumberSection}
    </div>
  );
};

const CounterpartyCard = ({
  loading,
  active,
  counterparty,
}: CounterpartyCardProps) => {
  if (loading) {
    return (
      <div className="flex w-full items-center justify-center p-6">
        <Spinner size={34} />
      </div>
    );
  }

  if (!counterparty) {
    return (
      <div className="text-center text-sm text-slate-500">
        No counterparty information available.
      </div>
    );
  }

  return (
    <div className="p-4">
      {active && <CounterpartyDetails counterparty={counterparty} />}
    </div>
  );
};

export { CounterpartyCard, CounterpartyDetails, CounterpartyBasicInfoFragment };
