import React from 'react';

import { IconViewfinder } from '@tabler/icons-react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ModalNotStyled } from 'ui-kit';

import { GetMarketplaceListings_listings } from './__generated__/GetMarketplaceListings';
import RequestAccess from './RequestAccess';

type DealLinkProps = {
  isMyListing: boolean;
  listingHref: string;
  listingId: string;
  listingDetails: GetMarketplaceListings_listings;
  dealId: string | null;
};

const DealLink: React.FC<DealLinkProps> = ({
  isMyListing,
  listingHref,
  listingId,
  listingDetails,
  dealId,
}) => {
  const navigate = useNavigate();

  const handleTriggerClick = (e: React.MouseEvent) => {
    if (!isMyListing) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      navigate(listingHref);
    }
  };

  const Trigger = (
    <div
      onClick={(e) => {
        handleTriggerClick(e);
      }}
      className={clsx(
        'cursor-pointer rounded border-l-2 border-l-slate-700 bg-slate-700 py-2 pl-[6px] pr-2',
        {
          '!border-l-blue-500': isMyListing,
        },
      )}
    >
      <IconViewfinder size={20} className="text-slate-200" />
    </div>
  );

  return (
    <>
      {isMyListing ? (
        Trigger
      ) : (
        <ModalNotStyled trigger={Trigger}>
          {({ closeModal }) => (
            <div className="max-h-[540px] w-full max-w-[985px] overflow-y-auto rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">
                  {listingDetails.name}
                </h2>
                <button
                  className="text-gray-500 hover:text-gray-300"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
              <RequestAccess
                listingId={listingId}
                listingDetails={listingDetails}
                dealId={dealId}
              />
            </div>
          )}
        </ModalNotStyled>
      )}
    </>
  );
};

export default DealLink;
