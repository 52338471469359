import React from 'react';

interface FileUploadProgressBarProps {
  fileName: string;
  progress: number; // progress should be a number between 0 and 100
}

const FileUploadProgressBar: React.FC<FileUploadProgressBarProps> = ({
  fileName,
  progress,
}) => {
  return (
    <div className="flex w-full items-center justify-between text-slate-200">
      <div className="text-slate-400">{fileName}</div>
      <div className="ml-4 w-[158px]">
        <div className="h-2 w-full rounded-full bg-slate-800">
          <div
            className="h-full rounded-full bg-slate-400"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <div className="ml-4 text-slate-400">{progress}%</div>
    </div>
  );
};

export default FileUploadProgressBar;
