import { useCallback, useEffect, useMemo, useState } from 'react';

import { Criteria } from 'features/pages/marketplace/TradeCarveManager/CreateCarveForm';
import {
  convertFiltersToCriteria,
  criteriaToFilters,
} from 'features/pages/marketplace/TradeCarveManager/criteriaToFilterMapper';
import _ from 'lodash';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  CreateDealCarve,
  CreateDealCarveVariables,
} from 'mutation/__generated__/CreateDealCarve';
import {
  DeleteDealCarve,
  DeleteDealCarveVariables,
} from 'mutation/__generated__/DeleteDealCarve';
import {
  UpdateDealCarve,
  UpdateDealCarveVariables,
} from 'mutation/__generated__/UpdateDealCarve';
import { CREATE_DEAL_CARVE } from 'mutation/createDealCarve';
import { DELETE_DEAL_CARVE } from 'mutation/deleteDealCarve';
import { UPDATE_DEAL_CARVE } from 'mutation/updateDealCarve';

import { GET_CARVES } from 'query/getCarves';

import { CarveSpecification } from './types';
import { GET_DEAL_TIMELINE } from '../../fragments';
import {
  GetCarve,
  GetCarveVariables,
} from '../CarveDiffTable/__generated__/GetCarve';
import { GET_CARVE } from '../CarveDiffTable/fragments';
import { CarveDetailsCard_CarveDetailsCard_carves as Carves } from './__generated__/CarveDetailsCard';

const useCarveFunctions = (dealId: string) => {
  const refetchQueries = useMemo(
    () => [
      {
        query: GET_DEAL_TIMELINE,
        variables: { id: dealId },
      },
      {
        query: GET_CARVES,
        variables: { deal_id: dealId },
      },
    ],
    [dealId],
  );

  const [createDealCarve, { loading: createLoading, called: createCalled }] =
    useMutation<CreateDealCarve, CreateDealCarveVariables>(CREATE_DEAL_CARVE, {
      refetchQueries,
    });

  const [updateDealCarve] = useMutation<
    UpdateDealCarve,
    UpdateDealCarveVariables
  >(UPDATE_DEAL_CARVE, {
    refetchQueries,
  });

  const [deleteDealCarve, { loading: deleteLoading, called: deleteCalled }] =
    useMutation<DeleteDealCarve, DeleteDealCarveVariables>(DELETE_DEAL_CARVE, {
      refetchQueries,
    });

  return {
    createDealCarve,
    createLoading,
    createCalled,
    updateDealCarve,
    deleteDealCarve,
    deleteLoading,
    deleteCalled,
  };
};

type UseCarveFunctions = ReturnType<typeof useCarveFunctions>;

const useCarveSpecs = (allCarves: Carves[]) => {
  // Offer carve is a special carve that is not editable. We show a default card for it
  // This logic will break if the user saves a carve with the name 'Offer'
  const offerCarve = _.find(allCarves, (carve) => carve.name === 'Offer');
  const carves = useMemo(
    () => allCarves.filter((carve) => carve.id !== offerCarve?.id),
    [offerCarve, allCarves],
  );

  const [carveSpecifications, setCarveSpecifications] = useState<
    CarveSpecification[]
  >([]);
  useEffect(() => {
    const carveIdsFromProps = carves.map((carve) => carve.id);
    setCarveSpecifications((currentCarveSpecs) =>
      currentCarveSpecs.filter((spec) => !carveIdsFromProps.includes(spec.id)),
    );
  }, [carves]);

  const [updatingCarves, setUpdatingCarves] = useState<string[]>([]);
  const [deletingCarves, setDeletingCarves] = useState<string[]>([]);
  useEffect(
    () =>
      setCarveSpecifications((carves) =>
        carves.filter((carve) => !deletingCarves.includes(carve.id)),
      ),
    [deletingCarves],
  );

  const serverCarves = useMemo(
    () =>
      (carves || []).map((carve) => ({
        id: carve.id,
        name: carve.name,
        criteria: convertFiltersToCriteria(carve.carve || []),
        performanceSummary: carve.carve_summary,
        isDraft: false,
        isUpdating: updatingCarves.includes(carve.id),
        isDeleted: deletingCarves.includes(carve.id),
      })),
    [carves, updatingCarves, deletingCarves],
  );

  const updatingCarveSpecifications = useMemo(
    () =>
      carveSpecifications.map((carve) => ({
        ...carve,
        isUpdating: updatingCarves.includes(carve.id),
      })),
    [carveSpecifications, updatingCarves],
  );

  const allCarveSpecs = useMemo(
    () => _.uniqBy([...updatingCarveSpecifications, ...serverCarves], 'id'),
    [updatingCarveSpecifications, serverCarves],
  );

  const [fetchCarveData] = useLazyQuery<GetCarve, GetCarveVariables>(GET_CARVE);

  const updateCarveState = (
    id: string,
    updateData: Partial<CarveSpecification>,
  ) => {
    setCarveSpecifications((carves) =>
      carves.map((carve) => {
        if (carve.id === id) {
          return {
            ...carve,
            ...updateData,
          };
        }
        return carve;
      }),
    );
  };
  const updatePerformanceSummary = useCallback(
    async (dealId: string, id: string, criteria: Criteria[]) => {
      const response = await fetchCarveData({
        variables: {
          id: dealId,
          filters: criteriaToFilters(criteria),
        },
      });

      const carveSummary = response.data?.deal?.performance_summary;
      if (carveSummary) {
        updateCarveState(id, {
          criteria: criteria,
          performanceSummary: carveSummary,
          isUpdating: false,
        });
      }
    },
    [fetchCarveData],
  );

  const createDraftCarve = useCallback(
    async (dealId: string, id: string, name: string, criteria: Criteria[]) => {
      const newCarveSpec = {
        id: id,
        name: name,
        criteria: criteria,
        performanceSummary: null,
        isDraft: true,
        isUpdating: true,
        isDeleted: false,
      };
      setCarveSpecifications((carves) => [newCarveSpec, ...carves]);
      await updatePerformanceSummary(dealId, id, criteria);
    },
    [updatePerformanceSummary],
  );

  const handleCarveUpdateLogic = useCallback(
    (carveSpec: CarveSpecification, callback: () => Promise<unknown>) => {
      setUpdatingCarves((prevCarves) => [...prevCarves, carveSpec.id]);
      callback().then(() => {
        setUpdatingCarves((prevCarves) =>
          prevCarves.filter((id) => id !== carveSpec.id),
        );
      });
    },
    [],
  );

  const handleCarveDeleteLogic = useCallback(
    (carveId: string, callback: () => Promise<unknown>) => {
      setDeletingCarves((prevCarves) => [...prevCarves, carveId]);
      callback().then(() => {
        setDeletingCarves((prevCarves) =>
          prevCarves.filter((id) => id !== carveId),
        );
      });
    },
    [],
  );

  return {
    allCarveSpecs,
    createDraftCarve,
    updateCarveState,
    handleCarveUpdateLogic,
    handleCarveDeleteLogic,
    setDeletingCarves,
    updatePerformanceSummary,
  };
};

export type { UseCarveFunctions };

export { useCarveFunctions, useCarveSpecs };
