import LoanDatatableFragments from 'features/drilldown/LoanDatatable/fragments';
import { StratificationsFragments } from 'features/drilldown/Stratifications';
import { PoolHeaderFragments } from 'features/loanPool/PoolHeader';
import { PoolSummaryFragments } from 'features/loanPool/PoolSummary';

import { gql } from '@apollo/client';

const GET_DEAL_QUERY = gql`
  query GetDeal(
    $id: ID!
    $sort: Sort!
    $pagination: Pagination!
    $filters: [Filter!]
  ) {
    deal(id: $id) {
      id
      carvingEnabled
      state {
        status
        blocker
        loanDataVisible
        label
        tooltip
      }
      seller {
        ... on PrivateCompany {
          id
        }
        ... on UserCompany {
          id
        }
      }
      createdDate: created_date
      listing {
        id
        ...PoolHeaderListing
        performanceData: performance_summary {
          ...PoolSummaryPerformanceSummary
        }
      }
      loans(sort: $sort, pagination: $pagination, filters: $filters) {
        id
        ...LoanDatatableLoan
      }
      performanceData: performance_summary(filters: $filters) {
        ...LoanDatatablePerformanceSummary
        ...PoolSummaryPerformanceSummary
        ...StratificationsPerformanceSummary
      }
      ...PoolHeaderDeal
    }
  }
  ${LoanDatatableFragments.loan}
  ${LoanDatatableFragments.performanceSummary}
  ${PoolHeaderFragments.listing}
  ${PoolHeaderFragments.deal}
  ${PoolSummaryFragments.performanceSummary}
  ${StratificationsFragments.performanceSummary}
`;

const GET_DEAL_LOAN_SPREADSHEET_URL = gql`
  query GetDealLoanSpreadsheetUrl($id: ID!) {
    deal(id: $id) {
      id
      loans_spreadsheet_url
    }
  }
`;

export { GET_DEAL_QUERY, GET_DEAL_LOAN_SPREADSHEET_URL };
