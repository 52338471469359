import { VFC } from 'react';

import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'style/ORSNNTheme';

export const Badge = styled.div`
  background: #e85ee5;
  /* theme/bg/canvas */

  border: 1px solid #171717;
  /* Shadow/Theme/20 */

  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.9),
    0px 1px 3px rgba(13, 13, 13, 0.9);
  border-radius: 100px;
  height: 24px;
  width: 24px;
  position: absolute;
  left: -5px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageBadge: VFC = () => {
  return (
    <Badge>
      <FontAwesomeIcon icon={faCommentDots} color="#171717" />
    </Badge>
  );
};
