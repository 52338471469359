//this is here until they fix the exported types
//https://github.com/react-component/slider/issues/835
//https://github.com/react-component/slider/issues/891
import { ForwardedRef, forwardRef, useMemo } from 'react';
import React from 'react';

import { uniqueId, debounce } from 'lodash';
import RcSlider, { SliderProps, SliderRef } from 'rc-slider/lib/Slider';
import { ITooltip } from 'react-tooltip';
import { styled } from 'style/ORSNNTheme';

import { getTooltipRender } from './SliderHandleTooltip';

type TipFormatter = (value: number) => React.ReactNode;
export const RangeSlider = forwardRef(
  (
    props: SliderProps<number[]> & {
      withTooltip?: boolean;
      alwaysShowTooltip?: boolean;
      tooltip?: {
        tipFormatter?: TipFormatter;
        tipProps?: ITooltip;
      };
      minMaxFormatter?: (value: number | undefined) => string;
    },
    ref: ForwardedRef<SliderRef>,
  ) => {
    const {
      withTooltip,
      tooltip,
      alwaysShowTooltip = false,
      ...sliderProps
    } = props;

    const sliderUniqueId = useMemo(() => uniqueId('slider-'), []);

    const tipRender = useMemo(
      () =>
        withTooltip
          ? getTooltipRender(
              sliderUniqueId,
              tooltip?.tipFormatter,
              tooltip?.tipProps,
              alwaysShowTooltip,
            )
          : undefined,
      [
        withTooltip,
        tooltip?.tipFormatter,
        tooltip?.tipProps,
        sliderUniqueId,
        alwaysShowTooltip,
      ],
    );

    const debouncedOnChange = useMemo(
      () => (props.onChange ? debounce(props.onChange, 100) : undefined),
      [props.onChange],
    );

    // as SliderProps<number[] | number> is temp, until the type of Slider is fixed.
    return (
      <StyledSliderContainer id={sliderUniqueId}>
        {props.minMaxFormatter ? props.minMaxFormatter(props.min) : props.min}
        <StyledSlider
          {...(sliderProps as SliderProps<number[] | number>)}
          ref={ref}
          range={true}
          handleRender={tipRender}
          allowCross={false}
          onChange={
            debouncedOnChange as SliderProps<number[] | number>['onChange']
          }
        />
        {props.minMaxFormatter ? props.minMaxFormatter(props.max) : props.max}
      </StyledSliderContainer>
    );
  },
);

const StyledSlider = styled(RcSlider)`
  .rc-slider-rail {
    background-color: ${(props) => props.theme.color.borderDefault};
  }

  .rc-slider-track {
    background-color: ${(props) => props.theme.color.brandMainPink};
  }

  .rc-slider-handle {
    border: solid 2px ${(props) => props.theme.color.brandMainPink};
    background-color: ${(props) => props.theme.color.brandMainPink};
  }
`;

const StyledSliderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  color: ${(props) => props.theme.color.fgDefault};
`;
