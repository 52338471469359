import { FC, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useQuery } from '@apollo/client';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';

import {
  USTHistory,
  USTYieldCurve,
  CorporateSpreads,
  MacroChart,
  MortgageCreditCurveChart,
  ConsumerSecuredLoanSpreadsChart,
  ConsumerUnsecuredLoanSpreadsChart,
} from './components';
import { URLMap } from './shared';

const queryClient = new QueryClient();

const MarketData: FC = () => {
  const [marketDataMapUrls, setMarketDataMapUrls] = useState<URLMap>(null);
  const { data, loading } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.marketData) {
      setMarketDataMapUrls(data.marketData.urlMap as unknown as URLMap);
    }
  }, [data, loading]);

  return (
    <div style={{ gridArea: 'page' }}>
      <div className="grid grid-cols-1 gap-2 p-4 lg:grid-cols-2">
        <USTHistory dataUrl={marketDataMapUrls?.['market_charts_data.json']} />
        <CorporateSpreads
          dataUrl={marketDataMapUrls?.['market_charts_data.json']}
        />
        <ConsumerSecuredLoanSpreadsChart
          dataUrl={marketDataMapUrls?.['market_charts_data.json']}
        />
        <ConsumerUnsecuredLoanSpreadsChart
          dataUrl={marketDataMapUrls?.['market_charts_data.json']}
        />
        <USTYieldCurve dataUrl={marketDataMapUrls?.['yc_chart_data.json']} />
        <MortgageCreditCurveChart
          dataUrl={marketDataMapUrls?.['mortgage_credit_curve_chart_data.json']}
        />
        <MacroChart dataUrl={marketDataMapUrls?.['macro_charts_data.json']} />
      </div>
    </div>
  );
};

export const MarketDataPage: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MarketData />
    </QueryClientProvider>
  );
};
