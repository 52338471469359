import { Subheader } from 'ui-kit';

import { SettlementCard_SettlementCard } from './__generated__/SettlementCard';
import TransactionDocuments from './TransactionDocuments';

type SettlementCardBodyProps = {
  dealId: string;
} & SettlementCard_SettlementCard;

const SettlementCardBody: React.FC<SettlementCardBodyProps> = (props) => {
  return (
    <div className="flex flex-col gap-6">
      <Subheader>{`Review ${props.role === 'SELLER' ? 'and Upload' : ''} Final Versions of the Settlement Documents`}</Subheader>
      <TransactionDocuments
        dealId={props.dealId}
        role={props.role}
        isCompleted={props.is_complete}
      />
    </div>
  );
};

export default SettlementCardBody;
export { default as SettlementCardFragments } from './fragments';
