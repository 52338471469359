import { useState } from 'react';

import { RadioButton } from 'common-ui/form/RadioButtonField';

import { DealRole, NoteStatus } from '__generated__/globalTypes';

import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';

import AddNoteIcon from './AddNoteIcon';
import { toCollateralStatus } from './DiligenceController';
import {
  FileDiligenceStatus,
  FileStatusDropdown,
  fromCollateralStatus,
} from './FileStatus';

interface DocumentsTableProps {
  role: DealRole;
  collateral?: Collateral[];
  selectedDocId?: string;
  onDocSelected: (docId: string) => void;
  handleNoteClick: (docId: string) => void;
  onFileStatusSelect?: (
    dealId: string,
    loanId: string,
    fileName: string,
    status: FileDiligenceStatus,
    callback?: () => void,
  ) => void;
  hasNote: (docId: string) => boolean;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  role,
  collateral,
  selectedDocId,
  onDocSelected,
  handleNoteClick,
  onFileStatusSelect,
  hasNote,
}) => {
  // TODO: temporary solution that should be fixed during controller refactoring
  const [localStatus, setLocalStatus] = useState<
    Record<string, FileDiligenceStatus>
  >({});

  return (
    <div className="h-60 overflow-y-auto rounded-md border border-gray-900 bg-slate-900">
      {collateral && collateral.length === 0 && <div>No documents found</div>}
      {collateral && collateral.length > 0 && (
        <table className="w-full border-collapse text-slate-400">
          <thead className="text-left font-bold">
            <tr>
              <th></th>
              <th></th>
              <th>Index</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {collateral.map((doc, idx) => {
              const isSelected = selectedDocId === doc.collateralID;
              const status =
                localStatus[doc.collateralID] !== undefined
                  ? toCollateralStatus(localStatus[doc.collateralID])
                  : doc.collateralStatus;

              return (
                <tr
                  key={doc.collateralID}
                  aria-selected={isSelected ? 'true' : 'false'}
                  className={`${
                    isSelected ? 'bg-purple-500 text-white' : 'bg-transparent'
                  } cursor-pointer even:bg-slate-800 hover:bg-purple-700`}
                  onClick={() => onDocSelected(doc.collateralID)}
                >
                  <td className="w-8 pl-2">
                    <RadioButton
                      label=""
                      name=""
                      value=""
                      checked={isSelected}
                      onChange={() => onDocSelected(doc.collateralID)}
                    />
                  </td>
                  <td>
                    {hasNote(doc.collateralID) && (
                      <AddNoteIcon
                        onNoteClick={() => handleNoteClick(doc.collateralID)}
                        noteStatus={NoteStatus.NONE}
                      />
                    )}
                  </td>
                  <td>{idx + 1}</td>
                  <td>
                    <span className="text-slate-50">{doc.fileName}</span>
                  </td>
                  <td className="relative w-56">
                    {role === DealRole.BUYER && (
                      <FileStatusDropdown
                        value={status} // TODO: temporary solution that should be fixed during controller refactoring
                        onChange={(option) =>
                          onFileStatusSelect &&
                          onFileStatusSelect(
                            doc.dealID,
                            doc.loanID,
                            doc.fileName,
                            option,
                            () =>
                              setLocalStatus((prev) => ({
                                ...prev,
                                [doc.collateralID]: option,
                              })),
                          )
                        }
                        isInSelectedRow={isSelected}
                      />
                    )}
                    {role === DealRole.SELLER && (
                      <div>{fromCollateralStatus(doc.collateralStatus)}</div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DocumentsTable;
