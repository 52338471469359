import { FloatingOverlay } from '@floating-ui/react';
import styled from 'styled-components';

import loadAnim from '../../assets/dna-load-anim.gif';
import successIcon from '../../assets/pngs/check-circle.png';

export const Overlay = styled(FloatingOverlay)`
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
`;

export const OverlayContent = styled.div`
  width: 422px;
  height: 371px;
  background-color: ${(props) => props.theme.color.bgSurface};
  padding: 15px;
  border-radius: 6px;
  box-shadow:
    0px 16px 24px 0px rgba(13, 13, 13, 0.9),
    0px 0px 1px 0px #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
`;

export const LoadAnim = styled.div`
  background-image: url(${loadAnim});
  background-size: 300px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 100%;
`;

export const SuccessIcon = styled.div`
  background-image: url(${successIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 62px;
  height: 100%;
`;

export const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-top: -12px;
  margin-bottom: 13px;
`;

export const Description = styled.div`
  color: #fff;
  margin-bottom: 37px;
`;

export const ProgressWrapper = styled.div`
  width: 224px;
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: 41px;
  width: 222px;

  button {
    width: 100%;
    height: 36px;
    justify-content: center;
  }

  .c-button--primary {
    font-weight: bold;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 128px;
`;
