import { DocumentDetailsFragments } from 'features/pages/portfolio/DocumentLibrary/DocumentDetails.fragments';

import { gql } from '@apollo/client';

const DealCarve = gql`
  fragment BidStipulationsDealCarve on DealCarve {
    id
    stipulations {
      bid_basis_points
      is_servicing_retained
      servicing_rate
      has_diligence_completion_date_seconds
      diligence_completion_date_seconds
      has_settlement_date_seconds
      settlement_date_seconds
      has_percent_collateral_diligence
      percent_collateral_diligence
      has_min_net_yield_to_buyer
      min_net_yield_to_buyer
      custom_stipulations
    }
  }
`;

const BidStipulationFragments = {
  DealCarve,
  PlaceBidCard: gql`
    fragment BidStipulationsPlaceBidCard on PlaceBidCard {
      is_servicing_negotiable
      servicing_fee_rate
      servicing_preference
      documents {
        id
        ...TermsDocument
      }
      carves {
        id
        ...BidStipulationsDealCarve
      }
    }
    ${DocumentDetailsFragments.termsDocument}
    ${DealCarve}
  `,
};

const UPDATE_CARVE_STIPULATIONS = gql`
  mutation UpdateCarveStipulation(
    $carveId: ID!
    $fieldName: StipulationFieldName!
    $value: String!
  ) {
    updateCarveStipulation(
      input: { carve_id: $carveId, field_name: $fieldName, value: $value }
    ) {
      id
      ...BidStipulationsDealCarve
    }
  }
  ${DealCarve}
`;

const TOGGLE_CARVE_STIPULATION = gql`
  mutation ToggleCarveStipulation(
    $carveId: ID!
    $fieldName: StipulationToggleName!
    $value: Boolean!
  ) {
    toggleCarveStipulation(
      input: { carve_id: $carveId, field_name: $fieldName, value: $value }
    ) {
      id
      ...BidStipulationsDealCarve
    }
  }
  ${DealCarve}
`;

const UPDATE_CUSTOM_STIPULATIONS = gql`
  mutation UpdateCustomStipulations(
    $carveId: ID!
    $customStipulations: [String!]!
  ) {
    updateCustomStipulations(
      input: { carve_id: $carveId, custom_stipulations: $customStipulations }
    ) {
      id
      ...BidStipulationsDealCarve
    }
  }
  ${DealCarve}
`;

export default BidStipulationFragments;
export {
  TOGGLE_CARVE_STIPULATION,
  UPDATE_CARVE_STIPULATIONS,
  UPDATE_CUSTOM_STIPULATIONS,
};
