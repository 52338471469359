import CarveDiffTableFragments from 'features/deals/DealStages/EventActionCards/CarveDiffTable/fragments';

import { gql } from '@apollo/client';

export const UPDATE_DEAL_CARVE = gql`
  mutation UpdateDealCarve($input: UpdateDealCarveInput!) {
    updateDealCarve(input: $input) {
      id
      carve_summary {
        ...CarveSummary
      }
    }
  }
  ${CarveDiffTableFragments.PerformanceSummary}
`;
